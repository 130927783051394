<template>
  <form id="contact-form" class="mb-4 md:flex md:flex-wrap md:justify-between text-black" @submit="formSubmissionHandler">
    <p class="text-sm tracking-wide flex flex-col mb-4 w-full text-black">* denotes a required field</p>
    <div class="flex flex-col mb-4 md:w-1/2 pr-4">
      <label class="mb-2 text-lg tracking-wide text-black" for="first_name">First Name*</label>
      <input :class="hasErrors ? 'border py-2 px-3 md:mr-2 border-red text-black' : 'border py-2 px-3 md:mr-2 text-black'"
             type="text"
             name="first_name" id="first_name">
      <p v-if="errors['first_name']" class="text-red-500">{{ errors.first_name }}</p>
    </div>
    <div class="flex flex-col mb-4 md:w-1/2 pr-4">
      <label class="mb-2 text-lg md:ml-2 tracking-wide text-black" for="last_name">Last Name*</label>
      <input :class="hasErrors ? 'border py-2 px-3 md:mr-2 border-red' : 'border py-2 px-3 md:mr-2'"
             type="text"
             name="last_name" id="last_name">
      <p v-if="errors['last_name']" class="text-red-500">{{ errors.last_name }}</p>
    </div>
    <div class="flex flex-col mb-4 md:w-1/2 pr-4">
      <label class="mb-2 text-lg tracking-wide text-black" for="email">Email*</label>
      <input :class="hasErrors ? 'border py-2 px-3 md:mr-2 border-red' : 'border py-2 px-3 md:mr-2'"
             type="email" name="email" id="email">
      <p v-if="errors['email']" class="text-red-500">{{ errors.email }}</p>
    </div>
    <div class="flex flex-col mb-4 md:w-1/2 pr-4">
      <label class="mb-2 text-lg tracking-wide text-black" for="contact-number">Contact Number</label>
      <input :class="hasErrors ? 'border py-2 px-3 md:mr-2 border-red' : 'border py-2 px-3 md:mr-2'"
             type="text"
             name="contact_number" id="contact-number">
      <p v-if="errors['contact_number']" class="text-red-500">{{ errors.contact_number }}</p>
    </div>
    <div class="flex flex-col mb-4 md:w-1/2 pr-4">
      <label class="mb-2 text-lg text-black" for="company">Company*</label>
      <input :class="hasErrors ? 'border py-2 px-3 md:mr-2 border-red' : 'border py-2 px-3 md:mr-2'"
             type="text"
             name="company" id="company">
      <p v-if="errors['company']" class="text-red-500">{{ errors.company }}</p>
    </div>
    <div class="flex flex-col mb-4 md:w-1/2 pr-4">
      <label class="mb-2 text-lg tracking-wide text-black" for="contact-time">Contact Time</label>
      <input :class="hasErrors ? 'border py-2 px-3 md:mr-2 border-red' : 'border py-2 px-3 md:mr-2'"
             type="text"
             name="contact_time" id="contact-time">
      <p v-if="errors['contact_time']" class="text-red-500">{{ errors.contact_time }}</p>
      <p class="text-sm tracking-wide">When is the most convenient time to contact you?</p>
    </div>
    <div class="flex flex-col mb-4 md:w-full">
      <label class="mb-2 text-lg tracking-wide text-black" for="contact-time">Products</label>
      <input :class="hasErrors ? 'border py-2 px-3 md:mr-2 border-red' : 'border py-2 px-3 md:mr-2'"
             type="text"
             name="products" id="products">
      <p v-if="errors['products']" class="text-red-500">{{ errors.products }}</p>
      <p class="text-sm tracking-wide">Which products are you interested in?</p>
    </div>
    <div class="flex flex-col mb-4 md:w-full">
      <label class="mb-2 text-lg tracking-wide text-black" for="email"><input type="checkbox" name="privacy"
                                                                              id="privacy"/>
        Check to agree to our <a href="https://novacyt.com/privacy-policy" class="underline">Privacy
          Policy</a>
      </label>
      <p v-if="errors['privacy']" class="text-red-500">{{ errors.privacy }}</p>
    </div>
    <div class="flex flex-col md:w-full">
      <p class="text-sm text-black">Subject to availability</p>
    </div>
    <div class="flex flex-col mb-4 md:w-full">
      <vue-recaptcha  siteKey="6LePX0EcAAAAAM8fnwGjwP8JCeCnuJnB40VjVDTZ"
                      size="normal"
                      theme="light"
                      :tabindex="0"
                      @verify="recaptchaVerified"
                      @expire="recaptchaExpired"
                      @fail="recaptchaFailed"
                      ref="vueRecaptcha">
      </vue-recaptcha>
    </div>


    <button class="bg-b-orange-mid hover:bg-b-orange-mid text-white font-bold py-2 px-4 text-center tracking-wide rounded" type="submit">
      Submit
    </button>
  </form>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
export default {
  name: "contactFormElement",
  data() {
    return {
      hasErrors: false,
      formSuccess: false,
      errors: {},
      canSubmit : false,
      captchResponse: null,
    }
  },
  components: {
    vueRecaptcha
  },
  methods: {
    recaptchaVerified(response) {
      console.log(response)
      this.canSubmit = true;
      this.captchResponse = response.data;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.$refs.vueRecaptcha.reset();
    },
    formSubmissionHandler(e) {
      e.preventDefault();
      const formElement = e.target,
          method = 'post',
          body = new FormData(formElement);

      this.formSuccess = false;
      this.hasErrors = false;
      let that = this;
      this.errors = [];

      fetch('/admin/wp-json/contact-form-7/v1/contact-forms/185/feedback', {
        method,
        body
      })
          .then((response) => response.json())
          .then((response) => this.normalizeContactForm7Response(response))
          .then(() => {
            console.log('running');
            that.formSuccess = true;
            that.$gtag.event('conversion', {'send_to': 'AW-319444870/fawVCOyJs-cCEIavqZgB'});
            that.$emit('complete')
            formElement.reset();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    normalizeContactForm7Response(response) {
      const isSuccess = response.status === 'mail_sent';
      const message = response.message;
      const validationError = isSuccess
          ? {}
          : // We transform an array of objects into an object
          Object.fromEntries(
              response.invalid_fields.map((error) => {
                // Extracts the part after "cf7-form-control-wrap"
                const key = /cf7[-a-z]*.(.*)/.exec(error.into)[1];

                return [key, error.message];
              })
          );
      return {
        isSuccess,
        message,
        validationError,
      };
    }
  },
}
</script>

<style scoped>

</style>