<template>
  <div class=" mt-4 bg-b-orange-mid hover:bg-b-orange-mid text-white font-bold py-2 px-4 text-center rounded cursor-pointer inline-block md:text-xl" @click="open = true">Watch Video</div>
  <transition name="modal-fade" v-if="open">
    <div class="fixed z-100 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" @click="open = false"></div>
        </div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
        >&#8203;</span
        >
        <div
            class="mx-6 z-50 w-full mt-24 self-center inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
          <div class="bg-white px-2 py-2  h-screen-75 z-200">
            <div
                class="relative h-0 overflow-hidden max-w-full w-full"
                style="padding-bottom: 56.25%"
            >
              <iframe
                  src="https://www.youtube-nocookie.com/embed/lQTrAmpZqyg"
                  frameborder="0"
                  allowfullscreen
                  class="absolute top-0 left-0 w-full h-full"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "documentary",
  data(){
    return {
      open:false,
    }
  }
}
</script>

<style scoped>

</style>