<template>
  <div class="bg-gray-300 p-8">
    <div class="container  pt-12">
      <div class="md:flex flex-wrap">
        <div class="md:w-7/12 mx-auto">
          <h3 class="text-4xl text-b-orange-mid mb-4">Contact us to discuss your free sample today!</h3>
          <contact-form-element @complete="formSent"></contact-form-element>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContactFormElement from "../contactFormElement";
export default {
  name: 'footer',
  components: {ContactFormElement},
  methods:{
    formSent(){
      this.$router.push('/success');
    }
  }
}
</script>