<template>

  <div class="fixed top-8 right-8 z-50 flex justify-center">
    <div class="hidden md:block mr-4">
      <contact-form> <template v-slot:button-text>Enquire now</template></contact-form>
    </div>

    <translate :colour="colour"></translate>

    <a target="_blank" href="https://www.novacyt.com "
       class="hidden md:flex text-xl md:text-3xl tracking-wide  items-center mr-4" :style="'color:'+colour">
      Novacyt
    </a>

    <a class="text-white flex md:hidden z-50">
      <button class="text-gray-500 w-10 h-10 relative focus:outline-none bg-white" @click="openMenu">
        <span class="sr-only">Open main menu</span>
        <div class="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
          <span aria-hidden="true"
                class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
                :class="{'rotate-45': open,' -translate-y-1.5': !open }"></span>
          <span aria-hidden="true"
                class="block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out"
                :class="{'opacity-0': open } "></span>
          <span aria-hidden="true"
                class="block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out"
                :class="{'-rotate-45': open, ' translate-y-1.5': !open}"></span>
        </div>
      </button>
    </a>



    <router-link v-if="$route.name !== 'about.view'"
                 class="hidden md:flex  text-xl md:text-3xl tracking-wide  items-center" :style="'color:'+colour"
                 :to="{name : 'about.view'}">
      <div class="mr-2 mb-1">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"/>
        </svg>
      </div>
      About
    </router-link>

    <router-link v-else class="hidden md:flex text-xl md:text-3xl tracking-wide items-center" :style="'color:'+colour"
                 :to="{name : 'home'}">

      <div class="mr-2 mb-1">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"/>
        </svg>
      </div>
      Back
    </router-link>

  </div>

  <div
      class="fixed w-full h-screen z-40 bg-b-blue-darkest text-white flex text-center flex-wrap justify-items-center content-center items-center md:hidden"
      v-show="open">

    <a target="_blank" href="https://www.novacyt.com " class="text-4xl block w-full mb-4">
      Novacyt
    </a>

    <router-link v-if="$route.name !== 'about.view'" class="text-4xl block w-full mb-4"
                 :to="{name : 'about.view'}">
      About
    </router-link>


    <router-link v-else class="text-4xl block w-full mb-4"
                 :to="{name : 'home'}">
      Back
    </router-link>

    <div class="w-full text-2xl text-center">
      <contact-form> <template v-slot:button-text>Enquire now</template></contact-form>
    </div>
  </div>


</template>
<script>
import Translate from "../translate";
import ContactForm from "../form";

export default {
  name: 'about',
  components: {ContactForm, Translate},
  props: ['colour'],
  data: function () {
    return {
      open: false,
      setColour: 'white'
    }
  },
  mounted() {
    this.setColour = this.colour;
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    openMenu(){
      this.open = !this.open;
      if(this.open){
        this.setColour = 'white'
        this.$emit('colourChange', 'open')
      }else{
        this.setColour = this.colour;
        this.$emit('colourChange','close')
      }
    }
  },
  watch: {
    '$route' () {
      this.open =false;
    }
  },
}
</script>