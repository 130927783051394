<template>
  <router-link class="w-36 md:w-48 fixed top-8 left-8 z-50 " :to="{name : 'home'}">
    <svg id="logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 459 122" style="enable-background:new 0 0 459 122;" xml:space="preserve">
            <g>
                <path :fill="colour" d="M115.1,106.8h7.4v7.4c0,2.3-3.5,3.9-6.7,3.9h-0.6c-7.3,0-9.9-7.1-9.9-13.2c0-6.1,2.6-13.2,9.9-13.2h0.6
                      c2.2,0,4.2,0.7,5.8,2l1,0.8l2.9-2.6l-1.4-1.1c-2.2-1.8-5.1-2.8-8.3-2.8h-0.6c-10.2,0-13.8,9.1-13.8,17c0,7.8,3.6,17,13.8,17h0.6
                      c5.1,0,10.5-2.7,10.5-7.7V103h-11.2V106.8z"/>
              <path :fill="colour" d="M182.4,97.7c0-5.5-4.1-9.4-10-9.4H160v33.2h3.9v-14.4h8.5c2.8,0,5.6,1.7,5.6,5.6v8.7h3.9v-8.7
                      c0-3-1.3-5.6-3.4-7.4C180.2,104.1,182.4,101.8,182.4,97.7z M178.5,97.7c0,3.4-2.4,5.6-6.1,5.6h-8.5V92h8.5
                      C176.1,92,178.5,94.2,178.5,97.7z"/>
              <path :fill="colour" d="M229.5,87.9c-10.2,0-13.8,9.1-13.8,17c0,7.8,3.6,17,13.8,17c10.2,0,13.8-9.2,13.8-17
                      C243.4,97,239.8,87.9,229.5,87.9z M239.4,104.8c0,6.1-2.6,13.2-9.9,13.2s-9.9-7.1-9.9-13.2c0-6.1,2.6-13.2,9.9-13.2
                      S239.4,98.8,239.4,104.8z"/>
              <path :fill="colour" d="M296.9,107.8c0,4.7-1.3,10.3-7.4,10.3h-1.2c-6.1,0-7.4-5.6-7.4-10.3V88.3h-3.9v19.5c0,8.9,4.1,14.1,11.2,14.1
                      h1.2c7.1,0,11.2-5.1,11.2-14.1V88.3h-3.9V107.8z"/>
              <path :fill="colour" d="M347.2,88.3h-12.8v33.2h3.9v-13.6h8.9c5.8,0,10-4.1,10-9.8S353,88.3,347.2,88.3z M353.3,98.1
                      c0,3.5-2.5,6-6.1,6h-8.9V92h8.9C350.7,92,353.3,94.6,353.3,98.1z"/>
              <path :fill="colour" d="M31.2,39.2L9.6,3.5C8.5,1.6,6.8,0.6,4.9,0.6C2.1,0.6,0,2.7,0,5.6v47.5h7.5V14.5C15,26.8,28.9,49.8,29.2,50.3
                      c1.1,1.8,2.8,2.9,4.7,2.9c2.8,0,4.9-2.2,4.9-5V0.6h-7.5V39.2z"/>
              <path :fill="colour" d="M114.6,26.8c0-12.4-5.8-26.8-22.1-26.8c-16.3,0-22,14.5-22,26.8c0,12.4,5.8,26.9,22,26.9
                      C108.8,53.7,114.6,39.2,114.6,26.8z M106.9,26.8c0,9-3.8,19.5-14.4,19.5c-10.6,0-14.4-10.5-14.4-19.5c0-9,3.8-19.5,14.4-19.5
                      C103.2,7.4,106.9,17.9,106.9,26.8z"/>
              <path :fill="colour" d="M178,3c-4.3,17.5-8.3,30.8-12.7,42.7c0,0-0.2,0.1-0.5,0.1h-0.5c-0.2,0-0.4,0-0.4,0
                      c-4.5-12.1-8.5-25.3-12.8-42.8l-0.6-2.4h-7.7l0.9,3.8c0.9,3.9,6.8,27.4,13.2,44.1c1.1,2.9,3.9,4.6,7.4,4.6h0.5
                      c3.6,0,6.3-1.7,7.4-4.6c6.4-16.7,12.3-40.2,13.2-44.1l0.9-3.8h-7.7L178,3z"/>
              <path :fill="colour" d="M303.5,7.4h0.9c3.2,0,6.1,1,8.4,2.9l2.1,1.7l5.6-5.1l-2.8-2.3c-3.6-2.9-8.2-4.5-13.3-4.5h-0.9
                      c-16.3,0-22.1,14.5-22.1,26.8c0,12.4,5.8,26.9,22.1,26.9h0.9c5.1,0,9.7-1.6,13.3-4.5l2.8-2.3l-5.6-5.1l-2.1,1.7
                      c-2.3,1.9-5.2,2.9-8.4,2.9h-0.9c-10.6,0-14.4-10.5-14.4-19.5C289.1,17.9,292.9,7.4,303.5,7.4z"/>
              <path :fill="colour" d="M381.6,2.4c-3.4,6.9-7.5,14.9-12.6,22.2c-5.1-7.3-9.2-15.3-12.6-22.2l-0.9-1.8h-8.3l2.1,4.5
                      c5,10.6,9.2,18,14,24.6c0.3,0.5,1,1.2,1.9,1.9v21.6h7.5V31.5c0.9-0.6,1.6-1.4,1.9-1.9c4.8-6.6,9-13.9,14-24.6l2.1-4.5h-8.3
                      L381.6,2.4z"/>
              <polygon :fill="colour"
                       points="419.5,0.6 419.5,8 435.3,8 435.3,53.1 442.8,53.1 442.8,8 458.6,8 458.6,0.6 	"/>
              <path :fill="colour" d="M247.6,29.3c-4-13.7-7.7-23.8-7.8-24.1c-1.2-2.9-3.9-4.5-7.4-4.5h-0.6c-3.5,0-6.2,1.6-7.3,4.3
                      c-0.3,0.6-3.8,10.3-8,24.4c-2.1,7.2-3.9,13.8-5.4,20l-0.9,3.9h7.8l0.6-2.4c0.8-3.5,3.3-12.5,3.3-12.6l0.8-2.8c0,0,2-7,2.6-9
                      c2.1-7.1,4-12.9,6-18.2c0,0,0.2-0.1,0.5-0.1h0.6c0.2,0,0.4,0,0.4,0c1.8,4.7,5.1,15.2,5.8,17.3c0.6,2,2.3,8.1,2.8,9.7
                      c0,0.1,0.1,0.3,0.2,0.5l0,0c0.8,2.8,3.4,12.4,4.1,15.3l0.6,2.4h7.8l-0.9-3.9C251.5,43.2,249.7,36.5,247.6,29.3z"/>
            </g>
        </svg>
  </router-link>
</template>
<script>
export default {
  name: 'logo',
  props: ['colour']
}
</script>
<style>
  #logo path,  #logo polygon{
    transition: fill 2s;
  }
</style>