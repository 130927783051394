<template>
    <div class="flex w-full justify-center items-center relative">
      <div id="svg-relative" class="md:w-3/6 max-w-960 relative" @mousemove="cursorPlotter">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 2035 2035" style="enable-background:new 0 0 2035 2035;" xml:space="preserve">
<pattern  width="71.91" height="54.09" patternUnits="userSpaceOnUse" id="Unnamed_Pattern_4" viewBox="0.29 -54.39 71.91 54.09" style="overflow:visible;">
  <g>
    <rect x="0.29" y="-54.39" style="fill:none;" width="71.91" height="54.09"/>
    <g>
      <defs>
        <rect id="SVGID_1_" x="0.29" y="-54.39" width="71.91" height="54.09"/>
      </defs>
      <clipPath id="SVGID_2_">
        <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
      </clipPath>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.48-49.88c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        s-0.03-0.15-0.08-0.21C1.64-49.85,1.56-49.88,1.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.69-51.08c-0.08,0-0.16,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21s-0.04-0.15-0.09-0.21
        C2.84-51.05,2.76-51.08,2.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.89-52.28c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C4.05-52.25,3.97-52.28,3.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.09-53.49c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.2c0.14,0.13,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.03-0.17-0.09-0.22C5.25-53.45,5.17-53.49,5.09-53.49"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.47-43.87c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.09-0.21C1.64-43.84,1.56-43.87,1.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.68-45.07c-0.08,0-0.15,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.16-0.09-0.21
        C2.83-45.04,2.75-45.07,2.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.88-46.27c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.07,0.03,0.15,0.08,0.21c0.13,0.12,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.1-0.22C4.04-46.24,3.96-46.27,3.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.08-47.47c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.11,0.33,0.11,0.44-0.01c0.05-0.04,0.08-0.12,0.08-0.2
        c0-0.08-0.03-0.16-0.09-0.22C5.24-47.44,5.16-47.47,5.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C6.44-48.65,6.36-48.68,6.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.48-49.88c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.09-0.21C7.65-49.84,7.57-49.88,7.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.69-51.08c-0.08,0-0.15,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21s-0.03-0.16-0.08-0.21
        C8.84-51.05,8.76-51.08,8.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.89-52.28c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C10.05-52.25,9.97-52.28,9.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.09-53.49c-0.08,0-0.16,0.04-0.21,0.1
        c-0.06,0.05-0.09,0.12-0.09,0.21c0,0.07,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.08-0.21C11.23-53.45,11.17-53.49,11.09-53.49"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.46-37.86c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.09-0.21C1.62-37.83,1.54-37.86,1.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.67-39.06c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.15,0.09,0.21c0.13,0.12,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.1-0.21C2.81-39.03,2.75-39.06,2.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.87-40.26c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C4.03-40.23,3.95-40.26,3.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.07-41.46c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.2c0.13,0.13,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C5.23-41.43,5.15-41.46,5.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.27-42.67c-0.07,0-0.15,0.03-0.21,0.09C6-42.52,5.97-42.44,5.97-42.37
        c0,0.08,0.03,0.16,0.09,0.21c0.12,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.09-0.21
        C6.42-42.64,6.35-42.67,6.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.47-43.87c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.07,0.1-0.14,0.1-0.22
        s-0.04-0.15-0.1-0.21C7.63-43.84,7.56-43.87,7.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.68-45.07c-0.09,0-0.16,0.03-0.22,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C8.84-45.04,8.76-45.07,8.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.88-46.27c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.21C10.04-46.24,9.96-46.27,9.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.08-47.47c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.13,0.12,0.32,0.11,0.43-0.01c0.06-0.05,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.22C11.23-47.44,11.16-47.47,11.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C12.43-48.65,12.36-48.68,12.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.48-49.88c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.02-0.15-0.08-0.21C13.64-49.85,13.56-49.88,13.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.69-51.08c-0.08,0-0.16,0.03-0.22,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.21c0.09,0.11,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.04-0.15-0.09-0.21C14.84-51.05,14.76-51.08,14.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.89-52.28c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.21C16.04-52.25,15.97-52.28,15.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.09-53.48c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.2c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.03-0.16-0.09-0.22C17.25-53.45,17.17-53.48,17.09-53.48"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.45-31.85c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.08-0.21C1.61-31.82,1.53-31.85,1.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.66-33.05c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.08,0.09,0.3,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C2.81-33.02,2.74-33.05,2.66-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.86-34.25c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.22C4.02-34.22,3.94-34.25,3.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.06-35.46c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.3,0.12,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.14-0.08-0.2C5.21-35.43,5.14-35.46,5.06-35.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.26-36.66c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.05-0.09,0.12-0.09,0.2
        s0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21s-0.03-0.15-0.09-0.21
        C6.42-36.62,6.34-36.66,6.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C7.6-37.83,7.54-37.86,7.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.67-39.06c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.21c0.1,0.11,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C8.81-39.03,8.75-39.06,8.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.87-40.26c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C10.03-40.23,9.95-40.26,9.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.07-41.46c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.21c0,0.07,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C11.23-41.43,11.15-41.46,11.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.27-42.67c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C12.43-42.64,12.35-42.67,12.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.47-43.87c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C13.63-43.84,13.55-43.87,13.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.68-45.07c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.11,0.31,0.11,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.08-0.04-0.15-0.09-0.21C14.83-45.04,14.76-45.07,14.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.88-46.27c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.07,0.03,0.15,0.08,0.21c0.13,0.12,0.32,0.11,0.43,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.16-0.1-0.22C16.04-46.24,15.96-46.27,15.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.08-47.47c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.22C17.23-47.44,17.16-47.47,17.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.28-48.68c-0.08,0-0.16,0.04-0.21,0.1c-0.05,0.04-0.09,0.12-0.09,0.2
        c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.22c0-0.08-0.03-0.15-0.09-0.21
        C18.44-48.64,18.36-48.68,18.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.48-49.88c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C19.64-49.85,19.56-49.88,19.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.69-51.08c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.16,0.08,0.21c0.12,0.11,0.31,0.12,0.42,0.01c0.07-0.07,0.1-0.15,0.1-0.22
        c0-0.08-0.03-0.15-0.09-0.21C20.84-51.05,20.77-51.08,20.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.89-52.28c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.21C22.04-52.25,21.97-52.28,21.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.09-53.48c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C23.25-53.45,23.17-53.48,23.09-53.48"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C1.6-25.8,1.52-25.84,1.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.65-27.04c-0.08,0-0.16,0.03-0.22,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C2.8-27.01,2.72-27.04,2.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.85-28.24c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.11,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C4-28.21,3.92-28.24,3.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.05-29.44c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C5.19-29.41,5.13-29.44,5.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.25-30.65c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C6.4-30.61,6.33-30.65,6.25-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.45-31.85c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C7.61-31.81,7.54-31.85,7.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.66-33.05c-0.09,0-0.16,0.03-0.22,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.15-0.09-0.21C8.82-33.02,8.74-33.05,8.66-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.86-34.25c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.11,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.21C10.01-34.22,9.94-34.25,9.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.06-35.46c-0.08,0-0.16,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C11.22-35.42,11.14-35.46,11.06-35.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.26-36.66c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C12.42-36.62,12.34-36.66,12.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C13.62-37.83,13.54-37.86,13.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.67-39.06c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.21c0.09,0.11,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C14.81-39.03,14.73-39.06,14.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.87-40.26c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.22C16.03-40.23,15.95-40.26,15.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.07-41.46c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.07,0.03,0.15,0.09,0.2c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.03-0.16-0.09-0.22C17.23-41.43,17.15-41.46,17.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.27-42.67c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C18.43-42.63,18.36-42.67,18.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.47-43.87c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        s-0.03-0.16-0.08-0.21C19.63-43.84,19.56-43.87,19.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.68-45.07c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.32,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C20.83-45.04,20.75-45.07,20.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.88-46.27c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C22.04-46.24,21.96-46.27,21.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.08-47.47c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C23.23-47.44,23.16-47.47,23.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C24.43-48.65,24.36-48.68,24.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.48-49.88c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.22c0.11,0.1,0.32,0.1,0.43-0.01c0.05-0.06,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C25.64-49.85,25.57-49.88,25.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.69-51.08c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.16,0.08,0.21c0.12,0.11,0.31,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        s-0.04-0.16-0.1-0.22C26.85-51.05,26.77-51.08,26.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.89-52.28c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C28.04-52.25,27.97-52.28,27.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.09-53.48c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C29.25-53.45,29.17-53.48,29.09-53.48"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C1.59-19.8,1.51-19.83,1.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.63-21.03c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.08-0.21C2.79-21,2.71-21.03,2.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.84-22.23c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.15,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.16-0.1-0.22C4-22.2,3.92-22.23,3.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.04-23.43c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.12,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.22C5.19-23.4,5.12-23.43,5.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.24-24.64c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.05-0.09,0.12-0.09,0.21
        c0,0.07,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.03-0.16-0.09-0.22
        C6.4-24.6,6.32-24.64,6.24-24.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C7.6-25.81,7.52-25.84,7.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.65-27.04c-0.08,0-0.16,0.03-0.22,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.08-0.21C8.8-27.01,8.73-27.04,8.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.85-28.24c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C10-28.21,9.93-28.24,9.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.05-29.44c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C11.21-29.41,11.13-29.44,11.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.25-30.64c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.2
        c0-0.09-0.03-0.16-0.09-0.22C12.41-30.61,12.33-30.64,12.25-30.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.45-31.85c-0.08,0-0.16,0.04-0.21,0.1c-0.05,0.04-0.09,0.12-0.09,0.2
        c0,0.08,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.09-0.21
        C13.61-31.82,13.53-31.85,13.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.66-33.05c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C14.81-33.02,14.73-33.05,14.66-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.86-34.25c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.07,0.03,0.15,0.08,0.21c0.13,0.12,0.32,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.16-0.09-0.22C16.02-34.22,15.94-34.25,15.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.06-35.46c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C17.21-35.42,17.14-35.46,17.06-35.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.26-36.65c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        s-0.03-0.15-0.09-0.21C18.42-36.62,18.34-36.65,18.26-36.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.46-37.86c-0.08,0-0.16,0.04-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.16-0.09-0.21C19.62-37.82,19.55-37.86,19.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.67-39.06c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.11,0.31,0.12,0.42,0.01c0.07-0.07,0.1-0.14,0.1-0.22
        c0-0.08-0.03-0.15-0.09-0.21C20.81-39.03,20.75-39.06,20.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.87-40.26c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.22C22.03-40.23,21.95-40.26,21.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.07-41.46c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.04-0.09,0.12-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.03-0.16-0.09-0.22C23.23-41.43,23.15-41.46,23.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.27-42.67c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.1,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C24.43-42.64,24.35-42.67,24.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.47-43.87c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C25.63-43.84,25.56-43.87,25.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.68-45.07c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.1-0.22C26.84-45.04,26.76-45.07,26.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.88-46.27c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C28.02-46.24,27.96-46.27,27.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.08-47.47c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.32,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.09-0.03-0.16-0.09-0.22C29.23-47.44,29.16-47.47,29.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C30.44-48.65,30.36-48.68,30.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.48-49.88c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C31.64-49.85,31.56-49.88,31.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.69-51.08c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.16,0.09,0.21c0.08,0.1,0.3,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.04-0.16-0.09-0.21C32.84-51.05,32.77-51.08,32.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.89-52.28c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.3,0.12,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C34.04-52.25,33.97-52.28,33.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.09-53.48c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.05-0.09,0.13-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2
        c0-0.08-0.03-0.16-0.09-0.22C35.25-53.45,35.17-53.48,35.09-53.48"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.42-13.82c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C1.58-13.78,1.5-13.82,1.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.62-15.02c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C2.78-14.99,2.71-15.02,2.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.83-16.22c-0.08,0-0.15,0.03-0.21,0.08C3.56-16.07,3.53-16,3.53-15.92
        c0,0.08,0.03,0.16,0.08,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.16-0.08-0.21
        C3.98-16.19,3.91-16.22,3.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.03-17.42c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.13,0.12,0.32,0.11,0.43,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.16-0.1-0.22C5.19-17.39,5.11-17.42,5.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.23-18.63c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.07-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C6.39-18.59,6.31-18.63,6.23-18.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C7.59-19.8,7.51-19.83,7.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.63-21.03c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C8.79-21,8.71-21.03,8.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.84-22.23c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.15,0.09,0.21c0.12,0.11,0.31,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C9.98-22.2,9.92-22.23,9.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.04-23.43c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C11.19-23.4,11.12-23.43,11.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.24-24.63c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.2c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C12.4-24.6,12.32-24.63,12.24-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C13.59-25.81,13.52-25.84,13.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.65-27.04c-0.08,0-0.16,0.03-0.22,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C14.8-27.01,14.72-27.04,14.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.85-28.24c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.32,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.1-0.22C16.01-28.21,15.93-28.24,15.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.05-29.44c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C17.21-29.41,17.13-29.44,17.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.25-30.65c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C18.4-30.61,18.33-30.65,18.25-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.45-31.85c-0.08,0-0.16,0.04-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C19.61-31.81,19.54-31.85,19.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.65-33.05c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.11,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C20.81-33.02,20.73-33.05,20.65-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.86-34.25c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.3,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C22.01-34.22,21.94-34.25,21.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.06-35.45c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C23.21-35.42,23.14-35.45,23.06-35.45"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.26-36.66c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C24.41-36.63,24.34-36.66,24.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.46-37.86c-0.08,0-0.16,0.04-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.16-0.09-0.21C25.62-37.82,25.55-37.86,25.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.67-39.06c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.11,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.1-0.21C26.83-39.03,26.75-39.06,26.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.87-40.26c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C28.02-40.23,27.95-40.26,27.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.07-41.46c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.2c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.3,0.12,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C29.23-41.43,29.15-41.46,29.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.27-42.67c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C30.43-42.63,30.35-42.67,30.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.47-43.87c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.12,0.12,0.31,0.12,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C31.63-43.84,31.55-43.87,31.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.68-45.07c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C32.83-45.04,32.75-45.07,32.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.88-46.27c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C34.04-46.24,33.96-46.27,33.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.08-47.47c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.1,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C35.23-47.44,35.16-47.47,35.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C36.44-48.64,36.36-48.68,36.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.48-49.88c-0.07,0-0.14,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.04,0.16,0.09,0.22c0.11,0.1,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.08-0.21
        C37.63-49.85,37.56-49.88,37.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.69-51.08c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22s0.03,0.16,0.09,0.22c0.08,0.09,0.3,0.11,0.42-0.01c0.05-0.06,0.09-0.13,0.09-0.21
        s-0.03-0.15-0.09-0.21C38.84-51.05,38.76-51.08,38.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.89-52.28c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C40.05-52.25,39.97-52.28,39.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.09-53.48c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C41.25-53.45,41.17-53.48,41.09-53.48"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.41-7.81c-0.08,0-0.16,0.04-0.21,0.09C1.14-7.66,1.11-7.59,1.11-7.51
        S1.14-7.35,1.2-7.3c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21S1.68-7.67,1.62-7.72
        C1.57-7.77,1.49-7.81,1.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.61-9.01c-0.08,0-0.16,0.04-0.21,0.1c-0.05,0.04-0.09,0.12-0.09,0.2
        c0,0.08,0.03,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22c0-0.08-0.03-0.15-0.09-0.21
        C2.77-8.98,2.7-9.01,2.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.82-10.21c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21S3.54-9.76,3.6-9.7c0.13,0.12,0.31,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        s-0.04-0.16-0.1-0.21C3.98-10.18,3.9-10.21,3.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.02-11.41c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.07,0.03,0.15,0.08,0.2c0.14,0.13,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.16-0.1-0.22C5.18-11.38,5.1-11.41,5.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.22-12.61c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.2c0.13,0.13,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.17-0.09-0.22C6.38-12.58,6.3-12.61,6.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.42-13.82c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C7.58-13.78,7.5-13.82,7.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.63-15.02c-0.08,0-0.16,0.03-0.22,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C8.77-14.99,8.69-15.02,8.63-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.83-16.22c-0.09,0-0.16,0.03-0.22,0.09C9.56-16.08,9.53-16,9.53-15.92
        c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C9.98-16.19,9.91-16.22,9.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.03-17.42c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C11.18-17.39,11.11-17.42,11.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.23-18.63c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C12.38-18.59,12.31-18.63,12.23-18.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.43-19.83c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C13.59-19.8,13.51-19.83,13.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.63-21.03c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.11,0.3,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.08-0.21C14.79-21,14.71-21.03,14.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.84-22.23c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.15,0.09,0.21c0.12,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C16-22.2,15.92-22.23,15.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.04-23.43c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C17.19-23.4,17.12-23.43,17.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.24-24.64c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C18.4-24.6,18.32-24.64,18.24-24.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.44-25.84c-0.08,0-0.16,0.04-0.21,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.08,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C19.59-25.81,19.52-25.84,19.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.65-27.04c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.12,0.11,0.31,0.11,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.08-0.21C20.8-27.01,20.73-27.04,20.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.85-28.24c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C22-28.21,21.93-28.24,21.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.05-29.44c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C23.21-29.41,23.13-29.44,23.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.25-30.65c-0.08,0-0.16,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.07-0.03-0.15-0.09-0.21
        C24.4-30.61,24.33-30.65,24.25-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.45-31.85c-0.08,0-0.16,0.04-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C25.61-31.81,25.54-31.85,25.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.66-33.05c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.11,0.31,0.11,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.08-0.21C26.82-33.02,26.74-33.05,26.66-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.86-34.25c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C28.02-34.22,27.94-34.25,27.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.06-35.45c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C29.21-35.42,29.14-35.45,29.06-35.45"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.26-36.65c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.04-0.09,0.12-0.09,0.2c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C30.42-36.62,30.34-36.65,30.26-36.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.22c0.08,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C31.62-37.83,31.54-37.86,31.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.67-39.06c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.1-0.22C32.81-39.03,32.75-39.06,32.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.87-40.26c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.3,0.12,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C34.02-40.23,33.94-40.26,33.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.07-41.47c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.05-0.09,0.12-0.09,0.2
        c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.42-0.01c0.06-0.04,0.09-0.12,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C35.23-41.43,35.15-41.47,35.07-41.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.27-42.67c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C36.43-42.63,36.35-42.67,36.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.47-43.87c-0.07,0-0.14,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.04,0.16,0.1,0.22c0.09,0.1,0.29,0.11,0.41,0c0.06-0.06,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.08-0.21
        C37.62-43.84,37.55-43.87,37.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.68-45.07c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.11,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.08-0.21C38.84-45.04,38.76-45.07,38.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.88-46.27c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.07,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C40.02-46.24,39.96-46.27,39.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.08-47.47c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C41.23-47.44,41.16-47.47,41.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.28-48.68c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.11,0.1,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C42.43-48.65,42.36-48.68,42.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.48-49.88c-0.07,0-0.14,0.03-0.2,0.08c-0.07,0.06-0.1,0.14-0.1,0.22
        s0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21s-0.03-0.15-0.08-0.21
        C43.64-49.85,43.56-49.88,43.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.69-51.08c-0.08,0-0.16,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21s-0.03-0.15-0.09-0.21
        C44.84-51.05,44.76-51.08,44.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.89-52.28c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C46.04-52.25,45.97-52.28,45.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.09-53.49c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C47.25-53.45,47.17-53.49,47.09-53.49"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M1.4-1.8c-0.08,0-0.15,0.03-0.21,0.09C1.13-1.65,1.1-1.58,1.1-1.5
        c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42-0.01C1.67-1.34,1.7-1.41,1.7-1.5c0-0.08-0.03-0.15-0.09-0.21
        C1.56-1.76,1.48-1.8,1.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M2.6-3C2.52-3,2.44-2.97,2.39-2.91C2.33-2.85,2.3-2.78,2.3-2.7
        c0,0.08,0.03,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0C2.87-2.54,2.9-2.62,2.9-2.7c0-0.08-0.03-0.15-0.08-0.21
        C2.76-2.97,2.69-3,2.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M3.81-4.2c-0.08,0-0.16,0.03-0.22,0.09C3.53-4.06,3.5-3.98,3.5-3.9
        c0,0.08,0.03,0.15,0.09,0.21c0.13,0.12,0.31,0.11,0.43,0C4.07-3.74,4.1-3.82,4.1-3.9c0-0.08-0.03-0.16-0.09-0.22
        C3.96-4.17,3.89-4.2,3.81-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M5.01-5.4c-0.08,0-0.16,0.03-0.21,0.08c-0.06,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.1,0.21c0.1,0.11,0.31,0.12,0.42,0C5.27-4.94,5.31-5.02,5.31-5.1c0-0.08-0.03-0.15-0.09-0.21
        C5.16-5.37,5.08-5.4,5.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.21-6.6C6.13-6.6,6.05-6.57,6-6.52C5.94-6.45,5.91-6.38,5.91-6.3
        c0,0.07,0.03,0.15,0.08,0.21C6.1-5.98,6.31-5.98,6.42-6.1c0.06-0.04,0.09-0.12,0.09-0.2S6.48-6.46,6.42-6.51
        C6.37-6.57,6.29-6.6,6.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.41-7.81c-0.08,0-0.16,0.04-0.21,0.09C7.14-7.66,7.11-7.59,7.11-7.51
        S7.14-7.36,7.19-7.3c0.13,0.13,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.22S7.68-7.66,7.62-7.72
        C7.57-7.77,7.49-7.81,7.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.61-9.01c-0.07,0-0.15,0.03-0.21,0.09C8.34-8.86,8.31-8.78,8.31-8.71
        c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C8.77-8.98,8.69-9.01,8.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.82-10.21c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21S9.55-9.76,9.6-9.7c0.12,0.11,0.3,0.12,0.42,0.01c0.07-0.07,0.1-0.15,0.1-0.22
        c0-0.08-0.04-0.15-0.09-0.21C9.97-10.18,9.9-10.21,9.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.02-11.41c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.03-0.16-0.09-0.21C11.17-11.38,11.1-11.41,11.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.22-12.61c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.07,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C12.38-12.58,12.3-12.61,12.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.42-13.82c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.05-0.09,0.12-0.09,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.08-0.2
        C13.58-13.78,13.5-13.82,13.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.62-15.02c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C14.78-14.98,14.71-15.02,14.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.83-16.22c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.09,0.31,0.11,0.42-0.01c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C15.98-16.19,15.9-16.22,15.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.03-17.42c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C17.19-17.39,17.11-17.42,17.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.23-18.62c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.04-0.08,0.12-0.08,0.21c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.03-0.17-0.09-0.22C18.39-18.59,18.31-18.62,18.23-18.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C19.58-19.8,19.51-19.83,19.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.63-21.03c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C20.79-21,20.71-21.03,20.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.84-22.23c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C21.98-22.2,21.92-22.23,21.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.04-23.43c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.22C23.19-23.4,23.12-23.43,23.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.24-24.63c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.04-0.09,0.12-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.1,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.08-0.22C24.4-24.6,24.32-24.63,24.24-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C25.6-25.81,25.52-25.84,25.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.65-27.04c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.08-0.21C26.8-27.01,26.72-27.04,26.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.85-28.24c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C28-28.21,27.93-28.24,27.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.05-29.44c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.3,0.12,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C29.19-29.41,29.12-29.44,29.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.25-30.64c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.05-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43,0c0.05-0.06,0.08-0.14,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.22C30.41-30.61,30.33-30.64,30.25-30.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.45-31.85c-0.08,0-0.16,0.04-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C31.61-31.82,31.53-31.85,31.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.66-33.05c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.1-0.21C32.81-33.02,32.73-33.05,32.66-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.86-34.25c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.12,0.42,0.01c0.06-0.07,0.09-0.15,0.09-0.22
        c0-0.08-0.03-0.15-0.08-0.21C34.01-34.22,33.93-34.25,33.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.06-35.45c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.05-0.09,0.13-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.2
        c0-0.09-0.03-0.16-0.09-0.22C35.21-35.42,35.14-35.45,35.06-35.45"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.26-36.66c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C36.42-36.62,36.34-36.66,36.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C37.62-37.83,37.54-37.86,37.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.67-39.06c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C38.81-39.03,38.75-39.06,38.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.87-40.26c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.3,0.12,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C40.03-40.23,39.95-40.26,39.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.07-41.46c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.2c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.42-0.01c0.06-0.04,0.09-0.12,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C41.23-41.43,41.15-41.46,41.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.27-42.67c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.09,0.1,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C42.43-42.63,42.35-42.67,42.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.47-43.87c-0.07,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.09-0.21C43.63-43.84,43.55-43.87,43.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.68-45.07c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.15,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C44.83-45.04,44.76-45.07,44.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.88-46.27c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C46.02-46.24,45.96-46.27,45.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.08-47.47c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C47.23-47.44,47.16-47.47,47.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C48.44-48.64,48.36-48.68,48.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.48-49.88c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.16-0.08-0.21C49.64-49.85,49.56-49.88,49.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.69-51.08c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.06-0.1,0.14-0.1,0.22
        s0.04,0.16,0.1,0.21c0.09,0.11,0.31,0.12,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2s-0.04-0.15-0.09-0.21
        C50.84-51.05,50.76-51.08,50.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.89-52.28c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.2C52.04-52.25,51.97-52.28,51.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.09-53.49c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.03-0.16-0.09-0.22C53.25-53.45,53.17-53.49,53.09-53.49"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M7.4-1.8c-0.08,0-0.15,0.03-0.21,0.09C7.13-1.65,7.1-1.58,7.1-1.5
        c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01C7.67-1.34,7.7-1.42,7.7-1.5c0-0.08-0.03-0.15-0.09-0.21
        C7.56-1.76,7.48-1.8,7.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M8.6-3C8.52-3,8.44-2.97,8.39-2.91C8.33-2.85,8.3-2.78,8.3-2.7
        c0,0.08,0.04,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01C8.87-2.54,8.9-2.62,8.9-2.7c0-0.08-0.03-0.15-0.08-0.21
        C8.76-2.97,8.69-3,8.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M9.81-4.2c-0.08,0-0.16,0.03-0.22,0.09C9.54-4.06,9.5-3.98,9.5-3.9
        c0,0.08,0.04,0.16,0.1,0.22c0.09,0.09,0.3,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C9.95-4.17,9.88-4.2,9.81-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M11.01-5.4c-0.09,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C11.16-5.37,11.09-5.4,11.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.21-6.6c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.09,0.14-0.09,0.22
        c0,0.07,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2c0-0.09-0.03-0.16-0.09-0.22
        C12.35-6.57,12.29-6.6,12.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.41-7.81c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C13.56-7.78,13.49-7.81,13.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.61-9.01c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C14.76-8.98,14.69-9.01,14.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.82-10.21c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.15,0.09,0.21c0.12,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        s-0.04-0.16-0.09-0.21C15.97-10.18,15.9-10.21,15.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.02-11.41c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C17.17-11.38,17.1-11.41,17.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.22-12.61c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.12,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C18.37-12.58,18.3-12.61,18.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.42-13.82c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C19.58-13.78,19.5-13.82,19.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.62-15.02c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C20.78-14.99,20.7-15.02,20.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.83-16.22c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C21.98-16.19,21.9-16.22,21.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.03-17.42c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.3,0.12,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C23.18-17.39,23.11-17.42,23.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.23-18.62c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.04-0.09,0.12-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.09-0.03-0.16-0.09-0.22C24.39-18.59,24.31-18.62,24.23-18.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C25.59-19.8,25.51-19.83,25.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.63-21.03c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C26.79-21,26.72-21.03,26.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.84-22.23c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.31,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C27.98-22.2,27.92-22.23,27.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.04-23.43c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C29.19-23.4,29.1-23.43,29.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.24-24.63c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.2c0,0.09,0.03,0.16,0.09,0.22c0.11,0.11,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.14-0.08-0.2C30.4-24.6,30.32-24.63,30.24-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.44-25.84c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.32,0.11,0.43-0.01c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C31.59-25.81,31.52-25.84,31.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.65-27.04c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.08-0.21C32.8-27.01,32.72-27.04,32.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.85-28.24c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C34-28.21,33.93-28.24,33.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.05-29.44c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C35.21-29.41,35.13-29.44,35.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.25-30.65c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C36.4-30.61,36.33-30.65,36.25-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.45-31.85c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.08-0.21C37.61-31.82,37.53-31.85,37.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.66-33.05c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.15-0.09-0.21C38.82-33.02,38.74-33.05,38.66-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.86-34.25c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C40.01-34.22,39.94-34.25,39.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.06-35.45c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C41.22-35.42,41.14-35.45,41.06-35.45"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.26-36.66c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C42.41-36.63,42.34-36.66,42.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.46-37.86c-0.08,0-0.16,0.04-0.21,0.1c-0.05,0.04-0.09,0.12-0.09,0.2
        c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.21c0-0.08-0.03-0.16-0.09-0.21
        C43.62-37.82,43.55-37.86,43.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.67-39.06c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.12,0.11,0.32,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C44.81-39.03,44.73-39.06,44.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.87-40.26c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.2
        c0-0.08-0.03-0.16-0.09-0.21C46.02-40.23,45.95-40.26,45.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.07-41.46c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.2c0.12,0.13,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C47.22-41.43,47.15-41.46,47.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.27-42.67c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C48.43-42.63,48.35-42.67,48.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.47-43.87c-0.07,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22s0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C49.62-43.84,49.55-43.87,49.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.68-45.07c-0.08,0-0.15,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.32,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C50.82-45.04,50.75-45.07,50.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.88-46.27c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.22C52.04-46.24,51.96-46.27,51.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.08-47.47c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2
        c0-0.08-0.03-0.15-0.08-0.21C53.24-47.44,53.16-47.47,53.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C54.44-48.64,54.36-48.68,54.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.48-49.88c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.04-0.16-0.1-0.21C55.65-49.84,55.57-49.88,55.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.69-51.08c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.16-0.08-0.21C56.85-51.05,56.77-51.08,56.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.89-52.28c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C58.04-52.25,57.97-52.28,57.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.09-53.49c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.08-0.21C59.25-53.45,59.17-53.49,59.09-53.49"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M13.4-1.8c-0.08,0-0.16,0.04-0.21,0.09C13.13-1.65,13.1-1.58,13.1-1.5
        c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21c0-0.07-0.03-0.15-0.08-0.21
        C13.56-1.76,13.48-1.8,13.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M14.6-3c-0.08,0-0.15,0.03-0.21,0.09C14.33-2.86,14.3-2.78,14.3-2.7
        c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C14.76-2.97,14.68-3,14.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M15.81-4.2c-0.08,0-0.16,0.03-0.22,0.09C15.54-4.06,15.5-3.98,15.5-3.9
        c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C15.96-4.17,15.88-4.2,15.81-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M17.01-5.4c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.16-0.09-0.22
        C17.16-5.37,17.09-5.4,17.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.21-6.6c-0.08,0-0.16,0.03-0.21,0.09c-0.06,0.05-0.09,0.13-0.09,0.21
        S17.94-6.14,18-6.09c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2s-0.03-0.16-0.09-0.22
        C18.35-6.57,18.29-6.6,18.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.41-7.81c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.42-0.01c0.06-0.04,0.09-0.12,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C19.57-7.78,19.49-7.81,19.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.61-9.01c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C20.78-8.97,20.7-9.01,20.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.82-10.21c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.16,0.09,0.21c0.09,0.11,0.31,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.04-0.16-0.1-0.21C21.98-10.18,21.9-10.21,21.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.02-11.41c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22s0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        s-0.03-0.16-0.09-0.22C23.17-11.38,23.1-11.41,23.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.22-12.62c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C24.37-12.58,24.3-12.62,24.22-12.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.42-13.82c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.04-0.09,0.12-0.09,0.2
        c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.07-0.03-0.15-0.09-0.21
        C25.58-13.78,25.5-13.82,25.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.62-15.02c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C26.78-14.99,26.71-15.02,26.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.83-16.22c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.1,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C27.98-16.19,27.9-16.22,27.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.03-17.42c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C29.18-17.39,29.11-17.42,29.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.23-18.63c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C30.39-18.59,30.31-18.63,30.23-18.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.43-19.83c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C31.59-19.8,31.51-19.83,31.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.63-21.03c-0.07,0-0.15,0.03-0.2,0.09c-0.07,0.06-0.1,0.13-0.1,0.21
        c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.08-0.21
        C32.79-21,32.71-21.03,32.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.84-22.23c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.11,0.11,0.31,0.12,0.42,0.01c0.07-0.07,0.1-0.14,0.1-0.22
        c0-0.08-0.03-0.15-0.09-0.21C33.98-22.2,33.92-22.23,33.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.04-23.43c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C35.19-23.4,35.12-23.43,35.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.24-24.64c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C36.4-24.6,36.32-24.64,36.24-24.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.12,0.11,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C37.6-25.8,37.52-25.84,37.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.65-27.04c-0.09,0-0.17,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.22C38.81-27.01,38.73-27.04,38.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.85-28.24c-0.08,0-0.15,0.03-0.21,0.08
        c-0.07,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C40-28.21,39.92-28.24,39.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.05-29.44c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.09-0.03-0.16-0.09-0.22C41.21-29.41,41.13-29.44,41.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.25-30.64c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C42.41-30.61,42.33-30.64,42.25-30.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.45-31.85c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C43.61-31.82,43.53-31.85,43.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.65-33.05c-0.07,0-0.14,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C44.81-33.02,44.73-33.05,44.65-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.86-34.25c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C46.01-34.22,45.94-34.25,45.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.06-35.46c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C47.21-35.43,47.13-35.46,47.06-35.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.26-36.66c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.12,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C48.42-36.62,48.34-36.66,48.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        s-0.03-0.15-0.08-0.21C49.62-37.83,49.54-37.86,49.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.67-39.06c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22c0-0.08-0.03-0.15-0.09-0.21
        C50.81-39.03,50.73-39.06,50.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.87-40.26c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.2
        c0-0.08-0.03-0.15-0.09-0.21C52.02-40.23,51.94-40.26,51.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.07-41.46c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.13,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C53.22-41.43,53.15-41.46,53.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.27-42.67c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C54.43-42.63,54.35-42.67,54.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.47-43.87c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.16-0.09-0.21C55.63-43.83,55.56-43.87,55.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.68-45.07c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.08,0.21c0.11,0.11,0.31,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C56.83-45.04,56.75-45.07,56.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.88-46.27c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.12,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C58.02-46.24,57.96-46.27,57.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.08-47.47c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.2
        c0-0.08-0.03-0.16-0.09-0.22C59.24-47.44,59.16-47.47,59.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C60.44-48.64,60.36-48.68,60.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.48-49.88c-0.07,0-0.14,0.03-0.2,0.08c-0.07,0.07-0.1,0.15-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.12,0.08-0.21c0-0.08-0.03-0.15-0.09-0.21
        C61.64-49.85,61.56-49.88,61.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.69-51.08c-0.08,0-0.16,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21s-0.03-0.15-0.09-0.21
        C62.84-51.05,62.76-51.08,62.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.89-52.28c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C64.04-52.25,63.97-52.28,63.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.09-53.48c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2
        c0-0.08-0.03-0.15-0.08-0.21C65.25-53.45,65.17-53.48,65.09-53.48"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M19.4-1.8c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.04-0.09,0.12-0.09,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C19.56-1.76,19.48-1.8,19.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M20.6-3c-0.08,0-0.16,0.03-0.21,0.09C20.33-2.86,20.3-2.78,20.3-2.7
        c0,0.08,0.03,0.15,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C20.76-2.97,20.69-3,20.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M21.81-4.2c-0.08,0-0.16,0.03-0.22,0.09C21.53-4.05,21.5-3.98,21.5-3.9
        c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.11,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.16-0.08-0.21
        C21.96-4.17,21.88-4.2,21.81-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M23.01-5.4c-0.09,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.16-0.09-0.21
        C23.17-5.37,23.09-5.4,23.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.21-6.6c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.06-0.09,0.14-0.09,0.22
        S23.94-6.14,24-6.09c0.09,0.1,0.32,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2s-0.03-0.16-0.09-0.22
        C24.35-6.57,24.29-6.6,24.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.41-7.81c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        s-0.03-0.15-0.09-0.21C25.57-7.77,25.49-7.81,25.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.61-9.01c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.1,0.1,0.3,0.11,0.41-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C26.77-8.98,26.69-9.01,26.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.82-10.21c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.16,0.09,0.22c0.08,0.09,0.3,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.03-0.15-0.09-0.21C27.97-10.18,27.89-10.21,27.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.02-11.41c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C29.18-11.38,29.1-11.41,29.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.22-12.61c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C30.37-12.59,30.3-12.61,30.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.42-13.82c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C31.58-13.78,31.5-13.82,31.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.62-15.02c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.15-0.1-0.21C32.78-14.99,32.71-15.02,32.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.83-16.22c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.1,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C33.98-16.19,33.91-16.22,33.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.03-17.42c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.2
        c0-0.08-0.03-0.16-0.09-0.21C35.18-17.39,35.11-17.42,35.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.23-18.63c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.2C36.39-18.59,36.31-18.63,36.23-18.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.43-19.83c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.15-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C37.58-19.8,37.51-19.83,37.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.63-21.03c-0.07,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C38.79-21,38.71-21.03,38.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.84-22.23c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.31,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C39.98-22.2,39.92-22.23,39.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.04-23.43c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.09-0.04-0.16-0.1-0.22C41.19-23.4,41.12-23.43,41.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.24-24.64c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.09,0.1,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C42.4-24.6,42.32-24.64,42.24-24.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.1,0.31,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C43.6-25.8,43.53-25.84,43.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.65-27.04c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.12,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.08-0.21C44.8-27.01,44.72-27.04,44.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.85-28.24c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.04-0.16-0.1-0.22C46.01-28.21,45.93-28.24,45.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.05-29.44c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.12-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.32,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.22C47.21-29.41,47.13-29.44,47.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.25-30.64c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.2
        c0-0.08-0.03-0.16-0.08-0.21C48.41-30.61,48.33-30.64,48.25-30.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.45-31.85c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C49.6-31.82,49.53-31.85,49.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.65-33.05c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C50.81-33.02,50.73-33.05,50.65-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.86-34.25c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.11,0.3,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C52.01-34.22,51.94-34.25,51.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.06-35.46c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.2c0.12,0.12,0.31,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C53.21-35.43,53.13-35.46,53.06-35.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.26-36.66c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C54.41-36.63,54.34-36.66,54.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.16-0.09-0.21C55.62-37.83,55.54-37.86,55.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.67-39.06c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C56.81-39.03,56.75-39.06,56.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.87-40.26c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.11,0.31,0.12,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C58.02-40.23,57.94-40.26,57.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.07-41.46c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42-0.01c0.06-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.22C59.23-41.43,59.15-41.46,59.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.27-42.67c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C60.43-42.64,60.35-42.67,60.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.47-43.87c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C61.63-43.84,61.55-43.87,61.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.68-45.07c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C62.83-45.04,62.76-45.07,62.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.88-46.27c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C64.02-46.24,63.94-46.27,63.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.08-47.47c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C65.23-47.44,65.16-47.47,65.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.28-48.68c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C66.44-48.64,66.37-48.68,66.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.48-49.88c-0.07,0-0.15,0.03-0.2,0.09c-0.07,0.06-0.1,0.14-0.1,0.21
        c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.07-0.03-0.15-0.08-0.21
        C67.63-49.85,67.56-49.88,67.48-49.88"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.69-51.08c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21s0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.05-0.06,0.09-0.13,0.09-0.21
        s-0.03-0.15-0.09-0.21C68.83-51.05,68.76-51.08,68.69-51.08"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.89-52.28c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C70.04-52.25,69.97-52.28,69.89-52.28"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.09-53.49c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2
        c0-0.08-0.03-0.15-0.08-0.21C71.25-53.45,71.17-53.49,71.09-53.49"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.25-54.69c-0.09,0-0.17,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C72.39-54.66,72.33-54.69,72.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M25.4-1.8c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.05-0.09,0.12-0.09,0.2
        c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21c0-0.08-0.03-0.16-0.09-0.21
        C25.56-1.76,25.48-1.8,25.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M26.6-3c-0.08,0-0.16,0.03-0.21,0.09C26.33-2.86,26.3-2.78,26.3-2.7
        c0,0.08,0.03,0.16,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C26.76-2.97,26.69-3,26.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M27.81-4.2c-0.09,0-0.17,0.03-0.22,0.09C27.54-4.06,27.5-3.98,27.5-3.9
        c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.3,0.12,0.42,0.01c0.07-0.07,0.1-0.14,0.1-0.22c0-0.08-0.04-0.16-0.09-0.21
        C27.96-4.17,27.89-4.2,27.81-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M29.01-5.4c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2c0-0.08-0.03-0.16-0.09-0.21
        C29.16-5.37,29.09-5.4,29.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.21-6.6c-0.08,0-0.16,0.03-0.21,0.08c-0.06,0.06-0.09,0.14-0.09,0.22
        S29.94-6.14,30-6.09c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.03-0.15-0.08-0.21
        C30.35-6.57,30.29-6.6,30.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.41-7.81c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.04-0.09,0.12-0.09,0.2
        c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21c0-0.08-0.03-0.16-0.09-0.21
        C31.57-7.77,31.49-7.81,31.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.61-9.01c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.09,0.09,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.08-0.21C32.76-8.98,32.69-9.01,32.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.82-10.21c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22s0.03,0.16,0.08,0.21c0.1,0.11,0.32,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.03-0.15-0.09-0.21C33.96-10.18,33.89-10.21,33.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.02-11.41c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.1,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.16-0.1-0.22C35.18-11.38,35.1-11.41,35.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.22-12.62c-0.08,0-0.16,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.09-0.2
        C36.37-12.58,36.3-12.62,36.22-12.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.42-13.82c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C37.58-13.78,37.5-13.82,37.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.62-15.02c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.1-0.14,0.1-0.22
        c0-0.08-0.04-0.15-0.09-0.21C38.78-14.99,38.7-15.02,38.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.83-16.22c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C39.98-16.19,39.91-16.22,39.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.03-17.42c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.12,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C41.18-17.39,41.11-17.42,41.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.23-18.63c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.2C42.38-18.6,42.31-18.63,42.23-18.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.43-19.83c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.04-0.09,0.12-0.09,0.2
        c0,0.09,0.04,0.16,0.09,0.22c0.09,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.16-0.09-0.21
        C43.59-19.79,43.51-19.83,43.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.63-21.03c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.3,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C44.79-21,44.72-21.03,44.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.84-22.23c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.15,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C45.98-22.2,45.92-22.23,45.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.04-23.43c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C47.19-23.4,47.1-23.43,47.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.24-24.63c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.05-0.09,0.13-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.2
        c0-0.09-0.03-0.16-0.09-0.22C48.4-24.6,48.32-24.63,48.24-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C49.6-25.81,49.52-25.84,49.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.64-27.04c-0.07,0-0.15,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.31,0.11,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C50.79-27.01,50.72-27.04,50.64-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.85-28.24c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.1,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C52-28.21,51.93-28.24,51.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.05-29.44c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C53.19-29.41,53.13-29.44,53.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.25-30.65c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.07-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C54.4-30.61,54.33-30.65,54.25-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.45-31.85c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C55.61-31.81,55.53-31.85,55.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.65-33.05c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.22C56.82-33.01,56.74-33.05,56.65-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.86-34.25c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.33,0.11,0.43-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.04-0.16-0.1-0.22C58.02-34.22,57.94-34.25,57.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.06-35.45c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42-0.01c0.06-0.05,0.09-0.12,0.09-0.2
        c0-0.09-0.03-0.16-0.09-0.22C59.21-35.42,59.14-35.45,59.06-35.45"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.26-36.65c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.32,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C60.42-36.62,60.34-36.65,60.26-36.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C61.62-37.83,61.54-37.86,61.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.67-39.06c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.15,0.09,0.21c0.13,0.13,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C62.81-39.03,62.73-39.06,62.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.87-40.26c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C64.02-40.23,63.95-40.26,63.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.07-41.46c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.08,0.22c0.14,0.12,0.32,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C65.23-41.43,65.15-41.46,65.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.27-42.67c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C66.43-42.63,66.35-42.67,66.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.47-43.87c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.16-0.08-0.21C67.63-43.84,67.55-43.87,67.47-43.87"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.68-45.07c-0.08,0-0.15,0.03-0.21,0.08c-0.07,0.07-0.1,0.15-0.1,0.22
        c0,0.07,0.03,0.15,0.09,0.21c0.14,0.13,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C68.83-45.04,68.75-45.07,68.68-45.07"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.88-46.27c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C70.02-46.24,69.96-46.27,69.88-46.27"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.08-47.47c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C71.24-47.44,71.16-47.47,71.08-47.47"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.28-48.68c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C72.43-48.65,72.36-48.68,72.28-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M31.4-1.8c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.05-0.09,0.12-0.09,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.11,0.11,0.3,0.11,0.42,0c0.06-0.07,0.09-0.14,0.09-0.22c0-0.07-0.03-0.14-0.08-0.2
        C31.56-1.76,31.48-1.8,31.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M32.6-3c-0.08,0-0.16,0.03-0.21,0.09C32.33-2.85,32.3-2.78,32.3-2.7
        c0,0.08,0.03,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.08-0.21
        C32.76-2.97,32.69-3,32.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M33.8-4.2c-0.08,0-0.15,0.03-0.21,0.09C33.54-4.06,33.5-3.98,33.5-3.9
        c0,0.08,0.04,0.16,0.1,0.21c0.08,0.1,0.3,0.12,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.16-0.09-0.21
        C33.96-4.17,33.88-4.2,33.8-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M35.01-5.4c-0.09,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.12-0.08,0.21
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2c0-0.08-0.04-0.16-0.1-0.22
        C35.17-5.37,35.09-5.4,35.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.21-6.6c-0.08,0-0.16,0.03-0.21,0.08c-0.06,0.06-0.09,0.13-0.09,0.22
        c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.32,0.11,0.42-0.01c0.06-0.04,0.09-0.13,0.09-0.2c0-0.08-0.03-0.16-0.09-0.22
        C36.35-6.57,36.29-6.6,36.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.41-7.81c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.1,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.09-0.21C37.57-7.77,37.49-7.81,37.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.61-9.01c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C38.77-8.98,38.7-9.01,38.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.82-10.21c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.06-0.1,0.14-0.1,0.22
        s0.04,0.16,0.09,0.21c0.12,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21s-0.03-0.15-0.09-0.21
        C39.96-10.18,39.89-10.21,39.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.02-11.41c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22s0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        s-0.03-0.16-0.09-0.21C41.17-11.38,41.1-11.41,41.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.22-12.61c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C42.38-12.58,42.3-12.61,42.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.42-13.82c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C43.58-13.78,43.5-13.82,43.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.62-15.02c-0.08,0-0.16,0.04-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C44.78-14.98,44.71-15.02,44.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.83-16.22c-0.08,0-0.15,0.03-0.21,0.08
        c-0.07,0.07-0.09,0.15-0.09,0.22c0,0.07,0.02,0.15,0.08,0.21c0.12,0.12,0.31,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C45.98-16.19,45.9-16.22,45.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.03-17.42c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.11,0.11,0.32,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C47.19-17.39,47.11-17.42,47.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.23-18.62c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C48.39-18.59,48.31-18.62,48.23-18.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.32,0.12,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C49.58-19.8,49.51-19.83,49.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.63-21.03c-0.07,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.32,0.11,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C50.77-21,50.71-21.03,50.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.84-22.23c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.15,0.09,0.21c0.11,0.11,0.32,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C51.98-22.2,51.92-22.23,51.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.04-23.43c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.33,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C53.19-23.4,53.12-23.43,53.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.24-24.63c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2
        c0-0.08-0.03-0.16-0.08-0.21C54.4-24.6,54.32-24.63,54.24-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C55.6-25.8,55.53-25.84,55.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.65-27.04c-0.08,0-0.16,0.03-0.21,0.08c-0.07,0.07-0.1,0.15-0.1,0.22
        c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.16-0.08-0.21
        C56.8-27.01,56.72-27.04,56.65-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.85-28.24c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.32,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.09-0.04-0.16-0.1-0.22C58.01-28.21,57.93-28.24,57.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.05-29.44c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.3,0.12,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C59.19-29.41,59.13-29.44,59.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.25-30.64c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C60.41-30.61,60.33-30.64,60.25-30.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.45-31.85c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.12,0.3,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C61.6-31.82,61.53-31.85,61.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.65-33.05c-0.07,0-0.15,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C62.81-33.02,62.73-33.05,62.65-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.86-34.25c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.33,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C64.01-34.22,63.94-34.25,63.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.06-35.45c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.09-0.04-0.17-0.1-0.22C65.22-35.42,65.14-35.45,65.06-35.45"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.26-36.66c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.2C66.41-36.63,66.34-36.66,66.26-36.66"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.46-37.86c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.11,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.08-0.21C67.62-37.83,67.54-37.86,67.46-37.86"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.67-39.06c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C68.81-39.03,68.73-39.06,68.67-39.06"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.87-40.26c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.33,0.11,0.43-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.21C70.02-40.23,69.95-40.26,69.87-40.26"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.07-41.46c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01c0.04-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C71.23-41.43,71.15-41.46,71.07-41.46"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.27-42.67c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.04-0.09,0.12-0.09,0.2
        c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.09-0.21
        C72.43-42.64,72.35-42.67,72.27-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M37.4-1.8c-0.08,0-0.15,0.03-0.21,0.09C37.13-1.65,37.1-1.58,37.1-1.5
        c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.07-0.03-0.15-0.08-0.21
        C37.55-1.77,37.48-1.8,37.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M38.6-3c-0.07,0-0.15,0.03-0.21,0.09C38.33-2.85,38.3-2.78,38.3-2.7
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22c0-0.08-0.03-0.15-0.08-0.21
        C38.75-2.97,38.68-3,38.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M39.8-4.2c-0.07,0-0.15,0.03-0.2,0.08c-0.06,0.06-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.1,0.22c0.09,0.09,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C39.96-4.17,39.88-4.2,39.8-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M41.01-5.4c-0.08,0-0.15,0.03-0.21,0.08c-0.07,0.07-0.09,0.14-0.09,0.22
        c0,0.07,0.02,0.15,0.08,0.2c0.12,0.13,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C41.16-5.37,41.08-5.4,41.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.21-6.6c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.06-0.09,0.14-0.09,0.22
        c0,0.07,0.03,0.15,0.08,0.21c0.12,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C42.35-6.57,42.27-6.6,42.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.41-7.81c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.09,0.1,0.3,0.12,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.08-0.21C43.57-7.77,43.49-7.81,43.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.61-9.01c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C44.77-8.98,44.69-9.01,44.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.82-10.21c-0.09,0-0.16,0.03-0.22,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        s-0.03-0.15-0.09-0.21C45.97-10.18,45.9-10.21,45.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.02-11.41c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        s-0.04-0.16-0.1-0.22C47.18-11.38,47.1-11.41,47.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.22-12.61c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C48.38-12.58,48.3-12.61,48.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.42-13.82c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C49.56-13.79,49.5-13.82,49.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.62-15.02c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.11,0.31,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C50.78-14.99,50.7-15.02,50.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.83-16.22c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.11,0.11,0.33,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C51.98-16.19,51.91-16.22,51.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.03-17.42c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C53.18-17.39,53.11-17.42,53.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.23-18.62c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C54.39-18.59,54.31-18.62,54.23-18.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.07-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C55.59-19.8,55.51-19.83,55.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.63-21.03c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C56.79-21,56.71-21.03,56.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.84-22.23c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.15,0.09,0.21c0.11,0.11,0.32,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C57.98-22.2,57.91-22.23,57.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.04-23.43c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C59.19-23.4,59.12-23.43,59.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.24-24.64c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.31,0.12,0.42,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C60.4-24.6,60.32-24.64,60.24-24.64"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.44-25.84c-0.08,0-0.15,0.03-0.21,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C61.6-25.8,61.53-25.84,61.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.64-27.04c-0.07,0-0.15,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C62.79-27.01,62.72-27.04,62.64-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.85-28.24c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.33,0.11,0.43-0.01c0.05-0.05,0.09-0.12,0.09-0.2
        c0-0.08-0.03-0.16-0.09-0.21C64-28.21,63.93-28.24,63.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.05-29.44c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C65.19-29.41,65.13-29.44,65.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.25-30.65c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.08-0.21C66.39-30.62,66.33-30.65,66.25-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.45-31.85c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C67.61-31.81,67.54-31.85,67.45-31.85"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.65-33.05c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.1,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.15-0.09-0.21C68.81-33.02,68.74-33.05,68.65-33.05"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.86-34.25c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.12,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C70.01-34.22,69.94-34.25,69.86-34.25"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.06-35.45c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.22C71.21-35.42,71.14-35.45,71.06-35.45"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.26-36.65c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.13,0.12,0.33,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C72.42-36.62,72.34-36.65,72.26-36.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M43.4-1.8c-0.08,0-0.15,0.03-0.21,0.09C43.13-1.65,43.1-1.58,43.1-1.5
        c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.08-0.21
        C43.56-1.76,43.48-1.8,43.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M44.6-3c-0.08,0-0.15,0.03-0.21,0.09C44.33-2.86,44.3-2.78,44.3-2.7
        c0,0.08,0.04,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C44.76-2.97,44.68-3,44.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M45.8-4.2c-0.07,0-0.15,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.11,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C45.94-4.17,45.88-4.2,45.8-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M47.01-5.4c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.04-0.16-0.09-0.21
        C47.16-5.37,47.09-5.4,47.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.21-6.6c-0.08,0-0.16,0.03-0.21,0.08c-0.06,0.06-0.09,0.13-0.09,0.22
        c0,0.08,0.03,0.16,0.09,0.21c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C48.35-6.57,48.29-6.6,48.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.41-7.81c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.05,0.09-0.13,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C49.57-7.78,49.49-7.81,49.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.61-9.01c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C50.76-8.98,50.69-9.01,50.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.82-10.21c-0.08,0-0.15,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.03,0.15,0.09,0.21c0.12,0.12,0.31,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21s-0.03-0.15-0.09-0.21
        C51.97-10.18,51.89-10.21,51.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.02-11.41c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.11,0.11,0.32,0.11,0.43-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        s-0.03-0.16-0.09-0.21C53.17-11.38,53.1-11.41,53.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.22-12.61c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.12,0.08-0.2
        s-0.03-0.16-0.09-0.22C54.38-12.58,54.3-12.61,54.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.42-13.82c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.32,0.12,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C55.56-13.79,55.5-13.82,55.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.62-15.02c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.3,0.12,0.42,0.01c0.07-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.02-0.15-0.08-0.2C56.78-14.99,56.7-15.02,56.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.83-16.22c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.08,0.21c0.11,0.11,0.31,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C57.98-16.19,57.9-16.22,57.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.03-17.42c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C59.18-17.39,59.11-17.42,59.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.23-18.63c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.42-0.01c0.06-0.05,0.09-0.13,0.09-0.2
        c0-0.09-0.03-0.16-0.09-0.22C60.39-18.59,60.31-18.63,60.23-18.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.09-0.21C61.59-19.8,61.51-19.83,61.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.63-21.03c-0.07,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.32,0.11,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C62.77-21,62.71-21.03,62.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.84-22.23c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.09,0.21c0.09,0.11,0.31,0.12,0.42,0c0.05-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C63.98-22.2,63.91-22.23,63.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.04-23.43c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.33,0.11,0.44-0.01c0.05-0.05,0.08-0.12,0.08-0.2
        c0-0.08-0.03-0.15-0.09-0.21C65.19-23.4,65.11-23.43,65.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.24-24.63c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.04,0.08-0.12,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C66.4-24.6,66.32-24.63,66.24-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.44-25.84c-0.07,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.15-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.13,0.13,0.31,0.11,0.42,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.21C67.59-25.81,67.52-25.84,67.44-25.84"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.64-27.04c-0.08,0-0.16,0.03-0.21,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C68.8-27.01,68.73-27.04,68.64-27.04"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.85-28.24c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.32,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C70-28.21,69.92-28.24,69.85-28.24"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.05-29.44c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.2c0.14,0.13,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C71.19-29.41,71.12-29.44,71.05-29.44"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.25-30.65c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.08-0.21C72.39-30.62,72.33-30.65,72.25-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M49.4-1.8c-0.08,0-0.15,0.03-0.21,0.09C49.13-1.65,49.1-1.58,49.1-1.5
        c0,0.08,0.03,0.16,0.09,0.22c0.1,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22c0-0.08-0.03-0.15-0.09-0.21
        C49.56-1.76,49.48-1.8,49.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M50.6-3c-0.08,0-0.15,0.03-0.21,0.09C50.33-2.85,50.3-2.78,50.3-2.7
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C50.76-2.97,50.68-3,50.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M51.8-4.2c-0.08,0-0.15,0.03-0.21,0.09C51.53-4.05,51.5-3.98,51.5-3.9
        c0,0.08,0.04,0.15,0.09,0.21c0.11,0.11,0.32,0.12,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C51.96-4.17,51.88-4.2,51.8-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M53.01-5.4c-0.08,0-0.16,0.03-0.22,0.08c-0.05,0.07-0.08,0.14-0.08,0.22
        c0,0.08,0.03,0.15,0.08,0.21c0.11,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C53.16-5.37,53.09-5.4,53.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.21-6.6c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.06-0.09,0.14-0.09,0.22
        c0,0.07,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C54.35-6.57,54.27-6.6,54.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.41-7.81c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.12,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.08-0.21C55.56-7.78,55.49-7.81,55.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.61-9.01c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.16-0.09-0.21C56.78-8.97,56.7-9.01,56.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.82-10.21c-0.08,0-0.15,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.04,0.15,0.09,0.21c0.11,0.11,0.31,0.12,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.07-0.03-0.15-0.09-0.21
        C57.97-10.18,57.89-10.21,57.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.02-11.41c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22s0.03,0.16,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.03-0.15-0.09-0.21C59.17-11.38,59.09-11.41,59.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.22-12.61c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2
        c0-0.08-0.03-0.16-0.08-0.21C60.37-12.59,60.29-12.61,60.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.42-13.82c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.2C61.58-13.78,61.5-13.82,61.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.62-15.02c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.14,0.13,0.32,0.12,0.44,0c0.05-0.06,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C62.77-14.99,62.7-15.02,62.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.83-16.22c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.33,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.16-0.09-0.21C63.98-16.19,63.91-16.22,63.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.03-17.42c-0.08,0-0.16,0.03-0.21,0.09
        c-0.06,0.05-0.09,0.13-0.09,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C65.19-17.39,65.11-17.42,65.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.23-18.63c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C66.38-18.59,66.31-18.63,66.23-18.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.43-19.83c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.13,0.12,0.31,0.12,0.42,0c0.06-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C67.59-19.8,67.51-19.83,67.43-19.83"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.63-21.03c-0.08,0-0.16,0.04-0.21,0.1c-0.05,0.04-0.09,0.12-0.09,0.2
        c0,0.08,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C68.79-21,68.71-21.03,68.63-21.03"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.84-22.23c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.15-0.09-0.21
        C69.98-22.2,69.91-22.23,69.84-22.23"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.04-23.43c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.33,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C71.19-23.4,71.12-23.43,71.04-23.43"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.24-24.63c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C72.4-24.6,72.32-24.63,72.24-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M55.4-1.8c-0.08,0-0.15,0.03-0.21,0.09C55.13-1.64,55.1-1.58,55.1-1.5
        c0,0.08,0.03,0.15,0.08,0.21c0.13,0.12,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.22c0-0.08-0.03-0.15-0.09-0.21
        C55.56-1.77,55.48-1.8,55.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M56.6-3c-0.08,0-0.15,0.03-0.21,0.09C56.33-2.85,56.3-2.78,56.3-2.7
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C56.76-2.97,56.68-3,56.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M57.8-4.2c-0.07,0-0.15,0.03-0.2,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.16-0.08-0.21
        C57.96-4.17,57.88-4.2,57.8-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M59.01-5.4c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C59.16-5.37,59.09-5.4,59.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.21-6.6c-0.08,0-0.16,0.03-0.21,0.08c-0.06,0.06-0.09,0.14-0.09,0.22
        S59.94-6.15,60-6.09c0.09,0.1,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C60.37-6.57,60.29-6.6,60.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.41-7.81c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21s0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        s-0.03-0.15-0.09-0.21C61.57-7.77,61.49-7.81,61.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.61-9.01c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C62.77-8.98,62.69-9.01,62.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.82-10.21c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21s0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        s-0.04-0.16-0.09-0.21C63.97-10.18,63.9-10.21,63.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.02-11.41c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22s0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.12,0.42-0.01c0.05-0.04,0.09-0.12,0.09-0.2
        s-0.04-0.16-0.09-0.22C65.17-11.38,65.1-11.41,65.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.22-12.61c-0.08,0-0.15,0.02-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.31,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2
        c0-0.08-0.03-0.16-0.08-0.21C66.37-12.59,66.3-12.61,66.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.42-13.82c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C67.58-13.78,67.5-13.82,67.42-13.82"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.62-15.02c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.12,0.12,0.32,0.11,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.08-0.03-0.15-0.08-0.21C68.78-14.99,68.7-15.02,68.62-15.02"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.83-16.22c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.08,0.03,0.16,0.08,0.21c0.11,0.11,0.31,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C69.98-16.19,69.89-16.22,69.83-16.22"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.03-17.42c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.14-0.09,0.22c0,0.07,0.03,0.15,0.08,0.21c0.12,0.11,0.31,0.12,0.43,0.01c0.06-0.07,0.09-0.15,0.09-0.22
        c0-0.08-0.03-0.15-0.09-0.21C71.18-17.39,71.1-17.42,71.03-17.42"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.23-18.62c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C72.39-18.59,72.31-18.62,72.23-18.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M61.4-1.8c-0.08,0-0.15,0.03-0.21,0.09C61.13-1.65,61.1-1.58,61.1-1.5
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.12,0.08-0.21c0-0.08-0.03-0.15-0.09-0.21
        C61.56-1.76,61.48-1.8,61.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M62.6-3c-0.07,0-0.15,0.03-0.21,0.09C62.33-2.85,62.3-2.77,62.3-2.7
        c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.07-0.03-0.15-0.08-0.21
        C62.75-2.97,62.68-3,62.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M63.8-4.2c-0.08,0-0.15,0.03-0.21,0.09C63.53-4.06,63.5-3.98,63.5-3.9
        c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.11,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.16-0.09-0.21
        C63.96-4.17,63.88-4.2,63.8-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M65.01-5.4c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.09,0.14-0.09,0.22
        c0,0.07,0.03,0.15,0.08,0.21c0.12,0.12,0.31,0.11,0.43,0c0.06-0.06,0.09-0.13,0.09-0.21c0-0.08-0.03-0.16-0.09-0.21
        C65.16-5.37,65.08-5.4,65.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.21-6.6c-0.08,0-0.16,0.03-0.21,0.08c-0.06,0.06-0.09,0.14-0.09,0.22
        c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2c0-0.08-0.03-0.16-0.09-0.22
        C66.36-6.57,66.29-6.6,66.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.41-7.81c-0.08,0-0.16,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.12,0.12,0.31,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C67.57-7.77,67.49-7.81,67.41-7.81"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.61-9.01c-0.07,0-0.15,0.03-0.21,0.08
        c-0.06,0.07-0.09,0.15-0.09,0.22c0,0.08,0.03,0.16,0.09,0.21c0.1,0.11,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C68.76-8.98,68.69-9.01,68.61-9.01"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.82-10.21c-0.08,0-0.16,0.03-0.21,0.08c-0.07,0.07-0.1,0.14-0.1,0.22
        s0.04,0.16,0.09,0.21c0.1,0.11,0.31,0.12,0.43,0c0.05-0.06,0.09-0.13,0.09-0.21c0-0.07-0.03-0.15-0.09-0.21
        C69.97-10.18,69.89-10.21,69.82-10.21"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.02-11.41c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.12-0.08,0.21c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.09-0.21C71.17-11.38,71.1-11.41,71.02-11.41"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.22-12.61c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C72.37-12.58,72.3-12.61,72.22-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M67.4-1.8c-0.08,0-0.16,0.04-0.21,0.09C67.13-1.65,67.1-1.58,67.1-1.5
        c0,0.08,0.03,0.15,0.08,0.21c0.14,0.13,0.32,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.09-0.21
        C67.56-1.76,67.48-1.8,67.4-1.8"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M68.6-3c-0.08,0-0.15,0.03-0.21,0.09C68.33-2.85,68.3-2.78,68.3-2.7
        c0,0.08,0.03,0.15,0.09,0.21c0.12,0.12,0.32,0.11,0.43,0c0.05-0.06,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.08-0.21
        C68.76-2.97,68.68-3,68.6-3"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M69.8-4.2c-0.08,0-0.15,0.03-0.21,0.09C69.54-4.06,69.5-3.98,69.5-3.9
        c0,0.08,0.04,0.16,0.09,0.21c0.1,0.11,0.32,0.11,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.16-0.09-0.22
        C69.97-4.17,69.89-4.2,69.8-4.2"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M71.01-5.4c-0.09,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.08,0.03,0.15,0.08,0.21c0.12,0.11,0.32,0.11,0.43,0c0.06-0.06,0.09-0.14,0.09-0.21c0-0.08-0.04-0.16-0.09-0.21
        C71.16-5.37,71.09-5.4,71.01-5.4"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.21-6.6c-0.08,0-0.15,0.03-0.21,0.08c-0.06,0.07-0.09,0.14-0.09,0.22
        c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.2c0-0.08-0.03-0.15-0.09-0.21
        C72.36-6.57,72.27-6.6,72.21-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.25-54.69c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.09-0.12,0.09-0.21
        c0-0.08-0.04-0.16-0.1-0.21C6.4-54.65,6.33-54.69,6.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.25-54.69c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.1,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.04-0.15-0.09-0.21C12.4-54.65,12.33-54.69,12.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.25-54.69c-0.09,0-0.16,0.04-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C18.41-54.65,18.33-54.69,18.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.25-54.69c-0.09,0-0.16,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C24.4-54.65,24.33-54.69,24.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.25-54.69c-0.09,0-0.16,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.12,0.09-0.21c0-0.08-0.04-0.16-0.1-0.21
        C30.41-54.65,30.33-54.69,30.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.25-54.69c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.08,0.13-0.08,0.21c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.12,0.09-0.21
        c0-0.08-0.04-0.15-0.09-0.21C36.4-54.65,36.33-54.69,36.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.25-54.69c-0.09,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.08,0.13-0.08,0.21c0,0.09,0.03,0.16,0.08,0.22c0.11,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.08-0.03-0.15-0.09-0.21C42.4-54.65,42.33-54.69,42.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.25-54.69c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.05-0.09,0.13-0.09,0.21c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C48.4-54.66,48.31-54.69,48.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.25-54.69c-0.08,0-0.16,0.03-0.22,0.09
        c-0.05,0.06-0.09,0.14-0.09,0.21c0,0.08,0.04,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C54.4-54.66,54.33-54.69,54.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.25-54.69c-0.08,0-0.16,0.04-0.22,0.09
        c-0.05,0.06-0.09,0.13-0.09,0.21c0,0.08,0.04,0.16,0.09,0.22c0.11,0.1,0.31,0.11,0.43,0c0.05-0.06,0.09-0.14,0.09-0.22
        c0-0.08-0.04-0.15-0.09-0.21C60.4-54.65,60.33-54.69,60.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.25-54.69c-0.08,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21
        c0-0.07-0.03-0.15-0.09-0.21C66.39-54.66,66.31-54.69,66.25-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.34-54.69c-0.09,0-0.17,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C0.49-54.66,0.42-54.69,0.34-54.69"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.37-48.68c-0.07,0-0.15,0.03-0.21,0.09
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.08-0.21C0.52-48.65,0.45-48.68,0.37-48.68"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.36-42.67c-0.08,0-0.16,0.04-0.21,0.1c-0.06,0.04-0.09,0.12-0.09,0.2
        c0,0.09,0.04,0.17,0.09,0.22c0.1,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.08-0.13,0.08-0.21c0-0.08-0.03-0.15-0.09-0.21
        C0.52-42.64,0.44-42.67,0.36-42.67"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.35-36.65c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.15,0.08,0.21c0.13,0.12,0.33,0.12,0.43,0.01c0.06-0.07,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.09-0.21C0.51-36.62,0.43-36.65,0.35-36.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.34-30.65c-0.08,0-0.15,0.04-0.21,0.09
        c-0.06,0.06-0.09,0.13-0.09,0.22c0,0.08,0.03,0.15,0.09,0.21c0.11,0.11,0.31,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.21
        c0-0.08-0.03-0.16-0.08-0.21C0.49-30.62,0.42-30.65,0.34-30.65"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.33-24.63c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.13-0.09,0.21c0,0.08,0.03,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.08-0.13,0.08-0.21
        c0-0.07-0.03-0.15-0.09-0.21C0.49-24.6,0.41-24.63,0.33-24.63"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.32-18.62c-0.08,0-0.16,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.09,0.22c0.11,0.11,0.31,0.11,0.42,0c0.05-0.05,0.09-0.13,0.09-0.22
        c0-0.08-0.03-0.16-0.09-0.21C0.48-18.59,0.4-18.62,0.32-18.62"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.31-12.61c-0.08,0-0.15,0.03-0.21,0.08
        c-0.06,0.06-0.09,0.14-0.09,0.21c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0.01c0.06-0.06,0.09-0.14,0.09-0.22
        c0-0.07-0.03-0.15-0.08-0.2C0.46-12.58,0.39-12.61,0.31-12.61"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.3-6.6c-0.08,0-0.15,0.03-0.21,0.08C0.03-6.45,0-6.38,0-6.3
        c0,0.07,0.03,0.15,0.09,0.21c0.1,0.11,0.32,0.11,0.43-0.01C0.57-6.15,0.6-6.23,0.6-6.3c0-0.08-0.03-0.15-0.09-0.21
        C0.45-6.57,0.37-6.6,0.3-6.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M72.25-0.6c-0.09,0-0.17,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21s-0.04-0.15-0.09-0.21
        C72.39-0.57,72.33-0.6,72.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M6.25-0.6c-0.09,0-0.16,0.03-0.22,0.09C5.98-0.45,5.94-0.38,5.94-0.3
        s0.04,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0C6.51-0.14,6.55-0.21,6.55-0.3c0-0.08-0.04-0.16-0.1-0.21
        C6.4-0.56,6.33-0.6,6.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M12.25-0.6c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.06-0.09,0.13-0.09,0.21
        s0.04,0.16,0.1,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21s-0.04-0.15-0.09-0.21
        C12.4-0.56,12.33-0.6,12.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M18.25-0.6c-0.09,0-0.16,0.04-0.22,0.09c-0.05,0.06-0.08,0.13-0.08,0.21
        c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21s-0.03-0.15-0.09-0.21
        C18.41-0.56,18.33-0.6,18.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M24.25-0.6c-0.09,0-0.16,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.13,0.09-0.21s-0.04-0.15-0.09-0.21
        C24.4-0.56,24.33-0.6,24.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M30.25-0.6c-0.09,0-0.16,0.04-0.22,0.1c-0.05,0.04-0.08,0.12-0.08,0.2
        c0,0.09,0.03,0.16,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.12,0.09-0.21c0-0.08-0.04-0.16-0.1-0.21
        C30.41-0.56,30.33-0.6,30.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M36.25-0.6c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.06-0.08,0.13-0.08,0.21
        c0,0.09,0.03,0.17,0.09,0.22c0.1,0.1,0.31,0.11,0.42-0.01c0.05-0.05,0.09-0.12,0.09-0.21c0-0.08-0.04-0.15-0.09-0.21
        C36.4-0.56,36.33-0.6,36.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M42.25-0.6c-0.09,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.08,0.13-0.08,0.21
        c0,0.09,0.03,0.16,0.08,0.22c0.11,0.1,0.32,0.11,0.43-0.01c0.05-0.05,0.09-0.13,0.09-0.21s-0.03-0.15-0.09-0.21
        C42.4-0.56,42.33-0.6,42.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M48.25-0.6c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.05-0.09,0.13-0.09,0.21
        c0,0.09,0.04,0.16,0.1,0.22c0.09,0.1,0.3,0.11,0.42,0c0.06-0.06,0.09-0.14,0.09-0.22c0-0.07-0.03-0.15-0.09-0.21
        C48.4-0.57,48.31-0.6,48.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M54.25-0.6c-0.08,0-0.16,0.03-0.22,0.09c-0.05,0.06-0.09,0.14-0.09,0.21
        c0,0.08,0.04,0.16,0.09,0.21c0.11,0.12,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.07-0.03-0.15-0.09-0.21
        C54.4-0.57,54.33-0.6,54.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M60.25-0.6c-0.08,0-0.16,0.04-0.22,0.09c-0.05,0.06-0.09,0.13-0.09,0.21
        s0.04,0.16,0.09,0.22c0.11,0.1,0.31,0.11,0.43,0c0.05-0.06,0.09-0.14,0.09-0.22s-0.04-0.15-0.09-0.21
        C60.4-0.56,60.33-0.6,60.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M66.25-0.6c-0.08,0-0.15,0.03-0.21,0.09c-0.06,0.06-0.09,0.14-0.09,0.21
        c0,0.08,0.03,0.16,0.08,0.21c0.12,0.12,0.32,0.12,0.43,0c0.05-0.05,0.09-0.13,0.09-0.21c0-0.07-0.03-0.15-0.09-0.21
        C66.39-0.57,66.31-0.6,66.25-0.6"/>
      <path style="clip-path:url(#SVGID_2_);fill:#231F20;" d="M0.34-0.6c-0.09,0-0.17,0.04-0.22,0.1C0.07-0.46,0.04-0.38,0.04-0.3
        c0,0.09,0.03,0.17,0.09,0.22c0.09,0.1,0.31,0.11,0.42-0.01C0.6-0.14,0.64-0.22,0.64-0.3S0.6-0.45,0.55-0.51
        C0.49-0.57,0.42-0.6,0.34-0.6"/>
    </g>
  </g>
</pattern>
          <g id="outer-circle">
  <g id="mncs">
    <g>
      <g id="background_10_">
        <path style="fill:#065777;" d="M1018.19,1015.27l478.9-772.23c193.31,85.73,291.03,213.11,370.52,383.57L1018.19,1015.27z"/>
      </g>
      <g id="private-companies_1_">
        <g id="icon_1_">

            <rect x="1472.35" y="443.5" transform="matrix(0.6502 0.7598 -0.7598 0.6502 933.9105 -1067.9271)" style="fill:#A6A8AB;" width="308.79" height="73.57"/>

          <rect x="1500.4" y="495.95" transform="matrix(0.6502 0.7598 -0.7598 0.6502 941.166 -1042.3485)" style="fill:#C5C8C9;" width="204.38" height="9.99"/>
          <g>

              <rect x="1556.08" y="372.47" transform="matrix(0.6502 0.7598 -0.7598 0.6502 852.1851 -1041.0254)" style="fill:#FAFAFA;" width="1.18" height="65.01"/>

            <rect x="1582.81" y="403.7" transform="matrix(0.6502 0.7598 -0.7598 0.6502 885.2659 -1050.4091)" style="fill:#FAFAFA;" width="1.18" height="65.01"/>

            <rect x="1609.54" y="434.94" transform="matrix(0.6502 0.7598 -0.7598 0.6502 918.3466 -1059.7926)" style="fill:#FAFAFA;" width="1.18" height="65.01"/>

            <rect x="1636.27" y="466.17" transform="matrix(0.6502 0.7598 -0.7598 0.6502 951.4274 -1069.1761)" style="fill:#FAFAFA;" width="1.18" height="65.01"/>

            <rect x="1663" y="497.41" transform="matrix(0.6502 0.7598 -0.7598 0.6502 984.5081 -1078.5597)" style="fill:#FAFAFA;" width="1.18" height="65.01"/>

            <rect x="1689.73" y="528.64" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1017.5889 -1087.9432)" style="fill:#FAFAFA;" width="1.18" height="65.01"/>
          </g>
          <g>
            <g>

                <rect x="1587.17" y="382.3" transform="matrix(0.7598 -0.6502 0.6502 0.7598 120.7693 1131.425)" style="fill:#C5C8C9;" width="8.56" height="39.96"/>

              <rect x="1613.9" y="413.54" transform="matrix(0.7598 -0.6502 0.6502 0.7598 106.8823 1156.3099)" style="fill:#C5C8C9;" width="8.56" height="39.96"/>

              <rect x="1640.63" y="444.77" transform="matrix(0.7598 -0.6502 0.6502 0.7598 92.9954 1181.1948)" style="fill:#C5C8C9;" width="8.56" height="39.96"/>

              <rect x="1667.36" y="476.01" transform="matrix(0.7598 -0.6502 0.6502 0.7598 79.1084 1206.0796)" style="fill:#C5C8C9;" width="8.56" height="39.96"/>

              <rect x="1694.09" y="507.24" transform="matrix(0.7598 -0.6502 0.6502 0.7598 65.2214 1230.9645)" style="fill:#C5C8C9;" width="8.56" height="39.96"/>
            </g>
          </g>

          <rect x="1565.52" y="403.91" transform="matrix(0.6502 0.7598 -0.7598 0.6502 864.0828 -1062.0848)" style="fill:#FAFAFA;" width="39.93" height="6.91"/>

          <rect x="1565.52" y="404.6" transform="matrix(0.6502 0.7598 -0.7598 0.6502 864.0828 -1062.0848)" style="fill:#C5C8C9;" width="39.93" height="5.54"/>

          <rect x="1585.24" y="404.6" transform="matrix(0.6502 0.7598 -0.7598 0.6502 864.0828 -1062.0848)" style="fill:#FAFAFA;" width="0.48" height="5.54"/>

          <rect x="1592.25" y="435.15" transform="matrix(0.6502 0.7598 -0.7598 0.6502 897.1635 -1071.4684)" style="fill:#FAFAFA;" width="39.93" height="6.91"/>

          <rect x="1592.25" y="435.83" transform="matrix(0.6502 0.7598 -0.7598 0.6502 897.1635 -1071.4684)" style="fill:#C5C8C9;" width="39.93" height="5.54"/>

          <rect x="1611.97" y="435.83" transform="matrix(0.6502 0.7598 -0.7598 0.6502 897.1635 -1071.4684)" style="fill:#FAFAFA;" width="0.48" height="5.54"/>

          <rect x="1618.98" y="466.38" transform="matrix(0.6502 0.7598 -0.7598 0.6502 930.2443 -1080.8519)" style="fill:#FAFAFA;" width="39.93" height="6.91"/>

          <rect x="1618.98" y="467.07" transform="matrix(0.6502 0.7598 -0.7598 0.6502 930.2443 -1080.8519)" style="fill:#C5C8C9;" width="39.93" height="5.54"/>

          <rect x="1638.7" y="467.07" transform="matrix(0.6502 0.7598 -0.7598 0.6502 930.2443 -1080.8519)" style="fill:#FAFAFA;" width="0.48" height="5.54"/>

          <rect x="1645.71" y="497.62" transform="matrix(0.6502 0.7598 -0.7598 0.6502 963.325 -1090.2355)" style="fill:#FAFAFA;" width="39.93" height="6.91"/>

          <rect x="1645.71" y="498.3" transform="matrix(0.6502 0.7598 -0.7598 0.6502 963.325 -1090.2355)" style="fill:#C5C8C9;" width="39.93" height="5.54"/>

          <rect x="1665.44" y="498.3" transform="matrix(0.6502 0.7598 -0.7598 0.6502 963.325 -1090.2355)" style="fill:#FAFAFA;" width="0.48" height="5.54"/>

          <rect x="1672.44" y="528.85" transform="matrix(0.6502 0.7598 -0.7598 0.6502 996.4058 -1099.619)" style="fill:#FAFAFA;" width="39.93" height="6.91"/>

          <rect x="1672.44" y="529.54" transform="matrix(0.6502 0.7598 -0.7598 0.6502 996.4058 -1099.619)" style="fill:#C5C8C9;" width="39.93" height="5.54"/>

          <rect x="1692.17" y="529.54" transform="matrix(0.6502 0.7598 -0.7598 0.6502 996.4058 -1099.619)" style="fill:#FAFAFA;" width="0.48" height="5.54"/>

          <rect x="1530.88" y="357.24" transform="matrix(0.6502 0.7598 -0.7598 0.6502 826.5377 -1047.4879)" style="fill:#C5C8C9;" width="39.96" height="33.3"/>
          <g>

              <rect x="1500.85" y="404.16" transform="matrix(0.6502 0.7598 -0.7598 0.6502 838.0374 -1009.713)" style="fill:#FFFFFF;" width="29.46" height="2.21"/>

            <rect x="1511.98" y="391.14" transform="matrix(0.6502 0.7598 -0.7598 0.6502 835.9307 -1017.1399)" style="fill:#FAFAFA;" width="21.24" height="16.25"/>

            <rect x="1512.4" y="391.71" transform="matrix(0.6502 0.7598 -0.7598 0.6502 836.0089 -1016.8642)" style="fill:#E0E0E0;" width="19.87" height="15.56"/>

            <rect x="1522.02" y="391.71" transform="matrix(0.6502 0.7598 -0.7598 0.6502 836.0089 -1016.8642)" style="fill:#FAFAFA;" width="0.63" height="15.56"/>
            <g>
              <path style="fill:#616161;" d="M1522.88,402.78L1522.88,402.78c-0.44-0.51-0.38-1.28,0.13-1.72l4.16-3.56
                c0.51-0.44,1.28-0.38,1.72,0.13l0,0c0.44,0.51,0.38,1.28-0.13,1.72l-4.16,3.56C1524.08,403.35,1523.31,403.29,1522.88,402.78
                z"/>
              <path style="fill:#616161;" d="M1519.17,398.45L1519.17,398.45c-0.44-0.51-0.38-1.28,0.13-1.72l4.16-3.56
                c0.51-0.44,1.28-0.38,1.72,0.13l0,0c0.44,0.51,0.38,1.28-0.13,1.72l-4.16,3.56C1520.38,399.02,1519.61,398.96,1519.17,398.45
                z"/>
            </g>
          </g>
          <polygon style="opacity:0.17;fill:#E0E0E0;" points="1549.02,430.67 1544.92,441.31 1560.49,400.93 1527.31,362.16
            1498.37,386.92 1532.32,426.59 1539.94,420.06 					"/>

          <rect x="1510.97" y="378.13" transform="matrix(0.6502 0.7598 -0.7598 0.6502 829.4972 -1034.7628)" style="fill:#FFFFFF;" width="55.1" height="10.68"/>

          <rect x="1675.43" y="574.83" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1042.4172 -1081.2017)" style="fill:#C5C8C9;" width="39.96" height="33.3"/>

          <rect x="1700.11" y="554.54" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1035.0039 -1107.3365)" style="fill:#FAFAFA;" width="39.96" height="31.66"/>

          <rect x="1704.95" y="551.07" transform="matrix(0.7598 -0.6502 0.6502 0.7598 42.3882 1255.4504)" style="fill:#C5C8C9;" width="30.29" height="38.59"/>

          <rect x="1668.87" y="600.48" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1045.9655 -1068.6931)" style="fill:#FFFFFF;" width="29.46" height="2.21"/>

          <rect x="1679.99" y="587.47" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1043.8588 -1076.12)" style="fill:#FAFAFA;" width="21.24" height="16.25"/>

          <rect x="1680.42" y="588.03" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1043.937 -1075.8444)" style="fill:#E0E0E0;" width="19.87" height="15.56"/>

          <rect x="1690.04" y="588.03" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1043.937 -1075.8444)" style="fill:#FAFAFA;" width="0.63" height="15.56"/>
          <g>
            <path style="fill:#58595B;" d="M1690.89,599.1L1690.89,599.1c-0.44-0.51-0.38-1.28,0.13-1.72l4.16-3.56
              c0.51-0.44,1.28-0.38,1.72,0.13l0,0c0.44,0.51,0.38,1.28-0.13,1.72l-4.16,3.56C1692.1,599.67,1691.33,599.61,1690.89,599.1z"
            />
            <path style="fill:#58595B;" d="M1687.19,594.77L1687.19,594.77c-0.44-0.51-0.38-1.28,0.13-1.72l4.16-3.56
              c0.51-0.44,1.28-0.38,1.72,0.13l0,0c0.44,0.51,0.38,1.28-0.13,1.72l-4.16,3.56C1688.4,595.35,1687.63,595.29,1687.19,594.77z"
            />
          </g>
          <g>

              <rect x="1710.94" y="544.81" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1023.9771 -1104.2087)" style="fill:#FFFFFF;" width="0.48" height="30.29"/>

            <rect x="1715.39" y="550.02" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1029.4905 -1105.7727)" style="fill:#FFFFFF;" width="0.48" height="30.29"/>

            <rect x="1719.85" y="555.22" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1035.004 -1107.3365)" style="fill:#FFFFFF;" width="0.48" height="30.29"/>

            <rect x="1719.85" y="551.07" transform="matrix(-0.7598 0.6502 -0.6502 -0.7598 3397.791 -114.7208)" style="fill:#FFFFFF;" width="0.48" height="38.59"/>

            <rect x="1714.12" y="555.97" transform="matrix(-0.7598 0.6502 -0.6502 -0.7598 3390.9004 -102.3735)" style="fill:#FFFFFF;" width="0.48" height="38.59"/>

            <rect x="1725.57" y="546.17" transform="matrix(-0.7598 0.6502 -0.6502 -0.7598 3404.6814 -127.0682)" style="fill:#FFFFFF;" width="0.48" height="38.59"/>

            <rect x="1724.3" y="560.43" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1040.5175 -1108.9005)" style="fill:#FFFFFF;" width="0.48" height="30.29"/>

            <rect x="1728.76" y="565.63" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1046.0309 -1110.4645)" style="fill:#FFFFFF;" width="0.48" height="30.29"/>
          </g>
          <g>

              <rect x="1647.4" y="307.84" transform="matrix(0.7598 -0.6502 0.6502 0.7598 95.3322 1182.4861)" style="opacity:0.5;fill:#BFBFBF;" width="0.85" height="308.79"/>

            <rect x="1495.1" y="454.81" transform="matrix(0.6502 0.7598 -0.7598 0.6502 926.491 -1094.0836)" style="fill:#FFFFFF;" width="312.67" height="8.66"/>
          </g>
          <polygon style="opacity:0.4;fill:#BFBFBF;" points="1561.94,445.79 1562.63,446.59 1583.18,429 1584.84,426.19 					"/>

          <rect x="1549.69" y="410.02" transform="matrix(0.6502 0.7598 -0.7598 0.6502 870.6642 -1038.8823)" style="fill:#FAFAFA;" width="27.77" height="32.2"/>

          <rect x="1551.48" y="412.08" transform="matrix(0.6502 0.7598 -0.7598 0.6502 870.6642 -1038.8823)" style="fill:#616161;" width="24.2" height="28.07"/>
          <g>
            <g>

                <rect x="1573.43" y="405.19" transform="matrix(0.7598 -0.6502 0.6502 0.7598 106.7956 1123.6143)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1572.65" y="405.86" transform="matrix(0.7598 -0.6502 0.6502 0.7598 106.1735 1123.2671)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1571.87" y="406.53" transform="matrix(0.7598 -0.6502 0.6502 0.7598 105.5514 1122.9199)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1571.09" y="407.19" transform="matrix(0.7598 -0.6502 0.6502 0.7598 104.9292 1122.5728)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1570.31" y="407.86" transform="matrix(0.7598 -0.6502 0.6502 0.7598 104.3071 1122.2255)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1569.53" y="408.53" transform="matrix(0.7598 -0.6502 0.6502 0.7598 103.685 1121.8783)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1568.75" y="409.2" transform="matrix(0.7598 -0.6502 0.6502 0.7598 103.0629 1121.5311)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1567.97" y="409.87" transform="matrix(0.7598 -0.6502 0.6502 0.7598 102.4407 1121.184)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1567.18" y="410.54" transform="matrix(0.7598 -0.6502 0.6502 0.7598 101.8186 1120.8368)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1566.4" y="411.2" transform="matrix(0.7598 -0.6502 0.6502 0.7598 101.1965 1120.4896)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>
            </g>
          </g>
          <g>
            <g>

                <rect x="1565.62" y="411.87" transform="matrix(0.7598 -0.6502 0.6502 0.7598 100.5744 1120.1425)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1564.84" y="412.54" transform="matrix(0.7598 -0.6502 0.6502 0.7598 99.9523 1119.7953)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1564.06" y="413.21" transform="matrix(0.7598 -0.6502 0.6502 0.7598 99.3301 1119.4481)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1563.28" y="413.88" transform="matrix(0.7598 -0.6502 0.6502 0.7598 98.708 1119.101)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1562.5" y="414.55" transform="matrix(0.7598 -0.6502 0.6502 0.7598 98.0859 1118.7538)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1561.72" y="415.21" transform="matrix(0.7598 -0.6502 0.6502 0.7598 97.4638 1118.4066)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1560.94" y="415.88" transform="matrix(0.7598 -0.6502 0.6502 0.7598 96.8417 1118.0594)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1560.16" y="416.55" transform="matrix(0.7598 -0.6502 0.6502 0.7598 96.2195 1117.7123)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1559.38" y="417.22" transform="matrix(0.7598 -0.6502 0.6502 0.7598 95.5974 1117.3651)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1558.6" y="417.89" transform="matrix(0.7598 -0.6502 0.6502 0.7598 94.9753 1117.0179)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>
            </g>
          </g>
          <g>
            <g>

                <rect x="1557.81" y="418.55" transform="matrix(0.7598 -0.6502 0.6502 0.7598 94.3532 1116.6708)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1557.03" y="419.22" transform="matrix(0.7598 -0.6502 0.6502 0.7598 93.731 1116.3236)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1556.25" y="419.89" transform="matrix(0.7598 -0.6502 0.6502 0.7598 93.1089 1115.9764)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1555.47" y="420.56" transform="matrix(0.7598 -0.6502 0.6502 0.7598 92.4868 1115.6292)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1554.69" y="421.23" transform="matrix(0.7598 -0.6502 0.6502 0.7598 91.8647 1115.282)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1553.91" y="421.9" transform="matrix(0.7598 -0.6502 0.6502 0.7598 91.2426 1114.9348)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>

              <rect x="1553.13" y="422.56" transform="matrix(0.7598 -0.6502 0.6502 0.7598 90.6204 1114.5876)" style="fill:#E4E4E6;" width="0.92" height="24.2"/>
              <path style="fill:#E4E4E6;" d="M1545.29,425.84l15.74,18.39L1545.29,425.84z"/>
            </g>
          </g>
          <polygon style="opacity:0.4;fill:#BFBFBF;" points="1587.1,478.37 1587.79,479.17 1609.91,460.24 1611.57,457.43 					"/>

          <rect x="1576.43" y="441.25" transform="matrix(0.6502 0.7598 -0.7598 0.6502 903.7449 -1048.2659)" style="fill:#FAFAFA;" width="27.77" height="32.2"/>

          <rect x="1578.21" y="443.32" transform="matrix(0.6502 0.7598 -0.7598 0.6502 903.7449 -1048.2659)" style="fill:#58595B;" width="24.2" height="28.07"/>
          <g>

              <rect x="1588.52" y="448.07" transform="matrix(0.6502 0.7598 -0.7598 0.6502 900.6466 -1059.1888)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1587.74" y="448.73" transform="matrix(0.6502 0.7598 -0.7598 0.6502 900.8812 -1058.3618)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1586.96" y="449.4" transform="matrix(0.6502 0.7598 -0.7598 0.6502 901.1158 -1057.5348)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1586.18" y="450.07" transform="matrix(0.6502 0.7598 -0.7598 0.6502 901.3503 -1056.7078)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1585.4" y="450.74" transform="matrix(0.6502 0.7598 -0.7598 0.6502 901.585 -1055.8807)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1584.62" y="451.41" transform="matrix(0.6502 0.7598 -0.7598 0.6502 901.8195 -1055.0537)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1583.84" y="452.07" transform="matrix(0.6502 0.7598 -0.7598 0.6502 902.0541 -1054.2267)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1583.06" y="452.74" transform="matrix(0.6502 0.7598 -0.7598 0.6502 902.2887 -1053.3997)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1582.27" y="453.41" transform="matrix(0.6502 0.7598 -0.7598 0.6502 902.5233 -1052.5726)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1581.49" y="454.08" transform="matrix(0.6502 0.7598 -0.7598 0.6502 902.7579 -1051.7456)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1580.71" y="454.75" transform="matrix(0.6502 0.7598 -0.7598 0.6502 902.9925 -1050.9186)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>
          </g>
          <polygon style="fill:#FFFFFF;" points="1570.62,455.93 1588.68,477.01 1583.76,486.63 1560.36,459.29 					"/>
          <polygon style="opacity:0.4;fill:#BFBFBF;" points="1613.83,509.6 1614.52,510.4 1636.64,491.47 1638.3,488.66 					"/>

          <rect x="1603.16" y="472.48" transform="matrix(0.6502 0.7598 -0.7598 0.6502 936.8257 -1057.6494)" style="fill:#FAFAFA;" width="27.77" height="32.2"/>

          <rect x="1604.94" y="474.55" transform="matrix(0.6502 0.7598 -0.7598 0.6502 936.8257 -1057.6494)" style="fill:#58595B;" width="24.2" height="28.07"/>
          <g>

              <rect x="1615.25" y="479.3" transform="matrix(0.6502 0.7598 -0.7598 0.6502 933.7274 -1068.5724)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1614.47" y="479.97" transform="matrix(0.6502 0.7598 -0.7598 0.6502 933.9619 -1067.7454)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1613.69" y="480.64" transform="matrix(0.6502 0.7598 -0.7598 0.6502 934.1965 -1066.9183)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1612.91" y="481.3" transform="matrix(0.6502 0.7598 -0.7598 0.6502 934.4311 -1066.0913)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1612.13" y="481.97" transform="matrix(0.6502 0.7598 -0.7598 0.6502 934.6657 -1065.2643)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1611.35" y="482.64" transform="matrix(0.6502 0.7598 -0.7598 0.6502 934.9003 -1064.4373)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1610.57" y="483.31" transform="matrix(0.6502 0.7598 -0.7598 0.6502 935.1349 -1063.6102)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1609.79" y="483.98" transform="matrix(0.6502 0.7598 -0.7598 0.6502 935.3694 -1062.7832)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1609.01" y="484.65" transform="matrix(0.6502 0.7598 -0.7598 0.6502 935.6041 -1061.9562)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1608.22" y="485.31" transform="matrix(0.6502 0.7598 -0.7598 0.6502 935.8386 -1061.1292)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1607.44" y="485.98" transform="matrix(0.6502 0.7598 -0.7598 0.6502 936.0732 -1060.3022)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1606.66" y="486.65" transform="matrix(0.6502 0.7598 -0.7598 0.6502 936.3078 -1059.4752)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1605.88" y="487.32" transform="matrix(0.6502 0.7598 -0.7598 0.6502 936.5424 -1058.6482)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1605.1" y="487.99" transform="matrix(0.6502 0.7598 -0.7598 0.6502 936.777 -1057.8212)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1604.32" y="488.66" transform="matrix(0.6502 0.7598 -0.7598 0.6502 937.0116 -1056.9941)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1603.54" y="489.32" transform="matrix(0.6502 0.7598 -0.7598 0.6502 937.2462 -1056.1671)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1602.76" y="489.99" transform="matrix(0.6502 0.7598 -0.7598 0.6502 937.4808 -1055.3401)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1601.98" y="490.66" transform="matrix(0.6502 0.7598 -0.7598 0.6502 937.7153 -1054.5131)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1601.2" y="491.33" transform="matrix(0.6502 0.7598 -0.7598 0.6502 937.95 -1053.686)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1600.42" y="492" transform="matrix(0.6502 0.7598 -0.7598 0.6502 938.1845 -1052.859)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1599.64" y="492.67" transform="matrix(0.6502 0.7598 -0.7598 0.6502 938.4191 -1052.032)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1598.85" y="493.33" transform="matrix(0.6502 0.7598 -0.7598 0.6502 938.6537 -1051.205)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1598.07" y="494" transform="matrix(0.6502 0.7598 -0.7598 0.6502 938.8883 -1050.3779)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1597.29" y="494.67" transform="matrix(0.6502 0.7598 -0.7598 0.6502 939.1229 -1049.5509)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1596.51" y="495.34" transform="matrix(0.6502 0.7598 -0.7598 0.6502 939.3575 -1048.7239)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>
          </g>
          <polygon style="fill:#FFFFFF;" points="1597.35,487.16 1615.42,508.25 1610.5,517.87 1587.1,490.53 					"/>
          <polygon style="opacity:0.4;fill:#BFBFBF;" points="1640.56,540.84 1641.25,541.64 1663.37,522.7 1665.03,519.9 					"/>

          <rect x="1629.89" y="503.72" transform="matrix(0.6502 0.7598 -0.7598 0.6502 969.9064 -1067.0331)" style="fill:#FAFAFA;" width="27.77" height="32.2"/>

          <rect x="1631.67" y="505.79" transform="matrix(0.6502 0.7598 -0.7598 0.6502 969.9064 -1067.0331)" style="fill:#58595B;" width="24.2" height="28.07"/>
          <g>

              <rect x="1641.98" y="510.53" transform="matrix(0.6502 0.7598 -0.7598 0.6502 966.8081 -1077.9559)" style="fill:#E0E0E0;" width="24.2" height="0.92"/>
          </g>
          <polygon style="fill:#FFFFFF;" points="1624.08,518.4 1642.15,539.48 1637.23,549.1 1613.83,521.76 					"/>
          <polygon style="opacity:0.4;fill:#BFBFBF;" points="1667.3,572.07 1667.98,572.87 1690.1,553.94 1691.76,551.13 					"/>

          <rect x="1656.62" y="534.95" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1002.9872 -1076.4166)" style="fill:#FAFAFA;" width="27.77" height="32.2"/>

          <rect x="1658.4" y="537.02" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1002.9872 -1076.4166)" style="fill:#58595B;" width="24.2" height="28.07"/>

          <pattern  id="SVGID_3_" xlink:href="#Unnamed_Pattern_4" patternTransform="matrix(0.2707 0.3163 0.3163 -0.2707 -3883.6179 -5711.5918)">
          </pattern>
          <polygon style="opacity:0.2;fill:url(#SVGID_3_);" points="1614.25,506.9 1598.51,488.52 1600.22,487.06 1615.95,505.44
            "/>
          <g>

              <rect x="1668.71" y="541.77" transform="matrix(0.6502 0.7598 -0.7598 0.6502 999.8889 -1087.3395)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1667.93" y="542.44" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1000.1234 -1086.5125)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1667.15" y="543.11" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1000.358 -1085.6854)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>

            <rect x="1666.37" y="543.77" transform="matrix(0.6502 0.7598 -0.7598 0.6502 1000.5926 -1084.8584)" style="fill:#E4E4E6;" width="24.2" height="0.92"/>
          </g>
          <polygon style="fill:#FFFFFF;" points="1650.81,549.63 1668.88,570.72 1663.96,580.34 1640.56,553 					"/>
        </g>
      </g>
    </g>
  </g>
            <g id="educational">
    <g>
      <g id="background_9_">
        <g>
          <path style="fill:#FFFFFF;" d="M1018.19,1015.27l827.05-378.85c96.08,141.67,123.1,322.45,96.92,508.71L1018.19,1015.27z"/>
        </g>
      </g>
      <g id="Universities">
        <g id="XMLID_18_">

            <rect id="XMLID_118_" x="1819.37" y="847.25" transform="matrix(-0.1645 -0.9864 0.9864 -0.1645 1239.4772 2842.0869)" style="fill:#5CBCE2;" width="8.06" height="97.72"/>

          <rect id="XMLID_117_" x="1796.13" y="820.74" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2377.4712 -1067.6564)" style="fill:#E4E4E6;" width="45.7" height="97.72"/>

          <rect id="XMLID_115_" x="1823.18" y="864" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2395.6206 -1098.1067)" style="fill:#5CBCE2;" width="45.7" height="2.17"/>

          <rect id="XMLID_113_" x="1781.43" y="870.97" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2367.6084 -1051.1089)" style="fill:#5CBCE2;" width="45.7" height="2.17"/>

          <rect id="XMLID_111_" x="1852.58" y="859.23" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2411.1301 -1124.1283)" style="fill:#5CBCE2;" width="33.11" height="4"/>

          <rect id="XMLID_110_" x="1859.3" y="858.57" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2413.7751 -1128.5662)" style="fill:#E4E4E6;" width="27.57" height="4"/>

          <rect id="XMLID_109_" x="1782.73" y="890.64" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2413.8611 -1013.7009)" style="fill:#A4EBF7;" width="45.19" height="54.84"/>

          <rect id="XMLID_108_" x="1805.45" y="917.15" transform="matrix(-0.1645 -0.9864 0.9864 -0.1645 1175.774 2885.0542)" style="fill:#E4E4E6;" width="8.58" height="54.84"/>
          <g id="XMLID_92_">
            <g id="XMLID_71_">
              <path id="XMLID_72_" style="fill:#5CBCE2;" d="M1857.49,849.85l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88
                l0.95,5.71l5.26-0.88C1856.87,852.23,1857.7,851.08,1857.49,849.85z"/>
            </g>
            <path style="fill:#5CBCE2;" d="M1859.01,858.92l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88l0.95,5.71l5.26-0.88
              C1858.38,861.3,1859.21,860.14,1859.01,858.92z"/>
            <path style="fill:#5CBCE2;" d="M1860.52,867.98l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88l0.95,5.71l5.26-0.88
              C1859.9,870.36,1860.72,869.21,1860.52,867.98z"/>
            <g id="XMLID_68_">
              <path id="XMLID_70_" style="fill:#5CBCE2;" d="M1862.03,877.05l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88
                l0.95,5.71l5.26-0.88C1861.41,879.43,1862.23,878.27,1862.03,877.05z"/>
            </g>
          </g>
          <g id="XMLID_76_">
            <g id="XMLID_54_">
              <path id="XMLID_65_" style="fill:#5CBCE2;" d="M1835.74,853.48l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88
                l0.95,5.71l5.26-0.88C1835.12,855.86,1835.94,854.7,1835.74,853.48z"/>
            </g>
            <path style="fill:#5CBCE2;" d="M1837.25,862.55l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88l0.95,5.71l5.26-0.88
              C1836.63,864.93,1837.45,863.77,1837.25,862.55z"/>
            <path style="fill:#5CBCE2;" d="M1838.76,871.61l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88l0.95,5.71l5.26-0.88
              C1838.14,873.99,1838.97,872.84,1838.76,871.61z"/>
            <g id="XMLID_48_">
              <path id="XMLID_51_" style="fill:#5CBCE2;" d="M1840.27,880.68l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88
                l0.95,5.71l5.26-0.88C1839.65,883.06,1840.48,881.9,1840.27,880.68z"/>
            </g>
          </g>
          <g id="XMLID_61_">
            <g id="XMLID_42_">
              <path id="XMLID_44_" style="fill:#5CBCE2;" d="M1818.64,856.33l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88
                l0.95,5.71l5.26-0.88C1818.02,858.71,1818.84,857.56,1818.64,856.33z"/>
            </g>
            <path style="fill:#5CBCE2;" d="M1820.15,865.4l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88l0.95,5.71l5.26-0.88
              C1819.53,867.78,1820.36,866.62,1820.15,865.4z"/>
            <path style="fill:#5CBCE2;" d="M1821.66,874.46l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88l0.95,5.71l5.26-0.88
              C1821.04,876.84,1821.87,875.69,1821.66,874.46z"/>
            <g id="XMLID_36_">
              <path id="XMLID_39_" style="fill:#5CBCE2;" d="M1823.18,883.53l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-5.26,0.88
                l0.95,5.71l5.26-0.88C1822.55,885.91,1823.38,884.75,1823.18,883.53z"/>
            </g>
          </g>
          <g id="XMLID_55_">

              <rect id="XMLID_60_" x="1771.58" y="875.73" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2349.7097 -1021.0791)" style="fill:#A4EBF7;" width="12.05" height="1.54"/>

            <rect id="XMLID_59_" x="1770.06" y="875.98" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2348.6931 -1019.3734)" style="fill:#E4E4E6;" width="12.05" height="1.54"/>

            <rect id="XMLID_58_" x="1768.54" y="876.24" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2347.6763 -1017.6676)" style="fill:#A4EBF7;" width="12.05" height="1.54"/>

            <rect id="XMLID_57_" x="1767.03" y="876.49" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2346.6597 -1015.9619)" style="fill:#E4E4E6;" width="12.05" height="1.54"/>

            <rect id="XMLID_56_" x="1765.51" y="876.74" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2345.6428 -1014.2561)" style="fill:#A4EBF7;" width="12.05" height="1.54"/>
          </g>
          <g id="XMLID_52_">

              <rect id="XMLID_53_" x="1782.9" y="867.25" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2355.4038 -1030.6324)" style="fill:#5CBCE2;" width="6.36" height="15.67"/>
          </g>
          <g id="XMLID_45_">
            <g id="XMLID_49_">
              <path id="XMLID_50_" style="fill:#5CBCE2;" d="M1792.72,860.66l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-11.33,1.89
                l0.95,5.71l11.33-1.89C1792.1,863.04,1792.93,861.88,1792.72,860.66z"/>
            </g>
            <g id="XMLID_46_">
              <path id="XMLID_47_" style="fill:#5CBCE2;" d="M1797.26,887.85l-0.21-1.28c-0.2-1.22-1.36-2.05-2.59-1.85l-11.33,1.89
                l0.95,5.71l11.33-1.89C1796.64,890.23,1797.46,889.08,1797.26,887.85z"/>
            </g>
          </g>

          <rect id="XMLID_43_" x="1762.3" y="860.68" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2330.6492 -1025.5499)" style="fill:#5CBCE2;" width="16.83" height="4.67"/>

          <rect id="XMLID_41_" x="1767.05" y="889.16" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2362.71 -1006.4407)" style="fill:#5CBCE2;" width="16.83" height="4.67"/>
          <path id="XMLID_40_" style="fill:#E4E4E6;" d="M1807.74,899.42l-0.28-1.66c-0.27-1.59,0.81-3.1,2.4-3.36l18.78-3.13l1.24,7.43
            l-18.78,3.13C1809.51,902.09,1808.01,901.01,1807.74,899.42z"/>
          <path id="XMLID_38_" style="fill:#5CBCE2;" d="M1808.98,906.85l-0.28-1.66c-0.27-1.59,0.81-3.1,2.4-3.36l18.78-3.13l1.24,7.43
            l-18.78,3.13C1810.75,909.51,1809.25,908.44,1808.98,906.85z"/>
          <path id="XMLID_37_" style="fill:#E4E4E6;" d="M1810.22,914.27l-0.28-1.66c-0.27-1.59,0.81-3.1,2.4-3.36l18.78-3.13l1.24,7.43
            l-18.78,3.13C1811.99,916.94,1810.49,915.87,1810.22,914.27z"/>
          <path id="XMLID_35_" style="fill:#5CBCE2;" d="M1811.46,921.7l-0.28-1.66c-0.27-1.59,0.81-3.1,2.4-3.36l18.78-3.13l1.24,7.43
            l-18.78,3.13C1813.23,924.37,1811.72,923.3,1811.46,921.7z"/>
          <path id="XMLID_34_" style="fill:#E4E4E6;" d="M1812.7,929.13l-0.28-1.66c-0.27-1.59,0.81-3.1,2.4-3.36l18.78-3.13l1.24,7.43
            l-18.78,3.13C1814.47,931.8,1812.96,930.73,1812.7,929.13z"/>
          <path id="XMLID_32_" style="fill:#5CBCE2;" d="M1813.94,936.56l-0.28-1.66c-0.27-1.59,0.81-3.1,2.4-3.36l18.78-3.13l1.24,7.43
            l-18.78,3.13C1815.71,939.23,1814.2,938.15,1813.94,936.56z"/>

          <rect id="XMLID_30_" x="1754.34" y="924.02" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2401.9614 -983.0456)" style="fill:#5CBCE2;" width="53.87" height="4.67"/>

          <rect id="XMLID_28_" x="1788.51" y="901.7" transform="matrix(-0.9864 0.1645 -0.1645 -0.9864 3717.1804 1517.6259)" style="fill:#5CBCE2;" width="14.46" height="22.09"/>
          <g id="XMLID_23_">

              <rect id="XMLID_26_" x="1785.13" y="928.74" transform="matrix(-0.9864 0.1645 -0.1645 -0.9864 3720.4912 1557.6373)" style="fill:#5CBCE2;" width="21.23" height="8.29"/>
            <path id="XMLID_25_" style="fill:none;stroke:#044A6D;stroke-width:0.6959;stroke-miterlimit:10;" d="M1785.27,934.64"/>
            <path id="XMLID_24_" style="fill:none;stroke:#044A6D;stroke-width:0.6959;stroke-miterlimit:10;" d="M1800.87,932.03"/>
          </g>
          <g id="XMLID_19_">

              <rect id="XMLID_22_" x="1778.23" y="934.02" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2412.7842 -978.9334)" style="fill:#E4E4E6;" width="12.05" height="1.56"/>

            <rect id="XMLID_21_" x="1776.69" y="934.28" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2411.7512 -977.2003)" style="fill:#A4EBF7;" width="12.05" height="1.56"/>

            <rect id="XMLID_20_" x="1775.15" y="934.54" transform="matrix(0.1645 0.9864 -0.9864 0.1645 2410.7183 -975.4671)" style="fill:#E4E4E6;" width="12.05" height="1.56"/>
          </g>

          <line style="fill:none;stroke:#5CBCE2;stroke-width:1.1799;stroke-miterlimit:10;" x1="1828.65" y1="891.26" x2="1774.56" y2="900.28"/>
        </g>
      </g>
    </g>
  </g>
            <g id="border-control">
  </g>
            <g id="surge-management">
    <g>
      <g id="background_7_">
        <g>
          <path style="fill:#5CBCE2;" d="M1018.19,1015.27l686.46,596.73c-129.12,170.63-248.47,248.34-429.28,300.18L1018.19,1015.27z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon style="fill:#E26139;" points="1448.81,1761.44 1495.99,1733.07 1496.1,1743.74 1458.18,1766.53 						"/>

            <rect x="1437.46" y="1745.03" transform="matrix(-0.857 0.5153 -0.5153 -0.857 3633.8811 2485.1743)" style="fill:#044A6D;" width="69.32" height="3.52"/>
            <path style="fill:#E26139;" d="M1393.89,1718.23l2.08-23.02l66.29-39.86l21.31,8.95c2.54,1.07,4.43,3.26,5.12,5.92
              l14.63,56.38l-63.67,38.28l-42.94-39.36C1394.69,1723.67,1393.64,1720.97,1393.89,1718.23z"/>
            <polygon style="fill:#044A6D;" points="1403.24,1720.02 1480.78,1673.4 1496.98,1726.02 1442.12,1759.01 						"/>
            <polygon style="fill:#E26139;" points="1426.76,1738.62 1486.17,1702.89 1493.76,1725.72 1443.37,1756.03 						"/>
            <polygon style="fill:#044A6D;" points="1447.73,1730.23 1484.27,1708.26 1490.25,1724.97 1457.92,1744.41 						"/>
            <polygon style="fill:#044A6D;" points="1447.98,1745.71 1452.75,1742.85 1455.17,1746.06 1450.55,1748.84 						"/>
            <polygon style="fill:#044A6D;" points="1443.89,1740.72 1448.88,1737.72 1451.53,1741.24 1446.69,1744.14 						"/>
            <polygon style="fill:#044A6D;" points="1439.4,1735.24 1444.63,1732.1 1447.54,1735.96 1442.48,1739 						"/>
            <polygon style="fill:#044A6D;" points="1441.62,1749.53 1446.39,1746.67 1449,1749.77 1444.38,1752.55 						"/>
            <polygon style="fill:#044A6D;" points="1437.24,1744.72 1442.23,1741.72 1445.08,1745.11 1440.25,1748.02 						"/>
            <polygon style="fill:#044A6D;" points="1432.43,1739.43 1437.66,1736.29 1440.79,1740.02 1435.73,1743.06 						"/>
            <g>
              <polygon style="fill:#E26139;" points="1459.65,1707.41 1465.54,1703.87 1466.93,1706.8 1461.16,1710.28 							"/>
              <polygon style="fill:#E26139;" points="1450.45,1712.94 1456.34,1709.4 1457.91,1712.23 1452.13,1715.7 							"/>
              <polygon style="fill:#E26139;" points="1441.24,1718.48 1447.13,1714.93 1448.88,1717.66 1443.11,1721.13 							"/>
              <polygon style="fill:#E26139;" points="1456.93,1702.21 1463.03,1698.55 1464.51,1701.7 1458.54,1705.29 							"/>
              <polygon style="fill:#E26139;" points="1447.4,1707.95 1453.5,1704.28 1455.18,1707.31 1449.2,1710.9 							"/>
              <polygon style="fill:#E26139;" points="1437.86,1713.68 1443.96,1710.01 1445.84,1712.92 1439.86,1716.52 							"/>
              <polygon style="fill:#E26139;" points="1454,1696.64 1460.33,1692.83 1461.92,1696.22 1455.73,1699.94 							"/>
              <polygon style="fill:#E26139;" points="1444.12,1702.58 1450.44,1698.78 1452.25,1702.03 1446.06,1705.76 							"/>
              <polygon style="fill:#E26139;" points="1434.24,1708.52 1440.56,1704.72 1442.58,1707.85 1436.38,1711.57 							"/>
            </g>

            <ellipse transform="matrix(0.4689 -0.8833 0.8833 0.4689 -761.8547 2175.8496)" style="fill:#E26139;" cx="1428.34" cy="1721.42" rx="5.32" ry="5.32"/>
            <path style="fill:#044A6D;" d="M1410.65,1701.13l-6.51-10.82l0.46-0.28l6.51,10.82c1.48,2.47,4.48,3.4,6.68,2.07l41.06-24.69
              c2.2-1.32,2.78-4.41,1.3-6.87l-6.51-10.82l0.46-0.28l6.51,10.82c1.64,2.72,0.97,6.14-1.49,7.62l-41.06,24.69
              C1415.61,1704.87,1412.28,1703.85,1410.65,1701.13z"/>

            <rect x="1460.44" y="1750.86" transform="matrix(0.857 -0.5153 0.5153 0.857 -692.374 1011.2908)" style="fill:#044A6D;" width="31.03" height="4.6"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="defence">
    <g>
      <g id="background_6_">
        <g>
          <path style="fill:#FFFFFF;" d="M1018.19,1015.27l249.84,874.17c-189.64,78.08-342.77,73.11-522.64,18.11L1018.19,1015.27z"/>
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon style="fill:#FFF4EA;" points="981.24,1849.27 981.76,1860.7 1013.37,1860.7 1014.98,1847.9 						"/>
            <polygon style="fill:#DB6638;" points="977.75,1800.44 976.56,1819.81 987.08,1834.73 1009.63,1835.42 1020.16,1819.1
              1015.97,1799.32 						"/>
            <path style="fill:#DB6638;" d="M947.4,1785.5c33.37,1.7,66.68,2.61,100.17,2.61c-1.6,3.99-3.02,20.82-18.51,26.75
              c-3.25,1.24-6.41,2.95-9.55,4.58c-1.26,0.65-2.01,0.44-2.98-0.66c-3.68-4.18-7.46-8.24-11.22-12.33
              c-2.46-2.68-3.74-2.89-6.88-1.45c-0.55,0.25-1.39,0.18-1.96-0.09c-2.75-1.31-4.32-1.08-6.51,1.29
              c-3.83,4.14-7.69,8.26-11.4,12.53c-1.08,1.24-1.89,1.3-3.2,0.64c-0.66-0.33-1.31-0.65-1.97-0.98
              c-2.08-1.03-4.16-2.04-6.24-3.06c-3.01-1.47-6.03-2.93-9.03-4.41C946.63,1802.14,947.4,1792.19,947.4,1785.5z"/>
            <path style="fill:#DB6638;" d="M997.93,1881.44c-6.52-0.15-12.45-0.71-18.04-3.29c-2.43-1.12-5.24-2.26-5.4-5.68
              c-0.15-3.4,2.6-4.6,4.92-5.73c1.67-0.81,1.44-2.17,1.45-3.59c0.02-4.21,0.01-4.24,3.73-5.59c9.05-3.3,18.03-3.11,27.02,0.41
              c1.94,0.76,2.9,1.81,2.56,4.09c-0.38,2.58,0.33,4.23,2.72,5.51c1.56,0.83,3.15,2.86,3.56,4.67c0.57,2.52-1.68,3.92-3.51,4.97
              C1010.82,1880.72,1004.13,1881.19,997.93,1881.44z"/>
            <path style="fill:#FFF4EA;" d="M997.58,1863.07c-2.03-0.04-3.8,1.84-3.84,4.09c-0.04,2.28,1.63,4.16,3.71,4.19
              c2.08,0.04,3.8-1.78,3.83-4.06C1001.32,1865.05,999.62,1863.12,997.58,1863.07z"/>
            <path style="fill:#DB6638;" d="M980.39,1847c0.1-1.9-0.82-4.93,2.86-5.5c0.38-0.06,0.65-1.16,0.91-1.81
              c2.73-6.7,7.55-9.91,14.17-9.83c6.44,0.08,10.99,3.42,13.73,9.81c0.39,0.9,1.49,1.4,2.03,2.26c0.69,1.08,1.65,2.37,1.59,3.5
              c-0.15,2.63,1.36,5.77-1.3,7.77c-0.62,0.47-2,0.25-2.8-0.16c-8.17-4.24-25.83-0.83-26.28-0.69c-1.18,0.37-2.51,1.08-3.55,0.75
              c-0.67-0.21-1.03-1.96-1.32-3.09C980.23,1849.27,980.39,1848.41,980.39,1847z"/>
            <path style="fill:#FFF4EA;" d="M997.9,1818.9c0.66-2.87,1.28-5.75,1.99-8.61c0.58-2.34,1.26-2.58,2.8-0.93
              c3.61,3.86,7.16,7.78,10.72,11.69c0.29,0.31,0.78,0.93,0.71,1.03c-1.39,2.02-2.74,4.09-4.36,5.89
              c-0.38,0.43-1.88,0.21-2.51-0.26c-3.28-2.45-6.44-5.07-9.74-7.72c-3.19,2.55-6.41,5.05-9.55,7.68
              c-1.29,1.08-2.22,1.12-3.39-0.23c-5.1-5.86-5.04-3.98-0.1-9.52c2.6-2.92,5.27-5.74,7.9-8.63c1.37-1.51,2.26-1.24,2.73,0.81
              c0.68,2.95,1.36,5.9,2.04,8.85C997.4,1818.94,997.65,1818.92,997.9,1818.9z"/>
            <path style="fill:#FFF4EA;" d="M974.95,1815.43l-13.2-6.49c-1.01-0.5-1.43-1.72-0.93-2.72l2.49-5.06
              c0.5-1.01,1.72-1.43,2.72-0.93l13.2,6.49c1.01,0.5,1.43,1.72,0.93,2.72l-2.49,5.06
              C977.18,1815.51,975.96,1815.92,974.95,1815.43z"/>
            <path style="fill:#FFF4EA;" d="M1034.58,1808.35l-12.74,7.35c-0.97,0.56-2.22,0.23-2.78-0.74l-2.82-4.88
              c-0.56-0.97-0.23-2.22,0.75-2.78l12.74-7.35c0.97-0.56,2.22-0.23,2.78,0.75l2.82,4.88
              C1035.89,1806.55,1035.55,1807.79,1034.58,1808.35z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="events">
    <g>
      <path style="fill:#ED8951;" d="M161.54,1310.24l852.36-293.49l-919.56,28.32C103.43,1129.03,125.66,1216.9,161.54,1310.24z"/>
      <g>
        <g>
          <g>

              <rect x="174.77" y="1154.12" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -838.2529 1611.3987)" style="fill:#044A6D;" width="157.55" height="3.01"/>

            <rect x="176.92" y="1154.56" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -835.3417 1613.0508)" style="fill:#1FC3F3;" width="157.55" height="1.35"/>
          </g>
          <g>

              <rect x="190.38" y="1155.23" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -848.5747 1608.1205)" style="fill:#044A6D;" width="113.28" height="6.13"/>
            <g>
              <path style="fill:#1FC3F3;" d="M214.51,1190.55c2.47,12.27,2.47,22.53,1.61,30.34l3.71-1.69c1.95-7.7,3.09-17.7,1.48-29.68
                c-3.3-24.56-16.03-59.09-10.51-83.59l-11.77-3.1C194.6,1127.53,209.27,1164.55,214.51,1190.55z"/>
              <path style="fill:#1FC3F3;" d="M214.51,1190.55c-5.24-26-19.9-63.02-15.47-87.72l-6.75-1.78
                c-15.14,13.88,15.52,123.64,15.52,123.64l8.31-3.79C216.97,1213.08,216.98,1202.83,214.51,1190.55z"/>
              <path style="fill:#1FC3F3;" d="M221.31,1189.52c1.61,11.98,0.47,21.98-1.48,29.68l11.01-5.03l-13.57-106.53l-6.47-1.71
                C205.28,1130.44,218.01,1164.97,221.31,1189.52z"/>
            </g>
            <path style="fill:#1FC3F3;" d="M190.67,1102.44c0,0-0.56,1.16-1.2,3.44l-0.62-0.25l-1.21,7.98l0.39,0.06
              c-0.97,9.15-0.3,23.8,7.53,43.59c0.08,0.21,0.16,0.41,0.24,0.61l0.11,1.34l0.39-0.04c2.59,6.79,4.61,13.41,6.18,19.67
              l0.16,2.8l0.5-0.1c5.79,24.7,4.66,43.16,4.66,43.16l12.57-2.47c0,0,0.21-1.76,0.3-4.81l14.28-7.72l-14.26,2.77
              c-0.07-3.63-0.33-8.1-1.01-13.12l14.12-5.07l-14.77,0.89c-0.86-4.97-2.14-10.35-4.02-15.91l16.76-3.23l-18.18-0.71
              c-0.38-0.99-0.77-1.99-1.2-2.98c-2.28-5.41-4.38-10.11-6.28-14.29l21.93-2.52l-24.58-3.24c-3.18-6.87-5.67-12.21-7.43-17.11
              l27.1,0.34l-29.08-6.98c-0.99-4.36-1.35-8.79-1.04-14.13l27.6,4.02l-26.91-10.82c0.23-1.71,0.52-3.5,0.85-5.42L190.67,1102.44
              z"/>
            <g>

                <rect x="254.19" y="1209.26" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -892.3597 1679.3964)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="253.77" y="1211.42" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -894.0165 1682.3157)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="254.75,1211.53 256.23,1215.71 254.77,1215.97 253.99,1211.67 							"/>
            </g>
            <g>

                <rect x="252.78" y="1201.48" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -886.3647 1668.8323)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="252.36" y="1203.63" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -888.0214 1671.7516)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="253.34,1203.75 254.82,1207.93 253.36,1208.19 252.58,1203.89 							"/>
            </g>
            <g>

                <rect x="251.31" y="1193.39" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -880.1333 1657.8517)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="250.89" y="1195.54" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -881.79 1660.771)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="251.87,1195.66 253.35,1199.83 251.89,1200.1 251.11,1195.8 							"/>
            </g>
            <g>

                <rect x="249.9" y="1185.6" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -874.1382 1647.2877)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="249.48" y="1187.76" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -875.795 1650.207)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="250.46,1187.87 251.94,1192.05 250.48,1192.31 249.7,1188.01 							"/>
            </g>
            <g>

                <rect x="248.44" y="1177.58" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -867.9598 1636.4004)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="248.02" y="1179.73" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -869.6165 1639.3197)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="249.01,1179.85 250.48,1184.03 249.02,1184.29 248.24,1179.99 							"/>
            </g>
            <g>

                <rect x="247.03" y="1169.8" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -861.9647 1625.8363)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="246.61" y="1171.95" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -863.6215 1628.7557)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="247.59,1172.06 249.07,1176.24 247.61,1176.51 246.83,1172.2 							"/>
            </g>
            <g>

                <rect x="245.56" y="1161.7" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -855.7333 1614.8558)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="245.14" y="1163.86" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -857.39 1617.7751)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="246.13,1163.97 247.6,1168.15 246.14,1168.41 245.36,1164.11 							"/>
            </g>
            <g>

                <rect x="244.15" y="1153.92" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -849.7383 1604.2917)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="243.73" y="1156.07" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -851.395 1607.2111)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="244.71,1156.19 246.19,1160.37 244.73,1160.63 243.95,1156.33 							"/>
            </g>
            <g>

                <rect x="242.77" y="1146.32" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -843.8826 1593.9731)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="242.35" y="1148.47" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -845.5392 1596.8926)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="243.33,1148.59 244.81,1152.76 243.35,1153.03 242.57,1148.72 							"/>
            </g>
            <g>

                <rect x="241.3" y="1138.22" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -837.6511 1582.9927)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="240.88" y="1140.38" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -839.3079 1585.912)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="241.87,1140.49 243.34,1144.67 241.88,1144.93 241.1,1140.63 							"/>
            </g>
            <g>

                <rect x="239.89" y="1130.44" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -831.6561 1572.4286)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="239.47" y="1132.59" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -833.3128 1575.3479)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="240.45,1132.71 241.93,1136.89 240.47,1137.15 239.69,1132.85 							"/>
            </g>
            <g>

                <rect x="238.43" y="1122.42" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -825.4776 1561.5413)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="238.01" y="1124.57" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -827.1343 1564.4607)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="239,1124.69 240.47,1128.86 239.02,1129.13 238.24,1124.82 							"/>
            </g>
            <g>

                <rect x="237.02" y="1114.63" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -819.4825 1550.9773)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="236.6" y="1116.78" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -821.1393 1553.8966)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="237.59,1116.9 239.06,1121.08 237.6,1121.34 236.82,1117.04 							"/>
            </g>
            <g>

                <rect x="235.55" y="1106.54" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -813.2512 1539.9967)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="235.13" y="1108.69" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -814.9078 1542.916)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="236.12,1108.81 237.59,1112.99 236.14,1113.25 235.36,1108.95 							"/>
            </g>
            <g>

                <rect x="234.14" y="1098.76" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -807.2561 1529.4326)" style="fill:#C5C8C9;" width="1.38" height="5.89"/>

              <rect x="233.72" y="1100.91" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -808.9128 1532.3521)" style="fill:#1FC3F3;" width="3" height="5.89"/>
              <polygon style="opacity:0.2;fill:#020202;" points="234.7,1101.03 236.18,1105.2 234.72,1105.47 233.94,1101.16 							"/>
            </g>
            <path style="fill:#1FC3F3;" d="M207.92,1234.84c0,0,13.39-27.76-9.65-81.72c0,0-13.81-33.52-6.11-56.61l-16.71,22.83
              c0,0,16.28,36.97-1.68,80.75C173.77,1200.09,195.89,1216.1,207.92,1234.84z"/>
            <polygon style="fill:#044A6D;" points="207.92,1234.84 238.09,1210.75 237.48,1207.41 207.81,1224.69 						"/>
            <polygon style="fill:#044A6D;" points="204.28,1214.77 235.66,1197.37 235.06,1194.03 206.04,1203.98 						"/>
            <polygon style="fill:#044A6D;" points="205.76,1189.73 232.39,1179.35 231.79,1176.01 203.72,1180.03 						"/>
            <polygon style="fill:#044A6D;" points="200.37,1167.96 228.67,1158.85 228.07,1155.5 197.08,1158.38 						"/>
            <polygon style="fill:#044A6D;" points="189.82,1135.07 224.44,1135.51 225.05,1138.85 193.28,1144.62 						"/>
            <path style="fill:#044A6D;" d="M189.3,1103.41l30.25,5.16l-0.61-3.34l-26.78-8.72c-0.13,0.65-0.92,1.38-1.19,1.99
              C190.23,1100.17,189.72,1101.68,189.3,1103.41z"/>
            <polygon style="fill:#044A6D;" points="185.88,1113.34 221.37,1118.6 221.98,1121.95 189.33,1122.88 						"/>

            <rect x="180.4" y="1156.56" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -854.7061 1603.8832)" style="fill:#A4EBF7;" width="123.55" height="4.34"/>

            <rect x="188.34" y="1155.12" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -843.9408 1609.9924)" style="fill:#A4EBF7;" width="123.55" height="4.34"/>
            <g>

                <rect x="235.8" y="1209.22" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -212.9132 61.9891)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="244.86" y="1207.53" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -212.4641 63.6168)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="240.12" y="1208.39" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -212.6936 62.7849)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="234.06" y="1199.65" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -211.2331 61.5255)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="243.12" y="1197.97" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -210.784 63.1532)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="238.38" y="1198.83" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -211.0135 62.3214)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="232.32" y="1190.08" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -209.553 61.0619)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="236.65" y="1189.26" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -209.3334 61.8578)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="241.38" y="1188.4" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -209.1039 62.6897)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="230.59" y="1180.51" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -207.8729 60.5984)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="234.91" y="1179.69" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -207.6533 61.3942)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="239.65" y="1178.83" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -207.4238 62.2261)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="228.85" y="1170.94" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -206.1928 60.1348)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="237.91" y="1169.26" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -205.7437 61.7625)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="233.18" y="1170.12" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -205.9732 60.9306)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="227.12" y="1161.38" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -204.5127 59.6712)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="231.44" y="1160.56" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -204.2931 60.467)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="236.18" y="1159.7" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -204.0635 61.2989)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="225.38" y="1151.81" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -202.8326 59.2076)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="229.7" y="1150.99" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -202.613 60.0035)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="234.44" y="1150.13" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -202.3834 60.8354)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="223.64" y="1142.24" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -201.1525 58.7441)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="227.97" y="1141.42" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -200.9329 59.5399)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="232.71" y="1140.56" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -200.7033 60.3718)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="221.91" y="1132.67" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -199.4723 58.2805)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="230.97" y="1130.99" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -199.0232 59.9082)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="226.23" y="1131.85" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -199.2528 59.0763)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="220.17" y="1123.11" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -197.7922 57.8169)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="229.23" y="1121.43" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -197.3431 59.4446)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="224.5" y="1122.28" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -197.5726 58.6127)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="218.44" y="1113.54" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -196.1121 57.3533)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="227.5" y="1111.86" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -195.663 58.9811)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="222.76" y="1112.72" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -195.8925 58.1492)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="216.7" y="1103.97" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -194.432 56.8897)" style="fill:none;" width="4.4" height="9.72"/>

              <rect x="225.76" y="1102.29" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -193.9829 58.5175)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>

              <rect x="221.02" y="1103.15" transform="matrix(0.9839 -0.1785 0.1785 0.9839 -194.2124 57.6856)" style="fill:#1FC3F3;" width="4.81" height="9.72"/>
            </g>

            <rect x="179.34" y="1157.84" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -856.1557 1603.0605)" style="fill:#A4EBF7;" width="123.55" height="2.17"/>

            <rect x="187.27" y="1156.4" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -845.3904 1609.1698)" style="fill:#A4EBF7;" width="123.55" height="2.17"/>
            <path style="fill:#A4EBF7;" d="M224.17,1229.2c0,0,2.13-60.19-10.44-130.51l5.39-0.98l23.26,128.19L224.17,1229.2z"/>

            <rect x="164.24" y="1160.67" transform="matrix(-0.1785 -0.9839 0.9839 -0.1785 -873.0552 1595.2)" style="fill:#A4EBF7;" width="130.28" height="2.77"/>
            <path style="fill:#A4EBF7;" d="M213.73,1098.69c0,0,11.38,67.16,10.44,130.51l4.99-0.9c0,0-0.42-49.61-14.37-129.8
              L213.73,1098.69z"/>
            <g>
              <path style="fill:#044A6D;" d="M180.79,1112.04c-1.92,15.93,5.27,20.47,14.61,42.24c15.28,35.64,13.54,70.37-0.34,58.82
                c-11.77-9.8-18.04-12.61-21.19-13.29c-0.04,0.09-0.07,0.18-0.11,0.27c0,0,22.12,16.01,34.15,34.75
                c0,0,13.39-27.76-9.65-81.72c0,0-13.81-33.52-6.11-56.61L180.79,1112.04z"/>
            </g>
            <polygon style="fill:#044A6D;" points="207.92,1234.84 222.68,1223.05 208.74,1229.04 207.69,1234.48 						"/>
            <polygon style="fill:#044A6D;" points="210.44,1211.36 223.19,1204.29 209.6,1206.76 						"/>
            <polygon style="fill:#044A6D;" points="207.95,1188.88 222.16,1183.34 205.97,1184.81 						"/>
            <polygon style="fill:#044A6D;" points="201.73,1167.52 219.43,1161.82 200.86,1162.72 						"/>
            <polygon style="fill:#044A6D;" points="193.28,1144.62 215.27,1140.63 192.38,1139.69 						"/>
            <polygon style="fill:#044A6D;" points="187.62,1122.94 213.75,1122.18 187.87,1118.09 						"/>
            <polygon style="fill:#044A6D;" points="188.63,1103.24 211.43,1107.19 191.26,1099.65 						"/>
            <path style="fill:#044A6D;" d="M174.84,1197.36c0,0,15.11-35.09,0.61-78.02C175.45,1119.34,200.74,1150.39,174.84,1197.36z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="sports">
    <g>
      <path style="fill:#ED8951;" d="M1010.11,1014.18L122.85,739.23c-30.13,97.28-40.02,197.68-28.66,304.41l-0.04-1.26
        L1010.11,1014.18z"/>
      <g>
        <g>

            <rect x="192.34" y="905.91" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -690.587 1038.5952)" style="fill:#044A6D;" width="90.72" height="2.08"/>
          <g>
            <polygon style="fill:#1FC3F3;" points="236.04,930.29 236.85,923.3 232.44,922.47 231.62,929.56 						"/>
            <polygon style="fill:#1FC3F3;" points="230.77,936.9 235.21,937.41 236.04,930.29 231.62,929.56 						"/>
            <polygon style="fill:#1FC3F3;" points="229.84,944.94 234.3,945.28 235.21,937.41 230.77,936.9 						"/>
            <path style="fill:#A4EBF7;" d="M180.48,921.13l51.14,8.43l0.82-7.09L181.85,913C181.3,915.78,180.83,918.5,180.48,921.13z"/>
            <path style="fill:#A4EBF7;" d="M190.98,949.69l38.09,1.92l0.77-6.66l-48.6-3.67C182.86,945.97,185.93,949.05,190.98,949.69z"
            />
            <path style="fill:#A4EBF7;" d="M179.78,930.99c0.01,3.91,0.46,7.4,1.45,10.29l48.6,3.67l0.93-8.05L179.78,930.99z"/>
            <path style="fill:#A4EBF7;" d="M181.85,913l50.59,9.48l0.9-7.76l-49.68-9.69C182.99,907.71,182.38,910.38,181.85,913z"/>
            <path style="fill:#A4EBF7;" d="M231.62,929.56l-51.14-8.43c-0.46,3.48-0.71,6.8-0.7,9.85l50.99,5.91L231.62,929.56z"/>
            <path style="fill:#A4EBF7;" d="M195.72,868.06l42.06,8.39l0.89-7.66l-40.28-7.46C197.57,863.33,196.67,865.59,195.72,868.06z"
            />
            <path style="fill:#A4EBF7;" d="M202.29,852.07c0,0-1.58,3.54-3.91,9.26l40.28,7.46l0.85-7.3L202.29,852.07z"/>
            <path style="fill:#A4EBF7;" d="M193,875.29l43.92,8.53l0.85-7.37l-42.06-8.39C194.85,870.31,193.93,872.73,193,875.29z"/>
            <path style="fill:#A4EBF7;" d="M190.64,881.91L235.97,892l0.95-8.18L193,875.29C192.22,877.41,191.43,879.63,190.64,881.91z"
            />
            <path style="fill:#A4EBF7;" d="M185.72,897.39l48.48,9.93l0.9-7.75l-47.04-9.9C187.25,892.22,186.47,894.8,185.72,897.39z"/>
            <path style="fill:#A4EBF7;" d="M183.66,905.02l49.68,9.69l0.86-7.39l-48.48-9.93C184.99,899.93,184.3,902.49,183.66,905.02z"
            />
            <path style="fill:#A4EBF7;" d="M188.06,889.68l47.04,9.9l0.88-7.57l-45.33-10.09C189.77,884.43,188.9,887.03,188.06,889.68z"
            />
          </g>
          <g>

              <rect x="177.22" y="905.64" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -680.8253 1050.0389)" style="fill:#044A6D;" width="143.56" height="3.09"/>

            <rect x="179.45" y="906.75" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -679.1131 1052.4771)" style="fill:#1FC3F3;" width="143.56" height="1.39"/>
          </g>
          <g>

              <rect x="233.6" y="943.24" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -734.5599 1072.9025)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="234.52" y="941.19" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -732.3141 1071.3253)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="234.54" y="935.13" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -725.6763 1066.6639)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="235.46" y="933.08" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -723.4305 1065.0868)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="235.52" y="926.61" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -716.3422 1060.109)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="236.45" y="924.57" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -714.0964 1058.5319)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="236.46" y="918.51" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -707.4586 1053.8705)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="237.39" y="916.46" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -705.2128 1052.2933)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="237.43" y="910.13" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -698.2756 1047.4216)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="238.36" y="908.08" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -696.0297 1045.8445)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="238.37" y="902.02" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -689.392 1041.1831)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="239.3" y="899.97" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -687.1461 1039.606)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="239.31" y="893.96" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -680.5596 1034.9806)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="240.23" y="891.92" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -678.3138 1033.4034)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="240.28" y="885.6" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -671.3892 1028.5406)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="241.2" y="883.55" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -669.1434 1026.9635)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="241.22" y="877.49" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -662.5056 1022.3021)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="242.14" y="875.44" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -660.2598 1020.7249)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="242.21" y="868.97" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -653.1715 1015.7471)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="243.13" y="866.93" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -650.9257 1014.17)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>
          <g>

              <rect x="243.15" y="860.87" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -644.2879 1009.5086)" style="fill:#1FC3F3;" width="2.75" height="11.09"/>

            <rect x="244.07" y="858.82" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -642.0421 1007.9315)" style="fill:#044A6D;" width="1.38" height="11.09"/>
          </g>

          <rect x="194.13" y="906.36" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -684.6797 1047.4645)" style="fill:#1FC3F3;" width="103" height="3.41"/>

          <rect x="190.12" y="905.49" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -692.2966 1036.1608)" style="fill:#A4EBF7;" width="90.72" height="2.4"/>
          <g>

              <rect x="185.67" y="936.85" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -756.1787 1028.2522)" style="fill:#1FC3F3;" width="26.85" height="3.47"/>

            <rect x="184.81" y="937.62" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -756.8423 1027.3073)" style="fill:#044A6D;" width="26.85" height="1.74"/>
          </g>
          <g>

              <rect x="192.74" y="932.69" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -741.4413 1036.4694)" style="fill:#1FC3F3;" width="36.68" height="3.47"/>

            <rect x="191.88" y="933.46" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -742.1049 1035.5243)" style="fill:#044A6D;" width="36.68" height="1.74"/>
          </g>
          <g>

              <rect x="199.64" y="927.15" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -724.6547 1044.2275)" style="fill:#1FC3F3;" width="48.38" height="3.47"/>

            <rect x="198.77" y="927.92" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -725.3183 1043.2826)" style="fill:#044A6D;" width="48.38" height="1.74"/>
          </g>
          <polygon style="fill:#075F82;" points="202.37,925.46 203.15,950.3 199.51,950.12 					"/>
          <polygon style="fill:#075F82;" points="214.92,916.41 215.19,950.91 210.94,950.7 					"/>
          <polygon style="fill:#075F82;" points="228.34,905.05 227.18,951.51 222.98,951.3 					"/>

          <rect x="193.29" y="907.11" transform="matrix(0.1152 -0.9933 0.9933 0.1152 -685.3316 1046.5363)" style="fill:#044A6D;" width="103" height="1.71"/>
          <g>
            <path style="fill:#A4EBF7;" d="M198.54,862.74c3.24-8.21,7.89-15.17,13.53-19.55c-10.58-2.48-19.44-1.63-25.96,0.03
              c-3.92,4.52-7.02,11-8.07,20.73C183.53,862.93,190.65,862.17,198.54,862.74z"/>
            <path style="fill:#1FC3F3;" d="M154.72,944.44c0.22,2.71,1.02,5.15,2.11,7.28c3.15,2.61,7.24,4.47,11.71,5.15
              c-1.35-2.03-2.64-4.47-3.45-7.14C160.68,948.64,157.27,946.59,154.72,944.44z"/>
            <path style="fill:#A4EBF7;" d="M192.13,885.63c-5.93-0.92-11.4-1.24-15.94-1.28c-1.01,7.56-2.57,14.98-5.17,22.39
              c3.26,0.94,7.02,1.92,11.23,2.86C186.09,901.89,189.58,893.69,192.13,885.63z"/>
            <path style="fill:#1FC3F3;" d="M203.87,835.95c-4.59,0.12-8.59,0.92-12.1,2.26c-1.98,1.35-3.9,2.99-5.66,5.02
              c6.52-1.66,15.38-2.5,25.96-0.03c2.38-1.85,4.93-3.25,7.63-4.08C215.02,836.97,209.64,835.8,203.87,835.95z"/>
            <path style="fill:#1FC3F3;" d="M182.25,909.6c-4.21-0.94-7.98-1.92-11.23-2.86c-2.48,7.07-5.91,14.12-10.73,21.26
              c2.52,1.37,5.6,2.64,9.32,3.56C173.76,925.31,178.2,917.74,182.25,909.6z"/>
            <path style="fill:#1FC3F3;" d="M192.13,885.63c1.17-3.68,2.15-7.33,2.87-10.88c0.85-4.18,2.04-8.22,3.54-12
              c-7.89-0.57-15.01,0.18-20.51,1.21c-0.1,0.95-0.19,1.93-0.25,2.94c-0.37,5.94-0.83,11.74-1.59,17.47
              C180.73,884.4,186.21,884.72,192.13,885.63z"/>
            <path style="fill:#A4EBF7;" d="M167.34,934.91c0.74-1.06,1.5-2.18,2.27-3.34c-3.72-0.92-6.8-2.19-9.32-3.56
              c-0.24,0.35-0.47,0.7-0.71,1.05c-4.02,5.8-5.22,10.96-4.86,15.38c2.55,2.15,5.96,4.2,10.37,5.29
              C163.73,945.22,163.77,940.02,167.34,934.91z"/>
            <path style="fill:#A4EBF7;" d="M171.02,906.75c-3.72-1.07-6.78-2.08-9.08-2.9c-2.92,7.44-6.11,12.99-8.38,19.25
              c1.55,1.51,3.75,3.29,6.74,4.91C165.11,920.87,168.54,913.81,171.02,906.75z"/>
            <path style="fill:#1FC3F3;" d="M154.72,944.44c-0.35-4.42,0.84-9.58,4.86-15.38c0.24-0.35,0.47-0.7,0.71-1.05
              c-2.98-1.63-5.19-3.4-6.74-4.91c-1.66,4.57-2.83,9.51-3.05,15.83c-0.01,0.34-0.01,0.68,0.01,1.02
              C151.5,941.29,152.89,942.89,154.72,944.44z"/>
            <path style="fill:#1FC3F3;" d="M176.19,884.35c-3.67-0.03-6.72,0.12-8.92,0.28c-1.38,7.9-3.29,14.02-5.33,19.21
              c2.3,0.82,5.36,1.83,9.08,2.9C173.62,899.34,175.17,891.91,176.19,884.35z"/>
            <path style="fill:#1FC3F3;" d="M186.1,843.22c-1.6,0.41-3.06,0.87-4.37,1.34c-5.79,5.56-9.25,13.22-11.3,21.2
              c1.92-0.56,4.5-1.22,7.61-1.8C179.08,854.22,182.18,847.74,186.1,843.22z"/>
            <path style="fill:#A4EBF7;" d="M177.78,866.89c0.06-1.01,0.15-1.99,0.25-2.94c-3.1,0.58-5.69,1.24-7.61,1.8
              c-1.01,3.91-1.68,7.89-2.13,11.74c-0.29,2.54-0.64,4.91-1.03,7.15c2.2-0.17,5.26-0.31,8.92-0.28
              C176.96,878.63,177.42,872.82,177.78,866.89z"/>
            <path style="fill:#1FC3F3;" d="M191.76,838.21c-3.95,1.5-7.26,3.69-10.04,6.35c1.31-0.47,2.77-0.93,4.37-1.34
              C187.86,841.19,189.78,839.56,191.76,838.21z"/>
            <path style="fill:#A4EBF7;" d="M150.5,939.95c0.18,4.53,2.6,8.68,6.33,11.78c-1.09-2.13-1.9-4.57-2.11-7.28
              C152.89,942.89,151.5,941.29,150.5,939.95z"/>
            <path style="fill:#1FC3F3;" d="M225.99,870.76c2.85,1.59,5.69,3.45,8.47,5.64c0.9-2.25,1.61-4.54,2.1-6.87
              c1.27-5.97,0.28-11.82-2.37-16.93c-1.68-1.04-3.34-1.98-4.97-2.85C229.67,857.15,228.42,864.13,225.99,870.76z"/>
            <path style="fill:#A4EBF7;" d="M234.2,852.6c-1.38-2.66-3.21-5.13-5.43-7.29c0.23,1.5,0.37,2.98,0.46,4.44
              C230.86,850.62,232.52,851.57,234.2,852.6z"/>
            <path style="fill:#1FC3F3;" d="M214.22,891.89c2.64,1.16,5.27,2.49,7.83,4.01c5.17-6.04,9.66-12.57,12.42-19.51
              c-2.78-2.18-5.62-4.05-8.47-5.64C223.28,878.2,219.1,885.22,214.22,891.89z"/>
            <path style="fill:#A4EBF7;" d="M175.27,950.42c3.79-0.35,8.05-1.34,12.82-3.21c-2-2.62-4.19-7.38-1.91-14.27
              c-2.45,0.13-4.75,0.13-6.91,0.02C174.84,939.73,174.32,945.7,175.27,950.42z"/>
            <path style="fill:#1FC3F3;" d="M177.58,956.62c4.44-0.95,8.89-3.16,12.9-6.98c0,0-1.16-0.81-2.39-2.43
              c-4.77,1.87-9.02,2.86-12.82,3.21C175.76,952.83,176.64,954.92,177.58,956.62z"/>
            <path style="fill:#A4EBF7;" d="M197.02,912.3c2.49,0.36,5.06,0.69,7.73,0.97c5.69-5.18,11.87-11,17.3-17.36
              c-2.56-1.52-5.19-2.85-7.83-4.01C208.99,899.04,202.94,905.8,197.02,912.3z"/>
            <path style="fill:#1FC3F3;" d="M197.02,912.3c-5.53,6.06-10.96,11.9-15.54,17.61c-0.83,1.03-1.56,2.05-2.21,3.05
              c2.16,0.11,4.46,0.11,6.91-0.02c0-0.01,0.01-0.02,0.01-0.03c1.54-4.64,9.42-11.32,18.56-19.64
              C202.08,912.99,199.51,912.66,197.02,912.3z"/>
            <path style="fill:#1FC3F3;" d="M169.61,931.57c-0.77,1.16-1.53,2.28-2.27,3.34c-3.57,5.11-3.61,10.31-2.24,14.83
              c2.93,0.73,6.31,1.04,10.18,0.68c-0.96-4.71-0.43-10.68,3.99-17.47C175.65,932.76,172.45,932.27,169.61,931.57z"/>
            <path style="fill:#A4EBF7;" d="M165.1,949.73c0.81,2.67,2.11,5.11,3.45,7.14c2.9,0.45,5.96,0.4,9.03-0.26
              c-0.94-1.7-1.82-3.78-2.31-6.2C171.41,950.77,168.03,950.46,165.1,949.73z"/>
            <path style="fill:#A4EBF7;" d="M212.06,843.19c5.31,1.24,11.06,3.33,17.17,6.56c-0.09-1.47-0.24-2.95-0.46-4.44
              c-2.56-2.5-5.62-4.62-9.07-6.2C217,839.95,214.44,841.34,212.06,843.19z"/>
            <path style="fill:#A4EBF7;" d="M169.61,931.57c2.84,0.7,6.04,1.2,9.66,1.39c0.65-1,1.38-2.01,2.21-3.05
              c4.58-5.7,10.01-11.54,15.54-17.61c-5.37-0.78-10.32-1.71-14.76-2.7C178.2,917.74,173.76,925.31,169.61,931.57z"/>
            <path style="fill:#1FC3F3;" d="M225.99,870.76c2.42-6.64,3.68-13.61,3.24-21.01c-6.1-3.24-11.85-5.32-17.17-6.56
              c-5.64,4.38-10.29,11.34-13.53,19.55C207.15,863.37,216.68,865.58,225.99,870.76z"/>
            <path style="fill:#A4EBF7;" d="M198.54,862.74c-1.49,3.78-2.69,7.82-3.54,12c-0.72,3.56-1.7,7.2-2.87,10.88
              c7,1.08,14.63,2.99,22.08,6.26c4.89-6.68,9.06-13.69,11.78-21.13C216.68,865.58,207.15,863.37,198.54,862.74z"/>
            <path style="fill:#1FC3F3;" d="M182.25,909.6c4.45,0.99,9.39,1.92,14.76,2.7c5.93-6.5,11.97-13.26,17.2-20.41
              c-7.45-3.27-15.08-5.18-22.08-6.26C189.58,893.69,186.09,901.89,182.25,909.6z"/>
          </g>
          <path style="fill:#075F82;" d="M150.68,929.84c0,0,14.06,11.89,34.53,10.66C185.2,940.5,163.39,948.1,150.68,929.84z"/>
          <path style="fill:#075F82;" d="M158.09,912.13c0,0,23.03,7.89,37.28,9.62C195.37,921.75,170.34,923.67,158.09,912.13z"/>
          <path style="fill:#075F82;" d="M164.94,896.85c0,0,25.1,2.21,49.18,8.52C214.12,905.37,190.53,894.05,164.94,896.85z"/>
          <path style="fill:#075F82;" d="M169.02,876.31c0,0,24.89-10.02,60.59,9.81C229.61,886.12,200.8,873.43,169.02,876.31z"/>
          <path style="fill:#075F82;" d="M172.92,859.74c0,0,27.24-22.49,63.39,5.85C236.31,865.59,204.93,846.1,172.92,859.74z"/>
          <g>
            <path style="fill:#044A6D;" d="M186.78,941.74c-0.05,0.46-0.42,0.85-0.95,0.95c-26.01,5.27-35.61-11.19-35.71-11.35
              c-0.32-0.56-0.07-1.24,0.56-1.5c0.62-0.27,1.38-0.03,1.7,0.53l0,0c0.35,0.61,8.84,14.98,32.82,10.12
              c0.68-0.14,1.37,0.24,1.54,0.85C186.78,941.48,186.79,941.61,186.78,941.74z"/>
            <path style="fill:#044A6D;" d="M196.68,923.03c-0.06,0.55-0.58,0.98-1.22,0.98c-23.79,0.14-38.28-9.69-38.88-10.11
              c-0.56-0.39-0.67-1.1-0.25-1.58c0.42-0.49,1.21-0.57,1.77-0.18c0.14,0.1,14.43,9.76,37.28,9.62c0.7,0,1.29,0.5,1.31,1.12
              C196.68,922.92,196.68,922.98,196.68,923.03z"/>
            <path style="fill:#044A6D;" d="M215.84,904.48c-0.01,0.1-0.04,0.2-0.08,0.3c-0.26,0.57-0.99,0.84-1.64,0.6
              c-25.84-9.67-48.95-8.53-49.18-8.52c-0.7,0.04-1.31-0.43-1.37-1.06c-0.07-0.62,0.45-1.16,1.15-1.2
              c0.24-0.01,23.91-1.19,50.33,8.7C215.59,903.5,215.9,903.99,215.84,904.48z"/>
            <path style="fill:#044A6D;" d="M231.51,885.31c-0.02,0.15-0.07,0.3-0.16,0.44c-0.35,0.53-1.13,0.7-1.73,0.37
              c-33.8-18.24-60.33-9.9-60.59-9.81c-0.65,0.21-1.39-0.09-1.64-0.67c-0.26-0.58,0.06-1.23,0.71-1.45
              c1.12-0.37,27.88-8.83,62.8,10.01C231.33,884.44,231.56,884.88,231.51,885.31z"/>
            <path style="fill:#044A6D;" d="M238.37,864.88c-0.02,0.21-0.11,0.41-0.27,0.58c-0.44,0.47-1.24,0.53-1.78,0.13
              c-18.15-13.42-34.44-14.27-44.9-12.63c-11.32,1.78-18.42,6.73-18.49,6.78c-0.54,0.38-1.34,0.29-1.78-0.2
              c-0.45-0.49-0.37-1.2,0.17-1.58c0.31-0.22,7.66-5.35,19.6-7.23c11-1.73,28.08-0.85,47,13.14
              C238.25,864.13,238.41,864.51,238.37,864.88z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="worship">
    <g>
      <path style="fill:#ED8951;" d="M253.87,480.09c-59.21,84.56-102.86,169.32-130.23,256.57l890.94,276.09L253.87,480.09z"/>
      <g>
        <g>
          <g>

              <rect x="235.48" y="627.71" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -438.9301 577.1006)" style="fill:#1FC3F3;" width="1.13" height="14.58"/>
          </g>
          <g>
            <g>

                <rect x="229.4" y="633.1" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -439.34 573.7913)" style="fill:#1FC3F3;" width="7.65" height="1.13"/>
            </g>
          </g>
          <g>
            <polygon style="fill:#1FC3F3;" points="253.66,701.7 244.77,693.28 263.19,654.29 273.71,659.26 						"/>
          </g>
          <g>
            <polygon style="fill:#1FC3F3;" points="298.76,606.26 286.61,604.74 268.18,643.73 278.7,648.7 						"/>
          </g>
          <g>
            <g>

                <rect x="279.62" y="642.12" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -430.3631 646.2773)" style="fill:#1FC3F3;" width="30.61" height="41.4"/>
            </g>
          </g>
          <g>

              <rect x="254.25" y="633.13" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -435.1669 607.4882)" style="fill:#1FC3F3;" width="15.31" height="28.19"/>
          </g>
          <g>

              <rect x="291.18" y="612.05" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -395.0331 641.902)" style="fill:#A4EBF7;" width="35.91" height="41.4"/>
          </g>
          <g>

              <rect x="262.76" y="672.2" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -465.6928 650.6526)" style="fill:#A4EBF7;" width="35.91" height="41.4"/>
          </g>
          <g>
            <polygon style="fill:#1FC3F3;" points="276.21,653.98 261.1,646.84 269.67,667.82 						"/>
          </g>
          <g>
            <polygon style="fill:#1FC3F3;" points="276.21,653.98 261.1,646.84 282.75,640.14 						"/>
          </g>
          <g>
            <g>
              <polygon style="fill:#044A6D;" points="272.63,671.64 270.72,672.45 259.76,646.21 262.06,646.44 							"/>
            </g>
          </g>
          <g>
            <g>
              <polygon style="fill:#044A6D;" points="287.58,640 261.4,647.84 259.76,646.21 286.99,638.01 							"/>
            </g>
          </g>
          <g>
            <polygon style="fill:#044A6D;" points="239.85,636.8 249.16,641.2 245.89,648.12 						"/>
          </g>
          <g>
            <polygon style="fill:#044A6D;" points="239.85,636.8 249.16,641.2 252.43,634.28 						"/>
          </g>
          <g>

              <rect x="275.64" y="661.77" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -442.8633 632.7869)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
          </g>
          <g>

              <rect x="284.22" y="643.61" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -421.5315 630.1451)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
          </g>
          <g>
            <polygon style="fill:#CCFFFF;" points="283.99,660.3 286.03,655.98 276.2,651.33 275.18,653.49 274.15,655.65 						"/>
          </g>
          <g>
            <path style="fill:#CCFFFF;" d="M272.79,652.36c-0.38,0.8-0.63,2.34,1.37,3.29l1.02-2.16l1.02-2.16
              C274.2,650.39,273.17,651.56,272.79,652.36z"/>
          </g>
          <g>
            <path style="fill:#CCFFFF;" d="M277,668.1l2.04-4.32L277,668.1z"/>

            <rect x="275.64" y="665.38" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -442.8633 632.7869)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
          </g>
          <g>
            <path style="fill:#CCFFFF;" d="M285.59,649.95l2.04-4.32L285.59,649.95z"/>

            <rect x="284.22" y="647.22" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -421.5315 630.1451)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
          </g>
          <g>

              <rect x="252.43" y="639.7" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -436.1982 599.1603)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
          </g>
          <g>
            <path style="fill:#CCFFFF;" d="M253.8,646.03l2.04-4.32L253.8,646.03z"/>

            <rect x="252.43" y="643.31" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -436.1983 599.1599)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
          </g>
          <g>
            <path style="fill:#CCFFFF;" d="M278.93,657.91l2.04-4.32L278.93,657.91z"/>

            <rect x="277.56" y="655.18" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -432.5417 628.6857)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
          </g>
          <g>
            <g>

                <rect x="283.39" y="703.33" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -475.9962 663.6028)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
              <g>
                <path style="fill:#CCFFFF;" d="M284.76,709.66l2.04-4.32L284.76,709.66z"/>

                <rect x="283.39" y="706.94" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -475.9962 663.6028)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>

                <rect x="288.11" y="693.35" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -464.2693 662.1505)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
              <g>
                <path style="fill:#CCFFFF;" d="M289.48,699.68l2.04-4.32L289.48,699.68z"/>

                <rect x="288.11" y="696.96" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -464.2693 662.1505)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>

                <rect x="292.83" y="683.36" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -452.5411 660.6981)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
              <g>
                <path style="fill:#CCFFFF;" d="M294.2,689.7l2.04-4.32L294.2,689.7z"/>

                <rect x="292.83" y="686.97" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -452.5411 660.6981)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>
              <g>
                <polygon style="fill:#CCFFFF;" points="274.96,705.03 277,700.71 267.16,696.06 266.14,698.22 265.12,700.38 								"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M263.76,697.1c-0.38,0.81-0.63,2.34,1.36,3.29l1.02-2.16l1.02-2.16
                  C265.17,695.12,264.14,696.29,263.76,697.1z"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M269.9,702.64l2.04-4.32L269.9,702.64z"/>

                <rect x="268.53" y="699.92" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -478.1588 646.1407)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>
              <g>
                <polygon style="fill:#CCFFFF;" points="279.67,695.05 281.72,690.73 271.88,686.08 270.86,688.24 269.84,690.4 								"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M268.48,687.11c-0.38,0.81-0.63,2.34,1.36,3.29l1.02-2.16l1.02-2.16
                  C269.89,685.14,268.86,686.31,268.48,687.11z"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M274.61,692.66l2.04-4.32L274.61,692.66z"/>

                <rect x="273.25" y="689.93" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -466.4318 644.6884)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>
              <g>
                <polygon style="fill:#CCFFFF;" points="284.39,685.07 286.43,680.75 276.6,676.1 275.58,678.26 274.56,680.42 								"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M273.19,677.13c-0.38,0.81-0.63,2.34,1.36,3.29l1.02-2.16l1.02-2.16
                  C274.6,675.16,273.57,676.33,273.19,677.13z"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M279.33,682.67l2.04-4.32L279.33,682.67z"/>

                <rect x="277.96" y="679.95" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -454.7049 643.2361)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
          </g>
          <g>
            <g>

                <rect x="311.57" y="643.69" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -405.9358 654.9263)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
              <g>
                <path style="fill:#CCFFFF;" d="M312.94,650.03l2.04-4.32L312.94,650.03z"/>

                <rect x="311.57" y="647.3" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -405.9358 654.9263)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>

                <rect x="316.29" y="633.71" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -394.2089 653.4741)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
              <g>
                <path style="fill:#CCFFFF;" d="M317.66,640.04l2.04-4.32L317.66,640.04z"/>

                <rect x="316.29" y="637.32" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -394.2089 653.4741)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>

                <rect x="321.01" y="623.73" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -382.4807 652.0216)" style="fill:#CCFFFF;" width="4.78" height="8.35"/>
              <g>
                <path style="fill:#CCFFFF;" d="M322.38,630.06l2.04-4.32L322.38,630.06z"/>

                <rect x="321.01" y="627.34" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -382.4807 652.0216)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>
              <g>
                <polygon style="fill:#CCFFFF;" points="303.14,645.39 305.18,641.07 295.35,636.43 294.33,638.59 293.31,640.75 								"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M291.94,637.46c-0.38,0.81-0.63,2.34,1.36,3.29l1.02-2.16l1.02-2.16
                  C293.35,635.48,292.32,636.66,291.94,637.46z"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M298.08,643l2.04-4.32L298.08,643z"/>

                <rect x="296.71" y="640.28" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -408.0984 637.4642)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>
              <g>
                <polygon style="fill:#CCFFFF;" points="307.86,635.41 309.9,631.09 300.06,626.45 299.04,628.6 298.02,630.76 								"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M296.66,627.48c-0.38,0.81-0.63,2.34,1.36,3.29l1.02-2.16l1.02-2.16
                  C298.07,625.5,297.04,626.67,296.66,627.48z"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M302.8,633.02l2.04-4.32L302.8,633.02z"/>

                <rect x="301.43" y="630.3" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -396.3714 636.0119)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
            <g>
              <g>
                <polygon style="fill:#CCFFFF;" points="312.57,625.43 314.61,621.11 304.78,616.46 303.76,618.62 302.74,620.78 								"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M301.38,617.5c-0.38,0.81-0.63,2.34,1.36,3.29l1.02-2.16l1.02-2.16
                  C302.79,615.52,301.76,616.69,301.38,617.5z"/>
              </g>
              <g>
                <path style="fill:#CCFFFF;" d="M307.51,623.04l2.04-4.32L307.51,623.04z"/>

                <rect x="306.14" y="620.32" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -384.6445 634.5596)" style="fill:#1FC3F3;" width="4.78" height="1.13"/>
              </g>
            </g>
          </g>
          <g>

              <rect x="262.31" y="670.75" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -427.4305 669.9597)" style="fill:#044A6D;" width="105.55" height="3.19"/>
          </g>
          <g>
            <g>

                <rect x="298.16" y="658" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -432.665 655.9476)" style="fill:#044A6D;" width="6.49" height="22.96"/>
            </g>
            <g>

                <rect x="300.93" y="652.13" transform="matrix(0.4273 -0.9041 0.9041 0.4273 -425.7718 655.0939)" style="fill:#044A6D;" width="6.49" height="22.96"/>
            </g>
            <g>
              <path style="fill:#CCFFFF;" d="M289.13,667.26l2.77-5.87l-3.87-1.83C287.44,661.19,286.28,665.38,289.13,667.26z"/>
            </g>
            <g>
              <path style="fill:#CCFFFF;" d="M291.9,661.4l2.77-5.87c-3.27-1.01-5.77,2.55-6.65,4.04L291.9,661.4z"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="oil-rig">
    <g>
      <path style="fill:#6AD0EF;" d="M274.37,494.65l740.15,518.06l-559.07-728.7C389.01,341.53,328.53,411.63,274.37,494.65z"/>
      <g>
        <path style="fill:#044A6D;" d="M412.59,469.65l-2.14,2.49l-3.66-3.15c-2.76-2.38-6.94-2.06-9.32,0.7l-4.92,5.72l-2.49-2.14
          l4.92-5.72c3.55-4.13,9.81-4.61,13.94-1.05L412.59,469.65z"/>
        <path style="fill:#044A6D;" d="M429.01,504.86l-7.66,8.92l-38.88-33.42c-0.97-0.84-1.08-2.3-0.25-3.28l4.63-5.39
          c0.84-0.97,2.3-1.08,3.28-0.25L429.01,504.86z"/>
        <path style="fill:#044A6D;" d="M374.49,469.53l3.74-4.35l-2.25-1.93l-3.74,4.35L374.49,469.53z M368.93,464.75l3.74-4.35
          l-2.25-1.93l-3.74,4.35L368.93,464.75z M361.21,454.33c-1.03,1.2-0.9,3.01,0.31,4.05l1.85,1.59l3.74-4.35l-1.85-1.59
          C364.06,452.99,362.25,453.12,361.21,454.33z M381.84,475.84l3.74-4.35l-4.03-3.46l-3.74,4.35L381.84,475.84z"/>
        <g>
          <path style="fill:#FFFFFF;" d="M369.89,457.82l-3.92,4.56c-0.49,0.57-1.36,0.64-1.93,0.15s-0.64-1.36-0.15-1.93l3.92-4.56
            c0.49-0.57,1.36-0.64,1.93-0.15S370.39,457.25,369.89,457.82z"/>
          <path style="fill:#FFFFFF;" d="M375.46,462.61l-3.92,4.56c-0.49,0.57-1.36,0.64-1.93,0.15s-0.64-1.36-0.15-1.93l3.92-4.56
            c0.49-0.57,1.36-0.64,1.93-0.15S375.95,462.03,375.46,462.61z"/>
          <path style="fill:#FFFFFF;" d="M381.02,467.39l-3.92,4.56c-0.49,0.57-1.36,0.64-1.93,0.15c-0.57-0.49-0.64-1.36-0.15-1.93
            l3.92-4.56c0.49-0.57,1.36-0.64,1.93-0.15C381.45,465.95,381.51,466.82,381.02,467.39z"/>
        </g>
        <path style="fill:#044A6D;" d="M489.52,439.51l-70.14,81.59l-1.5-1.29c-0.54-0.47-0.61-1.29-0.14-1.83l68.45-79.62
          c0.47-0.54,1.29-0.61,1.83-0.14L489.52,439.51z"/>
        <path style="fill:#044A6D;" d="M418.43,517.16l-0.71,0.83l-34.99-30.08c-1.21-1.04-1.35-2.87-0.31-4.08l1.74-2.02l0.83,0.71
          l-1.74,2.02c-0.65,0.75-0.56,1.89,0.19,2.54L418.43,517.16z"/>
        <g>

            <rect x="384.1" y="486.67" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -234.8786 462.7477)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="387.88" y="489.92" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -236.0283 466.7493)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="391.67" y="493.17" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -237.1779 470.7509)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="395.45" y="496.43" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -238.3276 474.7525)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="399.23" y="499.68" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -239.4773 478.7541)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="403.02" y="502.93" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -240.627 482.7557)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="406.8" y="506.18" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -241.7766 486.7573)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="410.58" y="509.44" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -242.9263 490.7589)" style="fill:#044A6D;" width="5.01" height="1.09"/>

          <rect x="414.37" y="512.69" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -244.076 494.7604)" style="fill:#044A6D;" width="5.01" height="1.09"/>
        </g>
        <path style="fill:#FFFFFF;" d="M421.07,494.61l15.33-17.83l-5.09-4.38l-15.33,17.83L421.07,494.61z M410.69,470.18
          c-4.23,4.92-3.67,12.35,1.25,16.58l0.73,0.62l15.33-17.83l-0.73-0.62C422.34,464.69,414.92,465.25,410.69,470.18z M430.7,502.89
          l15.33-17.83l-6.31-5.43l-15.33,17.83L430.7,502.89z"/>
        <path style="fill:#044A6D;" d="M430.72,471.83l-15.39,17.9c-0.49,0.57-1.36,0.64-1.93,0.15l0,0c-0.57-0.49-0.64-1.36-0.15-1.93
          l15.39-17.9c0.49-0.57,1.36-0.64,1.93-0.15l0,0C431.14,470.4,431.21,471.26,430.72,471.83z"/>
        <path style="fill:#044A6D;" d="M439.13,479.06l-15.39,17.9c-0.49,0.57-1.36,0.64-1.93,0.15l0,0c-0.57-0.49-0.64-1.36-0.15-1.93
          l15.39-17.9c0.49-0.57,1.36-0.64,1.93-0.15l0,0C439.55,477.63,439.62,478.49,439.13,479.06z"/>
        <g>

            <rect x="431.84" y="445.36" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -187.7747 484.9446)" style="fill:#FFFFFF;" width="4.98" height="3.28"/>

          <rect x="438.51" y="451.1" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -189.8007 491.9962)" style="fill:#FFFFFF;" width="4.98" height="3.28"/>
          <path style="fill:#FFFFFF;" d="M485.6,439.03l-2.14,2.49l-21.99-18.91c-1.27-1.09-3.18-0.95-4.27,0.32l-2.15,2.51l-2.49-2.14
            l2.15-2.51c2.27-2.64,6.26-2.94,8.9-0.67L485.6,439.03z"/>
          <path style="fill:#FFFFFF;" d="M470.27,425.85l-2.14,2.49c-1.27-1.09-3.18-0.94-4.27,0.32l-2.15,2.51l-2.49-2.14l2.15-2.51
            C463.64,423.88,467.64,423.58,470.27,425.85z"/>
          <polygon style="fill:#044A6D;" points="459.2,470.83 406.66,431.52 402.94,435.85 449.69,481.89 448.15,483.45 399.95,435.97
            406.33,428.55 460.51,469.08 					"/>
          <g>

              <rect x="401.45" y="435.27" transform="matrix(0.9999 -0.0139 0.0139 0.9999 -6.0173 5.7092)" style="fill:#044A6D;" width="12.69" height="1.09"/>

            <rect x="406.81" y="429.98" transform="matrix(0.9906 -0.137 0.137 0.9906 -55.9285 59.9139)" style="fill:#044A6D;" width="1.09" height="12.69"/>
          </g>
          <g>

              <rect x="408.2" y="441.48" transform="matrix(0.9963 -0.0859 0.0859 0.9963 -36.4165 37.26)" style="fill:#044A6D;" width="13.59" height="1.09"/>

            <rect x="414.03" y="435.72" transform="matrix(0.9979 -0.0642 0.0642 0.9979 -27.5503 27.5245)" style="fill:#044A6D;" width="1.09" height="13.59"/>
          </g>
          <g>

              <rect x="414.94" y="447.68" transform="matrix(0.9891 -0.1472 0.1472 0.9891 -61.3902 67.0447)" style="fill:#044A6D;" width="14.54" height="1.09"/>

            <rect x="421.24" y="441.44" transform="matrix(1 -3.363554e-03 3.363554e-03 1 -1.5069 1.4212)" style="fill:#044A6D;" width="1.09" height="14.54"/>
          </g>
          <g>

              <rect x="421.65" y="453.88" transform="matrix(0.9797 -0.2006 0.2006 0.9797 -82.4157 95.359)" style="fill:#044A6D;" width="15.53" height="1.09"/>

            <rect x="421.24" y="454.36" transform="matrix(0.0505 -0.9987 0.9987 0.0505 -47.0041 860.3724)" style="fill:#044A6D;" width="15.52" height="1.09"/>
          </g>
          <g>

              <rect x="428.35" y="460.07" transform="matrix(0.9692 -0.2464 0.2464 0.9692 -100.0498 121.8115)" style="fill:#044A6D;" width="16.56" height="1.09"/>

            <rect x="427.93" y="460.56" transform="matrix(0.0982 -0.9952 0.9952 0.0982 -65.5002 849.9338)" style="fill:#044A6D;" width="16.56" height="1.09"/>
          </g>
          <g>
            <polygon style="fill:#044A6D;" points="452.45,464.8 435.54,469.89 435.23,468.84 452.14,463.75 						"/>

            <rect x="434.6" y="466.76" transform="matrix(0.14 -0.9902 0.9902 0.14 -81.3579 840.9507)" style="fill:#044A6D;" width="17.64" height="1.09"/>
          </g>
          <g>

              <rect x="441.67" y="472.45" transform="matrix(0.9456 -0.3254 0.3254 0.9456 -129.3831 172.5328)" style="fill:#044A6D;" width="18.69" height="1.09"/>

            <rect x="441.25" y="472.93" transform="matrix(0.1808 -0.9835 0.9835 0.1808 -96.5201 831.0743)" style="fill:#044A6D;" width="18.71" height="1.09"/>
          </g>
        </g>

        <rect x="394.47" y="428.76" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -186.8422 454.0391)" style="fill:#044A6D;" width="13.33" height="3.55"/>
        <path style="fill:#FFFFFF;" d="M382.96,418.75c1.04-0.27,3.04,0.28,4.99,1.96c3.21,2.76,1.44,10.59,8.9,10.61l1.23-1.43
          c-2.78-0.77-4.33-4.54-5.29-6.96c2.71,0.16,6.45,1.52,7.61,4.26l1.09-1.27C399.83,418.28,390.2,415.37,382.96,418.75z"/>
        <path style="fill:#044A6D;" d="M433.11,443.12l28.16,24.21l6.19-7.21l-9.84-8.46c-0.6-0.51-0.66-1.41-0.15-2.01l0,0
          c0.51-0.6,1.41-0.66,2.01-0.15l9.84,8.46l1.96-2.28l-9.84-8.46c-0.6-0.51-0.66-1.41-0.15-2.01c0.51-0.6,1.41-0.66,2.01-0.15
          l9.84,8.46l6.19-7.21l-28.16-24.21L433.11,443.12z M450.51,441.68l-5.73,6.67l-6.67-5.73l5.73-6.67L450.51,441.68z
           M458.09,432.86l-5.73,6.67l-6.67-5.73l5.73-6.67L458.09,432.86z"/>
        <path style="fill:#044A6D;" d="M415.88,407.86l3.98-4.63c0.88-1.03,2.43-1.14,3.45-0.26l25.48,21.9l-7.17,8.35l-25.48-21.9
          C415.12,410.43,415,408.89,415.88,407.86z"/>
        <path style="fill:#FFFFFF;" d="M418.65,402.97l-3.21,3.73l-1.56-1.34c-0.44-0.38-0.49-1.03-0.11-1.47l1.85-2.15
          c0.38-0.44,1.03-0.49,1.47-0.11L418.65,402.97z"/>
        <path style="fill:#044A6D;" d="M439.72,436.27c-0.2,0.23-0.54,0.26-0.77,0.06l-25.95-22.31c-0.23-0.2-0.26-0.54-0.06-0.77
          c0.2-0.23,0.54-0.26,0.77-0.06l25.95,22.31C439.89,435.7,439.91,436.04,439.72,436.27z"/>
        <g>

            <rect x="415.27" y="413.89" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -169.0965 460.5023)" style="fill:#044A6D;" width="3.55" height="1.09"/>

          <rect x="421.77" y="419.48" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -171.0703 467.3724)" style="fill:#044A6D;" width="3.55" height="1.09"/>

          <rect x="428.27" y="425.06" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -173.0443 474.2432)" style="fill:#044A6D;" width="3.55" height="1.09"/>

          <rect x="434.76" y="430.65" transform="matrix(0.6519 -0.7583 0.7583 0.6519 -175.0181 481.1132)" style="fill:#044A6D;" width="3.55" height="1.09"/>
        </g>
      </g>
    </g>
  </g>
            <g id="large-ships">
    <g>
      <path style="fill:#6AD0EF;" d="M693,140.59c-85.13,32.86-163.78,79.95-235.51,141.65l556.34,725.15L693,140.59z"/>
      <g>
        <g>
          <g>
            <polygon style="fill:#044A6D;" points="677.91,264.86 672.03,268.49 653.04,230.37 655.35,229.07 						"/>
            <g>

                <rect x="656.23" y="246.19" transform="matrix(0.8716 -0.4902 0.4902 0.8716 -35.8684 357.1704)" style="fill:#044A6D;" width="15.45" height="1.74"/>
              <path style="fill:#044A6D;" d="M657.25,250.22c-0.16,0.09-0.37,0.03-0.46-0.13l-1.65-2.93c-0.09-0.16-0.03-0.37,0.13-0.46
                c0.16-0.09,0.37-0.03,0.46,0.13l1.65,2.93C657.47,249.92,657.41,250.13,657.25,250.22z"/>
              <path style="fill:#044A6D;" d="M659.95,248.7c-0.16,0.09-0.37,0.03-0.46-0.13l-1.65-2.93c-0.09-0.16-0.03-0.37,0.13-0.46
                c0.16-0.09,0.37-0.03,0.46,0.13l1.65,2.93C660.17,248.41,660.11,248.61,659.95,248.7z"/>
              <path style="fill:#044A6D;" d="M667.43,244.49c-0.16,0.09-0.37,0.03-0.46-0.13l-1.65-2.93c-0.09-0.16-0.03-0.37,0.13-0.46
                c0.16-0.09,0.37-0.03,0.46,0.13l1.65,2.93C667.65,244.2,667.59,244.4,667.43,244.49z"/>
              <path style="fill:#044A6D;" d="M670.13,242.98c-0.16,0.09-0.37,0.03-0.46-0.13l-1.65-2.93c-0.09-0.16-0.03-0.37,0.13-0.46
                c0.16-0.09,0.37-0.03,0.46,0.
13l1.65,2.93C670.35,242.68,670.29,242.89,670.13,242.98z"/>
              <path style="fill:#044A6D;" d="M668.48,240.05l-12.88,7.24c-0.16,0.09-0.37,0.03-0.46-0.13s-0.03-0.37,0.13-0.46l12.88-7.24
                c0.16-0.09,0.37-0.03,0.46,0.13S668.64,239.96,668.48,240.05z"/>
            </g>
            <g>
              <g>

                  <rect x="652.73" y="237.12" transform="matrix(0.8716 -0.4902 0.4902 0.8716 -31.9939 353.425)" style="fill:#044A6D;" width="12.02" height="1.35"/>
                <path style="fill:#044A6D;" d="M653.52,240.25c-0.13,0.07-0.29,0.03-0.36-0.1l-1.28-2.28c-0.07-0.13-0.03-0.29,0.1-0.36
                  c0.13-0.07,0.29-0.03,0.36,0.1l1.28,2.28C653.69,240.02,653.65,240.18,653.52,240.25z"/>
                <path style="fill:#044A6D;" d="M655.62,239.07c-0.13,0.07-0.29,0.03-0.36-0.1l-1.28-2.28c-0.07-0.13-0.03-0.29,0.1-0.36
                  c0.13-0.07,0.29-0.03,0.36,0.1l1.28,2.28C655.79,238.84,655.75,239,655.62,239.07z"/>
                <path style="fill:#044A6D;" d="M661.44,235.8c-0.13,0.07-0.29,0.03-0.36-0.1l-1.28-2.28c-0.07-0.13-0.03-0.29,0.1-0.36
                  c0.13-0.07,0.29-0.03,0.36,0.1l1.28,2.28C661.61,235.57,661.57,235.72,661.44,235.8z"/>
                <path style="fill:#044A6D;" d="M663.54,234.61c-0.13,0.07-0.29,0.03-0.36-0.1l-1.28-2.28c-0.07-0.13-0.03-0.29,0.1-0.36
                  c0.13-0.07,0.29-0.03,0.36,0.1l1.28,2.28C663.71,234.38,663.67,234.54,663.54,234.61z"/>
                <path style="fill:#044A6D;" d="M662.26,232.34l-10.02,5.63c-0.13,0.07-0.29,0.03-0.36-0.1c-0.07-0.13-0.03-0.29,0.1-0.36
                  l10.02-5.63c0.13-0.07,0.29-0.03,0.36,0.1C662.43,232.11,662.39,232.27,662.26,232.34z"/>
              </g>
              <g>

                  <rect x="656.83" y="238.32" transform="matrix(0.2439 -0.9698 0.9698 0.2439 263.3881 819.387)" style="fill:#044A6D;" width="0.67" height="4.94"/>

                <rect x="659.64" y="237.67" transform="matrix(0.2954 -0.9554 0.9554 0.2954 239.1532 800.2681)" style="fill:#044A6D;" width="4.94" height="0.67"/>
              </g>
            </g>
            <path style="fill:#044A6D;" d="M650.07,225.1l2.96,5.27l2.32-1.3l-2.96-5.27L650.07,225.1z M654.59,228.57l-1.57,0.88l-2.25-4
              l1.57-0.88L654.59,228.57z"/>
            <path style="fill:#FFFFFF;" d="M654.27,228.51l-1.15,0.65l-2.05-3.64l1.15-0.65L654.27,228.51z"/>
          </g>
          <g>
            <path style="fill:#FFFFFF;" d="M577.33,368.08c2.89-1.44,117.24-66.12,121.4-68.27c-6.23-1.63-8.8-4.93-9.36-9.18
              l-131.8,74.12C563.93,368.2,572.67,368.55,577.33,368.08z"/>
            <path style="fill:#044A6D;" d="M657.48,277.07l-7.61,18.73l-100.91,56.75c1.32,2.94,4.22,8.72,7.06,11.17l133.24-74.93
              c-0.03-3.81,1.22-8.22,2.75-12.79c3.6-10.73,12.21-19.46,9.99-26.37L657.48,277.07z M574.22,349.96
              c0.46,0.81,0.17,1.84-0.64,2.29l-1.4,0.79l-1.33-2.37l0.51-0.29c0.35-0.2,0.47-0.64,0.28-0.98l-0.19-0.34
              c-0.2-0.35-0.64-0.47-0.98-0.28l-3.77,2.12c-0.35,0.2-0.47,0.64-0.28,0.98l0.19,0.34c0.2,0.35,0.64,0.47,0.98,0.28l0.51-0.29
              l1.33,2.37l-3.98,2.24l-1.33-2.37l0.51-0.29c0.35-0.2,0.47-0.64,0.28-0.98l-0.19-0.34c-0.2-0.35-0.64-0.47-0.98-0.28
              l-3.77,2.12c-0.35,0.2-0.47,0.64-0.28,0.98l0.19,0.34c0.2,0.35,0.64,0.47,0.98,0.28l0.51-0.29l1.33,2.37l-1.54,0.87
              c-0.81,0.46-1.84,0.17-2.29-0.64l-1.98-3.52c-0.46-0.81-0.17-1.84,0.64-2.29l12.43-6.99c0.81-0.46,1.84-0.17,2.29,0.64
              L574.22,349.96z M663.4,281.66c0.27,0.49,0.1,1.11-0.39,1.38l-1.41,0.79c-0.49,0.27-1.11,0.1-1.38-0.39l-0.09-0.16
              c-0.27-0.49-0.1-1.11,0.39-1.38l1.41-0.79c0.49-0.27,1.11-0.1,1.38,0.39L663.4,281.66z M668.81,278.62
              c0.27,0.49,0.1,1.11-0.39,1.38l-1.41,0.79c-0.49,0.27-1.11,0.1-1.38-0.39l-0.09-0.16c-0.27-0.49-0.1-1.11,0.39-1.38l1.41-0.79
              c0.49-0.27,1.11-0.1,1.38,0.39L668.81,278.62z M674.21,275.58c0.27,0.49,0.1,1.11-0.39,1.38l-1.41,0.79
              c-0.49,0.27-1.11,0.1-1.38-0.39l-0.09-0.16c-0.27-0.49-0.1-1.11,0.39-1.38l1.41-0.79c0.49-0.27,1.11-0.1,1.38,0.39
              L674.21,275.58z M687.19,268.99c-0.08,0.16-0.17,0.31-0.32,0.46c0.01,0.06,0.03,0.12,0.04,0.18c0,0.01,0,0.01,0.01,0.02
              c0,0,0,0,0,0c0.2,1.09,0.21,2.29-0.01,3.42l1.62-0.91l0.27,0.47l-0.6,0.34l-0.84,2l-5.26,2.96l-2.15-0.32l-0.6,0.34
              l-0.27-0.47l1.62-0.91c-1.08-0.4-2.1-1.04-2.93-1.77l0,0c-0.01,0-0.01-0.01-0.02-0.02c-0.05-0.04-0.09-0.08-0.13-0.12
              c-0.2,0.05-0.38,0.05-0.56,0.04c-0.6-0.74-1.06-2-1.06-2.86c0.86-0.04,2.14,0.36,2.9,0.93c0.02,0.2,0.04,0.38-0.03,0.61
              c0.04,0.04,0.07,0.07,0.11,0.11c1.14,0.99,2.72,1.78,4.09,1.75l-2.86-5.09c0.13-0.26,0.33-0.47,0.6-0.62
              c0.27-0.15,0.56-0.21,0.84-0.19l2.86,5.09l0,0c0.74-1.16,0.89-2.91,0.63-4.4c-0.01-0.05-0.02-0.1-0.03-0.15
              c-0.23-0.06-0.38-0.17-0.54-0.29c-0.09-0.95,0.24-2.25,0.72-2.96C686.03,267.05,686.87,268.09,687.19,268.99z M680.64,269.21
              c-0.72,0.4-0.97,1.31-0.57,2.03c0.19,0.34,0.5,0.57,0.84,0.68l0.46,0.82c-0.79,0-1.55-0.4-1.97-1.13
              c-0.61-1.08-0.22-2.45,0.86-3.06c1.08-0.61,2.45-0.22,3.06,0.86c0.41,0.74,0.36,1.6-0.06,2.28l-0.46-0.82
              c0.09-0.35,0.05-0.74-0.14-1.09C682.27,269.07,681.36,268.81,680.64,269.21z M659.05,278.93l-7.58,18.71l-101.02,56.81
              c-0.32-0.65-0.59-1.23-0.8-1.68l100.64-56.6l7.63-18.75l43.75-26.98c0.14,0.67,0.18,1.37,0.13,2.08L659.05,278.93z"/>
          </g>
          <g>
            <path style="fill:#044A6D;" d="M552.49,290.86l4.28,7.61l10.73-6.03l2.21-9.23l-0.87-1.54L552.49,290.86z M566,291.3
              l-8.58,4.82l-2.59-4.61l12.85-7.23L566,291.3z"/>
            <g>

                <rect x="552.91" y="283.65" transform="matrix(0.8716 -0.4902 0.4902 0.8716 -69.2561 308.2068)" style="fill:#044A6D;" width="1.73" height="5.35"/>
              <polygon style="fill:#044A6D;" points="555.95,282.47 553.75,284.51 552.24,285.36 549.36,286.18 548.9,285.35
                555.49,281.65 							"/>
            </g>
            <g>
              <circle style="fill:#FFFFFF;" cx="545.47" cy="288.53" r="3.77"/>

              <rect x="544.98" y="290.55" transform="matrix(0.8716 -0.4902 0.4902 0.8716 -72.737 305.7043)" style="fill:#FFFFFF;" width="4.55" height="2.34"/>
            </g>
            <g>

                <rect x="557.62" y="288.82" transform="matrix(0.8716 -0.4902 0.4902 0.8716 -71.6568 311.3759)" style="fill:#044A6D;" width="2.02" height="7.34"/>
              <polygon style="fill:#044A6D;" points="564.72,293.47 562.86,294.65 561.49,286.27 563.49,285.95 							"/>
            </g>
            <path style="fill:#044A6D;" d="M558.47,342.26l3.89-2.19l-1.24-2.21l-3.89,2.19L558.47,342.26z M551.52,329.91l3.89-2.19
              l-0.97-1.73l-3.89,2.19L551.52,329.91z M556.73,339.17l3.89-2.19l-1.24-2.2l-3.89,2.19L556.73,339.17z M554.99,336.09
              l3.89-2.19l-1.24-2.2l-3.89,2.19L554.99,336.09z M574.36,326.84l-11.39-5.64l-7.64,4.3l9.32,16.58l29-16.31l-4.18-7.43
              L574.36,326.84z M553.18,348.52l5.81-3.27l-9.32-16.58l-5.81,3.27L553.18,348.52z M553.26,333l3.89-2.19l-1.24-2.21
              l-3.89,2.19L553.26,333z M559.87,344.76l3.89-2.19l-0.91-1.62l-3.89,2.19L559.87,344.76z"/>
            <path style="fill:#044A6D;" d="M546.17,294.42l4.28,7.61l6.32-3.55l-4.28-7.61L546.17,294.42z M553.8,297.97
              c0.25,0.44,0.09,0.99-0.35,1.24l-0.46,0.26c-0.44,0.25-0.99,0.09-1.24-0.35l-0.26-0.46c-0.25-0.44-0.09-0.99,0.35-1.24
              l0.46-0.26c0.44-0.25,0.99-0.09,1.24,0.35L553.8,297.97z M551.86,294.53c0.25,0.44,0.09,0.99-0.35,1.24l-0.46,0.26
              c-0.44,0.25-0.99,0.09-1.24-0.35l-0.26-0.46c-0.25-0.44-0.09-0.99,0.35-1.24l0.46-0.26c0.44-0.25,0.99-0.09,1.24,0.35
              L551.86,294.53z"/>
            <path style="fill:#044A6D;" d="M559.51,316.32l16.27-9.15l-3.21-5.71l-11.03,6.2L559.51,316.32z M569.6,305.81
              c-0.23-0.41-0.08-0.93,0.32-1.16l0.58-0.33c0.41-0.23,0.93-0.08,1.16,0.32l1.07,1.9c0.23,0.41,0.08,0.93-0.32,1.16l-0.58,0.33
              c-0.41,0.23-0.93,0.08-1.16-0.32L569.6,305.81z M564.02,308.95c-0.23-0.41-0.08-0.93,0.32-1.16l0.58-0.33
              c0.41-0.23,0.93-0.08,1.16,0.32l1.07,1.9c0.23,0.41,0.08,0.93-0.32,1.16l-0.58,0.33c-0.41,0.23-0.93,0.08-1.16-0.32
              L564.02,308.95z M557.48,323.13l5.88-3.31l11.39,5.64l8.6-4.84l-6.88-12.24l-20.73,11.66L557.48,323.13z M567.91,315.87
              c-0.23-0.41-0.08-0.93,0.32-1.16l0.58-0.33c0.41-0.23,0.93-0.08,1.16,0.32l1.07,1.9c0.23,0.41,0.08,0.93-0.32,1.16l-0.58,0.33
              c-0.41,0.23-0.93,0.08-1.16-0.32L567.91,315.87z M573.49,312.73c-0.23-0.41-0.08-0.93,0.32-1.16l0.58-0.33
              c0.41-0.23,0.93-0.08,1.16,0.32l1.07,1.9c0.23,0.41,0.08,0.93-0.32,1.16l-0.58,0.33c-0.41,0.23-0.93,0.08-1.16-0.32
              L573.49,312.73z M556.28,300.24l-8.87,4.99l3.77,6.71l2.65-1.49L556.28,300.24z M558.08,299.22l-2.45,10.21l16.28-9.15
              l-3.77-6.71L558.08,299.22z M563.26,302.79c0.23,0.41,0.08,0.93-0.32,1.16l-0.58,0.33c-0.41,0.23-0.93,0.08-1.16-0.32
              l-1.07-1.9c-0.23-0.41-0.08-0.93,0.32-1.16l0.58-0.33c0.41-0.23,0.93-0.08,1.16,0.32L563.26,302.79z M568.84,299.65
              c0.23,0.41,0.08,0.93-0.32,1.16l-0.58,0.33c-0.41,0.23-0.93,0.08-1.16-0.32l-1.07-1.9c-0.23-0.41-0.08-0.93,0.32-1.16
              l0.58-0.33c0.41-0.23,0.93-0.08,1.16,0.32L568.84,299.65z M559.74,308.66l-4.55,2.56l0,0.01l-0.01,0l-3.34,1.88l3.21,5.71
              l2.66-1.5L559.74,308.66z"/>
          </g>
          <g>

              <rect x="589.83" y="298.28" transform="matrix(0.8716 -0.4902 0.4902 0.8716 -67.8147 341.6575)" style="fill:#044A6D;" width="57.06" height="4.02"/>
            <path style="fill:#FFFFFF;" d="M623.99,308.82l7.41-4.16l-3.39-6.02l-7.41,4.16L623.99,308.82z M637.94,293.04l3.39,6.02
              l4-2.25l-3.39-6.02L637.94,293.04z M632.66,303.94l7.41-4.16l-3.39-6.02l-7.41,4.16L632.66,303.94z M615.31,313.69l7.41-4.16
              l-3.39-6.02l-7.41,4.16L615.31,313.69z M601.37,321.53l4-2.25l-3.39-6.02l-4,2.25L601.37,321.53z M606.64,318.57l7.41-4.16
              l-3.39-6.02l-7.41,4.16L606.64,318.57z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="cruise">
    <g>
      <path style="fill:#6AD0EF;" d="M1018.19,1011.41l0.63-928.38c-116.51,0-224.91,18.81-324.47,57.03l1.16-0.43L1018.19,1011.41z"/>
      <g>
        <g>
          <g>
            <g>
              <g>
                <polygon style="fill:#044A6D;" points="929.6,179.43 917.13,169.69 848.82,180.67 849.95,187.71 849.12,187.84
                  847.86,179.98 917.35,168.8 930.12,178.77 								"/>
              </g>
              <g>
                <g>

                    <rect x="856.58" y="179.01" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.5584 138.4227)" style="fill:#044A6D;" width="0.84" height="12.68"/>
                </g>
                <g>

                    <rect x="864.24" y="177.78" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.2661 139.6227)" style="fill:#044A6D;" width="0.84" height="12.69"/>
                </g>
                <g>

                    <rect x="871.89" y="176.55" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.9738 140.8227)" style="fill:#044A6D;" width="0.84" height="12.69"/>
                </g>
                <g>

                    <rect x="879.55" y="175.32" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.6815 142.0228)" style="fill:#044A6D;" width="0.84" height="12.69"/>
                </g>
                <g>

                    <rect x="887.2" y="174.09" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.3892 143.2228)" style="fill:#044A6D;" width="0.84" height="12.7"/>
                </g>
                <g>

                    <rect x="894.86" y="172.86" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.0969 144.4228)" style="fill:#044A6D;" width="0.84" height="12.7"/>
                </g>
                <g>

                    <rect x="902.42" y="171.63" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -16.7133 145.6006)" style="fill:#044A6D;" width="0.84" height="11.53"/>
                </g>
                <g>

                    <rect x="910.03" y="170.4" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -16.3774 146.79)" style="fill:#044A6D;" width="0.84" height="10.97"/>
                </g>
                <g>

                    <rect x="917.83" y="169.16" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -16.22 148.0229)" style="fill:#044A6D;" width="0.84" height="12.71"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <path style="fill:#044A6D;" d="M756.66,216.71c0.14-0.02,0.27-0.04,0.41-0.07l-0.91-5.67l27.49-4.42l7.34,5.73
                  c0.39-0.05,0.77-0.11,1.16-0.16l-8.27-6.45l-28.68,4.61l1.05,6.53C756.38,216.77,756.52,216.73,756.66,216.71z"/>
              </g>
              <g>
                <g>
                  <path style="fill:#044A6D;" d="M764.22,216.03l-1.06-6.61l-0.83,0.13l1.05,6.52C763.66,216.06,763.94,216.05,764.22,216.03
                    z"/>
                </g>
                <g>
                  <path style="fill:#044A6D;" d="M771.37,215.33l-1.13-7.04l-0.83,0.13l1.13,7.01C770.81,215.4,771.09,215.37,771.37,215.33z
                    "/>
                </g>
                <g>
                  <path style="fill:#044A6D;" d="M778.45,214.28l-1.15-7.13l-0.83,0.13l1.15,7.13C777.9,214.37,778.18,214.33,778.45,214.28z
                    "/>
                </g>
                <g>
                  <path style="fill:#044A6D;" d="M785.52,213.12l-1.14-7.11l-0.83,0.13l1.14,7.11C784.97,213.21,785.25,213.17,785.52,213.12
                    z"/>
                </g>
              </g>
            </g>
            <path style="fill:#044A6D;" d="M817.04,191.19l12.05-16.67c0.41-0.57,1.3-0.13,1.1,0.55c-0.8,2.72-1.47,6.21-0.66,8.67
              c1.51,4.58,6.85,2.94,10.87,2.3c4.02-0.65,6.02,1.6,6.02,1.6l-27.91,4.49L817.04,191.19z"/>
            <path style="fill:#044A6D;" d="M805.09,198.15l3.08-4.26c0.62-0.86,1.56-1.44,2.61-1.61l146.86-23.62
              c0.94-0.15,1.9,0.08,2.67,0.64l5.21,3.77L822.8,204.43L805.09,198.15z"/>
            <path style="fill:#044A6D;" d="M927.44,178.13l-123.14,19.8c-1.41,0.23-2.67,1-3.5,2.16l-5.71,7.9l144.46-22.13l-0.54-4.01
              L927.44,178.13z"/>
            <path style="fill:#DAE7ED;" d="M970.53,177.86l-5.01-4.8c-0.59-0.57-1.41-0.82-2.22-0.69l-35.86,5.77l14.43,9.22
              L970.53,177.86z"/>
            <g>
              <defs>
                <polygon id="SVGID_4_" points="970.53,177.86 964.58,172.16 927.44,178.13 941.86,187.35 								"/>
              </defs>
              <clipPath id="SVGID_5_">
                <use xlink:href="#SVGID_4_"  style="overflow:visible;"/>
              </clipPath>
              <g style="clip-path:url(#SVGID_5_);">

                  <rect x="937.79" y="175.17" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -15.8373 153.627)" style="fill:#044A6D;" width="31.45" height="1.52"/>

                <rect x="938.68" y="177.84" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -16.2507 153.8034)" style="fill:#044A6D;" width="31.45" height="1.52"/>
              </g>
            </g>
            <g>
              <polygon style="fill:#3E708C;" points="932.95,180 804.68,200.63 805.93,198.89 932.71,178.5 							"/>
              <polygon style="fill:#3E708C;" points="935.31,182.44 802.44,203.81 803.72,202.07 935.07,180.94 							"/>
            </g>
            <path style="fill:#044A6D;" d="M755.03,216.57l33.98-5.46l2.24-3.1c0.6-0.83,1.51-1.39,2.53-1.56l141.37-22.74l-7.72-5.58
              l9.28-1.49l7.53,5.44l26.28-4.23l10.41,7.53c0.78,0.56,1.3,1.41,1.45,2.36l0.17,1.05c0.36,2.23-4.79,6.37-5.66,8.69
              c-0.87,2.32-0.15,3.55-0.15,3.55L784.89,231.9C784.89,231.9,773.28,221,755.03,216.57z"/>
            <g>
              <defs>
                <path id="SVGID_6_" d="M755.03,216.57l33.98-5.46l2.24-3.1c0.6-0.83,1.51-1.39,2.53-1.56l141.37-22.74l-7.72-5.58l9.28-1.49
                  l7.53,5.44l26.28-4.23l11.63,8.41c0,0,0.05,0.31,0.41,2.53c0.36,2.23-4.79,6.37-5.66,8.69c-0.87,2.32-0.15,3.55-0.15,3.55
                  L784.89,231.9C784.89,231.9,773.28,221,755.03,216.57z"/>
              </defs>
              <use xlink:href="#SVGID_6_"  style="overflow:visible;fill:#FFFFFF;"/>
              <clipPath id="SVGID_7_">
                <use xlink:href="#SVGID_6_"  style="overflow:visible;"/>
              </clipPath>

              <rect x="778.01" y="212.12" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -23.9761 142.8813)" style="clip-path:url(#SVGID_7_);fill:#044A6D;" width="208.36" height="18.75"/>
            </g>
            <g>
              <g>

                  <rect x="971.76" y="186.1" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.3326 156.8636)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="972.73" y="192.11" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.2748 157.0933)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="961.2" y="181.63" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -16.7572 155.1294)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="962.17" y="187.64" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.6994 155.3592)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="963.13" y="193.65" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.6416 155.5889)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="951.6" y="183.18" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.124 153.6251)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="952.57" y="189.19" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.0663 153.8548)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="953.53" y="195.2" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.0085 154.0846)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="942" y="184.72" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.4909 152.1207)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="942.97" y="190.73" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.4331 152.3505)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="943.94" y="196.74" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.3753 152.5802)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="932.41" y="186.26" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -17.8577 150.6163)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="933.37" y="192.27" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.7999 150.8461)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="934.34" y="198.29" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.7421 151.0759)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="922.81" y="187.81" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.2245 149.112)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="923.77" y="193.82" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.1667 149.3417)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="924.74" y="199.83" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.109 149.5715)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="913.21" y="189.35" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.5914 147.6076)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="914.18" y="195.36" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.5336 147.8374)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="915.14" y="201.37" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.4758 148.0671)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="903.61" y="190.89" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -18.9582 146.1033)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="904.58" y="196.9" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.9004 146.333)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="905.54" y="202.92" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.8426 146.5628)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="894.01" y="192.44" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.325 144.5989)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="894.98" y="198.45" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.2672 144.8286)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="895.95" y="204.46" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.2094 145.0584)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="884.42" y="193.98" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -19.6919 143.0945)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="885.38" y="199.99" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.6341 143.3243)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="886.35" y="206" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.5763 143.554)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="874.82" y="195.52" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.0587 141.5902)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="875.78" y="201.53" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.0009 141.8199)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="876.75" y="207.55" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.9431 142.0497)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="865.22" y="197.07" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.4255 140.0858)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="866.19" y="203.08" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.3677 140.3156)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="867.15" y="209.09" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.3099 140.5453)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="855.62" y="198.61" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -20.7924 138.5815)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="856.59" y="204.62" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.7346 138.8112)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="857.55" y="210.63" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.6768 139.041)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="846.02" y="200.15" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.1592 137.0771)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="846.99" y="206.17" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.1014 137.3068)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="847.96" y="212.18" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -23.0436 137.5366)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="836.43" y="201.7" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.526 135.5727)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="837.39" y="207.71" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.4682 135.8025)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="838.36" y="213.72" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -23.4104 136.0322)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="826.83" y="203.24" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -21.8928 134.0684)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="827.79" y="209.25" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.8351 134.2981)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="828.76" y="215.26" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -23.7773 134.5279)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="817.23" y="204.78" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.2597 132.564)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="818.2" y="210.8" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -23.2019 132.7937)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="819.16" y="216.81" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -24.1441 133.0235)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="807.63" y="206.33" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.6265 131.0596)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="808.6" y="212.34" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -23.5687 131.2894)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="809.56" y="218.35" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -24.5109 131.5191)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="798.03" y="207.87" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -22.9933 129.5553)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="799" y="213.88" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -23.9355 129.785)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="799.97" y="219.89" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -24.8778 130.0148)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="789.4" y="215.43" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -24.3024 128.2807)" style="fill:#3E708C;" width="2.51" height="1.61"/>

                <rect x="790.37" y="221.44" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -25.2446 128.5104)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
              <g>

                  <rect x="780.77" y="222.98" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -25.6114 127.0061)" style="fill:#3E708C;" width="2.51" height="1.61"/>
              </g>
            </g>
            <polygon style="fill:#044A6D;" points="883.19,185.25 890.91,190.83 900.02,189.36 892.4,183.85 						"/>
            <g>
              <defs>
                <path id="SVGID_8_" d="M805.09,198.15l3.08-4.26c0.62-0.86,1.56-1.44,2.61-1.61l146.86-23.62c0.94-0.15,1.9,0.08,2.67,0.64
                  l5.21,3.77L822.8,204.43L805.09,198.15z"/>
              </defs>
              <clipPath id="SVGID_9_">
                <use xlink:href="#SVGID_8_"  style="overflow:visible;"/>
              </clipPath>
              <polygon style="clip-path:url(#SVGID_9_);fill:#3E708C;" points="964.13,170.63 809.11,195.56 810.36,193.82 963.89,169.13
                              "/>
            </g>
            <g>
              <path style="fill:#044A6D;" d="M946.35,168.07l-16.6,2.67c-0.16,0.03-0.32-0.09-0.35-0.25l-0.44-2.76
                c-0.03-0.16,0.09-0.32,0.25-0.35l16.6-2.67c0.16-0.03,0.32,0.09,0.35,0.25l0.44,2.76
                C946.62,167.89,946.51,168.05,946.35,168.07z"/>
              <path style="fill:#044A6D;" d="M939.58,157.99l-6.56,1.05l-1.71,10.07l13.04-2.1L939.58,157.99z M934.17,160.2l4.68-0.75
                l3.46,6.54l-9.38,1.51L934.17,160.2z"/>
              <g>
                <defs>
                  <path id="SVGID_10_" d="M927.59,169.46l0.33,2.08c0,0,4.16,2.13,9.89,1.21l1.42-0.23c5.74-0.92,9.01-4.25,9.01-4.25
                    l-0.33-2.08L927.59,169.46z"/>
                </defs>
                <use xlink:href="#SVGID_10_"  style="overflow:visible;fill:#044A6D;"/>
                <clipPath id="SVGID_11_">
                  <use xlink:href="#SVGID_10_"  style="overflow:visible;"/>
                </clipPath>

                <rect x="927.29" y="168.28" transform="matrix(0.9873 -0.1588 0.1588 0.9873 -14.9423 151.1601)" style="clip-path:url(#SVGID_11_);fill:#3E708C;" width="22.47" height="1.63"/>
              </g>
              <path style="fill:#044A6D;" d="M944.82,157.85l-16.82,2.7c-0.06,0.01-0.12-0.03-0.14-0.1l-0.17-1.08
                c-0.01-0.06,0.03-0.12,0.1-0.14l16.82-2.7c0.06-0.01,0.12,0.03,0.14,0.1l0.17,1.08
                C944.92,157.78,944.88,157.84,944.82,157.85z"/>
            </g>
          </g>
          <path style="fill:#FFFFFF;" d="M931.86,211.67c-0.35,0.02-0.63,0.32-0.61,0.67c0.1,1.97-1.31,3.7-3.26,4.01
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32s-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.58,0.25-3.14-0.47-3.95-1.85c-0.18-0.3-0.57-0.41-0.88-0.22c-0.3,0.18-0.41,0.57-0.22,0.88c1.09,1.84,3.15,2.8,5.26,2.47
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52s3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52s3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c2.6-0.42,4.47-2.71,4.34-5.34
            C932.51,211.93,932.21,211.65,931.86,211.67z"/>
          <path style="fill:#FFFFFF;" d="M988.91,219.41c-0.35,0.02-0.63,0.32-0.61,0.67c0.1,1.97-1.31,3.7-3.26,4.01
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32s-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32s-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32s-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.58,0.25-3.14-0.47-3.95-1.85c-0.18-0.3-0.57-0.41-0.88-0.22c-0.3,0.18-0.41,0.57-0.22,0.88c1.09,1.84,3.15,2.8,5.26,2.47
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52s3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c2.6-0.42,4.47-2.71,4.34-5.34
            C989.57,219.67,989.27,219.39,988.91,219.41z"/>
          <g>
            <path style="fill:#FFFFFF;" d="M934.08,225.51c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76
              c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76c-1.66,0.27-3.31-0.29-4.46-1.44
              c-0.73,1.46-2.13,2.5-3.79,2.76c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76
              c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76c-1.78,0.29-3.61-0.39-4.77-1.78
              c-0.23-0.27-0.19-0.68,0.08-0.9c0.27-0.23,0.68-0.19,0.9,0.08c0.89,1.05,2.23,1.55,3.59,1.34c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49s0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.54-0.25,2.78-1.39,3.15-2.91
              c0.08-0.34,0.43-0.55,0.78-0.47c0.34,0.08,0.55,0.43,0.47,0.78C937.78,223.66,936.13,225.18,934.08,225.51z"/>
          </g>
          <g>
            <path style="fill:#FFFFFF;" d="M991.23,207.75c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76
              c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76c-1.66,0.27-3.31-0.29-4.46-1.44
              c-0.73,1.46-2.13,2.5-3.79,2.77c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76
              c-1.66,0.27-3.31-0.29-4.46-1.44c-0.73,1.46-2.13,2.5-3.79,2.76c-1.78,0.29-3.61-0.39-4.77-1.78
              c-0.23-0.27-0.19-0.68,0.08-0.9c0.27-0.23,0.68-0.19,0.9,0.08c0.89,1.05,2.23,1.55,3.59,1.34c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.56-0.25,2.8-1.4,3.16-2.94
              c0.06-0.25,0.27-0.44,0.52-0.49c0.26-0.04,0.51,0.08,0.65,0.3c0.83,1.34,2.37,2.05,3.92,1.8c1.54-0.25,2.78-1.39,3.15-2.91
              c0.08-0.34,0.43-0.55,0.78-0.47c0.34,0.08,0.55,0.43,0.47,0.78C994.93,205.9,993.28,207.42,991.23,207.75z"/>
          </g>
          <g>
            <path style="fill:#FFFFFF;" d="M859.4,233.13c-0.34-0.08-0.69,0.13-0.78,0.47c-0.37,1.52-1.61,2.66-3.15,2.91
              c-1.56,0.25-3.1-0.46-3.92-1.8c-0.14-0.22-0.39-0.34-0.65-0.3c-0.26,0.04-0.46,0.23-0.52,0.49c-0.36,1.53-1.6,2.69-3.16,2.94
              c-1.56,0.25-3.1-0.46-3.92-1.8c-0.14-0.22-0.39-0.34-0.65-0.3c-0.26,0.04-0.46,0.23-0.52,0.49c-0.02,0.08-0.04,0.16-0.06,0.23
              c0.45,0.4,0.85,0.89,1.21,1.49c1.13,0.96,2.63,1.4,4.15,1.16c1.66-0.27,3.05-1.31,3.79-2.76c1.15,1.15,2.8,1.71,4.46,1.44
              c2.05-0.33,3.7-1.85,4.2-3.87C859.95,233.56,859.74,233.21,859.4,233.13z"/>
          </g>
          <path style="fill:#FFFFFF;" d="M846.23,225.48c-0.35,0.02-0.63,0.32-0.61,0.67c0.1,1.97-1.31,3.7-3.26,4.01
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32s-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32s-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.58,0.25-3.14-0.47-3.95-1.85c-0.18-0.3-0.57-0.41-0.88-0.22c-0.3,0.18-0.41,0.57-0.22,0.88c1.09,1.84,3.15,2.8,5.26,2.47
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52s3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52s3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c2.6-0.42,4.47-2.71,4.34-5.34
            C846.88,225.74,846.58,225.47,846.23,225.48z"/>
          <path style="fill:#FFFFFF;" d="M879.91,236.39c-0.35,0.02-0.63,0.32-0.61,0.67c0.1,1.97-1.31,3.7-3.26,4.01
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32s-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.59,2.82-3.19,3.08
            c-1.6,0.26-3.2-0.52-4-1.92c-0.13-0.23-0.4-0.36-0.66-0.32c-0.27,0.04-0.48,0.25-0.53,0.51c-0.31,1.59-1.6,2.82-3.19,3.08
            c-1.58,0.25-3.14-0.47-3.95-1.85c-0.18-0.3-0.57-0.41-0.88-0.22c-0.3,0.18-0.41,0.57-0.22,0.88c1.09,1.84,3.15,2.8,5.26,2.47
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52s3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52s3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52
            c1.69-0.27,3.11-1.37,3.84-2.87c1.16,1.2,2.85,1.79,4.54,1.52c2.6-0.42,4.47-2.71,4.34-5.34
            C880.57,236.65,880.27,236.37,879.91,236.39z"/>
        </g>
      </g>
    </g>
  </g>
            <g id="sme">
    <g>
      <g id="background_2_">
        <g>
          <path style="fill:#044A6D;" d="M1018.19,1015.27l0.63-905.41c193.76-27.47,334.3,14.46,493.81,114.13L1018.19,1015.27z"/>
        </g>
      </g>
      <g id="icon">
        <g>

            <rect x="1197.95" y="167.75" transform="matrix(0.9575 0.2885 -0.2885 0.9575 114.214 -343.1571)" style="fill:#FFFFFF;" width="46.9" height="96.37"/>
          <g>

              <rect x="1192.4" y="243.97" transform="matrix(0.9575 0.2885 -0.2885 0.9575 122.3337 -339.345)" style="fill:#A4EBF7;" width="40.26" height="2.84"/>
          </g>
          <g>
            <defs>

                <rect id="SVGID_12_" x="1192.93" y="246.41" transform="matrix(0.9575 0.2885 -0.2885 0.9575 124.8201 -338.1777)" width="33.76" height="16"/>
            </defs>
            <clipPath id="SVGID_13_">
              <use xlink:href="#SVGID_12_"  style="overflow:visible;"/>
            </clipPath>

            <rect x="1192.93" y="246.41" transform="matrix(0.9575 0.2885 -0.2885 0.9575 124.8201 -338.1777)" style="clip-path:url(#SVGID_13_);fill:#6AD0EF;" width="33.76" height="16"/>

            <rect x="1216.97" y="248.75" transform="matrix(0.9575 0.2885 -0.2885 0.9575 125.8231 -340.3141)" style="clip-path:url(#SVGID_13_);fill:#FFFFFF;" width="1.18" height="16"/>

            <rect x="1201.47" y="244.08" transform="matrix(-0.9575 -0.2885 0.2885 -0.9575 2280.3057 840.1967)" style="clip-path:url(#SVGID_13_);fill:#FFFFFF;" width="1.18" height="16"/>
            <path style="clip-path:url(#SVGID_13_);fill:#FFFFFF;" d="M1209.56,257.88L1209.56,257.88c-0.18-0.06-0.29-0.25-0.23-0.43
              l0.82-2.72c0.06-0.18,0.25-0.29,0.43-0.23l0,0c0.18,0.06,0.29,0.25,0.23,0.43l-0.82,2.72
              C1209.94,257.84,1209.74,257.94,1209.56,257.88z"/>
            <path style="clip-path:url(#SVGID_13_);fill:#FFFFFF;" d="M1208.1,257.45L1208.1,257.45c0.18,0.06,0.38-0.05,0.43-0.23
              l0.82-2.72c0.06-0.18-0.05-0.38-0.23-0.43l0,0c-0.18-0.06-0.38,0.05-0.43,0.23l-0.82,2.72
              C1207.82,257.2,1207.92,257.39,1208.1,257.45z"/>
          </g>
          <g>

              <rect x="1195.87" y="230.62" transform="matrix(0.9575 0.2885 -0.2885 0.9575 119.154 -340.8379)" style="fill:#6AD0EF;" width="40.26" height="6.47"/>
          </g>
          <g>

              <rect x="1199.87" y="217.35" transform="matrix(0.9575 0.2885 -0.2885 0.9575 115.4953 -342.5556)" style="fill:#6AD0EF;" width="40.26" height="6.47"/>
          </g>
          <g>

              <rect x="1203.87" y="204.08" transform="matrix(0.9575 0.2885 -0.2885 0.9575 111.8366 -344.2733)" style="fill:#6AD0EF;" width="40.26" height="6.47"/>
          </g>
          <g>

              <rect x="1207.87" y="190.81" transform="matrix(0.9575 0.2885 -0.2885 0.9575 108.1779 -345.991)" style="fill:#6AD0EF;" width="40.26" height="6.47"/>
          </g>
          <g>

              <rect x="1211.87" y="177.53" transform="matrix(0.9575 0.2885 -0.2885 0.9575 104.5192 -347.7087)" style="fill:#6AD0EF;" width="40.26" height="6.47"/>
          </g>

          <rect x="1214.97" y="174.01" transform="matrix(0.9575 0.2885 -0.2885 0.9575 102.6389 -343.6747)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1224.24" y="176.8" transform="matrix(0.9575 0.2885 -0.2885 0.9575 103.8388 -346.2306)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1234.8" y="179.98" transform="matrix(0.9575 0.2885 -0.2885 0.9575 105.2055 -349.1415)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1243.69" y="182.66" transform="matrix(0.9575 0.2885 -0.2885 0.9575 106.3555 -351.5911)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1210.98" y="187.24" transform="matrix(0.9575 0.2885 -0.2885 0.9575 106.2864 -341.9622)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1220.26" y="190.03" transform="matrix(0.9575 0.2885 -0.2885 0.9575 107.4864 -344.5181)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1230.82" y="193.21" transform="matrix(0.9575 0.2885 -0.2885 0.9575 108.853 -347.429)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1239.7" y="195.89" transform="matrix(0.9575 0.2885 -0.2885 0.9575 110.0031 -349.8786)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1207" y="200.47" transform="matrix(0.9575 0.2885 -0.2885 0.9575 109.934 -340.2497)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1216.27" y="203.26" transform="matrix(0.9575 0.2885 -0.2885 0.9575 111.134 -342.8056)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1226.83" y="206.44" transform="matrix(0.9575 0.2885 -0.2885 0.9575 112.5006 -345.7165)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1235.72" y="209.12" transform="matrix(0.9575 0.2885 -0.2885 0.9575 113.6507 -348.1661)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1203.01" y="213.7" transform="matrix(0.9575 0.2885 -0.2885 0.9575 113.5816 -338.5372)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1212.28" y="216.49" transform="matrix(0.9575 0.2885 -0.2885 0.9575 114.7815 -341.0931)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1222.84" y="219.68" transform="matrix(0.9575 0.2885 -0.2885 0.9575 116.1482 -344.0041)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1231.73" y="222.35" transform="matrix(0.9575 0.2885 -0.2885 0.9575 117.2982 -346.4537)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1199.03" y="226.93" transform="matrix(0.9575 0.2885 -0.2885 0.9575 117.2291 -336.8247)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1208.3" y="229.73" transform="matrix(0.9575 0.2885 -0.2885 0.9575 118.4291 -339.3807)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1218.86" y="232.91" transform="matrix(0.9575 0.2885 -0.2885 0.9575 119.7957 -342.2916)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect x="1227.74" y="235.59" transform="matrix(0.9575 0.2885 -0.2885 0.9575 120.9458 -344.7412)" style="fill:#FFFFFF;" width="4.8" height="4.8"/>

          <rect id="XMLID_17_" x="1182.17" y="261.43" transform="matrix(0.9575 0.2885 -0.2885 0.9575 126.9401 -337.1882)" style="fill:#6AD0EF;" width="50.69" height="1.33"/>
        </g>
        <g id="XMLID_1_">

            <rect id="XMLID_16_" x="1272.65" y="183.97" transform="matrix(0.9575 0.2885 -0.2885 0.9575 108.6335 -361.7779)" style="fill:#A4EBF7;" width="18.28" height="7.44"/>

          <rect id="XMLID_15_" x="1264.72" y="191.07" transform="matrix(0.9575 0.2885 -0.2885 0.9575 110.8369 -360.7593)" style="fill:#A4EBF7;" width="29.44" height="9.21"/>

          <rect id="XMLID_14_" x="1246.46" y="197.59" transform="matrix(0.9575 0.2885 -0.2885 0.9575 122.1495 -354.5416)" style="fill:#6AD0EF;" width="35.07" height="79.15"/>
          <g id="XMLID_6_">

              <rect id="XMLID_33_" x="1216.41" y="233.23" transform="matrix(0.2885 -0.9575 0.9575 0.2885 667.2719 1366.3438)" style="fill:#FFFFFF;" width="73.11" height="1.95"/>

            <rect x="1223.7" y="235.43" transform="matrix(0.2885 -0.9575 0.9575 0.2885 670.3557 1374.8859)" style="fill:#FFFFFF;" width="73.11" height="1.95"/>

            <rect x="1230.99" y="237.62" transform="matrix(0.2885 -0.9575 0.9575 0.2885 673.4396 1383.428)" style="fill:#FFFFFF;" width="73.11" height="1.95"/>

            <rect id="XMLID_31_" x="1238.27" y="239.82" transform="matrix(0.2885 -0.9575 0.9575 0.2885 676.5235 1391.9702)" style="fill:#FFFFFF;" width="73.11" height="1.95"/>
          </g>

          <rect id="XMLID_5_" x="1264.84" y="190.26" transform="matrix(0.9575 0.2885 -0.2885 0.9575 110.4881 -360.0165)" style="fill:#FFFFFF;" width="23.81" height="9.21"/>

          <rect id="XMLID_4_" x="1280.42" y="203.91" transform="matrix(0.9575 0.2885 -0.2885 0.9575 125.0405 -360.2695)" style="fill:#A4EBF7;" width="8.92" height="80.41"/>

          <rect id="XMLID_3_" x="1272.77" y="183.18" transform="matrix(0.9575 0.2885 -0.2885 0.9575 108.2908 -361.0481)" style="fill:#6AD0EF;" width="12.75" height="7.44"/>

          <rect id="XMLID_2_" x="1232.9" y="276.35" transform="matrix(0.9575 0.2885 -0.2885 0.9575 133.3269 -350.7655)" style="fill:#FFFFFF;" width="47.75" height="1.25"/>
        </g>
      </g>
    </g>
  </g>
            <g id="paramedics">
    <g>
      <g>
        <path style="fill:#076489;" d="M135.97,1319.05c21.19,61.54,46.53,118.93,76.27,171.9l799.65-473.5L135.97,1319.05z"/>
        <polygon style="fill:#044A6D;" points="1017.5,1014.12 1011.88,1017.45 1018.19,1015.27 				"/>
      </g>
      <g>
        <g>
          <g>
            <path style="fill:#FFF4EA;" d="M252.16,1362.54l3.78,8.51c3.72,8.39,1.02,17.75-6.02,20.88c-7.76,3.45-15,0.66-18.44-7.1
              l-5.94-13.38c-3.45-7.76-0.66-15,7.1-18.44C239.68,1349.88,248.44,1354.15,252.16,1362.54z M237.56,1382.13
              c2.45,5.53,6.61,5.07,9.66,3.72c3.63-1.61,4.83-7.15,2.64-12.1l-3.78-8.51c-2.2-4.95-7.11-7.76-10.74-6.15
              c-3.05,1.35-6.18,4.13-3.72,9.66L237.56,1382.13z"/>
          </g>
          <path style="fill:#5CBCE2;" d="M294.15,1304.58l32.94,74.21c2.98,6.72-0.05,14.59-6.76,17.57l-45.01,19.98
            c-6.72,2.98-14.58-0.05-17.57-6.77l-32.94-74.21c-2.98-6.72,0.05-14.58,6.76-17.57l45.01-19.98
            C283.3,1294.84,291.17,1297.86,294.15,1304.58z"/>
        </g>
        <polygon style="fill:#EE7A31;" points="267.82,1346.71 271.69,1355.44 262.97,1359.31 265.59,1365.21 274.31,1361.34
          278.18,1370.06 284.08,1367.44 280.21,1358.72 288.93,1354.84 286.31,1348.94 277.59,1352.82 273.72,1344.09 				"/>

        <rect x="261.92" y="1384.91" transform="matrix(0.914 -0.4057 0.4057 0.914 -537.9805 236.721)" style="fill:#FFF4EA;" width="54.85" height="4.72"/>

        <rect x="235.09" y="1324.47" transform="matrix(0.914 -0.4057 0.4057 0.914 -515.7661 220.6368)" style="fill:#FFF4EA;" width="54.85" height="4.71"/>
      </g>
    </g>
  </g>
            <g id="ambulance">
    <g>
      <path style="fill:#076489;" d="M1009.19,1022.16l-795.64,471.13c37.06,65.55,80.94,124.24,132.22,175.48L1009.19,1022.16z"/>
      <g>

          <rect x="332.51" y="1493.76" transform="matrix(0.7791 -0.6269 0.6269 0.7791 -860.0919 552.4091)" style="fill:#2D3643;" width="42.49" height="5.63"/>
        <path style="fill:#E4E4E6;" d="M341.93,1445.52l29.2,36.29l-37.82,30.43c-17.84-24.65-20.47-13.12-6.26-39.39l-3.13-9.54
          c-0.6-1.48-0.16-3.18,1.08-4.18L341.93,1445.52z"/>
        <path style="fill:#5CBCE2;" d="M330.42,1477.28l15.61,19.4l-12.43,10C320.21,1488.86,319.71,1497.34,330.42,1477.28z"/>

        <rect x="335.65" y="1467.32" transform="matrix(0.7791 -0.6269 0.6269 0.7791 -851.0105 544.5774)" style="fill:#F2F2F2;" width="23.08" height="24.9"/>
        <path style="fill:#FFFFFF;" d="M334.49,1451.5c1.19,1.42,3.6,3.83,1.42,5.52l0,0c-2.12,1.77-3.95-1.11-5.09-2.57L334.49,1451.5z
          "/>
        <path style="fill:#333333;" d="M347.99,1453.05c-2.35-2.12-6.39-11.03-10.58-7.52c-4.33,3.32,3.53,9.21,5.08,11.94
          L347.99,1453.05z"/>
        <path style="fill:#58595B;" d="M345.51,1486.11l2.67,3.32c1.59,1.91-1.45,4.36-2.98,2.4c0,0-2.67-3.32-2.67-3.32
          C340.94,1486.6,343.98,1484.15,345.51,1486.11z"/>

        <rect x="345.74" y="1489.2" transform="matrix(0.7791 -0.6269 0.6269 0.7791 -857.713 546.3689)" style="fill:#FFFFFF;" width="1.28" height="1.96"/>
        <path style="fill:#5CBCE2;" d="M372.63,1483.67l53.63,66.64l-48.49,39.02l-36.7-45.6c-9.35-11.62-7.51-28.62,4.11-37.97
          L372.63,1483.67z"/>

        <rect x="383" y="1433.44" transform="matrix(0.7791 -0.6269 0.6269 0.7791 -855.2347 572.0386)" style="fill:#2D3643;" width="2.07" height="132.12"/>
        <path style="fill:#5CBCE2;" d="M418.21,1551.26l2.7,3.36l-2.74,2.2c-0.76-1.35-4.6-4.19-2.43-5.83l0,0
          C416.5,1550.38,417.6,1550.5,418.21,1551.26z"/>
        <path style="fill:#2D3643;" d="M348.49,1474.1c4.44,5.52,12.52,6.39,18.04,1.95l-16.09-19.99
          C344.92,1460.5,344.05,1468.58,348.49,1474.1z"/>
        <path style="fill:#58595B;" d="M352.41,1458.51c10.06-7.81,21.93,6.94,12.15,15.09C354.5,1481.42,342.63,1466.66,352.41,1458.51
          z"/>
        <path style="fill:#FFFFFF;" d="M354.22,1460.75c6.96-5.55,15.45,5,8.54,10.61C355.79,1476.91,347.31,1466.36,354.22,1460.75z"/>
        <path style="fill:#E5E5E5;" d="M356.2,1463.21c3.65-3.02,8.31,2.77,4.58,5.69C357.13,1471.92,352.47,1466.13,356.2,1463.21z"/>
        <path style="fill:#EFEFEF;" d="M357.15,1464.4c2.13-1.77,4.86,1.62,2.67,3.32C357.69,1469.49,354.97,1466.1,357.15,1464.4z"/>
        <path style="fill:#2D3643;" d="M401.79,1540.36c4.44,5.52,12.52,6.39,18.04,1.95l-16.09-19.99
          C398.22,1526.77,397.35,1534.84,401.79,1540.36z"/>
        <path style="fill:#58595B;" d="M405.71,1524.77c10.06-7.81,21.93,6.94,12.15,15.09C407.8,1547.68,395.93,1532.92,405.71,1524.77
          z"/>
        <path style="fill:#FFFFFF;" d="M407.52,1527.02c6.96-5.55,15.45,5,8.54,10.61C409.09,1543.17,400.61,1532.63,407.52,1527.02z"/>
        <path style="fill:#E5E5E5;" d="M409.5,1529.48c3.65-3.02,8.31,2.77,4.58,5.69C410.43,1538.19,405.77,1532.39,409.5,1529.48z"/>
        <path style="fill:#EFEFEF;" d="M410.45,1530.66c2.13-1.77,4.86,1.62,2.67,3.32C410.99,1535.75,408.27,1532.36,410.45,1530.66z"
        />
        <g>
          <circle style="fill:#FFF4EA;" cx="373.02" cy="1538.26" r="8.75"/>
          <g>

              <rect x="371.06" y="1532.05" transform="matrix(-0.6269 -0.7791 0.7791 -0.6269 -591.5463 2793.2444)" style="fill:#EE7A31;" width="3.91" height="12.42"/>

            <rect x="371.06" y="1532.05" transform="matrix(-0.7791 0.6269 -0.6269 -0.7791 1628.0048 2502.8206)" style="fill:#EE7A31;" width="3.91" height="12.42"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="firefighters">
    <g>
      <g>

          <rect x="469.82" y="1649.56" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -164.6582 3290.8982)" style="fill:#5CBCE2;" width="71.96" height="50.62"/>
        <path style="fill:#076489;" d="M347.68,1670.67c51.9,51.49,110.56,94.97,178.13,130.63l0.03-0.06l485.3-777.23L347.68,1670.67z"
        />
        <g>

            <rect x="504.44" y="1636.87" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -109.873 3274.5789)" style="fill:#FFF4EA;" width="51.66" height="40.11"/>
          <path style="fill:#5CBCE2;" d="M511.56,1652.4l27.11,22.22c1.26,1.03,1.44,2.9,0.41,4.15l0,0c-1.03,1.26-2.9,1.44-4.15,0.41
            l-27.11-22.22c-1.26-1.03-1.44-2.9-0.41-4.15l0,0C508.44,1651.55,510.3,1651.37,511.56,1652.4z"/>
          <path style="fill:#5CBCE2;" d="M518.73,1643.65l27.11,22.22c1.26,1.03,1.44,2.9,0.41,4.15l0,0c-1.03,1.26-2.9,1.44-4.15,0.41
            l-27.11-22.22c-1.26-1.03-1.44-2.9-0.41-4.15l0,0C515.61,1642.8,517.47,1642.62,518.73,1643.65z"/>
          <path style="fill:#5CBCE2;" d="M525.9,1634.9l27.11,22.22c1.26,1.03,1.44,2.9,0.41,4.15l0,0c-1.03,1.26-2.9,1.44-4.15,0.41
            l-27.11-22.22c-1.26-1.03-1.44-2.9-0.41-4.15l0,0C522.78,1634.06,524.65,1633.87,525.9,1634.9z"/>

          <rect x="457.75" y="1598.02" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -189.5006 3200.269)" style="fill:#5CBCE2;" width="38.87" height="71.96"/>
          <path style="fill:#5CBCE2;" d="M439.34,1649.51l30.06,24.64l-1.79,2.19c-6.8,8.3-19.05,9.52-27.35,2.71h0
            c-8.3-6.8-9.52-19.05-2.71-27.35L439.34,1649.51z"/>

          <rect x="431.96" y="1661.24" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -249.9394 3236.4426)" style="fill:#5CBCE2;" width="42.93" height="3.3"/>

          <rect x="462.41" y="1652.85" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -221.0231 3238.3254)" style="fill:#FFF4EA;" width="11.62" height="11.62"/>

          <rect x="449.28" y="1642.09" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -237.4853 3210.9226)" style="fill:#FFF4EA;" width="11.62" height="11.62"/>

          <rect x="476.91" y="1635.16" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -184.089 3216.1372)" style="fill:#FFF4EA;" width="11.62" height="11.62"/>

          <rect x="463.78" y="1624.4" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -200.5512 3188.7344)" style="fill:#FFF4EA;" width="11.62" height="11.62"/>

          <rect x="484.09" y="1603.6" transform="matrix(-0.7734 -0.6339 0.6339 -0.7734 -151.6475 3178.7112)" style="fill:#FFF4EA;" width="16.33" height="25.71"/>
        </g>
      </g>
    </g>
  </g>
            <g id="police-force">
    <g>
      <path style="fill:#076489;" d="M1016.67,1020.25l-488.47,782.3c66.79,35.05,141.48,62.1,224.69,80.49L1016.67,1020.25z"/>
      <g>
        <polygon style="fill:#F7E8DC;" points="648.89,1771.54 650.65,1769.68 669.5,1770.34 685.8,1784.71 685.56,1787.94 				"/>
        <polygon style="fill:#FFF4EA;" points="665.54,1750.24 665.96,1745.71 685.04,1726.61 693.99,1730.47 692.55,1757.08
          689.33,1760.15 				"/>
        <polygon style="fill:#5CBCE2;" points="686.84,1717.55 682.87,1737.6 678.05,1741.24 680.88,1747.6 687.43,1745.26
          686.73,1739.25 698.49,1722.53 				"/>
        <path style="fill:#5CBCE2;" d="M647.15,1720.83c-2.16,11.33,17.99,24.35,18.81,24.88l25.42-22.69l1.16,34.06
          c0.95,0.23,24.28,5.81,30.98-3.59c1.67-2.34,5.96-8.17,7.39-10.07c-8.82-9.29-21.11-20.09-36.8-26.8
          c-15.68-6.71-31.99-8.13-44.8-8.09C648.94,1710.88,647.69,1718.01,647.15,1720.83z"/>
        <path style="fill:#FFF4EA;" d="M704.33,1752.18l9.02,0.17c0.68,0.01,1.23,0.58,1.21,1.26l-0.02,1.18
          c-0.01,0.68-0.58,1.23-1.26,1.21l-9.02-0.17c-0.68-0.01-1.23-0.58-1.21-1.26l0.02-1.18
          C703.08,1752.71,703.65,1752.16,704.33,1752.18z"/>
        <path style="fill:#FFF4EA;" d="M668.19,1719.31c0.5-1.2,2.04-3.35,6.67-3.27c0,0,0,0,0,0c0,0,0,0,0,0
          c3.26,3.29,2.77,5.89,2.24,7.08c-0.11,0.27-1.86,4.36-1.9,4.44c-0.04-0.02-0.1-0.05-0.15-0.06c-1.96-0.84-3.98-0.76-5.13,0.08
          c-0.19-1.41-1.53-2.93-3.49-3.77c-0.04-0.02-0.11-0.04-0.15-0.06C666.33,1723.67,668.08,1719.58,668.19,1719.31z"/>
        <path style="fill:#FFF4EA;" d="M652.43,1728.23l0.84-0.83c0.48-0.48,1.27-0.48,1.75,0.01l6.35,6.4
          c0.48,0.49,0.48,1.27-0.01,1.75l-0.84,0.83c-0.49,0.48-1.27,0.48-1.75-0.01l-6.35-6.4
          C651.94,1729.49,651.95,1728.71,652.43,1728.23z"/>
        <path style="fill:#5CBCE2;" d="M680.1,1749.43c7.7,3.29,11.84,12.66,10.39,23.11c0.28,0.11,0.63,0.34,0.88,0.82
          c0.81,1.56-0.13,4.57-0.44,5.45c-0.31,0.89-1.93,5.16-4.11,4.84c-0.09,0.17-0.19,0.35-0.29,0.53c-0.15,0.27-0.42,0.47-0.73,0.53
          c-0.31,0.06-0.62-0.01-0.87-0.21c-4.58-3.57-10.06-6.77-15.87-9.25c-5.81-2.48-11.91-4.24-17.65-5.08
          c-0.31-0.05-0.59-0.22-0.75-0.49c-0.17-0.27-0.21-0.59-0.12-0.89c0.06-0.2,0.12-0.4,0.18-0.58c-1.74-1.36,0.24-5.48,0.66-6.32
          c0.43-0.83,1.95-3.59,3.64-4.08c0.52-0.15,0.93-0.05,1.2,0.08C662.76,1749.61,672.4,1746.13,680.1,1749.43z"/>
        <path style="fill:#5CBCE2;" d="M688.75,1774.89c-0.04-0.02-0.07-0.03-0.1-0.05c-0.4-0.23-0.62-0.69-0.53-1.14
          c1.93-10.07-1.72-19.24-8.86-22.29s-16.29,0.64-22.24,8.99c-0.27,0.38-0.75,0.54-1.19,0.4c-0.3-0.1-0.55-0.32-0.67-0.6
          c-0.47,0.44-1.18,1.33-1.86,2.67c-0.78,1.53-1.08,2.78-1.13,3.42c0.24,0.01,0.49,0.1,0.67,0.27c0.33,0.29,0.47,0.75,0.31,1.16
          c-0.06,0.17-0.12,0.34-0.19,0.52c5.55,0.93,11.38,2.66,16.93,5.03s10.83,5.39,15.34,8.76c0.09-0.17,0.17-0.33,0.25-0.5
          c0.19-0.4,0.61-0.64,1.05-0.6c0.26,0.02,0.5,0.14,0.68,0.31c0.44-0.48,1.12-1.56,1.68-3.16c0.5-1.42,0.65-2.54,0.64-3.19
          C689.29,1775,689,1774.99,688.75,1774.89z"/>
        <path style="fill:#5CBCE2;" d="M655.04,1759.59L655.04,1759.59L655.04,1759.59z"/>
        <path style="fill:#5CBCE2;" d="M690.06,1774.56L690.06,1774.56L690.06,1774.56z"/>
        <path style="fill:#5CBCE2;" d="M655.04,1759.59L655.04,1759.59L655.04,1759.59z"/>
        <path style="fill:#F7E8DC;" d="M669.67,1773.82c6.33,2.71,11.96,5.92,16.6,9.34c0.68-6.11-5.41-11.71-14.01-15.39
          c-8.61-3.68-16.85-4.21-20.8,0.5C657.13,1769.27,663.33,1771.11,669.67,1773.82z"/>
        <path style="fill:#5CBCE2;" d="M640.56,1779.66l16.57,20.37c0.57,0.63,1.4,0.99,2.25,0.96l26.17-2.09
          c2.15-0.06,3.5-2.33,2.53-4.25l-2.75-5.42c0.11-0.65,0.22-1.31,0.33-1.96c-4.6-3.78-10.58-7.41-17.35-10.31
          c-6.77-2.9-13.52-4.71-19.44-5.42c-0.4,0.53-0.79,1.06-1.19,1.6l-5.82,1.76C639.83,1775.52,639.12,1778.07,640.56,1779.66z"/>
        <path style="fill:#FFF4EA;" d="M660.32,1784.49c-0.16,0-0.32-0.07-0.42-0.2c-0.17-0.23-0.12-0.55,0.1-0.71
          c0.23-0.17,0.55-0.12,0.71,0.1c0.1,0.14,0.13,0.32,0.07,0.47l2.33,1l2.03-1.53c-0.09-0.14-0.11-0.31-0.04-0.47
          c0.11-0.26,0.41-0.38,0.67-0.27c0.26,0.11,0.38,0.41,0.27,0.67c-0.07,0.16-0.21,0.27-0.37,0.3l0.31,2.52l2.33,1
          c0.08-0.14,0.22-0.25,0.39-0.27c0.28-0.03,0.53,0.17,0.57,0.45c0.03,0.28-0.17,0.53-0.45,0.57c-0.17,0.02-0.33-0.05-0.44-0.16
          l-2.03,1.52l0.31,2.52c0.16,0,0.32,0.07,0.42,0.2c0.17,0.23,0.12,0.55-0.1,0.71c-0.23,0.17-0.55,0.12-0.71-0.1
          c-0.11-0.14-0.13-0.32-0.07-0.47l-2.33-1l-2.04,1.52c0.09,0.14,0.11,0.31,0.04,0.48c-0.11,0.26-0.41,0.38-0.67,0.27
          c-0.26-0.11-0.38-0.41-0.27-0.67c0.07-0.16,0.21-0.27,0.37-0.3l-0.3-2.52l-2.33-1c-0.08,0.14-0.22,0.25-0.39,0.27
          c-0.28,0.03-0.53-0.17-0.57-0.45c-0.03-0.28,0.17-0.53,0.45-0.57c0.17-0.02,0.33,0.05,0.44,0.16l2.03-1.52L660.32,1784.49z"/>
      </g>
    </g>
  </g>
            <g id="airport">
    <g>
      <path style="fill:#EE7A31;" d="M1916.59,1141.26l-893.62-125.32l846.27,379.95C1898.41,1321.46,1914.35,1239.26,1916.59,1141.26z
        "/>
      <g>
        <g>
          <g>
            <g>

                <rect x="1795.05" y="1254.53" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3496.2634 -146.6289)" style="fill:#5CBCE2;" width="17.64" height="2.41"/>

              <rect x="1789.52" y="1274.46" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3508.4592 -116.0341)" style="fill:#5CBCE2;" width="17.64" height="2.41"/>

              <rect x="1805.99" y="1243.41" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3491.1938 -160.5252)" style="fill:#5CBCE2;" width="1.25" height="6.88"/>

              <rect x="1801.31" y="1260.27" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3501.5054 -134.6573)" style="fill:#5CBCE2;" width="1.25" height="6.88"/>

              <rect x="1800.42" y="1263.49" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3503.4771 -129.7111)" style="fill:#5CBCE2;" width="1.25" height="6.88"/>

              <rect x="1747.25" y="1200.23" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3446.1907 -178.3442)" style="fill:#5CBCE2;" width="87.29" height="41.21"/>

              <rect x="1742.76" y="1216.41" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3456.0874 -153.5167)" style="fill:#044A6D;" width="87.29" height="41.21"/>

              <rect x="1761.8" y="1239.17" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3479.0837 -164.159)" style="fill:#5CBCE2;" width="80.29" height="2.53"/>

              <rect x="1809.87" y="1189.66" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3448.7898 -240.1553)" style="fill:#044A6D;" width="11.64" height="2.53"/>

              <rect x="1802.82" y="1187.7" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3437.9792 -235.8459)" style="fill:#044A6D;" width="11.64" height="2.53"/>

              <rect x="1795.78" y="1185.75" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3427.1685 -231.5365)" style="fill:#044A6D;" width="11.64" height="2.53"/>

              <rect x="1788.74" y="1183.79" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3416.3579 -227.2271)" style="fill:#044A6D;" width="11.64" height="2.53"/>

              <rect x="1781.69" y="1181.84" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3405.5471 -222.9177)" style="fill:#044A6D;" width="11.64" height="2.53"/>

              <rect x="1754.75" y="1237.22" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3468.2732 -159.8496)" style="fill:#5CBCE2;" width="80.29" height="2.53"/>

              <rect x="1747.71" y="1235.26" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3457.4624 -155.5402)" style="fill:#5CBCE2;" width="80.29" height="2.53"/>

              <rect x="1740.67" y="1233.31" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3446.6519 -151.2308)" style="fill:#5CBCE2;" width="80.29" height="2.53"/>

              <rect x="1733.62" y="1231.35" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3435.8411 -146.9214)" style="fill:#5CBCE2;" width="80.29" height="2.53"/>
            </g>
            <g>

                <rect x="1778.28" y="1254.99" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3499.7681 -88.7242)" style="fill:#5CBCE2;" width="10.66" height="62.05"/>

              <rect x="1775.43" y="1265.26" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3506.0527 -72.9585)" style="fill:#044A6D;" width="10.66" height="62.05"/>
              <polygon style="fill:#044A6D;" points="1820.49,1285.48 1816.29,1300.62 1805.73,1297.68 1808.58,1287.41 							"/>
              <polygon style="fill:#5CBCE2;" points="1812.09,1315.75 1816.29,1300.62 1805.73,1297.69 1802.88,1307.96 							"/>

              <rect x="1813.16" y="1291.06" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3554.6575 -114.9259)" style="fill:#5CBCE2;" width="15.7" height="5.44"/>

              <rect x="1808.96" y="1306.19" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3563.9185 -91.6935)" style="fill:#044A6D;" width="15.7" height="5.44"/>
              <polygon style="fill:#044A6D;" points="1825.7,1303.23 1829.9,1288.1 1826.41,1284.52 1821.54,1302.08 							"/>
              <polygon style="fill:#5CBCE2;" points="1825.7,1303.23 1821.5,1318.36 1816.67,1319.63 1821.54,1302.07 							"/>

              <rect x="1824.36" y="1296.43" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3570.4463 -116.5328)" style="fill:#5CBCE2;" width="10.32" height="5.09"/>

              <rect x="1821.61" y="1306.34" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3576.5085 -101.3244)" style="fill:#044A6D;" width="10.32" height="5.09"/>

              <rect x="1827.77" y="1298.9" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3575.6863 -118.6216)" style="fill:#044A6D;" width="10.32" height="2.05"/>

              <rect x="1825.02" y="1308.81" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3581.7488 -103.4132)" style="fill:#5CBCE2;" width="10.32" height="2.05"/>

              <rect x="1831.09" y="1301.11" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3579.7805 -117.2689)" style="fill:#5CBCE2;" width="6.76" height="2.09"/>

              <rect x="1829.28" y="1307.63" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3583.7673 -107.2673)" style="fill:#044A6D;" width="6.76" height="2.09"/>

              <rect x="1832.45" y="1302.17" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3581.9207 -118.0897)" style="fill:#044A6D;" width="6.8" height="0.79"/>

              <rect x="1830.65" y="1308.67" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3585.8965 -108.116)" style="fill:#5CBCE2;" width="6.8" height="0.79"/>
              <g>

                  <rect x="1837.68" y="1299.29" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3582.7205 -122.3287)" style="fill:#044A6D;" width="0.35" height="2.91"/>

                <rect x="1837.31" y="1300.65" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3583.5537 -120.2385)" style="fill:#044A6D;" width="0.35" height="2.91"/>

                <rect x="1836.58" y="1303.26" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3585.1509 -116.2322)" style="fill:#044A6D;" width="0.35" height="2.91"/>

                <rect x="1835.06" y="1308.75" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3588.5068 -107.8131)" style="fill:#5CBCE2;" width="0.35" height="2.91"/>

                <rect x="1835.77" y="1306.2" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3586.9446 -111.7323)" style="fill:#5CBCE2;" width="0.35" height="2.91"/>

                <rect x="1834.68" y="1310.11" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3589.3401 -105.7228)" style="fill:#5CBCE2;" width="0.35" height="2.91"/>
              </g>
              <g>

                  <rect x="1821.59" y="1286.08" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3551.0908 -123.8731)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1820.58" y="1289.7" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3553.3049 -118.3185)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1819.58" y="1293.32" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3555.5193 -112.7638)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1818.57" y="1296.93" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3557.7334 -107.2091)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1817.17" y="1302" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3560.8347 -99.429)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1816.16" y="1305.62" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3563.0491 -93.8744)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1815.16" y="1309.24" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3565.2632 -88.3197)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1814.15" y="1312.86" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3567.4775 -82.7651)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>
              </g>
              <g>

                  <rect x="1829.26" y="1294.28" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3568.7209 -120.8749)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1828.47" y="1297.14" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3570.4722 -116.4815)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1827.69" y="1299.93" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3572.1812 -112.1945)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1826.47" y="1304.34" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3574.8774 -105.4305)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1825.67" y="1307.2" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3576.6287 -101.0371)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>

                <rect x="1824.9" y="1310" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3578.3376 -96.7502)" style="fill:#5CBCE2;" width="2.09" height="3.74"/>
              </g>
              <g>

                  <rect x="1792.96" y="1296.71" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3535.8115 -93.9565)" style="fill:#5CBCE2;" width="21.32" height="0.77"/>

                <rect x="1788.88" y="1295.58" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3529.5408 -91.4569)" style="fill:#5CBCE2;" width="21.32" height="0.77"/>

                <rect x="1773.57" y="1291.33" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3506.0457 -82.0912)" style="fill:#5CBCE2;" width="21.32" height="0.77"/>

                <rect x="1769.48" y="1290.2" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3499.7749 -79.5915)" style="fill:#5CBCE2;" width="21.32" height="0.77"/>

                <rect x="1754.18" y="1285.95" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3476.28 -70.2259)" style="fill:#5CBCE2;" width="21.32" height="0.77"/>

                <rect x="1750.09" y="1284.82" transform="matrix(-0.2674 0.9636 -0.9636 -0.2674 3470.009 -67.7262)" style="fill:#5CBCE2;" width="21.32" height="0.77"/>
              </g>
            </g>
          </g>
          <g>
            <path style="fill:#5CBCE2;" d="M1828.89,1231.6c-0.12,0.31,0.06,1.35,0.56,3.29c0,0,0.36,0.17,0.97,0.01
              c0.59-0.15,0.85-0.48,0.85-0.48c-0.5-1.94-0.85-2.93-1.1-3.15l-0.65,0.12L1828.89,1231.6z"/>
            <path style="fill:#5CBCE2;" d="M1832.98,1232.41c-0.12,0.31,0.06,1.35,0.56,3.29c0,0,0.36,0.17,0.97,0.01
              c0.59-0.15,0.85-0.48,0.85-0.48c-0.5-1.94-0.85-2.93-1.1-3.15l-0.65,0.12L1832.98,1232.41z"/>
            <path style="fill:#5CBCE2;" d="M1841.99,1234.82c0,0-22.01-5.2-23.06-5.81c-1.04-0.61-1.31-3.07-1.31-3.07l22.25,0.67
              L1841.99,1234.82z"/>
            <path style="fill:#5CBCE2;" d="M1818.63,1228.65c-0.06-0.03-0.12-0.07-0.18-0.12c0.14,0.2,0.3,0.37,0.48,0.48
              c1.04,0.61,23.06,5.81,23.06,5.81l-0.4-1.54C1838.79,1232.76,1819.63,1229.17,1818.63,1228.65z"/>
            <path style="fill:#5CBCE2;" d="M1835.21,1208.08c0,0-5.61-1.84-5.88-2.06c-0.63-0.54-0.73-1.84-0.73-1.84l5.97-0.76
              L1835.21,1208.08z"/>
            <path style="fill:#5CBCE2;" d="M1855.21,1224.8c0.25,0.21,0.6,1.21,1.1,3.15c0,0-0.23,0.32-0.85,0.48
              c-0.59,0.15-0.97-0.01-0.97-0.01c-0.5-1.94-0.68-2.98-0.56-3.29l0.63-0.21L1855.21,1224.8z"/>
            <path style="fill:#5CBCE2;" d="M1852.03,1227.49c0.25,0.21,0.6,1.21,1.1,3.15c0,0-0.23,0.32-0.85,0.48
              c-0.59,0.15-0.97-0.01-0.97-0.01c-0.5-1.94-0.68-2.98-0.56-3.29l0.63-0.21L1852.03,1227.49z"/>
            <path style="fill:#5CBCE2;" d="M1845.31,1233.97c0,0,16.74-15.21,17.35-16.25s-0.34-3.32-0.34-3.32l-19.13,11.37
              L1845.31,1233.97z"/>
            <path style="fill:#5CBCE2;" d="M1862.75,1217.25c0.04-0.06,0.07-0.12,0.1-0.19c-0.02,0.24-0.08,0.47-0.19,0.65
              c-0.61,1.04-17.35,16.25-17.35,16.25l-0.4-1.54C1847.11,1230.61,1862.14,1218.19,1862.75,1217.25z"/>
            <path style="fill:#5CBCE2;" d="M1838.28,1207.29c0,0,4.02-4.33,4.14-4.65c0.29-0.78-0.25-1.96-0.25-1.96l-5.59,2.22
              L1838.28,1207.29z"/>
            <path style="fill:#044A6D;" d="M1835.61,1214.12c0.6,2.3,7.38,28.55,7.89,30.55c0.67,2.59,3.26,5.69,4.21,5.44
              s1.71-4.21,1.04-6.8c-0.52-2-7.3-28.25-7.89-30.55c-1.14-4.42-5.7-12.82-6.09-12.72
              C1834.39,1200.15,1834.47,1209.7,1835.61,1214.12z"/>
            <path style="fill:#5CBCE2;" d="M1846.82,1246.64c-0.51,0.13-1.11,0.07-1.24-0.42l-0.4,0.4c0.08,0.29,0.69,1.41,1.91,1.09
              c1.22-0.32,1.22-1.59,1.14-1.88l-0.54-0.15C1847.82,1246.16,1847.33,1246.51,1846.82,1246.64z"/>
            <path style="fill:#5CBCE2;" d="M1835.2,1203.43c0.13,0.48,1.38,5.36,1.56,6.05c0.23,0.91,0.87,1.6,0.87,1.6
              s0.22-0.91-0.02-1.82c-0.18-0.69-1.44-5.56-1.56-6.05c-0.13-0.48-0.66-0.78-0.66-0.78S1835.07,1202.95,1835.2,1203.43z"/>
            <path style="fill:#5CBCE2;" d="M1846.13,1249.17c0.64,0.65,1.23,1.04,1.59,0.95c0.36-0.09,0.69-0.72,0.93-1.6L1846.13,1249.17
              z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="ports">
    <g>
      <path style="fill:#EE7A31;" d="M1722.38,1627.41c64.46-74.15,113.26-146.89,145.87-229.02l-846.55-380.07L1722.38,1627.41z"/>
      <g>
        <g>
          <polygon style="fill:none;" points="1732.58,1422.07 1732.08,1422.15 1731.67,1422.82 1733.09,1423.69 					"/>
          <polygon style="fill:none;" points="1732.44,1421.62 1732.43,1421.58 1732.4,1421.62 					"/>
          <path style="fill:none;" d="M1733.7,1424.06l0.08,0.05l0.43-0.7c-0.36-0.35-0.71-0.7-1.05-1.06L1733.7,1424.06z"/>
          <path style="fill:none;" d="M1734.37,1424.26l0.06-0.01c0.02-0.07,0.04-0.13,0.06-0.2L1734.37,1424.26z"/>
          <path style="fill:#EDF1BF;" d="M1744.03,1427.63c0.05-0.2,0.1-0.39,0.15-0.59l-0.42-0.26L1744.03,1427.63z"/>
          <polygon style="fill:#E3E1EA;" points="1709.93,1411.29 1709.88,1411.3 1708.58,1413.4 1708.6,1413.47 					"/>
          <polygon style="fill:#E3E1EA;" points="1712.93,1406.4 1713.6,1405.3 1712.92,1406.39 					"/>
          <polygon style="fill:#E3E1EA;" points="1710.25,1410.76 1710.3,1410.7 1710.26,1410.68 1710.21,1410.77 					"/>
          <polygon style="fill:#D7D6DF;" points="1703.36,1409.66 1705.93,1405.44 1705.92,1405.43 1703.34,1409.65 					"/>

          <rect x="1706.54" y="1404.22" transform="matrix(0.5197 -0.8543 0.8543 0.5197 -379.9904 2132.5225)" style="fill:#D7D6DF;" width="0.25" height="0.01"/>
          <polygon style="fill:#D7D6DF;" points="1707.16,1403.41 1707.16,1403.42 1707.87,1402.25 					"/>
          <polygon style="fill:#5CBCE2;" points="1704.9,1399.09 1706.62,1398.45 1708.5,1399.61 1710.1,1396.74 1709.13,1395.48
            1705.87,1391.22 1705.8,1391.33 1705.95,1391.42 1692.96,1412.64 1698.48,1416.02 1698.54,1416.06 1699.35,1414.74
            1694.32,1411.66 1694.33,1411.64 1694.29,1411.62 1694.23,1411.58 1697.57,1406.12 1698.94,1406.95 1701.52,1402.74
            1701.5,1402.73 1702.18,1401.63 1702.19,1401.64 1702.33,1401.42 1702.21,1401.35 1702.64,1400.65 1702.76,1400.72
            1704.2,1398.35 					"/>
          <polygon style="fill:#5CBCE2;" points="1707.34,1401.69 1707.36,1401.66 1708.1,1400.33 1706.25,1399.19 1705.81,1400.06
            "/>
          <polygon style="fill:#044A6D;" points="1707.34,1401.69 1705.81,1400.06 1705.8,1400.09 1704.89,1399.1 1704.9,1399.09
            1704.2,1398.35 1702.76,1400.72 1707.16,1403.41 1707.87,1402.25 					"/>

          <rect x="1704.33" y="1400.3" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -381.9212 2124.1624)" style="fill:#044A6D;" width="0.25" height="5.16"/>
          <path style="fill:#044A6D;" d="M1703.34,1409.65l2.58-4.22l-4.4-2.69l-2.58,4.22L1703.34,1409.65z M1703.57,1408.67
            c-0.17,0.28-0.53,0.36-0.81,0.19c-0.28-0.17-0.36-0.53-0.19-0.81s0.53-0.36,0.81-0.19
            C1703.66,1408.03,1703.74,1408.39,1703.57,1408.67z"/>
          <polygon style="fill:#044A6D;" points="1700.69,1414.05 1703.27,1409.8 1700.39,1410.13 1699.37,1411.8 1695.6,1409.49
            1694.29,1411.62 1694.33,1411.64 1694.99,1410.56 					"/>
          <polygon style="fill:#044A6D;" points="1691.24,1411.63 1692.94,1412.67 1692.96,1412.64 1705.95,1391.42 1705.8,1391.33
            1705.23,1390.98 1704.25,1390.38 1703.91,1390.94 1703.61,1391.43 1697.3,1401.73 					"/>
          <path style="fill:#044A6D;" d="M1703.38,1407.86c-0.28-0.17-0.64-0.08-0.81,0.19s-0.08,0.64,0.19,0.81
            c0.28,0.17,0.64,0.08,0.81-0.19C1703.74,1408.39,1703.66,1408.03,1703.38,1407.86z"/>
          <polygon style="fill:#044A6D;" points="1707.16,1403.42 1707.6,1403.69 1707.87,1402.25 					"/>
          <polygon style="fill:#044A6D;" points="1706.59,1404.33 1702.19,1401.64 1702.18,1401.63 1701.5,1402.73 1701.52,1402.74
            1705.92,1405.43 1705.93,1405.44 1703.36,1409.66 1703.34,1409.65 1698.94,1406.95 1697.57,1406.12 1694.23,1411.58
            1694.29,1411.62 1695.6,1409.49 1699.37,1411.8 1700.39,1410.13 1703.27,1409.8 1700.69,1414.05 1694.99,1410.56
            1694.33,1411.64 1694.32,1411.66 1699.35,1414.74 1700.57,1415.49 1700.58,1415.47 1700.98,1415.72 1703.57,1411.48
            1702.87,1411.05 1703.3,1410.35 1704,1410.78 1705.75,1407.92 1705.3,1407.64 1705.73,1406.94 1706.18,1407.22
            1707.27,1405.43 1707.44,1404.55 1706.73,1404.12 1706.6,1404.33 					"/>
          <path style="fill:#5CBCE2;" d="M1708.5,1399.61l-1.88-1.15l-1.71,0.64l-0.01,0l0.91,0.99l0.01-0.02l0.43-0.87l1.85,1.13
            l2.65,1.62l-1.63,2.67l0.63,0.38l2.14-0.36l0.65,2.07l1.66,1.02l2.84,0.39l0.69,0.09l2.04,2.92l1.39,0.85l2.14-0.36l0.65,2.07
            l1.01,0.62l2.14-0.36l0.65,2.07l0.65,0.4l0.84-1.37l0.79-1.3l0.6,0.36l0.52,2.1l0.55,2.22l0.99,0.61l-0.55-2.22l-0.79-3.23
            l-4.82-2.95l-8.01-4.9l-3.45-2.11l-0.28-0.17L1708.5,1399.61z M1715.5,1405.02l1.78,2.55l-3.08-0.42L1715.5,1405.02z
             M1718.49,1408.31l3.14,0.43l-1.33,2.17L1718.49,1408.31z M1725.22,1410.8l-1.61,0.27l-0.49-1.56L1725.22,1410.8z
             M1721.34,1411.47l1.26-2.05l0.55,1.74L1721.34,1411.47z M1723.75,1411.53l1.91-0.32l-1.33,2.17L1723.75,1411.53z
             M1725.07,1413.8l0.58-0.95l0.72-1.17l0.57,1.81l-0.2,0.03L1725.07,1413.8z M1728.72,1414.73l-0.6,0.97l-0.52-1.65l-0.06-0.19
            l1.91-0.32L1728.72,1414.73z M1729.02,1413.13l-1.61,0.27l-0.49-1.56L1729.02,1413.13z M1720.7,1408.04l-2.66-0.37l-0.4-0.57
            l-1.14-1.63l1.55,0.95L1720.7,1408.04z M1711.17,1402.37l0.57,1.81l-1.87,0.32L1711.17,1402.37z M1711.72,1402.54l2.1,1.29
            l-1.61,0.27L1711.72,1402.54z M1712.93,1406.4L1712.93,1406.4l-0.58-1.85l1.91-0.32l-0.66,1.07L1712.93,1406.4z"/>
          <polygon style="fill:#5CBCE2;" points="1745.72,1455.09 1745.15,1456.06 1745.74,1455.1 					"/>
          <path style="fill:#5CBCE2;" d="M1749.53,1449.59l-0.08,0.14l-0.04,0.01l0.01,0.05l-1.13,1.92l-0.85,1.45l-0.15,0.05l0.04,0.14
            l-6.28,10.67l-6.83,11.61l-8.75,14.88l-0.02,0.01l0,0.02l-1.99,3.38l-0.14,0.07l0.02,0.13l-0.46,0.79l0.03,0.4l-0.02,0
            c-0.02,0.31-0.01,0.58,0.02,0.81c0.16,0.41,0.4,0.69,0.72,0.83c0.06,0.03,0.12,0.05,0.19,0.06c-1.07-0.79-0.26-1.65-0.26-1.65
            l33.1-56.3l-0.69-0.42l-6.4,10.88l-0.04,0.01L1749.53,1449.59z"/>

          <rect x="1739.96" y="1451.78" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -407.3961 2179.1587)" style="fill:#5CBCE2;" width="1.68" height="2.66"/>
          <polygon style="fill:#5CBCE2;" points="1742.37,1453.08 1742.39,1453.05 1744,1450.42 1741.74,1449.03 1740.12,1451.66
            1740.1,1451.69 					"/>

          <rect x="1744.73" y="1450.9" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -404.5873 2182.4216)" style="fill:#5CBCE2;" width="0.76" height="2.66"/>
          <polygon style="fill:#5CBCE2;" points="1745.13,1454.75 1745.14,1454.73 1745.53,1454.1 1743.26,1452.71 1742.87,1453.34
            1742.86,1453.36 					"/>

          <rect x="1744.25" y="1451.83" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -405.6469 2182.3806)" style="fill:#5CBCE2;" width="0.59" height="2.66"/>

          <rect x="1745.17" y="1450.33" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -403.9287 2182.447)" style="fill:#5CBCE2;" width="0.59" height="2.66"/>
          <path style="fill:#5CBCE2;" d="M1751.38,1467.89l18.93-21.56l-0.26-0.06l-23.07,26.28l-3.57,4.06l-5.26,5.99l-12.72,14.15
            c-0.16,0.09-1.33,0.75-2.02,0.33c-0.27-0.17-0.44-0.49-0.51-0.96c-0.03-0.23-0.04-0.5-0.02-0.81l0.02,0l-0.03-0.4l-0.05-0.71
            l10.8-18.45l7.24-12.38l4.28-7.32l0.57-0.97l0.02,0.01l1.95-3.18l0.01-0.02l-0.08-0.05l3.11-5.32l4.77-8.16l-0.3-0.18
            l-4.55,7.78l-3.33,5.7l-5.82-3.56l-1.17,1.92l0.18,0.11l-0.11,0.18l-0.18-0.11l-0.61,1l0.18,0.11l-0.06,0.1l0.4,0.24l1.61-2.63
            l2.27,1.39l-1.61,2.63l0.48,0.3l0.39-0.63l0.31-0.5l2.27,1.39l-0.31,0.5l-0.39,0.63l0.28,0.17l-1.13,1.92l-3.66,6.25
            l-7.48,12.78l-10.66,18.22l-0.03,0.05l0.07,1.06l0,0c-0.08,1.13,0.15,1.88,0.7,2.21c0.92,0.56,2.34-0.31,2.4-0.34l0.09,0.08
            l6.17-7.03l11.9-13.24l3.62-4.03l23.66-26.32l-0.39-0.09L1751.38,1467.89z M1743.78,1451.86l0.4-0.65l0.31-0.5l2.27,1.39
            l-0.31,0.5l-0.4,0.65L1743.78,1451.86z"/>
          <path style="fill:#9E400F;" d="M1771.2,1445.14L1771.2,1445.14c0.2-0.32,0.17-0.72-0.05-1.01l-0.03,0.94L1771.2,1445.14z"/>
          <path style="fill:#5CBCE2;" d="M1772.15,1445.36l0-0.05l0,0l-0.45-0.53l-0.02,0.56l0.07,0c-0.02,0.03-0.03,0.07-0.06,0.1
            c-0.1,0.16-0.22,0.29-0.36,0.4l0.28,0.06l0.49-0.55L1772.15,1445.36z"/>
          <polygon style="fill:#5CBCE2;" points="1773.13,1419.41 1773.18,1418.25 1772.94,1418.25 1772.71,1418.24 1772.66,1419.37
            1772.66,1419.48 1772.61,1420.89 1772.58,1421.48 1771.74,1443.72 1772.19,1444.26 1773.06,1421.41 1773.11,1420.07 					"/>
          <polygon style="fill:#5CBCE2;" points="1771.91,1420.4 1771.68,1420.39 1771.66,1421.09 1770.96,1442.8 1771.18,1443.06
            1771.91,1420.66 					"/>
          <polygon style="fill:#5CBCE2;" points="1770.92,1443.86 1770.92,1443.92 1770.88,1445.33 1770.71,1445.52 1770.58,1445.67
            1770.84,1445.72 1771.29,1445.21 1771.2,1445.14 1771.12,1445.07 1771.15,1444.13 1771.15,1444.12 					"/>
          <path style="fill:#5CBCE2;" d="M1771.18,1443.06l-0.23-0.27l-0.62-0.73l-0.03,0.02l0.01-0.01l-8.09-4.95l4.47-7.62l0.07-0.02
            l-0.01-0.09l1.98-3.37l0.11-0.05l-0.03-0.1l2.82-4.8l0.02-0.7l0.23,0.01l-0.01,0.27l0.69-1.18l0.07-0.11l0.04-1.14l0.23,0.01
            l0.3-0.16l-0.04-0.32l0.04-0.06l-0.82-0.48l-1.37-0.8l-0.13,0.21l-0.1,0.04l0,0.03l0,0.1l-0.07,0.12l0.07,0.04l0,0.4
            c-0.18,0.16-0.51,0.53-0.93,1.06c-1.48,1.83-4.19,5.57-6.68,9.07l-0.04-0.03l-0.12,0.21l-0.13,0.02l0.03,0.15l-0.07,0.12
            c-2.04,2.86-3.87,5.48-4.7,6.66l-13.76-8.42l-10.77-6.59l-0.96-0.59l-0.99-0.61l-3.29-2.01l-0.65-0.4l-2.79-1.71l-1.01-0.62
            l-2.79-1.71l-1.39-0.85l-3.33-2.04l-2.24-1.37l-1.66-1.02l-2.79-1.71l-0.63-0.38l-1.52-0.93l-0.44-0.27l0,0l-4.4-2.69
            l-0.12-0.07l-0.43,0.7l0.12,0.07l4.4,2.69l0,0l0.71,0.43l1.27,0.78l-1.53,2.5l-1-0.61l-0.45-0.28l-0.43,0.7l0.45,0.28l0.98,0.6
            l-1.75,2.87l-0.98-0.6l-0.7-0.43l-0.43,0.7l0.7,0.43l4.49,2.75l0.34,0.21l3.45,2.11l4.93,3.02l7.91,4.84l1.35-0.32l4.44-1.05
            l0.77,0.47l2.88,1.76l9.03,5.53l12.05,7.37l0.3,0.18l0.46,0.28l-5.11,8.69l-1.29,2.19l6.4-10.88l0.69,0.42l-33.1,56.3
            l0.36-0.62l1.25,0.76l-0.3,0.49c-0.19,0.11-0.62,0.33-0.9,0.2c-0.2-0.09-0.34-0.39-0.41-0.84c0,0-0.81,0.86,0.26,1.65
            c-0.06-0.02-0.12-0.04-0.19-0.06c0.79,0.36,1.68-0.25,1.78-0.32l0.07-0.05l13.56-21.97l2.81-4.55l0.02-0.01l0-0.03l0.56-0.91
            l1.56-2.53l0,0l0,0l0.12-0.19l0,0v0l15.68-25.4l7.66,4.69l0,0l0,0l0.48,0.3l0.1-0.16l2.01,0.46l0.26,0.06l0.38,0.09l0.39,0.09
            l3.74,0.85l-2.63-3.1l-0.45-0.53L1771.18,1443.06z M1768.81,1424.32l0.02-1.22l1.05-0.59L1768.81,1424.32z M1771.64,1419.5
            l-0.48,0.82l0.01-1.09l0.02-0.01l0.92-0.51L1771.64,1419.5z M1771.32,1418.01c0.17-0.1,0.28-0.08,0.34-0.06
            c0.15,0.05,0.28,0.21,0.39,0.38l-0.89,0.49l0.01-0.67C1771.23,1418.08,1771.28,1418.04,1771.32,1418.01z M1770.57,1418.85
            c0.08-0.1,0.16-0.2,0.23-0.29l0,0.43l0,0.03l-0.65,0.36C1770.31,1419.19,1770.45,1419.01,1770.57,1418.85z M1769.61,1420.09
            l1.19-0.66l-0.01,1.51l-0.24,0.41l-1.32-0.76C1769.36,1420.42,1769.49,1420.25,1769.61,1420.09z M1768.88,1421.06l1.37,0.8
            l-0.02,0.04l-1.4,0.79l0.03-1.6C1768.87,1421.08,1768.87,1421.07,1768.88,1421.06z M1768.5,1421.58l-0.02,1.31l-1.6,0.91
            C1767.45,1423,1768,1422.26,1768.5,1421.58z M1768.47,1423.3l-0.03,1.64l-0.18,0.31l-1.59-0.94L1768.47,1423.3z
             M1766.61,1428.06l-0.13-1.11l1.02-0.41L1766.61,1428.06z M1767.96,1425.76l-0.1,0.17l-1.44,0.58l-0.21-1.78L1767.96,1425.76z
             M1765.84,1425.23l0.17,1.45l-1.7,0.69C1764.82,1426.63,1765.34,1425.92,1765.84,1425.23z M1766.06,1427.12l0.19,1.56
            l-0.27,0.46l-2-1.18L1766.06,1427.12z M1738.74,1469.56l0.1,1.3l-1.14,0.48L1738.74,1469.56z M1736.56,1473.26l0.1,1.32
            l-1.16,0.48L1736.56,1473.26z M1734.36,1477l0.07,1.25l-1.09,0.48L1734.36,1477z M1732.18,1480.72l0.06,1.23l-1.07,0.48
            L1732.18,1480.72z M1730.14,1484.18l0.01,1.53l-1.29,0.65L1730.14,1484.18z M1727.84,1488.08l0.02,1.27l-1.08,0.53
            L1727.84,1488.08z M1725.46,1492.13l0.01,0.8l-0.68,0.33L1725.46,1492.13z M1725.48,1495.02l-1.26-0.77l0.23-0.39l1.03-0.51
            l0.02,1.64L1725.48,1495.02z M1725.87,1494.38l-0.01-1.22l1.11-0.55L1725.87,1494.38z M1727.43,1491.86l-0.09,0.14l-1.49,0.74
            l-0.01-1.24l0.26-0.45L1727.43,1491.86z M1727.74,1491.36l-1.34-0.82l0.04-0.07l1.44-0.7l0.02,1.33L1727.74,1491.36z
             M1728.27,1490.5l-0.02-0.91l0.83-0.4L1728.27,1490.5z M1729.69,1488.2l-0.23,0.37l-1.21,0.59l-0.03-1.71l0.07-0.11
            L1729.69,1488.2z M1729.99,1487.7l-1.34-0.82l1.5-0.75l0.01,1.31L1729.99,1487.7z M1730.53,1486.83l-0.01-0.89l0.81-0.41
            L1730.53,1486.83z M1731.94,1484.54l-0.23,0.38l-1.19,0.6l-0.01-1.85L1731.94,1484.54z M1732.25,1484.04l-1.49-0.91l0.06-0.11
            l1.44-0.65l0.08,1.52L1732.25,1484.04z M1732.71,1483.3l-0.06-1.09l1.01-0.45L1732.71,1483.3z M1734.2,1480.88l-0.16,0.26
            l-1.41,0.63l-0.09-1.68l0.09-0.16L1734.2,1480.88z M1734.51,1480.39l-1.57-0.96l0.06-0.1l1.46-0.64l0.09,1.64L1734.51,1480.39z
             M1734.92,1479.72l-0.07-1.21l1.12-0.49L1734.92,1479.72z M1736.46,1477.23l-0.11,0.18l-1.52,0.67l-0.1-1.7l0.09-0.15
            L1736.46,1477.23z M1736.77,1476.73l-1.65-1.01l0.03-0.06l1.08-0.45l0.47-0.19l0.01,0.09l0.12,1.52L1736.77,1476.73z
             M1737.2,1476.02l-0.08-1l-0.01-0.18l1.1-0.46l-0.29,0.47L1737.2,1476.02z M1738.72,1473.57l-0.13,0.21l-1.51,0.63l-0.14-1.78
            l0.07-0.11L1738.72,1473.57z M1737.3,1472.01l0.04-0.07l1.52-0.64l0.13,1.75L1737.3,1472.01z M1739.37,1472.51l-0.1-1.38
            l1.29-0.54L1739.37,1472.51z M1740.98,1469.91l-0.04,0.06l-1.7,0.72l-0.13-1.76l0.07-0.12L1740.98,1469.91z M1740.93,1465.83
            l0.16,1.37l-1.23,0.46L1740.93,1465.83z M1741.28,1469.41l-1.81-1.11l0.02-0.04l1.64-0.61l0.19,1.68L1741.28,1469.41z
             M1742.13,1468.04l-0.42,0.68l-0.14-1.23l0.38-0.14l0.79-0.3L1742.13,1468.04z M1743.23,1466.25l-0.12,0.2l-1.28,0.48
            l-0.32,0.12l-0.21-1.84l0.04-0.07l0.02-0.03L1743.23,1466.25z M1768.42,1445.18l0.6-0.98c-0.13,0.38-0.09,0.79,0.08,1.14
            L1768.42,1445.18z M1762.57,1440.06l-1.55,0.26l1.08-1.75L1762.57,1440.06z M1762.5,1438.25l2.33,1.43l-1.79,0.3
            L1762.5,1438.25z M1762.71,1440.51l0.51,1.62l-2.18-1.33L1762.71,1440.51z M1763.18,1440.44l2.25-0.38l0.01,0l-1.53,2.5
            l-0.08-0.05L1763.18,1440.44z M1764.51,1442.71l1.3-2.12l0.57,1.81L1764.51,1442.71z M1766.51,1442.84l0.51,1.62l-2.18-1.33
            L1766.51,1442.84z M1766.3,1440.58l2.33,1.43l-1.79,0.3L1766.3,1440.58z M1769.94,1442.7l0.41,0.49
            c-0.37,0.02-0.72,0.17-0.98,0.45L1769.94,1442.7z M1758.47,1436.09l0.57,1.81l-1.87,0.32L1758.47,1436.09z M1759.52,1439.87
            l-2.01-1.23l1.67-0.28l0.43,1.37L1759.52,1439.87z M1759.94,1439.19l-0.29-0.92l0.96-0.16L1759.94,1439.19z M1759.51,1437.82
            l-0.5-1.61l2.01,1.24l-0.08,0.13L1759.51,1437.82z M1736.85,1424.32l2.25-0.38l0.01,0l-1.53,2.5l-0.08-0.05L1736.85,1424.32z
             M1736.89,1426.01l-2.18-1.33l1.67-0.28L1736.89,1426.01z M1733.78,1424.11l-0.08-0.05l-0.54-1.71l-0.11-0.36l2.25-0.38l0.01,0
            l-1.1,1.8L1733.78,1424.11z M1721.69,1417.67l-0.04,0.07l-2.37,0.4l-0.69-2.2l0.08-0.13L1721.69,1417.67z M1720.02,1420.41
            l-0.02,0.03l-0.02-0.06l-0.56-1.79l1.91-0.32L1720.02,1420.41z M1722.05,1418.75l0.57,1.81l-1.85,0.31l-0.02,0l0.01-0.01
            L1722.05,1418.75z M1718.27,1416.48l0.55,1.74l-1.8,0.31L1718.27,1416.48z M1715.98,1417.98l-1.82-2.6l3.14,0.43
            L1715.98,1417.98z M1718.95,1418.68l0.42,1.34l0.07,0.22l-2.1-1.29l0.23-0.04L1718.95,1418.68z M1721.37,1421.24l1.38-0.24
            l0.42,1.34l0.07,0.22l-2.1-1.29L1721.37,1421.24z M1723.78,1422.71l-0.56-1.79l1.91-0.32l-1.31,2.14l-0.02,0.03
            L1723.78,1422.71z M1723.08,1420.47l-0.71-2.26l0.06-0.09l3.07,1.88l-0.04,0.07L1723.08,1420.47z M1717.67,1415.21l-0.04,0.07
            l-3.91-0.54l-0.4-0.57l-1.82-2.61l0.06-0.09l2.49,1.52L1717.67,1415.21z M1712.95,1414.63l-3.08-0.42l1.3-2.12L1712.95,1414.63
            z M1711.57,1410.3l1.3-2.12l0.57,1.81L1711.57,1410.3z M1713.58,1410.44l0.51,1.62l-2.18-1.33L1713.58,1410.44z
             M1713.36,1408.18l2.33,1.43l-1.79,0.3L1713.36,1408.18z M1712.5,1407.65L1712.5,1407.65l-1.52,2.5l-0.08-0.05l-0.09-0.29
            l-0.56-1.77l1.85-0.32L1712.5,1407.65z M1726.77,1419.61l1.3-2.12l0.57,1.81L1726.77,1419.61z M1728.78,1419.74l0.51,1.62
            l-2.18-1.33L1728.78,1419.74z M1726.18,1419.46l-0.08-0.05l-0.65-2.07l2.25-0.38l0.01,0L1726.18,1419.46z M1724.98,1417.42
            l0.51,1.62l-2.18-1.33L1724.98,1417.42z M1722.97,1417.28l1.3-2.12l0.57,1.81L1722.97,1417.28z M1725.31,1416.89l-0.54-1.73
            l2.33,1.43L1725.31,1416.89z M1722.38,1417.13l-0.08-0.05l-0.65-2.07l2.25-0.38l0.01,0L1722.38,1417.13z M1721.18,1415.09
            l0.51,1.62l-2.18-1.33L1721.18,1415.09z M1719.17,1414.96l1.3-2.12l0.57,1.81L1719.17,1414.96z M1721.51,1414.56l-0.54-1.73
            l2.33,1.43L1721.51,1414.56z M1718.58,1414.81l-0.08-0.05l-0.65-2.07l2.25-0.38l0.01,0L1718.58,1414.81z M1717.71,1412.23
            l-0.54-1.73l2.33,1.43L1717.71,1412.23z M1717.24,1412.31l-1.87,0.32l1.3-2.12L1717.24,1412.31z M1717.38,1412.76l0.51,1.62
            l-2.18-1.33L1717.38,1412.76z M1729.9,1421.73l-0.65-2.07l2.25-0.38l0.01,0l-1.53,2.5L1729.9,1421.73z M1731.87,1419.81
            l0.55,1.77l0.01,0.03l-0.04,0.01l-1.83,0.31L1731.87,1419.81z M1736.71,1423.86l-0.54-1.73l2.33,1.43L1736.71,1423.86z
             M1739.96,1424.46l2.33,1.43l-1.79,0.3L1739.96,1424.46z M1740.04,1426.27l-1.87,0.32l1.3-2.12L1740.04,1426.27z
             M1740.18,1426.72l0.51,1.62l-2.18-1.33L1740.18,1426.72z M1740.65,1426.64l2.25-0.38l0.01,0l-1.53,2.5l-0.08-0.05
            L1740.65,1426.64z M1743.27,1426.79l0.53,1.68l0.04,0.12l-0.07,0.01l-1.79,0.31L1743.27,1426.79z M1746.7,1428.58
            L1746.7,1428.58l-1.52,2.5l-0.08-0.05l-0.65-2.07L1746.7,1428.58z M1747.56,1429.11l2.33,1.43l-1.79,0.3L1747.56,1429.11z
             M1747.64,1430.92l-1.87,0.32l1.3-2.12L1747.64,1430.92z M1747.78,1431.37l0.51,1.62l-2.18-1.33L1747.78,1431.37z
             M1748.25,1431.29l2.25-0.38l0.01,0l-1.53,2.5l-0.08-0.05L1748.25,1431.29z M1751.36,1431.44l2.33,1.43l-1.79,0.3
            L1751.36,1431.44z M1751.44,1433.25l-1.87,0.32l1.3-2.12L1751.44,1433.25z M1751.58,1433.7l0.51,1.62l-2.18-1.33
            L1751.58,1433.7z M1752.05,1433.62l2.25-0.38l0.01,0l-1.53,2.5l-0.08-0.05L1752.05,1433.62z M1753.37,1435.89l1.3-2.12
            l0.57,1.81L1753.37,1435.89z M1755.38,1436.03l0.51,1.62l-2.18-1.33L1755.38,1436.03z M1755.16,1433.76l2.33,1.43l-1.79,0.3
            L1755.16,1433.76z M1761.87,1436.13l-0.3-1.6l1.53-0.5L1761.87,1436.13z M1764.2,1432.16l-0.27-1.47l1.41-0.47L1764.2,1432.16z
             M1763.57,1428.4l2.08,1.23l-1.8,0.59l-0.33-1.75C1763.53,1428.44,1763.55,1428.42,1763.57,1428.4z M1763.14,1428.99l0.25,1.37
            l-1.6,0.53C1762.24,1430.27,1762.69,1429.63,1763.14,1428.99z M1761.34,1431.54l2.14-0.71l0.36,1.94l-0.15,0.26l-2.4-1.42
            C1761.3,1431.59,1761.32,1431.56,1761.34,1431.54z M1763.28,1433.47l-1.8,0.59l-0.35-1.86L1763.28,1433.47z M1760.71,1432.43
            l0.33,1.78l-2.06,0.68C1759.52,1434.12,1760.1,1433.29,1760.71,1432.43z M1761.12,1434.68l0.37,2l-2.25-1.38L1761.12,1434.68z
             M1746.09,1428.21l-1.79,0.3l-0.28-0.89l-0.26-0.84l0.42,0.26L1746.09,1428.21z M1734.69,1421.23l-1.79,0.3l-0.15-0.49
            l-0.39-1.25l0.84,0.51L1734.69,1421.23z M1730.89,1418.91l-1.79,0.3l-0.54-1.73L1730.89,1418.91z M1716.3,1409.98
            L1716.3,1409.98l-1.52,2.5l-0.08-0.05l-0.11-0.34l-0.54-1.73l1.79-0.3L1716.3,1409.98z M1711.89,1407.28l-1.79,0.3l-0.54-1.73
            L1711.89,1407.28z M1709.07,1405.85l0.57,1.81l-1.87,0.32L1709.07,1405.85z M1709.78,1408.11l0.51,1.62l-2.18-1.33
            L1709.78,1408.11z M1707.23,1408.82l3.04,1.86l0.03,0.02l-0.04,0.07l-0.05,0.01l-2.33,0.4l-0.71-2.26L1707.23,1408.82z
             M1706.85,1409.44l0.57,1.81l-1.87,0.32L1706.85,1409.44z M1705.94,1411.97l1.61-0.27l0.49,1.56L1705.94,1411.97z
             M1708.02,1411.62l1.85-0.32l0.05-0.01l-1.33,2.17l-0.02-0.07L1708.02,1411.62z M1710.73,1414.91l1.97,0.27l0.69,0.09l1.54,2.2
            l-2.64-1.62L1710.73,1414.91z M1725.05,1423.49l-0.21,0.05l-0.6-0.36l0.08-0.13l1.67-2.74l3.49,2.14L1725.05,1423.49z
             M1730.91,1422.35l1.17-0.2l0.5-0.09l0.51,1.62l-1.42-0.87L1730.91,1422.35z M1734.37,1424.26l0.13-0.21l1.17-1.91l0.57,1.81
            l-1.8,0.31L1734.37,1424.26z M1742.31,1429.33l1.32-0.22l0.35-0.06l0.51,1.62l-1.1-0.67L1742.31,1429.33z M1755.85,1435.95
            l1.9-0.32l-1.3,2.21L1755.85,1435.95z M1756.3,1439.7l0.29,1.71l-1.59,0.5L1756.3,1439.7z M1754.07,1443.48l0.27,1.59
            l-1.48,0.46L1754.07,1443.48z M1751.04,1448.64l0.81-1.38l0.25,1.48l-1,0.31l-0.37,0.12L1751.04,1448.64z M1749.47,1451.31
            l0.16-0.27l0.03,0.2l0.2,1.16l-1.26,0.4L1749.47,1451.31z M1747.53,1454.6l0.21,1.55l-1.42,0.5L1747.53,1454.6z
             M1745.46,1458.13l0.18,1.77l-1.58,0.62L1745.46,1458.13z M1743.14,1462.08l0.18,1.45l-1.31,0.48L1743.14,1462.08z
             M1743.54,1465.75l-1.88-1.15l1.71-0.63l0.21,1.72L1743.54,1465.75z M1743.95,1465.09l-0.15-1.27l1.22-0.45L1743.95,1465.09z
             M1745.49,1462.59l-0.1,0.17l-1.65,0.6l-0.23-1.92l0.03-0.05L1745.49,1462.59z M1745.8,1462.09l-1.79-1.09l1.67-0.65l0.17,1.67
            L1745.8,1462.09z M1746.22,1461.41l-0.12-1.23l1.16-0.45L1746.22,1461.41z M1747.75,1458.93l-0.12,0.19l-1.58,0.62l-0.2-1.97
            L1747.75,1458.93z M1746.07,1457.22l1.74-0.61l0.25,1.82L1746.07,1457.22z M1748.43,1457.83l-0.19-1.38l1.32-0.46
            L1748.43,1457.83z M1750.01,1455.28l-0.07,0.11l-1.76,0.62l-0.27-2.01L1750.01,1455.28z M1748.19,1453.48l0.04-0.07l1.7-0.53
            l0.32,1.87L1748.19,1453.48z M1750.64,1454.24l-0.26-1.51l1.48-0.46L1750.64,1454.24z M1752.24,1451.66l-1.93,0.61l-0.2-1.17
            l-0.11-0.68l0.08-0.14l0.87,0.53l1.32,0.81L1752.24,1451.66z M1751.29,1450.33l-0.91-0.56l0,0l0.79-0.25l1.01-0.32l0.32,1.87
            L1751.29,1450.33z M1752.89,1450.6l-0.26-1.54l1.5-0.47L1752.89,1450.6z M1754.52,1447.96l-0.02,0.03l-1.96,0.61l-0.33-1.96
            l0.04-0.07L1754.52,1447.96z M1752.62,1446.11l1.81-0.57l0.32,1.87L1752.62,1446.11z M1755.14,1446.96l-0.27-1.56l1.52-0.48
            L1755.14,1446.96z M1756.78,1444.3l-0.01,0.01l-1.98,0.62l-0.35-2.06L1756.78,1444.3z M1754.86,1442.44l1.81-0.57l0.32,1.87
            L1754.86,1442.44z M1757.39,1443.31l-0.27-1.58l1.55-0.48L1757.39,1443.31z M1759.04,1440.64l-2,0.63l-0.37-2.18l2.42,1.48
            L1759.04,1440.64z M1767.63,1444.83l-0.65-2.07l2.19-0.37l-1.51,2.46L1767.63,1444.83z M1772.9,1446.19l-1.28-0.29l-0.28-0.06
            c0.14-0.11,0.26-0.24,0.36-0.4c0.02-0.03,0.04-0.07,0.06-0.1l-0.07,0l0.02-0.56l0.45,0.53L1772.9,1446.19z"/>
          <path style="fill:#5CBCE2;" d="M1718.93,1436.92l-0.13,0.21l-1.39-0.59c-0.07-0.11-0.37-0.39-0.27-0.78
            c0.04-0.15,0.13-0.29,0.26-0.42c0.04,0.09,0.11,0.18,0.21,0.23c0.25,0.15,0.58,0.08,0.74-0.18c0.16-0.25,0.08-0.58-0.18-0.74
            c-0.14-0.08-0.29-0.1-0.43-0.05c-0.03,0-0.06,0.01-0.08,0.02c-0.38,0.18-0.66,0.4-0.82,0.67c-0.07,0.11-0.12,0.22-0.15,0.35
            c-0.17,0.68,0.29,1.29,0.31,1.32c0.02,0.02-0.06,0.21-0.04,0.23l1.21,0.98l-0.11,0.18l2.58,1.58l0.04-0.07l18.82,11.52
            l-1.11,1.82l1.97,1.2l3.95,2.42l1.13-1.92l-0.28-0.17l-0.01,0.02l-2.27-1.39l0.01-0.02l-0.48-0.3l-0.02,0.03l-0.88,1.43
            l-2.27-1.39l0.88-1.43l0.02-0.03l-0.4-0.24l0.06-0.1l-0.18-0.11l-18.82-11.52l0.61-1l18.82,11.52l0.18,0.11l0.11-0.18
            l-0.18-0.11L1718.93,1436.92z"/>
        </g>
        <g>
          <g>

              <rect x="1673.1" y="1476.31" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -464.9521 2136.3142)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1674.77" y="1473.59" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -461.8422 2136.4341)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1675.82" y="1472.46" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -460.5496 2136.4839)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1674.16" y="1475.18" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -463.6588 2136.364)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1677.49" y="1469.75" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -457.4401 2136.604)" style="fill:#044A6D;" width="0.63" height="13.47"/>

            <rect x="1672.5" y="1477.89" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -466.7679 2136.2441)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1676.43" y="1470.88" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -458.7329 2136.554)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1678.14" y="1468.26" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -455.7346 2136.6697)" style="fill:#044A6D;" width="1.14" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1667.46,1499.63 1658.93,1494.41 1658.6,1494.95 1666.91,1500.04 						"/>
            <polygon style="fill:#044A6D;" points="1664.67,1501.66 1657.27,1497.13 1656.94,1497.67 1664.11,1502.06 						"/>
            <polygon style="fill:#044A6D;" points="1669.42,1498.22 1660.1,1492.51 1659.38,1493.68 1668.21,1499.09 						"/>

            <rect x="1671.44" y="1479.02" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -468.0609 2136.1943)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1670.26,1497.61 1660.6,1491.7 1660.26,1492.24 1669.7,1498.02 						"/>
            <polygon style="fill:#044A6D;" points="1672.21,1496.2 1661.76,1489.8 1661.04,1490.97 1671.01,1497.07 						"/>
            <polygon style="fill:#044A6D;" points="1663.83,1502.26 1656.77,1497.94 1656.06,1499.11 1662.63,1503.13 						"/>
            <polygon style="fill:#044A6D;" points="1661.04,1504.29 1655.11,1500.66 1654.99,1500.85 1660.84,1504.43 						"/>
            <polygon style="fill:#044A6D;" points="1661.88,1503.68 1655.61,1499.84 1655.28,1500.38 1661.32,1504.08 						"/>
            <polygon style="fill:#044A6D;" points="1666.62,1500.24 1658.43,1495.23 1657.72,1496.4 1665.42,1501.11 						"/>

            <rect x="1669.18" y="1483.32" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -472.9867 2136.0042)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1673.05,1495.59 1662.26,1488.98 1661.93,1489.53 1672.49,1495.99 						"/>

            <rect x="1669.78" y="1481.74" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -471.1701 2136.0742)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1670.84" y="1480.61" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -469.8771 2136.1243)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1674.76,1494.35 1674.9,1494.12 1663.42,1487.09 1662.7,1488.25 1673.8,1495.05
              "/>
            <polygon style="fill:#044A6D;" points="1667.54,1506.14 1668.26,1504.97 1663.83,1502.26 1662.63,1503.13 						"/>
            <polygon style="fill:#044A6D;" points="1667.54,1506.14 1668.26,1504.97 1663.83,1502.26 1662.63,1503.13 						"/>
            <polygon style="fill:#044A6D;" points="1670.87,1500.71 1671.58,1499.54 1669.42,1498.22 1668.21,1499.09 						"/>
            <polygon style="fill:#044A6D;" points="1670.87,1500.71 1671.58,1499.54 1669.42,1498.22 1668.21,1499.09 						"/>
            <polygon style="fill:#044A6D;" points="1674.19,1495.28 1674.76,1494.35 1673.8,1495.05 						"/>
            <polygon style="fill:#044A6D;" points="1674.19,1495.28 1674.76,1494.35 1673.8,1495.05 						"/>

            <rect x="1655.88" y="1505.04" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -497.8606 2135.0447)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1655.88" y="1505.04" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -497.8606 2135.0447)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1668.42,1504.7 1668.76,1504.16 1664.67,1501.66 1664.11,1502.06 						"/>
            <polygon style="fill:#044A6D;" points="1668.42,1504.7 1668.76,1504.16 1664.67,1501.66 1664.11,1502.06 						"/>

            <rect x="1657.55" y="1502.32" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -494.7517 2135.1648)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1657.55" y="1502.32" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -494.7517 2135.1648)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1659.21" y="1499.61" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -491.6425 2135.2847)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1659.21" y="1499.61" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -491.6425 2135.2847)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1669.2,1503.43 1669.92,1502.26 1666.62,1500.24 1665.42,1501.11 						"/>
            <polygon style="fill:#044A6D;" points="1669.2,1503.43 1669.92,1502.26 1666.62,1500.24 1665.42,1501.11 						"/>

            <rect x="1658.15" y="1500.74" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -492.9355 2135.2349)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1658.15" y="1500.74" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -492.9355 2135.2349)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1666.76,1507.41 1667.09,1506.87 1661.88,1503.68 1661.32,1504.08 						"/>
            <polygon style="fill:#044A6D;" points="1666.76,1507.41 1667.09,1506.87 1661.88,1503.68 1661.32,1504.08 						"/>
            <polygon style="fill:#044A6D;" points="1671.75,1499.27 1672.08,1498.73 1670.26,1497.61 1669.7,1498.02 						"/>
            <polygon style="fill:#044A6D;" points="1671.75,1499.27 1672.08,1498.73 1670.26,1497.61 1669.7,1498.02 						"/>

            <rect x="1656.49" y="1503.45" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -496.0446 2135.1147)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1656.49" y="1503.45" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -496.0446 2135.1147)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1666.6,1507.69 1661.04,1504.29 1660.84,1504.43 1654.99,1500.85 1654.4,1501.83
              1665.88,1508.86 						"/>
            <polygon style="fill:#044A6D;" points="1666.6,1507.69 1661.04,1504.29 1660.84,1504.43 1654.99,1500.85 1654.4,1501.83
              1665.88,1508.86 						"/>

            <rect x="1655.32" y="1505.89" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -498.8347 2135.0073)" style="fill:#044A6D;" width="0.72" height="13.47"/>

            <rect x="1655.32" y="1505.89" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -498.8347 2135.0073)" style="fill:#044A6D;" width="0.72" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1673.41,1496.56 1673.74,1496.01 1673.05,1495.59 1672.49,1495.99 						"/>
            <polygon style="fill:#044A6D;" points="1673.41,1496.56 1673.74,1496.01 1673.05,1495.59 1672.49,1495.99 						"/>
            <polygon style="fill:#044A6D;" points="1672.53,1498 1673.24,1496.83 1672.21,1496.2 1671.01,1497.07 						"/>
            <polygon style="fill:#044A6D;" points="1672.53,1498 1673.24,1496.83 1672.21,1496.2 1671.01,1497.07 						"/>
            <polygon style="fill:#044A6D;" points="1670.09,1501.98 1670.42,1501.44 1667.46,1499.63 1666.91,1500.04 						"/>
            <polygon style="fill:#044A6D;" points="1670.09,1501.98 1670.42,1501.44 1667.46,1499.63 1666.91,1500.04 						"/>
            <polygon style="fill:#5CBCE2;" points="1685.55,1478.51 1684.76,1478.02 1684.16,1479 1672.67,1471.97 1672.23,1472.7
              1683.71,1479.73 1683.38,1480.27 1671.9,1473.24 1671.73,1473.51 1683.21,1480.54 1682.5,1481.71 1671.01,1474.68
              1670.57,1475.41 1682.05,1482.44 1681.72,1482.98 1670.23,1475.95 1670.07,1476.23 1681.55,1483.26 1680.84,1484.42
              1669.35,1477.39 1668.9,1478.13 1680.39,1485.16 1680.06,1485.7 1668.57,1478.67 1668.4,1478.94 1679.89,1485.97
              1679.17,1487.14 1667.69,1480.11 1667.24,1480.84 1678.73,1487.87 1678.39,1488.41 1666.91,1481.38 1666.74,1481.66
              1678.23,1488.69 1677.51,1489.85 1666.03,1482.82 1665.58,1483.55 1677.06,1490.58 1676.73,1491.13 1665.25,1484.1
              1665.08,1484.37 1676.57,1491.4 1675.85,1492.57 1664.37,1485.54 1663.92,1486.27 1675.4,1493.3 1675.07,1493.84
              1663.59,1486.81 1663.42,1487.09 1674.9,1494.12 1674.76,1494.35 1674.19,1495.28 1673.8,1495.05 1662.7,1488.25
              1662.26,1488.98 1673.05,1495.59 1673.74,1496.01 1673.41,1496.56 1672.49,1495.99 1661.93,1489.53 1661.76,1489.8
              1672.21,1496.2 1673.24,1496.83 1672.53,1498 1671.01,1497.07 1661.04,1490.97 1660.6,1491.7 1670.26,1497.61
              1672.08,1498.73 1671.75,1499.27 1669.7,1498.02 1660.26,1492.24 1660.1,1492.51 1669.42,1498.22 1671.58,1499.54
              1670.87,1500.71 1668.21,1499.09 1659.38,1493.68 1658.93,1494.41 1667.46,1499.63 1670.42,1501.44 1670.09,1501.98
              1666.91,1500.04 1658.6,1494.95 1658.43,1495.23 1666.62,1500.24 1669.92,1502.26 1669.2,1503.43 1665.42,1501.11
              1657.72,1496.4 1657.27,1497.13 1664.67,1501.66 1668.76,1504.16 1668.42,1504.7 1664.11,1502.06 1656.94,1497.67
              1656.77,1497.94 1663.83,1502.26 1668.26,1504.97 1667.54,1506.14 1662.63,1503.13 1656.06,1499.11 1655.61,1499.84
              1661.88,1503.68 1667.09,1506.87 1666.76,1507.41 1661.32,1504.08 1655.28,1500.38 1655.11,1500.66 1661.04,1504.29
              1666.6,1507.69 1665.88,1508.86 1654.4,1501.83 1653.95,1502.56 1665.43,1509.59 1665.1,1510.13 1653.62,1503.1
              1653.45,1503.37 1664.93,1510.4 1664.22,1511.57 1652.73,1504.54 1652.29,1505.27 1663.77,1512.3 1663.44,1512.84
              1651.96,1505.81 1651.79,1506.09 1663.27,1513.12 1662.56,1514.28 1651.07,1507.25 1650.63,1507.98 1662.11,1515.01
              1661.78,1515.56 1650.29,1508.53 1650.13,1508.8 1661.61,1515.83 1661.24,1516.44 1661.24,1516.44 1662.03,1516.93 						"/>

            <rect x="1638.56" y="1489.47" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -476.9081 2128.7747)" style="fill:#5CBCE2;" width="45.05" height="0.94"/>
          </g>
          <g>

              <rect x="1702.14" y="1429.07" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -410.8384 2138.4014)" style="fill:#044A6D;" width="1.14" height="13.47"/>

            <rect x="1701.48" y="1430.55" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -412.5439 2138.3357)" style="fill:#044A6D;" width="0.63" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1687.82,1463.07 1680.77,1458.75 1680.05,1459.92 1686.62,1463.94 						"/>
            <polygon style="fill:#044A6D;" points="1685.03,1465.09 1679.1,1461.46 1678.99,1461.65 1684.83,1465.23 						"/>
            <polygon style="fill:#044A6D;" points="1685.87,1464.48 1679.6,1460.65 1679.27,1461.19 1685.31,1464.89 						"/>

            <rect x="1698.16" y="1435.98" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -418.7626 2138.0957)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1696.49" y="1438.7" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -421.8717 2137.9758)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1697.1" y="1437.11" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -420.0558 2138.0459)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1699.82" y="1433.27" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -415.6534 2138.2158)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1700.42" y="1431.68" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -413.8367 2138.2859)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1698.76" y="1434.4" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -416.946 2138.1658)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1689.41,1461.92 1690.62,1461.05 1682.43,1456.03 1681.71,1457.2 						"/>
            <polygon style="fill:#044A6D;" points="1692.21,1459.89 1693.41,1459.02 1684.09,1453.32 1683.37,1454.49 						"/>
            <polygon style="fill:#044A6D;" points="1695,1457.87 1696.2,1457 1685.75,1450.6 1685.04,1451.77 						"/>
            <polygon style="fill:#044A6D;" points="1693.69,1458.82 1694.25,1458.42 1684.59,1452.5 1684.26,1453.04 						"/>
            <polygon style="fill:#044A6D;" points="1697.79,1455.85 1698.75,1455.15 1698.9,1454.92 1687.41,1447.89 1686.7,1449.06
                          "/>
            <polygon style="fill:#044A6D;" points="1696.48,1456.8 1697.04,1456.39 1686.25,1449.79 1685.92,1450.33 						"/>

            <rect x="1693.17" y="1444.13" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -428.0905 2137.7361)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1688.11,1462.86 1688.66,1462.46 1681.27,1457.93 1680.93,1458.47 						"/>
            <polygon style="fill:#044A6D;" points="1690.9,1460.84 1691.46,1460.44 1682.93,1455.22 1682.6,1455.76 						"/>

            <rect x="1694.83" y="1441.41" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -424.9809 2137.856)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1693.77" y="1442.54" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -426.2739 2137.8062)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1701.51,1450.66 1702.22,1449.49 1690.74,1442.46 1690.09,1443.51 1690.02,1443.63
                          "/>
            <polygon style="fill:#044A6D;" points="1686.62,1463.94 1691.54,1466.95 1692.25,1465.78 1687.82,1463.07 						"/>
            <polygon style="fill:#044A6D;" points="1694.86,1461.52 1695.57,1460.35 1693.41,1459.02 1692.21,1459.89 						"/>
            <polygon style="fill:#044A6D;" points="1698.18,1456.09 1698.75,1455.15 1697.79,1455.85 						"/>

            <rect x="1679.88" y="1465.84" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -452.9644 2136.7766)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1692.42,1465.5 1692.75,1464.96 1688.66,1462.46 1688.11,1462.86 						"/>

            <rect x="1681.54" y="1463.13" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -449.8555 2136.8965)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1683.2" y="1460.41" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -446.7462 2137.0164)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1693.2,1464.23 1693.91,1463.06 1690.62,1461.05 1689.41,1461.92 						"/>

            <rect x="1682.14" y="1461.54" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -448.0393 2136.9666)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1690.76,1468.22 1691.09,1467.68 1685.87,1464.48 1685.31,1464.89 						"/>
            <polygon style="fill:#044A6D;" points="1695.74,1460.07 1696.07,1459.53 1694.25,1458.42 1693.69,1458.82 						"/>

            <rect x="1680.48" y="1464.26" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -451.1484 2136.8467)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1689.87,1469.66 1690.59,1468.49 1685.03,1465.09 1684.83,1465.23 1678.99,1461.65
              1678.39,1462.63 						"/>

            <rect x="1679.32" y="1466.69" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -453.9385 2136.739)" style="fill:#044A6D;" width="0.72" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1697.4,1457.36 1697.73,1456.82 1697.04,1456.39 1696.48,1456.8 						"/>
            <polygon style="fill:#044A6D;" points="1696.52,1458.8 1697.24,1457.63 1696.2,1457 1695,1457.87 						"/>
            <polygon style="fill:#044A6D;" points="1694.08,1462.79 1694.41,1462.25 1691.46,1460.44 1690.9,1460.84 						"/>
            <polygon style="fill:#5CBCE2;" points="1704.38,1445.96 1704.05,1446.5 1692.57,1439.47 1692.4,1439.75 1703.88,1446.78
              1703.17,1447.94 1691.68,1440.91 1691.24,1441.64 1702.72,1448.67 1702.39,1449.22 1690.9,1442.19 1690.74,1442.46
              1702.22,1449.49 1701.51,1450.66 1690.02,1443.63 1689.57,1444.36 1701.06,1451.39 1700.73,1451.93 1689.24,1444.9
              1689.07,1445.17 1700.56,1452.2 1699.84,1453.37 1688.36,1446.34 1687.91,1447.07 1699.4,1454.1 1699.06,1454.65
              1687.58,1447.62 1687.41,1447.89 1698.9,1454.92 1698.75,1455.15 1698.18,1456.09 1697.79,1455.85 1686.7,1449.06
              1686.25,1449.79 1697.04,1456.39 1697.73,1456.82 1697.4,1457.36 1696.48,1456.8 1685.92,1450.33 1685.75,1450.6 1696.2,1457
              1697.24,1457.63 1696.52,1458.8 1695,1457.87 1685.04,1451.77 1684.59,1452.5 1694.25,1458.42 1696.07,1459.53
              1695.74,1460.07 1693.69,1458.82 1684.26,1453.04 1684.09,1453.32 1693.41,1459.02 1695.57,1460.35 1694.86,1461.52
              1692.21,1459.89 1683.37,1454.49 1682.93,1455.22 1691.46,1460.44 1694.41,1462.25 1694.08,1462.79 1690.9,1460.84
              1682.6,1455.76 1682.43,1456.03 1690.62,1461.05 1693.91,1463.06 1693.2,1464.23 1689.41,1461.92 1681.71,1457.2
              1681.27,1457.93 1688.66,1462.46 1692.75,1464.96 1692.42,1465.5 1688.11,1462.86 1680.93,1458.47 1680.77,1458.75
              1687.82,1463.07 1692.25,1465.78 1691.54,1466.95 1686.62,1463.94 1680.05,1459.92 1679.6,1460.65 1685.87,1464.48
              1691.09,1467.68 1690.76,1468.22 1685.31,1464.89 1679.27,1461.19 1679.1,1461.46 1685.03,1465.09 1690.59,1468.49
              1689.87,1469.66 1678.39,1462.63 1677.94,1463.36 1689.43,1470.39 1689.09,1470.93 1677.61,1463.9 1677.44,1464.18
              1688.93,1471.21 1688.21,1472.37 1676.73,1465.34 1676.28,1466.07 1687.76,1473.11 1687.43,1473.65 1675.95,1466.62
              1675.78,1466.89 1687.27,1473.92 1686.55,1475.09 1675.07,1468.06 1674.62,1468.79 1686.1,1475.82 1685.77,1476.36
              1674.29,1469.33 1674.12,1469.6 1685.6,1476.64 1685.23,1477.25 1685.23,1477.25 1686.03,1477.74 1686.48,1477 1691.8,1468.3
              1709.55,1439.31 1708.75,1438.83 1708.15,1439.8 1696.67,1432.77 1696.22,1433.5 1707.7,1440.53 1707.37,1441.07
              1695.89,1434.04 1695.72,1434.32 1707.21,1441.35 1706.49,1442.51 1695.01,1435.48 1694.56,1436.22 1706.04,1443.25
              1705.71,1443.79 1694.23,1436.76 1694.06,1437.03 1705.54,1444.06 1704.83,1445.23 1693.34,1438.2 1693.1,1438.6
              1692.9,1438.93 						"/>
            <polygon style="fill:#5CBCE2;" points="1672.92,1469.71 1673.72,1470.2 1690.06,1443.5 1693.07,1438.58 1697.24,1431.78
              1696.44,1431.29 1692.27,1438.1 1689.26,1443.01 						"/>
          </g>
          <g>

              <rect x="1688.63" y="1420.64" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -410.0504 2122.9521)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1690.29" y="1417.92" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -406.9407 2123.072)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1691.35" y="1416.79" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -405.648 2123.1221)" style="fill:#044A6D;" width="0.63" height="13.47"/>

            <rect x="1689.69" y="1419.51" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -408.7573 2123.002)" style="fill:#044A6D;" width="0.63" height="13.47"/>

            <rect x="1693.01" y="1414.08" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -402.5386 2123.2419)" style="fill:#044A6D;" width="0.63" height="13.47"/>

            <rect x="1688.03" y="1422.22" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -411.8664 2122.8821)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1691.96" y="1415.21" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -403.8314 2123.1921)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1688.8,1415.32 1688.2,1416.3 1699.69,1423.33 1700.28,1422.35 1694.18,1418.61
              1693.78,1418.37 						"/>
            <polygon style="fill:#044A6D;" points="1682.43,1444.37 1682.99,1443.96 1674.46,1438.74 1674.13,1439.28 						"/>
            <polygon style="fill:#044A6D;" points="1679.64,1446.39 1680.2,1445.99 1672.8,1441.46 1672.47,1442 						"/>
            <polygon style="fill:#044A6D;" points="1683.74,1443.42 1684.94,1442.55 1675.62,1436.84 1674.91,1438.01 						"/>

            <rect x="1686.97" y="1423.35" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -413.1593 2122.8323)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1685.23,1442.34 1685.78,1441.94 1676.12,1436.03 1675.79,1436.57 						"/>
            <polygon style="fill:#044A6D;" points="1686.54,1441.4 1687.74,1440.53 1677.29,1434.13 1676.57,1435.3 						"/>
            <polygon style="fill:#044A6D;" points="1678.16,1447.46 1679.36,1446.59 1672.3,1442.27 1671.59,1443.44 						"/>
            <polygon style="fill:#044A6D;" points="1676.37,1448.76 1676.57,1448.62 1670.64,1444.99 1670.52,1445.18 						"/>
            <polygon style="fill:#044A6D;" points="1676.85,1448.41 1677.4,1448.01 1671.14,1444.17 1670.81,1444.71 						"/>
            <polygon style="fill:#044A6D;" points="1680.95,1445.44 1682.15,1444.57 1673.96,1439.56 1673.25,1440.73 						"/>

            <rect x="1684.71" y="1427.65" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -418.0852 2122.6423)" style="fill:#044A6D;" width="0.63" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1688.02,1440.32 1688.58,1439.92 1677.78,1433.31 1677.45,1433.85 						"/>

            <rect x="1685.31" y="1426.07" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -416.2686 2122.7124)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1686.37" y="1424.94" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -414.9754 2122.7622)" style="fill:#044A6D;" width="0.63" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1689.33,1439.37 1690.29,1438.68 1690.43,1438.44 1678.95,1431.41 1678.23,1432.58
                          "/>
            <polygon style="fill:#044A6D;" points="1683.07,1450.47 1683.78,1449.3 1679.36,1446.59 1678.16,1447.46 						"/>
            <polygon style="fill:#044A6D;" points="1683.07,1450.47 1683.78,1449.3 1679.36,1446.59 1678.16,1447.46 						"/>
            <polygon style="fill:#044A6D;" points="1686.39,1445.04 1687.11,1443.87 1684.94,1442.55 1683.74,1443.42 						"/>
            <polygon style="fill:#044A6D;" points="1686.39,1445.04 1687.11,1443.87 1684.94,1442.55 1683.74,1443.42 						"/>
            <polygon style="fill:#044A6D;" points="1689.72,1439.61 1690.29,1438.68 1689.33,1439.37 						"/>
            <polygon style="fill:#044A6D;" points="1689.72,1439.61 1690.29,1438.68 1689.33,1439.37 						"/>

            <rect x="1671.41" y="1449.37" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -442.9591 2121.6829)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1671.41" y="1449.37" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -442.9591 2121.6829)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1683.95,1449.03 1684.28,1448.49 1680.2,1445.99 1679.64,1446.39 						"/>
            <polygon style="fill:#044A6D;" points="1683.95,1449.03 1684.28,1448.49 1680.2,1445.99 1679.64,1446.39 						"/>

            <rect x="1673.07" y="1446.65" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -439.85 2121.8027)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1673.07" y="1446.65" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -439.85 2121.8027)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1674.73" y="1443.94" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -436.7409 2121.9226)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1674.73" y="1443.94" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -436.7409 2121.9226)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1684.73,1447.76 1685.45,1446.59 1682.15,1444.57 1680.95,1445.44 						"/>
            <polygon style="fill:#044A6D;" points="1684.73,1447.76 1685.45,1446.59 1682.15,1444.57 1680.95,1445.44 						"/>

            <rect x="1673.68" y="1445.07" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -438.0338 2121.8728)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1673.68" y="1445.07" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -438.0338 2121.8728)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1682.29,1451.74 1682.62,1451.2 1677.4,1448.01 1676.85,1448.41 						"/>
            <polygon style="fill:#044A6D;" points="1682.29,1451.74 1682.62,1451.2 1677.4,1448.01 1676.85,1448.41 						"/>
            <polygon style="fill:#044A6D;" points="1687.28,1443.6 1687.61,1443.06 1685.78,1441.94 1685.23,1442.34 						"/>
            <polygon style="fill:#044A6D;" points="1687.28,1443.6 1687.61,1443.06 1685.78,1441.94 1685.23,1442.34 						"/>

            <rect x="1672.02" y="1447.78" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -441.1431 2121.7529)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1672.02" y="1447.78" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -441.1431 2121.7529)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1670.52,1445.18 1669.92,1446.15 1681.41,1453.19 1682.12,1452.02 1676.57,1448.62
              1676.37,1448.76 						"/>
            <polygon style="fill:#044A6D;" points="1670.52,1445.18 1669.92,1446.15 1681.41,1453.19 1682.12,1452.02 1676.57,1448.62
              1676.37,1448.76 						"/>
            <polygon style="fill:#044A6D;" points="1665.65,1453.13 1665.28,1453.74 1670.66,1457.03 1676.76,1460.77 1677.14,1460.16
                          "/>
            <polygon style="fill:#044A6D;" points="1665.65,1453.13 1665.28,1453.74 1670.66,1457.03 1676.76,1460.77 1677.14,1460.16
                          "/>
            <polygon style="fill:#044A6D;" points="1688.94,1440.89 1689.27,1440.34 1688.58,1439.92 1688.02,1440.32 						"/>
            <polygon style="fill:#044A6D;" points="1688.94,1440.89 1689.27,1440.34 1688.58,1439.92 1688.02,1440.32 						"/>
            <polygon style="fill:#044A6D;" points="1686.54,1441.4 1688.05,1442.33 1688.77,1441.16 1687.74,1440.53 						"/>
            <polygon style="fill:#044A6D;" points="1686.54,1441.4 1688.05,1442.33 1688.77,1441.16 1687.74,1440.53 						"/>
            <polygon style="fill:#044A6D;" points="1685.61,1446.31 1685.95,1445.77 1682.99,1443.96 1682.43,1444.37 						"/>
            <polygon style="fill:#044A6D;" points="1685.61,1446.31 1685.95,1445.77 1682.99,1443.96 1682.43,1444.37 						"/>
            <polygon style="fill:#5CBCE2;" points="1688.2,1416.3 1687.75,1417.03 1699.24,1424.06 1698.91,1424.6 1687.42,1417.57
              1687.26,1417.84 1698.74,1424.87 1698.02,1426.04 1686.54,1419.01 1686.09,1419.74 1697.58,1426.77 1697.25,1427.31
              1685.76,1420.28 1685.59,1420.56 1697.08,1427.59 1696.36,1428.75 1684.88,1421.72 1684.43,1422.46 1695.92,1429.49
              1695.58,1430.03 1684.1,1423 1683.93,1423.27 1695.42,1430.3 1694.7,1431.47 1683.22,1424.44 1682.77,1425.17 1694.25,1432.2
              1693.92,1432.74 1682.44,1425.71 1682.27,1425.99 1693.75,1433.02 1693.04,1434.18 1681.56,1427.15 1681.11,1427.88
              1692.59,1434.91 1692.26,1435.46 1680.78,1428.43 1680.61,1428.7 1692.09,1435.73 1691.38,1436.9 1679.89,1429.87
              1679.45,1430.6 1690.93,1437.63 1690.6,1438.17 1679.11,1431.14 1678.95,1431.41 1690.43,1438.44 1690.29,1438.68
              1689.72,1439.61 1689.33,1439.37 1678.23,1432.58 1677.78,1433.31 1688.58,1439.92 1689.27,1440.34 1688.94,1440.89
              1688.02,1440.32 1677.45,1433.85 1677.29,1434.13 1687.74,1440.53 1688.77,1441.16 1688.05,1442.33 1686.54,1441.4
              1676.57,1435.3 1676.12,1436.03 1685.78,1441.94 1687.61,1443.06 1687.28,1443.6 1685.23,1442.34 1675.79,1436.57
              1675.62,1436.84 1684.94,1442.55 1687.11,1443.87 1686.39,1445.04 1683.74,1443.42 1674.91,1438.01 1674.8,1438.19
              1674.46,1438.74 1682.99,1443.96 1685.95,1445.77 1685.61,1446.31 1682.43,1444.37 1674.13,1439.28 1673.96,1439.56
              1682.15,1444.57 1685.45,1446.59 1684.73,1447.76 1680.95,1445.44 1673.25,1440.73 1672.8,1441.46 1680.2,1445.99
              1684.28,1448.49 1683.95,1449.03 1679.64,1446.39 1672.47,1442 1672.3,1442.27 1679.36,1446.59 1683.78,1449.3
              1683.07,1450.47 1678.16,1447.46 1671.59,1443.44 1671.14,1444.17 1677.4,1448.01 1682.62,1451.2 1682.29,1451.74
              1676.85,1448.41 1670.81,1444.71 1670.64,1444.99 1676.57,1448.62 1682.12,1452.02 1681.41,1453.19 1669.92,1446.15
              1669.48,1446.89 1680.96,1453.92 1680.63,1454.46 1669.14,1447.43 1668.98,1447.7 1680.46,1454.73 1679.75,1455.9
              1668.26,1448.87 1667.81,1449.6 1679.3,1456.63 1678.97,1457.17 1667.48,1450.14 1667.32,1450.42 1678.8,1457.45
              1678.08,1458.61 1666.6,1451.58 1666.15,1452.31 1677.64,1459.34 1677.31,1459.89 1665.82,1452.86 1665.65,1453.13
              1677.14,1460.16 1676.76,1460.77 1676.76,1460.77 1677.56,1461.26 1688.88,1442.77 1691.89,1437.86 1701.08,1422.84
              1700.28,1422.35 1699.69,1423.33 						"/>
          </g>
          <g>

              <rect x="1663.67" y="1461.42" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -456.7669 2121.1501)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1665.33" y="1458.71" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -453.6572 2121.27)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1666.39" y="1457.58" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -452.3645 2121.3201)" style="fill:#044A6D;" width="0.63" height="13.47"/>

            <rect x="1664.72" y="1460.29" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -455.4738 2121.2)" style="fill:#044A6D;" width="0.63" height="13.47"/>

            <rect x="1668.05" y="1454.86" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -449.2551 2121.4399)" style="fill:#044A6D;" width="0.63" height="13.47"/>

            <rect x="1663.06" y="1463.01" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -458.5828 2121.0801)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1666.99" y="1455.99" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -450.5478 2121.3901)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1663.83,1456.11 1663.24,1457.08 1674.72,1464.11 1675.32,1463.14 1669.21,1459.4
              1668.81,1459.15 						"/>
            <polygon style="fill:#044A6D;" points="1657.47,1485.15 1658.02,1484.75 1649.49,1479.53 1649.16,1480.07 						"/>
            <polygon style="fill:#044A6D;" points="1654.67,1487.17 1655.23,1486.77 1647.83,1482.24 1647.5,1482.78 						"/>
            <polygon style="fill:#044A6D;" points="1658.78,1484.2 1659.98,1483.33 1650.66,1477.63 1649.94,1478.8 						"/>

            <rect x="1662" y="1464.14" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -459.8758 2121.0303)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1660.26,1483.13 1660.82,1482.73 1651.16,1476.81 1650.83,1477.35 						"/>
            <polygon style="fill:#044A6D;" points="1661.57,1482.18 1662.77,1481.31 1652.32,1474.91 1651.6,1476.08 						"/>
            <polygon style="fill:#044A6D;" points="1653.19,1488.25 1654.39,1487.38 1647.33,1483.06 1646.62,1484.23 						"/>
            <polygon style="fill:#044A6D;" points="1651.4,1489.54 1651.6,1489.4 1645.67,1485.77 1645.55,1485.97 						"/>
            <polygon style="fill:#044A6D;" points="1651.88,1489.2 1652.44,1488.79 1646.17,1484.96 1645.84,1485.5 						"/>
            <polygon style="fill:#044A6D;" points="1655.98,1486.23 1657.18,1485.36 1649,1480.34 1648.28,1481.51 						"/>

            <rect x="1659.74" y="1468.44" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -464.8017 2120.8403)" style="fill:#044A6D;" width="0.63" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1663.05,1481.11 1663.61,1480.7 1652.82,1474.1 1652.49,1474.64 						"/>

            <rect x="1660.34" y="1466.85" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -462.9851 2120.9104)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1661.4" y="1465.72" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -461.6919 2120.9602)" style="fill:#044A6D;" width="0.63" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1664.36,1480.16 1665.32,1479.46 1665.47,1479.23 1653.98,1472.2 1653.27,1473.37
                          "/>
            <polygon style="fill:#044A6D;" points="1658.1,1491.26 1658.82,1490.09 1654.39,1487.38 1653.19,1488.25 						"/>
            <polygon style="fill:#044A6D;" points="1658.1,1491.26 1658.82,1490.09 1654.39,1487.38 1653.19,1488.25 						"/>
            <polygon style="fill:#044A6D;" points="1661.43,1485.83 1662.14,1484.66 1659.98,1483.33 1658.78,1484.2 						"/>
            <polygon style="fill:#044A6D;" points="1661.43,1485.83 1662.14,1484.66 1659.98,1483.33 1658.78,1484.2 						"/>
            <polygon style="fill:#044A6D;" points="1664.75,1480.4 1665.32,1479.46 1664.36,1480.16 						"/>
            <polygon style="fill:#044A6D;" points="1664.75,1480.4 1665.32,1479.46 1664.36,1480.16 						"/>

            <rect x="1646.45" y="1490.15" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -489.6756 2119.8809)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1646.45" y="1490.15" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -489.6756 2119.8809)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1658.99,1489.81 1659.32,1489.27 1655.23,1486.77 1654.67,1487.17 						"/>
            <polygon style="fill:#044A6D;" points="1658.99,1489.81 1659.32,1489.27 1655.23,1486.77 1654.67,1487.17 						"/>

            <rect x="1648.11" y="1487.44" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -486.5665 2120.0007)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1648.11" y="1487.44" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -486.5665 2120.0007)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1649.77" y="1484.72" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -483.4574 2120.1206)" style="fill:#044A6D;" width="0.64" height="13.47"/>

            <rect x="1649.77" y="1484.72" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -483.4574 2120.1206)" style="fill:#044A6D;" width="0.64" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1659.77,1488.54 1660.48,1487.37 1657.18,1485.36 1655.98,1486.23 						"/>
            <polygon style="fill:#044A6D;" points="1659.77,1488.54 1660.48,1487.37 1657.18,1485.36 1655.98,1486.23 						"/>

            <rect x="1648.71" y="1485.85" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -484.7503 2120.0708)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1648.71" y="1485.85" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -484.7503 2120.0708)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1657.32,1492.53 1657.66,1491.99 1652.44,1488.79 1651.88,1489.2 						"/>
            <polygon style="fill:#044A6D;" points="1657.32,1492.53 1657.66,1491.99 1652.44,1488.79 1651.88,1489.2 						"/>
            <polygon style="fill:#044A6D;" points="1662.31,1484.38 1662.64,1483.84 1660.82,1482.73 1660.26,1483.13 						"/>
            <polygon style="fill:#044A6D;" points="1662.31,1484.38 1662.64,1483.84 1660.82,1482.73 1660.26,1483.13 						"/>

            <rect x="1647.05" y="1488.57" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -487.8596 2119.9509)" style="fill:#044A6D;" width="1.37" height="13.47"/>

            <rect x="1647.05" y="1488.57" transform="matrix(0.5221 -0.8529 0.8529 0.5221 -487.8596 2119.9509)" style="fill:#044A6D;" width="1.37" height="13.47"/>
            <polygon style="fill:#044A6D;" points="1645.55,1485.97 1644.96,1486.94 1656.44,1493.97 1657.16,1492.8 1651.6,1489.4
              1651.4,1489.54 						"/>
            <polygon style="fill:#044A6D;" points="1645.55,1485.97 1644.96,1486.94 1656.44,1493.97 1657.16,1492.8 1651.6,1489.4
              1651.4,1489.54 						"/>
            <polygon style="fill:#044A6D;" points="1640.69,1493.91 1640.31,1494.53 1645.69,1497.82 1651.8,1501.56 1652.17,1500.95
                          "/>
            <polygon style="fill:#044A6D;" points="1640.69,1493.91 1640.31,1494.53 1645.69,1497.82 1651.8,1501.56 1652.17,1500.95
                          "/>
            <polygon style="fill:#044A6D;" points="1663.97,1481.67 1664.3,1481.13 1663.61,1480.7 1663.05,1481.11 						"/>
            <polygon style="fill:#044A6D;" points="1663.97,1481.67 1664.3,1481.13 1663.61,1480.7 1663.05,1481.11 						"/>
            <polygon style="fill:#044A6D;" points="1661.57,1482.18 1663.09,1483.11 1663.8,1481.94 1662.77,1481.31 						"/>
            <polygon style="fill:#044A6D;" points="1661.57,1482.18 1663.09,1483.11 1663.8,1481.94 1662.77,1481.31 						"/>
            <polygon style="fill:#044A6D;" points="1660.65,1487.1 1660.98,1486.56 1658.02,1484.75 1657.47,1485.15 						"/>
            <polygon style="fill:#044A6D;" points="1660.65,1487.1 1660.98,1486.56 1658.02,1484.75 1657.47,1485.15 						"/>
            <polygon style="fill:#5CBCE2;" points="1663.24,1457.08 1662.79,1457.81 1674.27,1464.84 1673.94,1465.38 1662.46,1458.35
              1662.29,1458.63 1673.77,1465.66 1673.06,1466.82 1661.57,1459.79 1661.13,1460.53 1672.61,1467.56 1672.28,1468.1
              1660.8,1461.07 1660.63,1461.34 1672.11,1468.37 1671.4,1469.54 1659.91,1462.51 1659.46,1463.24 1670.95,1470.27
              1670.62,1470.81 1659.13,1463.78 1658.97,1464.06 1670.45,1471.09 1669.74,1472.25 1658.25,1465.22 1657.8,1465.95
              1669.29,1472.98 1668.96,1473.53 1657.47,1466.5 1657.3,1466.77 1668.79,1473.8 1668.07,1474.97 1656.59,1467.94
              1656.14,1468.67 1667.63,1475.7 1667.29,1476.24 1655.81,1469.21 1655.64,1469.48 1667.13,1476.51 1666.41,1477.68
              1654.93,1470.65 1654.48,1471.38 1665.96,1478.41 1665.63,1478.96 1654.15,1471.93 1653.98,1472.2 1665.47,1479.23
              1665.32,1479.46 1664.75,1480.4 1664.36,1480.16 1653.27,1473.37 1652.82,1474.1 1663.61,1480.7 1664.3,1481.13
              1663.97,1481.67 1663.05,1481.11 1652.49,1474.64 1652.32,1474.91 1662.77,1481.31 1663.8,1481.94 1663.09,1483.11
              1661.57,1482.18 1651.6,1476.08 1651.16,1476.81 1660.82,1482.73 1662.64,1483.84 1662.31,1484.38 1660.26,1483.13
              1650.83,1477.35 1650.66,1477.63 1659.98,1483.33 1662.14,1484.66 1661.43,1485.83 1658.78,1484.2 1649.94,1478.8
              1649.83,1478.97 1649.49,1479.53 1658.02,1484.75 1660.98,1486.56 1660.65,1487.1 1657.47,1485.15 1649.16,1480.07
              1649,1480.34 1657.18,1485.36 1660.48,1487.37 1659.77,1488.54 1655.98,1486.23 1648.28,1481.51 1647.83,1482.24
              1655.23,1486.77 1659.32,1489.27 1658.99,1489.81 1654.67,1487.17 1647.5,1482.78 1647.33,1483.06 1654.39,1487.38
              1658.82,1490.09 1658.1,1491.26 1653.19,1488.25 1646.62,1484.23 1646.17,1484.96 1652.44,1488.79 1657.66,1491.99
              1657.32,1492.53 1651.88,1489.2 1645.84,1485.5 1645.67,1485.77 1651.6,1489.4 1657.16,1492.8 1656.44,1493.97
              1644.96,1486.94 1644.51,1487.67 1655.99,1494.7 1655.66,1495.24 1644.18,1488.21 1644.01,1488.49 1655.5,1495.52
              1654.78,1496.68 1643.3,1489.65 1642.85,1490.38 1654.33,1497.42 1654,1497.96 1642.52,1490.93 1642.35,1491.2
              1653.83,1498.23 1653.12,1499.4 1641.63,1492.37 1641.19,1493.1 1652.67,1500.13 1652.34,1500.67 1640.86,1493.64
              1640.69,1493.91 1652.17,1500.95 1651.8,1501.56 1651.8,1501.56 1652.6,1502.05 1663.91,1483.56 1666.92,1478.64
              1676.12,1463.62 1675.32,1463.14 1674.72,1464.11 						"/>
          </g>
        </g>

        <rect x="1602.62" y="1441.11" transform="matrix(-0.5221 0.8529 -0.8529 -0.5221 3773.5674 769.9246)" style="fill:#5CBCE2;" width="136.91" height="2.19"/>
      </g>
    </g>
  </g>
</g>
          <g id="outer-titles">
  <g>
    <g id="mncs_1_">
      <path style="fill:#044A6D;" d="M1724.54,338.26l33.83-15.95l-23.57,34.78l-1.87-1.95l19.32-27.49l-32.5,15.17l16.62-31.8
        l-28.32,18.07l-1.91-2l35.88-21.89L1724.54,338.26z"/>
      <path style="fill:#044A6D;" d="M1760.21,379.27l26.32-23.17l1.82,2.07l-31.87,28.05l7.54-47.59l-25.6,22.53l-1.82-2.07
        l31.01-27.29L1760.21,379.27z"/>
      <path style="fill:#044A6D;" d="M1779.6,408.21c-2.49-1.15-4.83-2.88-6.69-5.16c-3.33-4.18-4.6-9.43-3.87-14.52
        c0.68-5.05,3.35-9.95,7.77-13.55c4.42-3.61,9.75-5.25,14.83-4.9c5.17,0.35,10.02,2.59,13.4,6.73c1.63,2,2.81,4.29,3.47,6.62
        c0.64,2.5,0.81,5.07,0.51,7.65l-2.7-0.36c0.22-2.2,0.11-4.43-0.45-6.53c-0.56-2.02-1.57-3.91-2.97-5.63
        c-2.89-3.54-7-5.44-11.41-5.72c-4.41-0.27-9.08,1.13-12.94,4.28c-3.86,3.15-6.17,7.44-6.78,11.82
        c-0.65,4.33,0.44,8.79,3.27,12.36c1.6,1.95,3.54,3.39,5.7,4.42c2.29,1,4.76,1.46,7.28,1.42l0.02,2.78
        C1785.15,409.97,1782.26,409.38,1779.6,408.21z"/>
      <path style="fill:#044A6D;" d="M1802.18,408.14c-2.62,2.01-1.62,4.58,0.01,7.79c1.79,3.4,3.2,7.69-1.18,11.06
        c-2.52,1.94-7.43,2.16-11.2-2.74c-2.82-3.66-2.73-8.28-0.33-10.96l2.17,1.05c-1.39,2.13-2.04,5.51,0.48,8.8
        c0.95,1.24,3.48,2.85,5.7,2.28c0.97-0.22,2.4-1.32,2.94-2.19c0.93-1.55,0.29-3.56-0.91-6.2c-1.57-3.42-3.08-7.94,0.77-10.9
        c2.81-2.16,6.64-1.71,9.5,2.01c1.98,2.57,2.4,5.58,1.5,8.24l-2.46-0.53c0.48-2.04,0.69-4.32-0.67-6.08
        C1806.58,407.26,1803.85,406.86,1802.18,408.14z"/>
    </g>
    <g id="educational_1_">
      <path style="fill:#044A6D;" d="M1942.58,667.07l5.58,14.45l-2.3,0.89l-5.58-14.45l-15.91,6.14l6.34,16.41l-2.24,0.87l-6.34-16.41
        l-1-2.58l2.24-0.86l15.91-6.14l2.3-0.89l14.06-5.43l2.3-0.89l0.99,2.58l5.97,15.46l-2.3,0.89l-5.97-15.46L1942.58,667.07z"/>
      <path style="fill:#044A6D;" d="M1948.01,710.01l-11.24,4.05l-0.94-2.6l3.11-1.12c-0.23-0.11-0.44-0.16-0.67-0.27
        c-2.97-1.16-5.5-3.57-6.6-6.62c-1.1-3.05-0.59-6.43,0.96-9.22c1.49-2.77,4.11-5.06,7.45-6.26c3.33-1.2,6.81-1.11,9.73,0.07
        c2.97,1.16,5.38,3.42,6.5,6.53c1.1,3.05,0.57,6.56-0.95,9.41c-0.13,0.17-0.26,0.35-0.37,0.58l21.69-7.81l0.94,2.6L1948.01,710.01
        z M1948.8,690.58c-2.3-0.89-5.08-0.98-7.79,0c-2.71,0.98-4.8,2.81-6,4.97c-1.15,2.14-1.47,4.61-0.63,6.93
        c0.81,2.26,2.64,3.96,4.88,4.88c2.32,0.95,5.1,1.04,7.81,0.06c2.71-0.98,5.07-2.59,6.25-4.8c1.2-2.16,1.2-4.83,0.38-7.09
        C1952.87,693.2,1951.04,691.5,1948.8,690.58z"/>
      <path style="fill:#044A6D;" d="M1946.43,717.43c-3.87,1.29-5.02,5.84-4.02,8.86c1.02,3.08,4.66,6.05,8.53,4.76l15.1-5l0.87,2.62
        l-15.1,5c-4.5,1.49-9.91-1.08-11.74-6.61c-1.81-5.47,0.99-10.76,5.49-12.25l15.1-5l0.87,2.62L1946.43,717.43z"/>
      <path style="fill:#044A6D;" d="M1951.14,757.24c-1.41-1.33-2.54-2.99-3.14-4.94c-0.98-3.22-0.44-6.58,1.23-9.35
        c1.61-2.75,4.31-5.02,7.7-6.05c3.39-1.03,6.89-0.66,9.76,0.73c2.93,1.37,5.26,3.86,6.24,7.07c0.51,1.67,0.6,3.4,0.34,5.04
        c-0.29,1.72-0.92,3.36-1.91,4.85l-2.28-1.5c0.71-1.16,1.26-2.45,1.43-3.76c0.18-1.25,0.16-2.56-0.22-3.82
        c-0.75-2.47-2.53-4.38-4.8-5.44c-2.27-1.07-5-1.37-7.75-0.52c-2.76,0.84-4.85,2.61-6.14,4.76c-1.29,2.15-1.7,4.72-0.95,7.19
        c0.45,1.49,1.28,2.75,2.39,3.73c1.15,1.1,2.52,1.87,4.1,2.33l-0.71,2.6C1954.44,759.63,1952.62,758.61,1951.14,757.24z"/>
      <path style="fill:#044A6D;" d="M1978.33,777.36c-0.18,0.24-0.55,0.71-0.79,0.97l3.67-1.14l0.74,2.66l-23.03,6.38l-0.74-2.66
        l3.79-0.93c-0.34-0.09-0.95-0.3-1.23-0.41c-2.89-1.44-5.12-3.88-5.99-7c-0.87-3.13-0.2-6.36,1.53-9.09
        c1.7-2.65,4.48-4.73,7.9-5.68c3.41-0.95,6.88-0.6,9.69,0.8c2.89,1.44,5.12,3.88,5.99,7
        C1980.73,771.4,1980.07,774.63,1978.33,777.36z M1977.2,769.01c-0.66-2.37-2.35-4.21-4.52-5.29c-2.23-1.07-4.99-1.36-7.77-0.59
        c-2.78,0.77-4.99,2.44-6.35,4.5c-1.3,2.04-1.81,4.49-1.15,6.86c0.64,2.32,2.25,4.3,4.42,5.38c2.23,1.07,5.09,1.27,7.87,0.5
        c2.78-0.77,5.2-2.37,6.54-4.49C1977.54,773.84,1977.85,771.32,1977.2,769.01z"/>
      <path style="fill:#044A6D;" d="M1983.88,787.13l11.34-2.89l0.68,2.68l-11.34,2.89l0.98,3.84l-2.68,0.68l-0.98-3.84l-20.54,5.23
        l-0.68-2.68l20.54-5.23l-0.98-3.84l2.68-0.68L1983.88,787.13z"/>
      <path style="fill:#044A6D;" d="M1964.03,806.2l-0.65-2.68l23.22-5.65l0.65,2.68L1964.03,806.2z M1996.59,799.32
        c-1.28,0.31-2.59-0.48-2.9-1.77c-0.31-1.28,0.48-2.59,1.77-2.9c1.28-0.31,2.59,0.48,2.9,1.77
        C1998.67,797.7,1997.87,799.01,1996.59,799.32z"/>
      <path style="fill:#044A6D;" d="M1991.95,816.29c0.74,3.28,0.13,6.56-1.72,9.26c-1.73,2.42-4.5,4.34-8.3,5.2
        c-3.81,0.86-7.16,0.45-9.84-1.04c-2.75-1.53-4.64-4.18-5.42-7.64c-0.78-3.46-0.14-6.62,1.69-9.12c1.78-2.5,4.63-4.31,8.38-5.16
        c3.75-0.85,7.06-0.36,9.73,1.12C1989.27,810.43,1991.22,813.07,1991.95,816.29z M1985.1,811.31c-2.04-1.14-4.71-1.52-7.76-0.83
        c-3.05,0.69-5.37,2.14-6.78,4.06c-1.35,1.91-1.8,4.29-1.2,6.92c0.6,2.64,2.04,4.65,4.09,5.85c2.04,1.14,4.76,1.45,7.86,0.74
        c3.1-0.7,5.29-2.18,6.65-4.09c1.44-2.05,1.86-4.55,1.3-7.07C1988.7,814.44,1987.21,812.5,1985.1,811.31z"/>
      <path style="fill:#044A6D;" d="M1991.65,834.19c2.74,1.16,4.97,3.11,5.87,7.58c0.89,4.41-1.08,8.67-7.38,9.93l-15.48,3.11
        l-0.54-2.71l14.95-3c5-1,6.56-3.95,5.94-7.01c-1.03-5.12-5.34-7.07-8.48-6.87l-15.19,3.05l-0.54-2.71l23.43-4.7l0.54,2.71
        L1991.65,834.19z"/>
      <path style="fill:#044A6D;" d="M1999.52,874.31c-0.21,0.22-0.62,0.66-0.88,0.89l3.77-0.78l0.48,2.72l-23.54,4.12l-0.48-2.72
        l3.87-0.56c-0.33-0.13-0.92-0.39-1.19-0.52c-2.74-1.71-4.73-4.35-5.28-7.55c-0.56-3.19,0.41-6.35,2.4-8.9
        c1.94-2.47,4.92-4.27,8.41-4.88c3.49-0.61,6.9,0.07,9.57,1.74c2.74,1.71,4.72,4.35,5.28,7.55S2001.51,871.77,1999.52,874.31z
         M1999.21,865.89c-0.42-2.43-1.93-4.42-3.98-5.7c-2.11-1.28-4.83-1.84-7.67-1.34c-2.84,0.5-5.21,1.95-6.76,3.87
        c-1.5,1.91-2.24,4.29-1.81,6.72c0.41,2.37,1.82,4.5,3.88,5.78c2.11,1.28,4.94,1.76,7.78,1.26c2.84-0.5,5.4-1.86,6.95-3.84
        C1999.08,870.73,1999.62,868.26,1999.21,865.89z"/>
      <path style="fill:#044A6D;" d="M1980.25,887.22l-0.43-2.73l42.88-6.77l0.43,2.73L1980.25,887.22z"/>
      <path style="fill:#044A6D;" d="M1984.33,915.93l-0.35-2.74l39.19-5.05l0.35,2.74L1984.33,915.93z"/>
      <path style="fill:#044A6D;" d="M2005.87,919.94c2.64,1.39,4.69,3.51,5.21,8.05c0.51,4.47-1.8,8.55-8.19,9.28l-15.69,1.79
        l-0.31-2.74l15.15-1.73c5.07-0.58,6.87-3.38,6.51-6.49c-0.59-5.19-4.72-7.5-7.87-7.56l-15.39,1.76l-0.31-2.74l23.74-2.71
        l0.31,2.74L2005.87,919.94z"/>
      <path style="fill:#044A6D;" d="M2006.34,944.54c-3.29,0.3-3.82,3.01-4.15,6.6c-0.31,3.83-1.41,8.21-6.91,8.72
        c-3.17,0.29-7.43-2.14-8-8.3c-0.43-4.6,2.11-8.46,5.58-9.44l1.28,2.05c-2.31,1.06-4.67,3.57-4.29,7.69
        c0.14,1.55,1.42,4.27,3.6,4.97c0.94,0.34,2.73,0.17,3.65-0.28c1.61-0.81,2.15-2.85,2.55-5.72c0.5-3.72,1.64-8.35,6.48-8.8
        c3.53-0.33,6.53,2.11,6.96,6.77c0.3,3.23-0.95,6-3.14,7.77l-1.79-1.76c1.49-1.46,2.89-3.28,2.69-5.49
        C2010.52,946.15,2008.43,944.35,2006.34,944.54z"/>
      <path style="fill:#044A6D;" d="M2013.53,964.59l11.68-0.88l0.21,2.75l-11.68,0.88l0.3,3.95l-2.75,0.21l-0.3-3.95l-21.14,1.59
        l-0.21-2.76l21.14-1.59l-0.3-3.95l2.75-0.21L2013.53,964.59z"/>
      <path style="fill:#044A6D;" d="M1990.67,979.91l-0.18-2.76l23.85-1.54l0.18,2.76L1990.67,979.91z M2023.92,978.79
        c-1.32,0.09-2.47-0.92-2.55-2.24c-0.09-1.32,0.92-2.47,2.24-2.55c1.32-0.09,2.47,0.92,2.55,2.24
        C2026.25,977.56,2025.24,978.71,2023.92,978.79z"/>
      <path style="fill:#044A6D;" d="M2015.03,986.73l11.69-0.63l0.15,2.76l-11.69,0.63l0.21,3.96l-2.76,0.15l-0.21-3.96l-21.16,1.15
        l-0.15-2.76l21.16-1.14l-0.21-3.96l2.76-0.15L2015.03,986.73z"/>
      <path style="fill:#044A6D;" d="M1999.87,1001.27c-4.08,0.14-6.46,4.19-6.35,7.37c0.11,3.24,2.77,7.11,6.85,6.97l15.9-0.56
        l0.1,2.76l-15.9,0.56c-4.74,0.16-9.2-3.83-9.41-9.65c-0.2-5.76,3.97-10.05,8.71-10.22l15.9-0.56l0.1,2.76L1999.87,1001.27z"/>
      <path style="fill:#044A6D;" d="M2016.54,1026.27l11.71-0.23l0.06,2.76l-11.71,0.23l0.08,3.96l-2.76,0.06l-0.08-3.96l-21.19,0.42
        l-0.05-2.76l21.19-0.42l-0.08-3.96l2.76-0.05L2016.54,1026.27z"/>
      <path style="fill:#044A6D;" d="M1992.7,1040.18l0.07-2.76l23.89,0.6l-0.07,2.76L1992.7,1040.18z M2025.93,1042.04
        c-1.32-0.03-2.37-1.14-2.34-2.46c0.03-1.32,1.14-2.37,2.46-2.34c1.32,0.03,2.37,1.14,2.34,2.46
        C2028.35,1041.02,2027.25,1042.07,2025.93,1042.04z"/>
      <path style="fill:#044A6D;" d="M2016.63,1058.1c-0.29,3.35-1.86,6.29-4.45,8.3c-2.38,1.78-5.6,2.77-9.49,2.44
        c-3.89-0.33-6.96-1.74-9.06-3.97c-2.15-2.29-3.15-5.39-2.85-8.92c0.3-3.53,1.87-6.35,4.38-8.18c2.45-1.84,5.71-2.7,9.54-2.38
        c3.83,0.33,6.84,1.79,8.94,4.02C2015.86,1051.7,2016.92,1054.81,2016.63,1058.1z M2011.61,1051.28c-1.6-1.7-4.03-2.88-7.14-3.14
        s-5.76,0.41-7.69,1.81c-1.87,1.41-3.01,3.54-3.25,6.23c-0.23,2.69,0.53,5.05,2.13,6.81c1.6,1.7,4.1,2.82,7.27,3.09
        c3.17,0.27,5.71-0.47,7.57-1.88c2-1.52,3.16-3.77,3.38-6.34C2014.1,1055.35,2013.27,1053.05,2011.61,1051.28z"/>
      <path style="fill:#044A6D;" d="M2010.62,1075.86c2.16,2.05,3.57,4.65,2.84,9.16c-0.72,4.45-4.05,7.74-10.39,6.72l-15.59-2.52
        l0.44-2.73l15.06,2.43c5.04,0.81,7.52-1.4,8.02-4.48c0.83-5.16-2.52-8.5-5.54-9.41l-15.29-2.47l0.44-2.73l23.59,3.81l-0.44,2.73
        L2010.62,1075.86z"/>
      <path style="fill:#044A6D;" d="M2004.16,1100.1c-3.23-0.7-4.55,1.71-5.95,5.03c-1.45,3.55-3.83,7.4-9.22,6.22
        c-3.11-0.68-6.44-4.29-5.12-10.33c0.99-4.52,4.57-7.42,8.17-7.31l0.59,2.34c-2.53,0.31-5.53,1.99-6.42,6.04
        c-0.33,1.52,0.06,4.5,1.92,5.83c0.79,0.6,2.55,0.99,3.56,0.84c1.78-0.29,2.91-2.07,4.16-4.69c1.6-3.4,4.09-7.46,8.84-6.43
        c3.46,0.76,5.58,3.98,4.58,8.56c-0.69,3.17-2.72,5.43-5.34,6.46l-1.17-2.22c1.87-0.95,3.75-2.26,4.22-4.42
        C2007.67,1102.9,2006.22,1100.55,2004.16,1100.1z"/>
    </g>
    <g id="sugar-management">
      <path style="fill:#044A6D;" d="M1709.01,1741.62c2.92,1.3,7.3,1.67,10.25-1.41c3.32-3.47,3.34-8.78,0.56-11.44
        c-4.08-3.9-8.4-1.39-11.93,0.22c-4.63,2.14-10.8,5.37-16.7-0.28c-4.6-4.4-3.96-12.36,0.27-16.78c5.52-5.77,12.46-3.28,15.26-1.35
        l-1.27,2.36c-3.41-2.6-8.68-2.83-12.05,0.69c-4.36,4.55-3.39,10.31-0.39,13.17c5.29,5.07,10.35,1.18,13.96-0.51
        c4.79-2.23,9.94-3.78,14.36,0.45c4.99,4.78,3.18,11.44-0.31,15.08c-4.03,4.21-8.66,4.01-12.91,2.1L1709.01,1741.62z"/>
      <path style="fill:#044A6D;" d="M1683.86,1733.33c-2.87-2.91-7.46-1.92-9.73,0.32c-2.31,2.28-3.36,6.86-0.49,9.76l11.17,11.33
        l-1.97,1.94l-11.17-11.33c-3.33-3.38-3.42-9.36,0.73-13.46c4.1-4.05,10.09-3.88,13.42-0.5l11.17,11.33l-1.97,1.94
        L1683.86,1733.33z"/>
      <path style="fill:#044A6D;" d="M1674.94,1759.19c0.49,2.18,0.07,4.8-1.93,6.7c-1.62,1.52-3.67,2.06-5.78,1.58l-0.27-2.47
        c1.35,0.38,3.12,0.68,4.65-0.76c2.14-2.02,1.61-5.9-0.5-9.1l-10.1-10.69l2.01-1.9l16.45,17.42l-2.01,1.9L1674.94,1759.19z"/>
      <path style="fill:#044A6D;" d="M1641.72,1778.89l-8-8.88c-2.33-2.59-3.33-5.49-3.27-8.21c0.03-3.02,1.37-5.84,3.6-7.85
        c2.05-1.85,3.89-2.86,5.57-3.32c1.77-0.46,3.25-0.43,4.78-0.35l-0.19,2.75c-1.27-0.07-2.5-0.09-3.84,0.22
        c-1.34,0.4-2.74,1.17-4.48,2.74c-1.7,1.53-2.69,3.56-2.68,5.81c-0.07,2.08,0.71,4.29,2.56,6.34l2.45,2.72
        c-0.02-0.38-0.05-0.68-0.03-1.02c-0.01-3.15,1.05-6.2,3.68-8.57c2.59-2.33,5.66-3.32,8.75-3.03c3,0.37,6.06,1.97,8.63,4.83
        c2.57,2.85,3.72,6.02,3.78,9.04c0.05,3.19-1.3,6.18-3.8,8.43c-2.45,2.21-5.81,3.06-9.07,2.67c-0.25-0.01-0.59-0.03-0.89-0.09
        l2.29,2.54l-2.05,1.85L1641.72,1778.89z M1660.27,1772.12c-0.01-2.34-0.98-4.85-3.07-7.18c-2.13-2.36-4.53-3.68-6.85-3.93
        c-2.33-0.25-4.62,0.52-6.63,2.33c-2.05,1.85-3.1,4.09-3.1,6.43c0.01,2.34,0.98,4.86,3.15,7.26c2.09,2.32,4.54,3.51,6.86,3.76
        c2.45,0.21,4.88-0.6,6.75-2.28C1659.26,1776.83,1660.27,1774.55,1660.27,1772.12z"/>
      <path style="fill:#044A6D;" d="M1617.91,1783.08c1.01-1.88,2.5-3.54,4.01-4.83c2.47-2.1,5.65-2.9,8.85-2.47
        c3.07,0.46,6.18,2.08,8.47,4.78c2.29,2.7,3.38,6.03,3.34,9.13c-0.09,3.23-1.36,6.28-3.84,8.38c-2.11,1.79-4.73,2.6-7.45,2.54
        c-2.64-0.04-5.37-1.03-7.74-2.8c-0.36-0.33-0.76-0.62-1.11-1.03c-0.12-0.05-0.16-0.1-0.24-0.19l1.33-1.13l0.78-0.66l13.55-11.5
        c-0.23-0.36-0.46-0.63-0.73-0.95c-1.87-2.2-4.31-3.5-6.8-3.83c-2.36-0.36-4.75,0.25-6.62,1.84c-1.28,1.09-2.54,2.48-3.44,4.02
        c-0.65,1.18-1.01,2.43-0.93,3.63l-2.74,0.2C1616.44,1786.45,1617.01,1784.71,1617.91,1783.08z M1631.4,1797.85
        c2,0.04,4.01-0.57,5.56-1.89c1.88-1.59,2.83-3.9,2.86-6.28c0.02-1.28-0.17-2.61-0.67-3.84l-12.54,10.65
        C1628.12,1797.32,1629.78,1797.8,1631.4,1797.85z"/>
      <path style="fill:#044A6D;" d="M1567.47,1829.18l10.95,35.76l-31.06-28.3l2.2-1.57l24.45,23.05l-10.37-34.33l29.1,20.99
        l-13.84-30.61l2.25-1.6l16.53,38.64L1567.47,1829.18z"/>
      <path style="fill:#044A6D;" d="M1539.93,1866.61c-0.28-0.1-0.85-0.31-1.16-0.46l2.2,3.16l-2.32,1.51l-13.03-20.03l2.32-1.51
        l2.03,3.34c-0.01-0.35-0.01-1,0.01-1.3c0.5-3.19,2.15-6.06,4.87-7.82c2.72-1.77,6-2.11,9.12-1.28c3.04,0.82,5.86,2.85,7.8,5.82
        c1.93,2.97,2.64,6.38,2.16,9.48c-0.5,3.19-2.15,6.05-4.87,7.82C1546.33,1867.09,1543.05,1867.44,1539.93,1866.61z
         M1547.54,1863.01c2.06-1.34,3.3-3.51,3.68-5.9c0.34-2.44-0.21-5.17-1.78-7.58c-1.57-2.42-3.84-4.02-6.21-4.7
        c-2.34-0.63-4.83-0.37-6.89,0.97c-2.01,1.31-3.42,3.44-3.8,5.84c-0.34,2.44,0.32,5.23,1.9,7.65c1.57,2.42,3.83,4.24,6.26,4.88
        C1543.04,1864.79,1545.53,1864.32,1547.54,1863.01z"/>
      <path style="fill:#044A6D;" d="M1531.56,1871.55c-0.16,2.98-1.22,5.73-5.12,8.11c-3.85,2.34-8.51,1.95-11.86-3.54l-8.21-13.49
        l2.36-1.44l7.93,13.03c2.65,4.36,5.95,4.81,8.62,3.19c4.46-2.72,4.82-7.44,3.56-10.32l-8.06-13.23l2.36-1.44l12.43,20.41
        l-2.36,1.44L1531.56,1871.55z"/>
      <path style="fill:#044A6D;" d="M1496.59,1892.51c-0.28-0.12-0.83-0.36-1.13-0.53l2,3.28l-2.4,1.36l-11.8-20.78l2.4-1.37
        l1.82,3.45c0.01-0.35,0.05-1,0.09-1.3c0.69-3.16,2.51-5.91,5.33-7.52c2.82-1.6,6.12-1.75,9.18-0.73c2.98,1,5.68,3.19,7.43,6.27
        c1.75,3.08,2.25,6.52,1.58,9.6c-0.69,3.16-2.51,5.91-5.33,7.52C1502.96,1893.38,1499.66,1893.53,1496.59,1892.51z
         M1504.41,1889.38c2.14-1.22,3.51-3.3,4.03-5.67c0.49-2.42,0.1-5.17-1.32-7.67c-1.42-2.51-3.59-4.25-5.92-5.06
        c-2.3-0.76-4.79-0.66-6.93,0.56c-2.09,1.19-3.62,3.23-4.14,5.6c-0.49,2.42,0.01,5.24,1.43,7.75c1.42,2.51,3.57,4.46,5.95,5.25
        C1499.82,1890.88,1502.33,1890.56,1504.41,1889.38z"/>
      <path style="fill:#044A6D;" d="M1465.46,1899.23l-5.56-10.58c-1.62-3.08-1.87-6.14-1.15-8.76c0.77-2.92,2.77-5.32,5.42-6.72
        c2.44-1.29,4.48-1.81,6.22-1.85c1.83-0.01,3.26,0.39,4.72,0.84l-0.86,2.62c-1.22-0.38-2.4-0.7-3.78-0.73
        c-1.4,0.06-2.95,0.46-5.02,1.55c-2.02,1.06-3.49,2.78-4.03,4.97c-0.58,2-0.37,4.33,0.92,6.78l1.7,3.24
        c0.07-0.38,0.12-0.67,0.22-1c0.77-3.05,2.54-5.75,5.68-7.4c3.08-1.62,6.3-1.82,9.22-0.78c2.82,1.1,5.39,3.41,7.18,6.81
        c1.79,3.4,2.13,6.75,1.43,9.69c-0.74,3.1-2.78,5.67-5.76,7.24c-2.92,1.54-6.39,1.53-9.45,0.35c-0.24-0.08-0.57-0.18-0.84-0.31
        l1.59,3.03l-2.44,1.28L1465.46,1899.23z M1485.1,1897.24c0.57-2.27,0.25-4.95-1.21-7.71c-1.48-2.82-3.48-4.68-5.67-5.5
        c-2.19-0.81-4.61-0.63-7,0.63c-2.44,1.29-4.02,3.2-4.59,5.47c-0.57,2.27-0.25,4.95,1.26,7.82c1.45,2.76,3.53,4.52,5.72,5.34
        c2.33,0.81,4.88,0.63,7.11-0.55C1482.96,1901.56,1484.51,1899.59,1485.1,1897.24z"/>
      <path style="fill:#044A6D;" d="M1441.36,1897.34c1.44-1.57,3.31-2.81,5.09-3.67c2.92-1.41,6.19-1.4,9.19-0.18
        c2.86,1.22,5.46,3.56,7.01,6.75c1.55,3.19,1.77,6.68,0.96,9.68c-0.9,3.1-2.89,5.74-5.81,7.15c-2.49,1.2-5.23,1.33-7.85,0.6
        c-2.54-0.7-4.94-2.34-6.79-4.65c-0.26-0.41-0.58-0.79-0.82-1.27c-0.11-0.08-0.13-0.14-0.18-0.24l1.57-0.76l0.92-0.45l15.99-7.75
        c-0.13-0.4-0.29-0.73-0.47-1.11c-1.26-2.59-3.3-4.47-5.63-5.41c-2.19-0.94-4.66-0.94-6.88,0.13c-1.51,0.73-3.08,1.76-4.33,3.04
        c-0.92,0.98-1.58,2.1-1.81,3.28l-2.71-0.49C1439.1,1900.24,1440.08,1898.69,1441.36,1897.34z M1450.73,1915.02
        c1.93,0.53,4.02,0.45,5.86-0.44c2.22-1.07,3.72-3.07,4.34-5.37c0.34-1.23,0.49-2.57,0.32-3.89l-14.81,7.18
        C1447.69,1913.69,1449.18,1914.57,1450.73,1915.02z"/>
      <path style="fill:#044A6D;" d="M1439.39,1920.71c-0.34,2.97-1.85,5.98-5.31,7.49c-3.08,1.34-6.19,1.13-8.67-1.4
        c-0.65,3.62-2.65,6.39-5.79,7.76c-4.73,2.06-8.3,0.94-10.97-5.17l-6.34-14.53l2.53-1.1l6.33,14.53c1.63,3.74,3.99,5.4,7.46,3.89
        c4.13-1.8,4.69-5.91,4.37-9.05l-6.34-14.53l2.53-1.1l6.34,14.53c2.04,4.68,4.81,5.17,7.84,3.85c3.58-1.56,4.54-6.11,3.91-9.96
        l-5.98-13.71l2.53-1.1l9.58,21.96l-2.53,1.1L1439.39,1920.71z"/>
      <path style="fill:#044A6D;" d="M1382.57,1922.55c1.56-1.44,3.53-2.53,5.37-3.25c3.02-1.17,6.29-0.9,9.17,0.56
        c2.75,1.44,5.16,3.99,6.44,7.29c1.28,3.3,1.22,6.8,0.17,9.73c-1.15,3.02-3.34,5.48-6.37,6.66c-2.57,1-5.32,0.9-7.87-0.04
        c-2.48-0.91-4.73-2.73-6.39-5.18c-0.23-0.43-0.52-0.83-0.71-1.33c-0.1-0.09-0.12-0.15-0.16-0.26l1.62-0.63l0.95-0.37l16.57-6.44
        c-0.1-0.41-0.23-0.75-0.38-1.14c-1.04-2.69-2.93-4.72-5.17-5.85c-2.11-1.11-4.57-1.32-6.86-0.43c-1.57,0.61-3.22,1.51-4.56,2.67
        c-1,0.9-1.75,1.97-2.07,3.12l-2.66-0.71C1380.07,1925.26,1381.18,1923.8,1382.57,1922.55z M1390.48,1940.93
        c1.88,0.69,3.97,0.78,5.88,0.04c2.29-0.89,3.95-2.76,4.76-5c0.44-1.2,0.69-2.52,0.63-3.85l-15.33,5.96
        C1387.55,1939.36,1388.96,1940.36,1390.48,1940.93z"/>
      <path style="fill:#044A6D;" d="M1378.98,1946.11c-0.77,2.88-2.38,5.36-6.68,6.88c-4.25,1.5-8.73,0.15-10.87-5.91l-5.25-14.89
        l2.6-0.92l5.07,14.38c1.7,4.81,4.83,5.94,7.78,4.9c4.93-1.74,6.25-6.28,5.61-9.37l-5.15-14.61l2.6-0.92l7.95,22.54l-2.6,0.92
        L1378.98,1946.11z"/>
      <path style="fill:#044A6D;" d="M1356.75,1957.17l3.63,11.13l-2.63,0.86l-3.63-11.13l-3.77,1.23l-0.86-2.63l3.77-1.23l-6.58-20.15
        l2.63-0.86l6.58,20.15l3.77-1.23l0.86,2.63L1356.75,1957.17z"/>
    </g>
    <g>
      <path style="fill:#044A6D;" d="M1066.05,1987.07l1.36,39.42l-2.76,0.1l-5.52,0.19c-12.36,0.43-20.68-7.46-21.08-19.04
        c-0.4-11.52,7.36-19.96,19.72-20.39l5.52-0.19L1066.05,1987.07z M1063.37,1989.63l-5.16,0.18c-10.92,0.38-17.87,7.89-17.53,17.85
        c0.35,10.14,7.8,17.03,18.72,16.66l5.16-0.18L1063.37,1989.63z"/>
      <path style="fill:#044A6D;" d="M1015.34,1988.38c1.98-0.79,4.2-1.1,6.18-1.12c3.24-0.02,6.19,1.41,8.37,3.8
        c2.05,2.33,3.39,5.56,3.41,9.11c0.02,3.54-1.28,6.79-3.31,9.15c-2.15,2.41-5.08,3.93-8.32,3.95c-2.76,0.02-5.29-1.05-7.34-2.84
        c-1.99-1.73-3.45-4.24-4.12-7.12c-0.06-0.48-0.19-0.96-0.19-1.5c-0.06-0.12-0.06-0.18-0.06-0.3l1.74-0.01l1.02-0.01l17.77-0.1
        c0.06-0.42,0.06-0.78,0.05-1.2c-0.02-2.88-1.05-5.46-2.74-7.31c-1.57-1.79-3.8-2.86-6.26-2.85c-1.68,0.01-3.54,0.26-5.22,0.87
        c-1.26,0.49-2.34,1.21-3.05,2.18l-2.23-1.61C1012.05,1990.02,1013.61,1989.05,1015.34,1988.38z M1016.18,2008.37
        c1.51,1.31,3.43,2.14,5.48,2.13c2.46-0.01,4.68-1.17,6.23-2.98c0.84-0.97,1.55-2.11,1.96-3.38l-16.45,0.1
        C1014,2005.86,1014.97,2007.3,1016.18,2008.37z"/>
      <path style="fill:#044A6D;" d="M1005.87,2009.66l3.72,0.04l-0.03,2.76l-3.72-0.04l-0.15,14.11c0,0.42-0.07,0.84-0.13,1.26
        c-0.32,1.74-1.11,2.87-2.2,3.64c-1.09,0.65-2.35,0.94-3.67,0.92c-0.42,0-0.84-0.01-1.26-0.07l0.03-2.82
        c0.42,0.06,0.84,0.13,1.26,0.13c0.78,0.01,1.56-0.1,2.11-0.52c0.48-0.29,0.85-0.83,1.04-1.73c0-0.24,0.07-0.54,0.07-0.84
        l0.15-14.11l-4.32-0.05l0.03-2.76l4.32,0.05l0.22-21.31l2.76,0.03L1005.87,2009.66z"/>
      <path style="fill:#044A6D;" d="M978.57,1987.77c2-0.72,4.23-0.95,6.21-0.89c3.24,0.1,6.14,1.63,8.23,4.09
        c1.97,2.4,3.19,5.68,3.09,9.22c-0.11,3.54-1.52,6.74-3.64,9.02c-2.23,2.34-5.22,3.75-8.46,3.65c-2.76-0.08-5.25-1.24-7.23-3.1
        c-1.93-1.8-3.29-4.36-3.87-7.26c-0.05-0.48-0.15-0.97-0.13-1.51c-0.06-0.12-0.05-0.18-0.05-0.3l1.74,0.05l1.02,0.03l17.76,0.53
        c0.07-0.42,0.08-0.78,0.1-1.2c0.09-2.88-0.86-5.49-2.48-7.4c-1.51-1.85-3.69-2.99-6.15-3.07c-1.68-0.05-3.55,0.13-5.25,0.68
        c-1.27,0.44-2.38,1.13-3.13,2.07l-2.17-1.69C975.22,1989.29,976.81,1988.38,978.57,1987.77z M978.69,2007.77
        c1.46,1.37,3.36,2.26,5.4,2.33c2.46,0.07,4.72-1,6.33-2.75c0.87-0.94,1.62-2.05,2.08-3.3l-16.44-0.5
        C976.6,2005.19,977.52,2006.66,978.69,2007.77z"/>
      <path style="fill:#044A6D;" d="M966.1,2008.09c-1.81,2.37-4.25,4.04-8.81,3.79c-4.5-0.25-8.12-3.21-7.77-9.62l0.86-15.77
        l2.76,0.15l-0.83,15.23c-0.28,5.1,2.18,7.33,5.3,7.51c5.22,0.28,8.18-3.4,8.77-6.5l0.84-15.47l2.76,0.15l-1.3,23.86l-2.76-0.15
        L966.1,2008.09z"/>
      <path style="fill:#044A6D;" d="M929.06,1985.27c1.75-0.83,3.71-1.28,5.75-1.12c3.35,0.26,6.29,2,8.26,4.56
        c1.97,2.5,3.1,5.84,2.82,9.37c-0.28,3.53-1.9,6.66-4.24,8.82c-2.34,2.23-5.51,3.49-8.86,3.22c-1.74-0.14-3.38-0.68-4.82-1.52
        c-1.5-0.9-2.79-2.08-3.82-3.55l2.23-1.57c0.82,1.09,1.83,2.07,2.98,2.7c1.1,0.63,2.32,1.08,3.64,1.19c2.57,0.2,5-0.75,6.82-2.48
        c1.82-1.73,3.09-4.16,3.32-7.03c0.22-2.87-0.66-5.47-2.19-7.46c-1.53-1.99-3.78-3.3-6.35-3.51c-1.56-0.12-3.03,0.19-4.34,0.87
        c-1.44,0.67-2.66,1.66-3.66,2.97l-2.16-1.62C925.64,1987.47,927.25,1986.16,929.06,1985.27z"/>
      <path style="fill:#044A6D;" d="M904.72,1982.51c2.05-0.57,4.29-0.64,6.26-0.44c3.23,0.33,6,2.07,7.91,4.68
        c1.79,2.54,2.77,5.9,2.41,9.42c-0.36,3.52-2.01,6.61-4.28,8.73c-2.4,2.17-5.48,3.36-8.7,3.02c-2.75-0.28-5.14-1.62-6.99-3.62
        c-1.79-1.94-2.97-4.59-3.33-7.53c-0.01-0.48-0.08-0.97-0.02-1.51c-0.05-0.13-0.04-0.19-0.03-0.3l1.73,0.18l1.02,0.1l17.68,1.83
        c0.1-0.41,0.14-0.77,0.18-1.19c0.3-2.87-0.45-5.54-1.93-7.56c-1.37-1.95-3.47-3.26-5.91-3.51c-1.67-0.17-3.55-0.12-5.28,0.3
        c-1.3,0.35-2.45,0.95-3.27,1.83l-2.04-1.84C901.27,1983.79,902.92,1982.99,904.72,1982.51z M903.38,2002.47
        c1.36,1.47,3.18,2.5,5.21,2.71c2.45,0.25,4.78-0.65,6.51-2.28c0.94-0.87,1.77-1.93,2.32-3.14l-16.36-1.69
        C901.49,1999.74,902.3,2001.28,903.38,2002.47z"/>
    </g>
    <g id="mass-testing_1_">
      <path style="fill:#044A6D;" d="M38.29,1001.53L3.1,988.87l41.27-7.91l-0.06,2.7l-33.11,5.76l33.7,12.27l-34.25,10.72l32.8,7.26
        l-0.07,2.76l-40.84-9.91L38.29,1001.53z"/>
      <path style="fill:#044A6D;" d="M24.65,957.12c0.25-0.17,0.75-0.5,1.06-0.66l-3.84-0.1l0.16-2.76l23.86,1.4l-0.16,2.76l-3.89-0.35
        c0.29,0.2,0.81,0.59,1.04,0.78c2.27,2.3,3.6,5.32,3.41,8.56c-0.19,3.24-1.86,6.09-4.38,8.1c-2.46,1.96-5.77,3.03-9.31,2.82
        c-3.54-0.21-6.7-1.66-8.91-3.89c-2.27-2.3-3.6-5.32-3.41-8.56C20.46,961.99,22.13,959.14,24.65,957.12z M23.03,965.38
        c-0.14,2.46,0.87,4.74,2.57,6.47c1.76,1.73,4.28,2.9,7.16,3.06c2.88,0.17,5.51-0.7,7.47-2.21c1.89-1.51,3.16-3.66,3.31-6.12
        c0.14-2.4-0.74-4.79-2.45-6.52c-1.76-1.73-4.4-2.84-7.28-3.01c-2.88-0.17-5.69,0.57-7.64,2.14
        C24.26,960.7,23.17,962.99,23.03,965.38z"/>
      <path style="fill:#044A6D;" d="M27.91,946.72c3.29,0.27,4.28-2.3,5.23-5.78c0.96-3.71,2.81-7.84,8.31-7.4
        c3.17,0.26,6.95,3.4,6.45,9.56c-0.38,4.61-3.54,7.97-7.12,8.34l-0.9-2.24c2.46-0.64,5.22-2.71,5.56-6.84
        c0.13-1.56-0.66-4.45-2.68-5.52c-0.86-0.49-2.66-0.64-3.65-0.36c-1.73,0.52-2.61,2.44-3.5,5.2c-1.14,3.58-3.06,7.94-7.9,7.55
        c-3.53-0.29-6.06-3.2-5.68-7.87c0.26-3.23,1.97-5.74,4.43-7.11l1.46,2.05c-1.72,1.18-3.42,2.73-3.6,4.95
        C24.06,944.42,25.82,946.55,27.91,946.72z"/>
      <path style="fill:#044A6D;" d="M29.64,926.45c3.29,0.33,4.33-2.22,5.34-5.68c1.04-3.7,2.96-7.79,8.46-7.23
        c3.17,0.32,6.89,3.53,6.26,9.69c-0.47,4.6-3.7,7.9-7.29,8.19l-0.86-2.26c2.47-0.6,5.27-2.61,5.69-6.73
        c0.16-1.55-0.57-4.46-2.57-5.57c-0.85-0.51-2.65-0.69-3.64-0.43c-1.74,0.49-2.66,2.39-3.6,5.13c-1.21,3.56-3.21,7.88-8.05,7.39
        c-3.52-0.36-6-3.32-5.53-7.98c0.33-3.23,2.09-5.7,4.57-7.02l1.42,2.08c-1.75,1.15-3.47,2.67-3.69,4.88
        C25.83,924.07,27.55,926.23,29.64,926.45z"/>
      <path style="fill:#044A6D;" d="M34.56,887.99l1.45-10.23l2.74,0.39l-1.45,10.23L34.56,887.99z"/>
      <path style="fill:#044A6D;" d="M60.45,840.34l-0.49,2.72l-36.22-6.56l-1.71,9.45l-2.66-0.48l1.71-9.45l0.49-2.72l1.71-9.45
        l2.66,0.48l-1.71,9.45L60.45,840.34z"/>
      <path style="fill:#044A6D;" d="M64.85,817.67c0.37,2.1,0.22,4.33-0.18,6.28c-0.65,3.18-2.65,5.77-5.43,7.41
        c-2.7,1.53-6.14,2.18-9.62,1.47c-3.47-0.71-6.38-2.65-8.27-5.12c-1.92-2.6-2.8-5.78-2.16-8.96c0.55-2.71,2.12-4.96,4.29-6.6
        c2.1-1.59,4.86-2.5,7.82-2.57c0.48,0.04,0.98,0.02,1.51,0.12c0.13-0.03,0.19-0.02,0.31,0l-0.35,1.71l-0.2,1l-3.55,17.42
        c0.4,0.14,0.75,0.22,1.16,0.3c2.82,0.58,5.56,0.09,7.72-1.18c2.08-1.17,3.58-3.13,4.07-5.54c0.34-1.65,0.47-3.52,0.22-5.29
        c-0.22-1.33-0.71-2.54-1.51-3.43l2.03-1.85C63.92,814.11,64.55,815.83,64.85,817.67z M45.12,814.38
        c-1.59,1.21-2.8,2.92-3.21,4.92c-0.49,2.41,0.18,4.82,1.63,6.71c0.77,1.02,1.75,1.95,2.9,2.61l3.29-16.12
        C48.02,812.77,46.42,813.42,45.12,814.38z"/>
      <path style="fill:#044A6D;" d="M49.35,801.51c3.22,0.73,4.57-1.67,6-4.97c1.49-3.54,3.9-7.36,9.28-6.13
        c3.1,0.71,6.4,4.35,5.02,10.39c-1.03,4.51-4.64,7.38-8.24,7.23l-0.57-2.35c2.53-0.28,5.55-1.93,6.48-5.97
        c0.35-1.52-0.02-4.5-1.87-5.85c-0.78-0.61-2.54-1.01-3.56-0.87c-1.79,0.27-2.93,2.04-4.2,4.65c-1.63,3.38-4.16,7.43-8.9,6.34
        c-3.45-0.79-5.55-4.04-4.5-8.6c0.72-3.16,2.77-5.4,5.4-6.4l1.15,2.23c-1.87,0.93-3.77,2.22-4.27,4.38
        C45.86,798.69,47.3,801.05,49.35,801.51z"/>
      <path style="fill:#044A6D;" d="M48.74,780.31l-11.37-2.8l0.66-2.68l11.37,2.8l0.95-3.85l2.68,0.66l-0.95,3.85l20.58,5.07
        L72,786.05l-20.58-5.07l-0.95,3.85l-2.68-0.66L48.74,780.31z"/>
      <path style="fill:#044A6D;" d="M43.28,763.6c1.28,0.33,2.06,1.64,1.73,2.92c-0.33,1.28-1.65,2.06-2.93,1.73
        c-1.28-0.33-2.06-1.65-1.73-2.93C40.68,764.05,42,763.27,43.28,763.6z M75.23,772.9l-0.69,2.68L51.4,769.6l0.69-2.67L75.23,772.9
        z"/>
      <path style="fill:#044A6D;" d="M56.95,760.77c-1.93-2.27-3.05-5.01-1.84-9.41c1.19-4.34,4.85-7.26,11.05-5.56l15.23,4.17
        l-0.73,2.66l-14.71-4.03c-4.92-1.35-7.63,0.59-8.46,3.6c-1.38,5.04,1.6,8.72,4.5,9.95l14.94,4.09l-0.73,2.66l-23.05-6.32
        l0.73-2.66L56.95,760.77z"/>
      <path style="fill:#044A6D;" d="M77.33,720.54l11.44,3.44c3.34,1,5.69,2.97,7.05,5.32c1.54,2.59,1.86,5.7,1,8.58
        c-0.8,2.65-1.84,4.46-3.04,5.73c-1.27,1.31-2.56,2.05-3.91,2.78l-1.27-2.45c1.12-0.6,2.19-1.23,3.16-2.19
        c0.94-1.03,1.73-2.43,2.41-4.67c0.66-2.18,0.46-4.44-0.72-6.36c-1.02-1.81-2.83-3.3-5.48-4.09l-3.51-1.05
        c0.22,0.32,0.4,0.56,0.56,0.86c1.64,2.69,2.32,5.84,1.3,9.23c-1,3.34-3.11,5.77-5.91,7.13c-2.76,1.24-6.21,1.46-9.89,0.35
        c-3.68-1.11-6.31-3.21-7.92-5.77c-1.69-2.71-2.1-5.96-1.13-9.18c0.95-3.16,3.39-5.63,6.37-6.99c0.22-0.12,0.52-0.28,0.81-0.38
        l-3.28-0.99l0.8-2.64L77.33,720.54z M64.99,735.95c1.22,2,3.36,3.64,6.35,4.54c3.05,0.92,5.78,0.8,7.9-0.2
        c2.12-0.99,3.68-2.85,4.46-5.43c0.8-2.64,0.53-5.11-0.69-7.1c-1.22-2-3.36-3.64-6.46-4.58c-2.99-0.9-5.7-0.65-7.82,0.35
        c-2.21,1.09-3.86,3.04-4.59,5.46C63.41,731.4,63.73,733.88,64.99,735.95z"/>
    </g>
    <g id="off-shore_1_">
      <path style="fill:#044A6D;" d="M455.24,183.48c5.06,1.13,9.76,4.17,12.97,8.88c3.22,4.71,4.35,10.19,3.55,15.32
        c-0.79,5.12-3.49,9.8-8,12.88c-8.93,6.1-21.36,3.32-27.83-6.15c-3.22-4.71-4.34-10.19-3.55-15.32c0.79-5.12,3.52-9.75,7.98-12.8
        C444.87,183.22,450.18,182.36,455.24,183.48z M441.92,188.58c-3.82,2.61-6.12,6.58-6.82,10.99c-0.7,4.4,0.3,9.17,3.11,13.29
        c5.59,8.18,16.32,10.59,23.96,5.38c3.82-2.61,6.17-6.61,6.87-11.02c0.7-4.4-0.3-9.17-3.11-13.29c-2.81-4.12-6.89-6.79-11.25-7.74
        C450.33,185.24,445.74,185.97,441.92,188.58z"/>
      <path style="fill:#044A6D;" d="M474.49,186.51l-3.13,2.01l-1.49-2.32l3.13-2.01l-7.63-11.87c-0.23-0.35-0.4-0.74-0.58-1.13
        c-0.69-1.63-0.65-3.01-0.16-4.25c0.55-1.14,1.45-2.07,2.56-2.79c0.35-0.23,0.71-0.45,1.09-0.63l1.53,2.37
        c-0.39,0.18-0.77,0.35-1.13,0.58c-0.66,0.42-1.25,0.95-1.48,1.59c-0.24,0.51-0.25,1.16,0.08,2.02c0.13,0.2,0.24,0.49,0.4,0.74
        l7.63,11.87l3.64-2.34l1.49,2.32l-3.64,2.34l11.52,17.93l-2.32,1.49L474.49,186.51z"/>
      <path style="fill:#044A6D;" d="M485.91,179.23l-3.16,1.97l-1.46-2.34l3.16-1.97l-7.47-11.97c-0.22-0.36-0.39-0.75-0.57-1.13
        c-0.67-1.64-0.61-3.02-0.11-4.25c0.57-1.13,1.48-2.06,2.6-2.75c0.36-0.22,0.71-0.45,1.1-0.62l1.49,2.4
        c-0.39,0.17-0.78,0.34-1.13,0.57c-0.66,0.41-1.26,0.93-1.5,1.57c-0.25,0.51-0.27,1.16,0.06,2.02c0.13,0.2,0.24,0.49,0.39,0.75
        l7.47,11.97l3.67-2.29l1.46,2.34l-3.67,2.29l11.29,18.08l-2.34,1.46L485.91,179.23z"/>
      <path style="fill:#044A6D;" d="M515.01,165.4l8.95-5.15l1.38,2.39l-8.95,5.15L515.01,165.4z"/>
      <path style="fill:#044A6D;" d="M561.56,124.23c-2.34-2.17-6.36-3.94-10.15-1.99c-4.27,2.21-6,7.22-4.24,10.64
        c2.59,5.01,7.49,4.04,11.35,3.66c5.07-0.52,11.96-1.58,15.7,5.67c2.92,5.65-0.26,12.97-5.7,15.79
        c-7.09,3.67-12.85-0.93-14.87-3.67l1.96-1.82c2.38,3.57,7.3,5.49,11.62,3.25c5.6-2.89,6.54-8.65,4.64-12.33
        c-3.36-6.51-9.4-4.47-13.37-4.04c-5.26,0.55-10.63,0.36-13.44-5.08c-3.17-6.13,0.7-11.85,5.18-14.17
        c5.17-2.67,9.49-0.99,12.89,2.19L561.56,124.23z"/>
      <path style="fill:#044A6D;" d="M577.35,128.97c0.52-2.79,2.2-5.66,5.23-7.13c4.27-2.06,9.42-1.22,11.79,3.71l7.03,14.54
        l-2.49,1.2l-7.03-14.54c-1.59-3.3-5.1-4.47-8.18-2.98c-3.03,1.46-5.78,6.39-3.9,10.29l6.45,13.35l-2.49,1.2l-18.89-39.09
        l2.49-1.2L577.35,128.97z"/>
      <path style="fill:#044A6D;" d="M606.12,110.46c3.06-1.39,6.4-1.45,9.41-0.17c2.72,1.21,5.16,3.53,6.77,7.09
        c1.61,3.56,1.88,6.93,0.96,9.85c-0.95,3-3.16,5.39-6.39,6.85c-3.23,1.46-6.45,1.47-9.28,0.18c-2.8-1.24-5.15-3.67-6.74-7.17
        c-1.59-3.5-1.78-6.84-0.86-9.76C600.92,114.26,603.11,111.82,606.12,110.46z M602.62,118.17c-0.7,2.23-0.54,4.92,0.75,7.77
        c1.29,2.85,3.17,4.82,5.34,5.82c2.14,0.94,4.56,0.9,7.02-0.21c2.46-1.12,4.14-2.93,4.9-5.19c0.7-2.23,0.46-4.95-0.85-7.85
        c-1.31-2.9-3.2-4.75-5.34-5.69c-2.3-1-4.83-0.91-7.18,0.15C604.96,114.02,603.36,115.86,602.62,118.17z"/>
      <path style="fill:#044A6D;" d="M623.85,107.62c0.29-2.22,1.59-4.53,4.13-5.62c2.04-0.87,4.16-0.67,5.97,0.52l-0.6,2.41
        c-1.13-0.82-2.7-1.72-4.63-0.9c-2.71,1.16-3.55,4.98-2.67,8.72l5.78,13.53l-2.54,1.08l-9.42-22.03l2.54-1.08L623.85,107.62z"/>
      <path style="fill:#044A6D;" d="M658.81,115.04c-1.54,1.47-3.49,2.58-5.33,3.33c-3.01,1.22-6.27,0.99-9.18-0.43
        c-2.77-1.4-5.21-3.91-6.54-7.2c-1.33-3.28-1.32-6.79-0.31-9.72c1.1-3.04,3.27-5.53,6.27-6.75c2.56-1.04,5.3-0.98,7.87-0.08
        c2.49,0.87,4.77,2.67,6.47,5.09c0.24,0.42,0.53,0.82,0.73,1.32c0.1,0.09,0.12,0.14,0.17,0.26l-1.61,0.65l-0.95,0.38l-16.47,6.67
        c0.1,0.41,0.24,0.75,0.4,1.13c1.08,2.67,3,4.68,5.25,5.78c2.12,1.08,4.59,1.25,6.87,0.33c1.56-0.63,3.19-1.55,4.53-2.74
        c0.99-0.92,1.72-1.99,2.03-3.15l2.67,0.67C661.26,112.3,660.18,113.78,658.81,115.04z M650.64,96.78
        c-1.89-0.66-3.98-0.72-5.88,0.05c-2.28,0.93-3.91,2.82-4.68,5.07c-0.42,1.21-0.66,2.53-0.57,3.86l15.25-6.18
        C653.59,98.31,652.15,97.33,650.64,96.78z"/>
    </g>
    <g id="title">
      <path style="fill:#044A6D;" d="M1270.1,43.12c-0.33-3.17-2.18-7.16-6.32-8.19c-4.66-1.16-9.27,1.47-10.2,5.2
        c-1.36,5.48,2.97,7.98,6.12,10.24c4.16,2.95,10.03,6.69,8.07,14.62c-1.53,6.18-8.75,9.58-14.69,8.11
        c-7.75-1.92-9.05-9.17-8.76-12.56l2.68-0.08c-0.56,4.25,1.87,8.94,6.59,10.11c6.12,1.52,10.63-2.19,11.62-6.21
        c1.76-7.11-4.12-9.56-7.39-11.86c-4.32-3.05-8.23-6.74-6.75-12.69c1.66-6.7,8.34-8.45,13.24-7.23c5.65,1.4,7.79,5.52,8.25,10.15
        L1270.1,43.12z"/>
      <path style="fill:#044A6D;" d="M1292.47,76.42l21.17-30.84l-2.8,41.93l-2.6-0.75l2.81-33.49l-20.41,29.49l-1.69-35.85
        l-15.34,29.89l-2.65-0.76l19.94-37L1292.47,76.42z"/>
      <path style="fill:#044A6D;" d="M1322.7,68.41l14.73,4.78l-0.76,2.34l-14.74-4.78l-5.26,16.22l16.73,5.43l-0.74,2.29l-16.73-5.43
        l-2.63-0.85l0.74-2.28l5.26-16.22l0.76-2.34l4.65-14.34l0.76-2.34l2.63,0.85l15.76,5.11l-0.76,2.34l-15.76-5.11L1322.7,68.41z"/>
    </g>
    <g>
      <path style="fill:#044A6D;" d="M306.56,1711.92c2.15-2.5,0.66-4.82-1.59-7.63c-2.44-2.97-4.69-6.89-1.09-11.07
        c2.08-2.41,6.84-3.62,11.53,0.41c3.5,3.01,4.35,7.55,2.54,10.67l-2.34-0.59c0.93-2.37,0.88-5.81-2.26-8.52
        c-1.18-1.02-3.99-2.09-6.04-1.08c-0.91,0.41-2.08,1.77-2.43,2.74c-0.6,1.71,0.44,3.54,2.14,5.89c2.23,3.03,4.62,7.15,1.45,10.83
        c-2.31,2.68-6.16,3.02-9.71-0.04c-2.46-2.11-3.48-4.98-3.14-7.77l2.51,0.03c-0.06,2.09,0.2,4.37,1.88,5.82
        C302.44,1713.68,305.19,1713.51,306.56,1711.92z"/>
      <path style="fill:#044A6D;" d="M408.62,1829.52l-11.96-8.66l1.44-1.99l11.96,8.66l8.87-12.26l-10.85-7.85l1.41-1.95l10.85,7.85
        l11.44-15.81l2.24,1.62l-21.72,30.01l-1.44,1.99L408.62,1829.52z"/>
      <path style="fill:#044A6D;" d="M394,1811.11c0.8-1.05,2.31-1.27,3.36-0.47c1.05,0.8,1.27,2.31,0.47,3.36
        c-0.8,1.05-2.31,1.27-3.36,0.47C393.41,1813.68,393.2,1812.17,394,1811.11z M414.86,1785.18l2.2,1.66l-14.4,19.07l-2.2-1.66
        L414.86,1785.18z"/>
      <path style="fill:#044A6D;" d="M396.84,1796.92c-2.08,0.81-4.74,0.79-6.91-0.91c-1.75-1.37-2.59-3.32-2.43-5.48l2.4-0.64
        c-0.17,1.39-0.21,3.19,1.45,4.48c2.32,1.81,6.07,0.71,8.92-1.86l9.06-11.59l2.18,1.7l-14.75,18.87l-2.18-1.7L396.84,1796.92z"/>
      <path style="fill:#044A6D;" d="M383.84,1784.45c2.1-2.55,0.56-4.83-1.76-7.59c-2.5-2.91-4.84-6.78-1.33-11.05
        c2.02-2.46,6.76-3.77,11.53,0.16c3.57,2.94,4.52,7.45,2.78,10.61l-2.36-0.54c0.88-2.39,0.76-5.83-2.44-8.47
        c-1.21-0.99-4.03-2-6.06-0.95c-0.9,0.43-2.04,1.82-2.38,2.79c-0.56,1.72,0.51,3.53,2.27,5.84c2.29,2.98,4.78,7.05,1.69,10.8
        c-2.25,2.73-6.09,3.15-9.71,0.17c-2.5-2.06-3.59-4.9-3.31-7.7l2.51-0.03c-0.01,2.09,0.3,4.37,2.01,5.78
        C379.75,1786.3,382.5,1786.07,383.84,1784.45z"/>
      <path style="fill:#044A6D;" d="M364.56,1775.41l-7.65,8.86l-2.09-1.8l7.65-8.86l-3-2.59l1.8-2.09l3,2.59l13.85-16.05l2.09,1.8
        l-13.85,16.05l3,2.59l-1.8,2.09L364.56,1775.41z"/>
      <path style="fill:#044A6D;" d="M340.95,1747.34l13.02-13.47l1.99,1.92l-27.33,28.28l-0.13-0.12c-8.18-6.4-13.34-13.15-6.29-20.45
        c3.71-3.84,10.2-4.25,15.3,0.51l0.93-25.15l2.59,2.5l-1.36,24.74L340.95,1747.34z M339.32,1749.02l-3.07-2.96
        c-5.44-5.26-10.37-3.09-12.46-0.93c-4.67,4.83-2.21,10.06,4.38,15.43L339.32,1749.02z"/>
      <path style="fill:#044A6D;" d="M327.02,1707.25c1.95,0.87,3.72,2.24,5.1,3.66c2.27,2.31,3.3,5.42,3.1,8.65
        c-0.24,3.1-1.63,6.31-4.16,8.79s-5.77,3.81-8.87,3.99c-3.23,0.14-6.36-0.91-8.63-3.22c-1.93-1.97-2.93-4.53-3.07-7.25
        c-0.15-2.63,0.65-5.43,2.24-7.92c0.3-0.38,0.56-0.8,0.95-1.18c0.04-0.13,0.09-0.17,0.17-0.25l1.22,1.24l0.72,0.73l12.45,12.68
        c0.34-0.25,0.6-0.5,0.9-0.8c2.06-2.02,3.18-4.56,3.34-7.06c0.19-2.38-0.59-4.72-2.32-6.48c-1.18-1.2-2.65-2.36-4.26-3.14
        c-1.23-0.56-2.5-0.83-3.69-0.67l-0.4-2.72C323.55,1706.03,325.33,1706.47,327.02,1707.25z M313.25,1721.77
        c0.11,2,0.85,3.96,2.28,5.41c1.72,1.76,4.1,2.54,6.47,2.4c1.27-0.07,2.59-0.36,3.79-0.94l-11.53-11.74
        C313.54,1718.46,313.18,1720.16,313.25,1721.77z"/>
      <path style="fill:#044A6D;" d="M297.87,1693.1l-9.35,8.64l-1.87-2.03l3-2.77c-0.21,0.03-0.43,0.07-0.68,0.06
        c-3.19,0.09-6.46-1.15-8.66-3.53c-2.2-2.38-3.05-5.69-2.71-8.86c0.29-3.13,1.82-6.26,4.42-8.66c2.6-2.4,5.84-3.68,8.99-3.72
        c3.19-0.09,6.29,1.06,8.49,3.44c2.24,2.43,3.12,5.86,2.83,9.08c-0.01,0.17-0.06,0.38-0.11,0.59l10.59-9.78l1.87,2.03
        L297.87,1693.1z M291.07,1674.94c-2.47,0.07-5.06,1.07-7.17,3.03c-2.07,1.91-3.28,4.42-3.54,6.87c-0.27,2.45,0.45,4.82,2.08,6.58
        c1.67,1.81,3.97,2.71,6.44,2.64c2.47-0.07,5.06-1.07,7.13-2.99c2.12-1.96,3.7-4.4,3.97-6.85c0.22-2.41-0.83-4.88-2.5-6.68
        C295.84,1675.77,293.49,1674.91,291.07,1674.94z"/>
      <path style="fill:#044A6D;" d="M262.39,1674.44c-2.21-2.53-3.23-5.71-2.87-8.96c0.37-2.95,1.9-5.95,4.84-8.52
        c2.94-2.56,6.09-3.8,9.15-3.76c3.15,0.05,6.07,1.48,8.4,4.15c2.33,2.67,3.26,5.76,2.84,8.83c-0.38,3.04-2.03,5.99-4.92,8.51
        c-2.9,2.52-6.04,3.67-9.1,3.63C267.53,1678.32,264.56,1676.93,262.39,1674.44z M270.78,1675.57c2.34,0.03,4.87-0.9,7.22-2.95
        c2.35-2.05,3.71-4.43,4.04-6.79c0.29-2.32-0.45-4.63-2.22-6.66c-1.78-2.04-4-3.12-6.38-3.2c-2.34-0.03-4.88,0.98-7.27,3.07
        c-2.4,2.09-3.62,4.43-3.91,6.75c-0.3,2.49,0.52,4.89,2.21,6.83C266.13,1674.52,268.36,1675.53,270.78,1675.57z"/>
      <path style="fill:#044A6D;" d="M254.24,1658.63c-2.97,0.27-5.85-0.38-8.76-3.9c-2.87-3.47-3.15-8.15,1.8-12.24l12.17-10.06
        l1.76,2.13l-11.76,9.72c-3.93,3.25-3.91,6.58-1.92,8.99c3.33,4.03,8.05,3.71,10.72,2.04l11.94-9.87l1.76,2.13l-18.42,15.22
        l-1.76-2.13L254.24,1658.63z"/>
      <path style="fill:#044A6D;" d="M233.42,1618.69l9.4-7.37l1.7,2.17l-2.6,2.04c0.25,0.03,0.47,0.01,0.72,0.04
        c3.19,0.17,6.34,1.66,8.34,4.22c2,2.55,2.58,5.91,1.99,9.05c-0.55,3.1-2.32,6.09-5.11,8.28c-2.79,2.19-6.12,3.2-9.26,2.99
        c-3.19-0.17-6.18-1.56-8.22-4.16c-2-2.55-2.61-6.05-2.05-9.23c0.07-0.21,0.14-0.41,0.17-0.66l-14.72,11.55l-1.7-2.17
        L233.42,1618.69z M238.77,1637.38c2.47,0.13,5.13-0.66,7.39-2.44c2.27-1.78,3.67-4.18,4.13-6.6c0.42-2.39-0.06-4.84-1.58-6.78
        c-1.48-1.89-3.75-2.93-6.17-3.1c-2.5-0.17-5.16,0.62-7.43,2.39c-2.27,1.78-4,4.05-4.42,6.52c-0.46,2.42,0.38,4.97,1.87,6.85
        C234.09,1636.17,236.36,1637.22,238.77,1637.38z"/>
      <path style="fill:#044A6D;" d="M230.01,1594.22c1.81,1.12,3.37,2.73,4.56,4.32c1.93,2.6,2.53,5.82,1.89,8.99
        c-0.66,3.04-2.48,6.03-5.32,8.14c-2.84,2.11-6.23,2.99-9.33,2.74c-3.22-0.3-6.18-1.77-8.11-4.37c-1.65-2.22-2.28-4.89-2.06-7.6
        c0.22-2.63,1.38-5.29,3.3-7.54c0.35-0.33,0.66-0.72,1.1-1.04c0.06-0.12,0.11-0.16,0.21-0.23l1.04,1.4l0.61,0.82l10.6,14.27
        c0.37-0.2,0.66-0.42,1-0.67c2.31-1.72,3.78-4.08,4.27-6.54c0.51-2.33,0.06-4.76-1.41-6.73c-1-1.35-2.31-2.7-3.79-3.69
        c-1.14-0.73-2.36-1.16-3.56-1.17l-0.02-2.75C226.74,1592.53,228.44,1593.21,230.01,1594.22z M214.39,1606.72
        c-0.16,1.99,0.31,4.04,1.52,5.68c1.47,1.98,3.71,3.08,6.09,3.26c1.27,0.1,2.62,0,3.88-0.41l-9.81-13.21
        C215.13,1603.48,214.54,1605.11,214.39,1606.72z"/>
      <path style="fill:#044A6D;" d="M206.75,1596.75c-2.23,0.19-4.77-0.59-6.37-2.84c-1.29-1.81-1.54-3.92-0.77-5.94l2.49,0.07
        c-0.56,1.28-1.11,3,0.11,4.71c1.71,2.4,5.62,2.41,9.09,0.75l11.98-8.54l1.6,2.25l-19.52,13.9l-1.6-2.25L206.75,1596.75z"/>
      <path style="fill:#044A6D;" d="M197.85,1581.15c2.72-1.87,1.87-4.49,0.4-7.78c-1.6-3.49-2.78-7.85,1.77-10.98
        c2.62-1.8,7.54-1.76,11.04,3.34c2.62,3.81,2.29,8.41-0.26,10.96l-2.12-1.17c1.5-2.05,2.34-5.4-0.01-8.81
        c-0.88-1.29-3.32-3.03-5.57-2.59c-0.98,0.16-2.46,1.18-3.05,2.03c-1.01,1.5-0.48,3.54,0.57,6.24c1.38,3.49,2.65,8.09-1.36,10.84
        c-2.92,2.01-6.73,1.35-9.38-2.51c-1.84-2.67-2.1-5.7-1.05-8.31l2.42,0.67c-0.59,2.01-0.92,4.28,0.34,6.11
        C193.41,1581.8,196.11,1582.34,197.85,1581.15z"/>
    </g>
    <g id="border-control_1_">
      <path style="fill:#044A6D;" d="M1952.63,1291.92l37.11,12.08l0.23,0.07c-1.82,10.65-6.25,18.04-14.93,15.22
        c-4.91-1.6-5.75-4.84-6.38-7.76c-1.84,4.89-6.46,7.81-12.91,5.71c-7.59-2.47-8.22-9.68-5.43-18.25l1.45-4.45L1952.63,1291.92z
         M1954.29,1295.37l-2.03,6.22c-3.07,9.42,1.36,12.31,4.16,13.22c8.39,2.73,10.68-3.53,11.95-7.41l2.19-6.74L1954.29,1295.37z
         M1972.73,1301.37l-1.71,5.25c-1.73,5.31,0.69,8.75,4.8,10.09c7.94,2.58,10.12-5.67,11.36-10.64L1972.73,1301.37z"/>
      <path style="fill:#044A6D;" d="M1963.93,1336.88c-1.12,3.17-3.39,5.61-6.4,6.9c-2.76,1.12-6.12,1.27-9.8-0.04
        c-3.68-1.3-6.3-3.44-7.76-6.13c-1.5-2.76-1.69-6.02-0.5-9.36c1.19-3.34,3.41-5.67,6.3-6.81c2.83-1.16,6.21-1.17,9.84,0.11
        c3.62,1.28,6.16,3.46,7.63,6.15C1964.79,1330.49,1965.03,1333.76,1963.93,1336.88z M1960.79,1329.01
        c-1.12-2.05-3.17-3.8-6.12-4.85c-2.94-1.04-5.68-1.06-7.9-0.19c-2.16,0.89-3.81,2.66-4.72,5.21c-0.9,2.55-0.76,5.02,0.34,7.13
        c1.12,2.05,3.25,3.77,6.25,4.83c3,1.06,5.64,0.98,7.8,0.09c2.32-0.96,4.01-2.85,4.87-5.28
        C1962.17,1333.58,1961.95,1331.14,1960.79,1329.01z"/>
      <path style="fill:#044A6D;" d="M1953.68,1351.59c1.39,1.75,2.16,4.29,1.19,6.87c-0.79,2.08-2.4,3.46-4.51,3.94l-1.32-2.1
        c1.38-0.25,3.11-0.75,3.85-2.72c1.04-2.75-1.13-6.01-4.43-7.97l-13.76-5.21l0.98-2.58l22.41,8.48l-0.98,2.58L1953.68,1351.59z"/>
      <path style="fill:#044A6D;" d="M1932.13,1383.63l-11.08-4.48l1.03-2.56l3.06,1.24c-0.1-0.23-0.22-0.41-0.32-0.65
        c-1.44-2.85-1.72-6.33-0.5-9.33c1.21-3.01,3.84-5.18,6.86-6.23c2.96-1.07,6.44-1.03,9.72,0.3c3.28,1.33,5.82,3.71,7.2,6.54
        c1.44,2.85,1.73,6.14,0.49,9.2c-1.21,3.01-3.94,5.27-6.98,6.38c-0.21,0.04-0.42,0.09-0.66,0.19l19.48,8.02l-1.03,2.56
        L1932.13,1383.63z M1945.67,1369.67c-1.12-2.2-3.13-4.11-5.8-5.19c-2.67-1.08-5.45-1.1-7.78-0.3c-2.28,0.83-4.17,2.46-5.09,4.74
        c-0.9,2.23-0.67,4.71,0.39,6.89c1.1,2.25,3.11,4.17,5.78,5.25c2.67,1.08,5.5,1.45,7.86,0.58c2.33-0.81,4.11-2.81,5.01-5.03
        C1946.96,1374.34,1946.73,1371.85,1945.67,1369.67z"/>
      <path style="fill:#044A6D;" d="M1912.61,1398.78c0.07-2.13,0.69-4.29,1.48-6.1c1.29-2.97,3.79-5.1,6.85-6.12
        c2.96-0.94,6.46-0.86,9.71,0.56c3.25,1.41,5.7,3.92,7.03,6.72c1.34,2.94,1.54,6.24,0.25,9.21c-1.1,2.53-3.1,4.41-5.56,5.57
        c-2.39,1.12-5.27,1.44-8.18,0.89c-0.47-0.14-0.95-0.22-1.45-0.43c-0.13,0.01-0.19-0.02-0.3-0.06l0.7-1.6l0.41-0.94l7.09-16.3
        c-0.36-0.22-0.69-0.37-1.08-0.53c-2.64-1.15-5.42-1.24-7.8-0.44c-2.27,0.71-4.15,2.32-5.13,4.58c-0.67,1.54-1.19,3.34-1.31,5.13
        c-0.06,1.35,0.17,2.63,0.76,3.67l-2.37,1.39C1912.78,1402.45,1912.53,1400.64,1912.61,1398.78z M1931.24,1406.09
        c1.81-0.85,3.35-2.28,4.16-4.15c0.98-2.26,0.82-4.75-0.21-6.9c-0.55-1.15-1.3-2.27-2.29-3.16l-6.56,15.09
        C1928.06,1407.07,1929.77,1406.76,1931.24,1406.09z"/>
      <path style="fill:#044A6D;" d="M1926.38,1417.7c1.27,1.84,1.86,4.43,0.7,6.94c-0.93,2.02-2.64,3.28-4.77,3.62l-1.17-2.19
        c1.39-0.15,3.15-0.53,4.03-2.44c1.23-2.67-0.7-6.08-3.86-8.26l-13.36-6.16l1.16-2.51l21.76,10.03l-1.16,2.51L1926.38,1417.7z"/>
      <path style="fill:#044A6D;" d="M1880.77,1466.17c0.07-2.74,0.74-5.57,2.1-8.18c2.52-4.71,6.85-7.94,11.81-9.28
        c4.91-1.37,10.46-0.84,15.52,1.79c5.06,2.64,8.67,6.89,10.36,11.7c1.72,4.89,1.57,10.23-0.9,14.97c-1.19,2.29-2.84,4.28-4.72,5.8
        c-2.04,1.58-4.34,2.75-6.83,3.48l-0.73-2.62c2.11-0.66,4.11-1.65,5.82-2.99c1.63-1.32,2.98-2.98,4-4.95
        c2.11-4.05,2.24-8.58,0.75-12.74c-1.49-4.16-4.62-7.89-9.04-10.2c-4.42-2.3-9.27-2.73-13.53-1.57
        c-4.24,1.11-7.91,3.87-10.07,7.89c-1.17,2.24-1.72,4.59-1.81,6.98c-0.02,2.5,0.53,4.95,1.57,7.25l-2.54,1.11
        C1881.35,1471.96,1880.75,1469.08,1880.77,1466.17z"/>
      <path style="fill:#044A6D;" d="M1891.4,1497c-1.65,2.93-4.3,4.95-7.48,5.71c-2.91,0.64-6.25,0.21-9.65-1.7
        c-3.4-1.91-5.62-4.46-6.61-7.36c-1.01-2.98-0.64-6.21,1.09-9.3c1.73-3.09,4.33-5.01,7.37-5.64c2.99-0.66,6.32-0.1,9.67,1.78
        c3.35,1.88,5.48,4.46,6.47,7.35C1893.34,1490.86,1893.02,1494.12,1891.4,1497z M1889.65,1488.72c-0.75-2.21-2.48-4.28-5.2-5.81
        c-2.72-1.53-5.41-2.01-7.75-1.53c-2.28,0.51-4.21,1.98-5.53,4.33c-1.32,2.36-1.6,4.82-0.88,7.08c0.75,2.21,2.56,4.26,5.34,5.82
        c2.77,1.56,5.39,1.93,7.67,1.42c2.45-0.56,4.43-2.13,5.69-4.38C1890.23,1493.45,1890.43,1491.01,1889.65,1488.72z"/>
      <path style="fill:#044A6D;" d="M1879.27,1509.96c1.26,2.7,1.61,5.63-0.72,9.56c-2.3,3.87-6.6,5.71-12.13,2.43l-13.58-8.06
        l1.41-2.38l13.11,7.79c4.39,2.61,7.51,1.46,9.11-1.23c2.67-4.49,0.77-8.83-1.7-10.78l-13.32-7.91l1.41-2.38l20.55,12.2
        l-1.41,2.38L1879.27,1509.96z"/>
      <path style="fill:#044A6D;" d="M1869.36,1532.66l9.96,6.16l-1.45,2.35l-9.96-6.16l-2.09,3.37l-2.35-1.45l2.09-3.37l-18.02-11.16
        l1.45-2.35l18.02,11.16l2.09-3.37l2.35,1.45L1869.36,1532.66z"/>
      <path style="fill:#044A6D;" d="M1858.87,1542.56c1,2,1.23,4.64-0.26,6.97c-1.2,1.87-3.06,2.89-5.23,2.93l-0.86-2.33
        c1.4,0.04,3.2-0.09,4.33-1.86c1.59-2.48,0.14-6.11-2.69-8.71l-12.39-7.94l1.49-2.33l20.17,12.93l-1.49,2.33L1858.87,1542.56z"/>
      <path style="fill:#044A6D;" d="M1850.28,1564.27c-1.86,2.8-4.66,4.61-7.89,5.13c-2.95,0.42-6.25-0.27-9.49-2.43
        c-3.25-2.16-5.26-4.88-6.03-7.84c-0.78-3.05-0.17-6.25,1.8-9.19c1.97-2.95,4.7-4.66,7.78-5.06c3.03-0.43,6.31,0.38,9.51,2.51
        c3.2,2.13,5.13,4.86,5.9,7.83C1852.68,1558.3,1852.11,1561.53,1850.28,1564.27z M1849.17,1555.88c-0.58-2.26-2.15-4.46-4.75-6.19
        c-2.6-1.73-5.25-2.41-7.61-2.11c-2.32,0.33-4.35,1.65-5.85,3.9c-1.5,2.25-1.96,4.68-1.41,6.99c0.58,2.26,2.23,4.45,4.88,6.21
        c2.65,1.76,5.23,2.33,7.54,2c2.48-0.37,4.58-1.78,6.01-3.93C1849.38,1560.65,1849.77,1558.23,1849.17,1555.88z"/>
      <path style="fill:#044A6D;" d="M1819.71,1564.97l1.57-2.27l35.71,24.68l-1.57,2.27L1819.71,1564.97z"/>
    </g>
    <circle style="fill:none;" cx="1017.5" cy="1017.5" r="1014.96"/>
  </g>
</g>
          <g id="inner-circle">
  <g>
    <g>
      <path style="fill:#DCECEF;" d="M1017,1703.45c-92.8,0-182.82-18.17-267.54-54.01c-81.85-34.62-155.36-84.18-218.49-147.31
        c-63.13-63.13-112.7-136.64-147.31-218.49c-35.84-84.73-54.01-174.74-54.01-267.54s18.17-182.82,54.01-267.54
        c34.62-81.85,84.18-155.36,147.31-218.49c63.13-63.13,136.64-112.7,218.49-147.31c84.73-35.84,174.74-54.01,267.54-54.01
        s182.82,18.17,267.54,54.01c81.85,34.62,155.36,84.18,218.49,147.31c63.13,63.13,112.7,136.64,147.31,218.49
        c35.84,84.73,54.01,174.74,54.01,267.54s-18.17,182.82-54.01,267.54c-34.62,81.85-84.18,155.36-147.31,218.49
        c-63.13,63.13-136.64,112.7-218.49,147.31C1199.81,1685.28,1109.8,1703.45,1017,1703.45z"/>
      <path style="fill:#FFFFFF;" d="M1017,350.41c89.89,0,177.06,17.59,259.1,52.29c39.44,16.68,77.47,37.33,113.06,61.37
        c35.26,23.82,68.41,51.18,98.55,81.31c30.13,30.13,57.49,63.29,81.31,98.55c24.04,35.58,44.69,73.62,61.37,113.06
        c34.7,82.04,52.29,169.21,52.29,259.1s-17.59,177.06-52.29,259.1c-16.68,39.44-37.33,77.47-61.37,113.06
        c-23.82,35.26-51.18,68.41-81.31,98.55c-30.13,30.13-63.29,57.49-98.55,81.31c-35.58,24.04-73.62,44.69-113.06,61.37
        c-82.04,34.7-169.21,52.29-259.1,52.29s-177.06-17.59-259.1-52.29c-39.44-16.68-77.47-37.33-113.06-61.37
        c-35.26-23.82-68.41-51.18-98.55-81.31c-30.13-30.13-57.49-63.29-81.31-98.55c-24.04-35.58-44.69-73.62-61.37-113.06
        c-34.7-82.04-52.29-169.21-52.29-259.1s17.59-177.06,52.29-259.1c16.68-39.44,37.33-77.47,61.37-113.06
        c23.82-35.26,51.18-68.41,81.31-98.55c30.13-30.13,63.29-57.49,98.55-81.31c35.58-24.04,73.62-44.69,113.06-61.37
        C839.94,368.01,927.11,350.41,1017,350.41 M1017,307.06c-391.59,0-709.03,317.44-709.03,709.03s317.44,709.03,709.03,709.03
        s709.03-317.44,709.03-709.03S1408.59,307.06,1017,307.06L1017,307.06z"/>
    </g>
    <circle style="fill:#A4EBF7;" cx="1017" cy="1016.09" r="693.67"/>
  </g>
            <g id="gps-clinics">
    <g>
      <path style="fill:#044A6D;" d="M1593.27,931.39l-572.66,84.94l480.3,324.14C1575.03,1230.41,1613.19,1090.57,1593.27,931.39z"/>
      <polygon style="fill:#076489;" points="1018.75,1016.62 1020.61,1016.34 1019.46,1015.56 1018.04,1017.67 1020.02,1019
        1020.02,1017.47 			"/>
    </g>
              <g>
      <path style="fill:#5CBCE2;" d="M1461.56,1088.21c0.39-1.63,0.78-3.25,1.17-4.88c0.1,0.03,0.21,0.03,0.23,0.08
        c2.47,5.87,7.4,9.07,12.83,11.67c3.93,1.88,8.05,3.21,12.34,4.11c6.89,1.44,13.53,3.62,19.38,7.69
        c6.38,4.44,10.45,10.33,10.9,18.31c0.58,10.29-5.43,19.38-15.12,22.88c-5.59,2.02-11.37,2.23-17.16,1.37
        c-3.8-0.56-7.54-1.52-11.3-2.34c-6.07-1.32-12.17-2.01-18.36-1.04c-3.41,0.53-6.58,1.66-9.24,3.96c-0.11,0.1-0.26,0.15-0.56,0.32
        c1.04-2.57,2.02-4.97,2.99-7.37c-0.07-0.04-0.15-0.09-0.22-0.13c-0.24,0.3-0.49,0.6-0.72,0.91
        c-8.02,10.92-21.67,15.35-34.58,11.23c-6.23-1.99-9.31-6.38-8.43-12.96c0.65-4.82,1.89-9.57,3.01-14.32
        c3.32-14.07,6.73-28.11,10.06-42.17c1.17-4.91,1.95-9.93,4.13-14.54c2.6-5.48,6.83-7.78,12.85-6.69
        c13.13,2.38,21.51,10.11,25.27,22.88c0.1,0.35,0.2,0.71,0.3,1.06C1461.41,1088.22,1461.48,1088.21,1461.56,1088.21z"/>
                <path style="fill:#FFFFFF;" d="M1494.38,1139.3c-0.04-0.16-0.06-0.32-0.12-0.47c-4.45-10.97-6.94-22.28-5.95-34.19
        c0.06-0.78-0.33-0.89-0.93-0.96c-5.08-0.58-10.09-0.34-15.02,1.1c-3.42,1-6.57,2.53-9.24,4.93c-4.99,4.47-6.53,10.68-4.19,16.95
        c1.24,3.33,3.31,6.1,5.84,8.55c4.57,4.43,10.11,7.13,16.09,9.07c1.56,0.51,1.58,0.49,2.18-1.13c0.74-1.99,1.5-3.97,2.29-6.06
        C1487.43,1140.21,1491,1141.03,1494.38,1139.3z"/>
                <path style="fill:#FFFFFF;" d="M1466.81,1104.28c0.1-0.55,0.18-0.9,0.22-1.27c0.54-5.21-2.49-10.92-7.22-13.48
        c-1.55-0.84-2.39-2.31-3-3.72c-0.93-2.17-1.98-4.19-3.59-5.9c-3.7-3.92-8.57-5.75-13.51-7.39c-1.4-0.46-2.92-0.6-4.4-0.74
        c-1.38-0.13-2.63,0.31-3.56,1.41c-1.38,1.66-2.21,3.56-2.46,5.71c-0.13,1.13,0.46,2.03,1.47,2.27c1.06,0.25,2.03-0.32,2.36-1.48
        c0.28-0.95,0.52-1.92,0.69-2.9c0.31-1.73,0.56-1.97,2.23-1.72c3.39,0.52,6.61,1.54,9.49,3.47c0.27,0.18,0.56,0.36,0.78,0.6
        c0.75,0.85,1.47,1.73,2.22,2.59c0.84,0.97,1.79,1.86,2.51,2.91c2.35,3.42,1.72,6.03-2.02,7.89c-2.05,1.02-4.2,2.07-6.42,2.51
        c-4.05,0.82-8.08,0.23-11.93-1.37c-0.68-0.28-0.87-0.79-0.63-1.52c0.33-1.02,0.59-2.07,0.84-3.11c0.31-1.34-0.23-2.39-1.33-2.68
        c-1.09-0.29-2.13,0.38-2.48,1.69c-0.4,1.51-0.69,2.98-0.47,4.62c0.28,2.12,1.33,3.34,3.18,4.18c4.96,2.27,10.29,1.7,15.46,1.77
        c3.07,0.04,6-1.54,8.43-3.61c1.03-0.88,1.99-1.76,3.26-2.36c0.83-0.39,1.32-0.37,1.96,0.12c3.5,2.68,5.36,6.11,4.87,10.67
        c0.29,0.1,0.55,0.21,0.82,0.28C1465.27,1103.92,1465.95,1104.07,1466.81,1104.28z"/>
                <path style="fill:#FFFFFF;" d="M1456.56,1130.11c-4.58-9.92-2.47-18.03,6.21-24.66c-2.57,0.48-5.14,0.95-8.05,1.49
        c2.1-2.3,3.06-4.79,3.75-7.49c-0.93,0.32-1.79,0.69-2.55,1.21c-2.31,1.57-3.99,3.73-5.27,6.16c-0.58,1.09-1.34,1.65-2.48,2
        c-3.28,1.01-6.54,2.11-9.8,3.17c-0.42,0.14-0.82,0.3-1.39,0.51c0.43,0.45,0.73,0.78,1.05,1.1c2.48,2.49,4.94,5.01,7.47,7.45
        c0.75,0.72,1.06,1.46,1.07,2.49c0.05,2.88,0.57,5.68,1.98,8.24c0.8,1.46,1.83,2.73,3.6,3.45c0.72-2.64,1.26-5.17,0.71-8.1
        C1454.26,1128.26,1455.41,1129.18,1456.56,1130.11z"/>
                <path style="fill:#FFFFFF;" d="M1459.24,1133.7c-0.96-0.58-1.79-1.09-2.68-1.63c-2.35,3.61-5.76,4.79-9.8,4.83
        c-0.08-0.63-0.12-1.08-0.19-1.52c-0.58-3.35-3.51-5.85-6.88-5.89c-3.37-0.04-6.31,2.25-7.08,5.5c-0.77,3.27,0.85,6.66,3.87,8.08
        c3.05,1.44,6.68,0.6,8.7-2.1c0.49-0.65,0.95-0.86,1.73-0.82c2.4,0.11,4.7-0.38,6.87-1.4
        C1456.07,1137.67,1457.94,1136.1,1459.24,1133.7z"/>
                <path style="fill:#5CBCE2;" d="M1440.55,1133.21c1.93,0.56,3,2.56,2.4,4.51c-0.55,1.8-2.52,2.87-4.34,2.36
        c-1.88-0.53-3.03-2.51-2.53-4.36C1436.61,1133.79,1438.63,1132.66,1440.55,1133.21z"/>
    </g>
  </g>
            <g id="care-homes">
    <path style="fill:#044A6D;" d="M588.74,1446.02c104.69,105.27,274.01,156.44,428.8,153.61l-0.07-581.75L588.74,1446.02z"/>
              <g>

        <rect id="XMLID_152_" x="768.34" y="1364.06" transform="matrix(-0.9065 -0.4221 0.4221 -0.9065 947.5876 3000.6069)" style="fill:#EE7A31;" width="75.25" height="62.7"/>
                <polygon id="XMLID_151_" style="fill:#EE7A31;" points="794.72,1473.75 755.43,1455.45 758.52,1407.9 797.82,1426.19 			"/>

                <rect id="XMLID_150_" x="797.81" y="1387.54" transform="matrix(-0.9065 -0.4221 0.4221 -0.9065 1033.8461 3066.6802)" style="fill:#F5F4F6;" width="117.19" height="62.7"/>
                <polygon id="XMLID_149_" style="fill:#EE7A31;" points="755.64,1455.56 861.89,1505.03 896.29,1472.05 790.05,1422.58 			"/>

                <rect id="XMLID_148_" x="771.72" y="1382.5" transform="matrix(-0.9065 -0.4221 0.4221 -0.9065 1039.8483 2999.7734)" style="fill:#CBECF4;" width="160.57" height="4.56"/>
                <g id="XMLID_135_">
        <g id="XMLID_27_">
          <path id="XMLID_29_" style="fill:#EE7A31;" d="M889.49,1451.33l0.06-0.12c-0.7,1.51-2.36,2.4-3.97,2.02
            c-2.22-0.53-3.29-2.91-2.38-4.87l-0.01,0.03c0.81-1.74,2.88-2.5,4.63-1.69l0,0C889.55,1447.51,890.31,1449.58,889.49,1451.33z"
          />
        </g>
                  <path style="fill:#EE7A31;" d="M865.32,1440.07l0.05-0.12c-0.68,1.46-2.26,2.35-3.84,2.04c-2.31-0.47-3.44-2.9-2.52-4.89
          l-0.01,0.03c0.81-1.74,2.88-2.5,4.63-1.69l0,0C865.38,1436.25,866.13,1438.33,865.32,1440.07z"/>
                  <path style="fill:#EE7A31;" d="M841.15,1428.81l0.05-0.11c-0.66,1.41-2.16,2.31-3.7,2.05c-2.4-0.4-3.6-2.88-2.65-4.92
          l-0.01,0.03c0.81-1.74,2.88-2.5,4.63-1.69l0,0C841.2,1425,841.96,1427.07,841.15,1428.81z"/>
                  <g id="XMLID_12_">
          <path id="XMLID_13_" style="fill:#EE7A31;" d="M816.97,1417.56l0.05-0.1c-0.64,1.37-2.07,2.27-3.56,2.07
            c-2.49-0.32-3.75-2.87-2.78-4.94l-0.01,0.03c0.81-1.74,2.88-2.5,4.63-1.69l0,0C817.03,1413.74,817.78,1415.81,816.97,1417.56z"
          />
        </g>
      </g>
                <g id="XMLID_133_">
        <path id="XMLID_134_" style="fill:#EE7A31;" d="M908.51,1412.95l-9,19.32c-0.94,2.03-3.35,2.91-5.38,1.96l-0.86-0.4
          c-2.03-0.94-2.91-3.35-1.96-5.38l9-19.32L908.51,1412.95z"/>
      </g>
                <g id="XMLID_131_">
        <path id="XMLID_132_" style="fill:#EE7A31;" d="M884.78,1401.91l-9,19.32c-0.94,2.03-3.35,2.91-5.38,1.96l-0.86-0.4
          c-2.03-0.94-2.91-3.35-1.96-5.38l9-19.32L884.78,1401.91z"/>
      </g>
                <g id="XMLID_129_">
        <path id="XMLID_130_" style="fill:#EE7A31;" d="M861.17,1390.66l-9,19.32c-0.94,2.03-3.35,2.91-5.38,1.96l-0.86-0.4
          c-2.03-0.94-2.91-3.35-1.96-5.38l9-19.32L861.17,1390.66z"/>
      </g>
                <g id="XMLID_127_">
        <path id="XMLID_128_" style="fill:#EE7A31;" d="M836.29,1379.07l-9,19.32c-0.94,2.03-3.35,2.91-5.38,1.96l-0.86-0.4
          c-2.03-0.94-2.91-3.35-1.96-5.38l9-19.32L836.29,1379.07z"/>
      </g>
                <g id="XMLID_125_">
        <path id="XMLID_126_" style="fill:#EE7A31;" d="M804.9,1364.46l-9,19.32c-0.94,2.03-3.35,2.91-5.38,1.96l-5.22-2.43
          c-2.03-0.94-2.91-3.35-1.96-5.38l9-19.32L804.9,1364.46z"/>
      </g>
                <g id="XMLID_123_">
        <path id="XMLID_124_" style="fill:#F5F4F6;" d="M773.2,1426.1l0.05-0.1c-0.64,1.37-2.07,2.27-3.56,2.07
          c-2.49-0.32-3.75-2.87-2.78-4.94l-0.01,0.03c0.81-1.74,2.88-2.5,4.63-1.69l0,0C773.26,1422.29,774.01,1424.36,773.2,1426.1z"/>
      </g>

                <rect id="XMLID_122_" x="784.46" y="1446.31" transform="matrix(-0.9065 -0.4221 0.4221 -0.9065 998.4549 3116.6829)" style="fill:#CBECF4;" width="119.56" height="3.01"/>

                <rect id="XMLID_121_" x="754.47" y="1413.2" transform="matrix(0.9065 0.4221 -0.4221 0.9065 669.4122 -194.1349)" style="fill:#CBECF4;" width="37.33" height="3.01"/>
    </g>
  </g>
            <g id="retail-pharmacies">
    <path style="fill:#044A6D;" d="M1020.09,1599.58c27.78-0.61,55.09-2.96,81.44-7.06c159.55-24.82,305.99-114.69,397.95-249.94
      L1020.02,1019L1020.09,1599.58z"/>
              <g>

        <rect x="1152.17" y="1369.93" transform="matrix(-0.8798 0.4753 -0.4753 -0.8798 2971.4568 2075.0635)" style="fill:#F5F4F6;" width="142.4" height="86.59"/>
                <path style="fill:#EE7A31;" d="M1132.89,1412.89l139.79-75.53l2.36,4.37c2.03,3.76,0.63,8.45-3.13,10.48l-126.18,68.17
        c-3.76,2.03-8.45,0.63-10.48-3.13L1132.89,1412.89z"/>
                <polygon style="fill:#EE7A31;" points="1174.2,1489 1313.7,1413.63 1314.35,1431.82 1189.06,1499.51 			"/>

                <rect x="1199.94" y="1370.61" transform="matrix(-0.8798 0.4753 -0.4753 -0.8798 2963.0149 2065.874)" style="fill:#EE7A31;" width="40.74" height="73.89"/>
                <polygon style="fill:#F5F4F6;" points="1220.27,1445.39 1251.98,1428.26 1255.12,1430.75 1220.63,1449.39 			"/>
                <polygon style="fill:#F5F4F6;" points="1220.36,1369.73 1188.65,1386.86 1185.5,1384.37 1220,1365.74 			"/>

                <rect x="1201.59" y="1374.3" transform="matrix(-0.8798 0.4753 -0.4753 -0.8798 2963.0149 2065.874)" style="fill:#EE7A31;" width="37.44" height="66.52"/>

                <rect x="1186.31" y="1389.22" transform="matrix(-0.4753 -0.8798 0.8798 -0.4753 561.9973 3150.2632)" style="fill:#EE7A31;" width="68" height="36.69"/>

                <rect x="1169.24" y="1421.36" transform="matrix(-0.4753 -0.8798 0.8798 -0.4753 483.0862 3174.9026)" style="fill:#EE7A31;" width="37.92" height="44.1"/>
                <path style="fill:#EE7A31;" d="M1217.76,1450.93l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38s-1.81,1.89-1.34,2.75l3.69,6.83
        L1217.76,1450.93z"/>
                <path style="fill:#F5F4F6;" d="M1213.38,1453.3l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38c-1.21,0.65-1.81,1.89-1.34,2.75
        l3.69,6.83L1213.38,1453.3z"/>
                <path style="fill:#EE7A31;" d="M1208.99,1455.67l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38c-1.21,0.65-1.81,1.89-1.34,2.75
        l3.69,6.83L1208.99,1455.67z"/>
                <path style="fill:#F5F4F6;" d="M1204.61,1458.04l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38s-1.81,1.89-1.34,2.75l3.69,6.83
        L1204.61,1458.04z"/>
                <path style="fill:#EE7A31;" d="M1200.23,1460.4l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38s-1.81,1.89-1.34,2.75l3.69,6.83
        L1200.23,1460.4z"/>
                <path style="fill:#F5F4F6;" d="M1195.85,1462.77l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38c-1.21,0.65-1.81,1.89-1.34,2.75
        l3.69,6.83L1195.85,1462.77z"/>
                <path style="fill:#EE7A31;" d="M1191.47,1465.14l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38c-1.21,0.65-1.81,1.89-1.34,2.75
        l3.69,6.83L1191.47,1465.14z"/>
                <path style="fill:#F5F4F6;" d="M1187.08,1467.51l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38c-1.21,0.65-1.81,1.89-1.34,2.75
        l3.69,6.83L1187.08,1467.51z"/>
                <path style="fill:#EE7A31;" d="M1182.7,1469.87l-3.69-6.83c-0.47-0.87-1.83-1.04-3.04-0.38s-1.81,1.89-1.34,2.75l3.69,6.83
        L1182.7,1469.87z"/>

                <rect x="1248.94" y="1378.3" transform="matrix(-0.4753 -0.8798 0.8798 -0.4753 638.5427 3181.4939)" style="fill:#EE7A31;" width="37.92" height="44.1"/>
                <path style="fill:#EE7A31;" d="M1257.99,1429.2l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1257.99,1429.2z"/>
                <path style="fill:#F5F4F6;" d="M1262.37,1426.83l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1262.37,1426.83z"/>
                <path style="fill:#EE7A31;" d="M1266.75,1424.46l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1266.75,1424.46z"/>
                <path style="fill:#F5F4F6;" d="M1271.14,1422.1l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1271.14,1422.1z"/>
                <path style="fill:#EE7A31;" d="M1275.52,1419.73l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1275.52,1419.73z"/>
                <path style="fill:#F5F4F6;" d="M1279.9,1417.36l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1279.9,1417.36z"/>
                <path style="fill:#EE7A31;" d="M1284.28,1414.99l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1284.28,1414.99z"/>
                <path style="fill:#F5F4F6;" d="M1288.66,1412.63l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1288.66,1412.63z"/>
                <path style="fill:#EE7A31;" d="M1293.05,1410.26l-3.69-6.83c-0.47-0.87,0.13-2.1,1.34-2.75c1.21-0.65,2.57-0.48,3.04,0.38
        l3.69,6.83L1293.05,1410.26z"/>
                <path style="fill:#EE7A31;" d="M1229.19,1400.85c-0.76-1.41-0.24-3.18,1.18-3.95c1.41-0.76,3.18-0.24,3.95,1.18
        c0.76,1.42,0.24,3.18-1.18,3.95C1231.72,1402.79,1229.95,1402.26,1229.19,1400.85z"/>
                <polygon style="fill:#F5F4F6;" points="1178.96,1447.22 1185.01,1443.96 1188.27,1450.01 1193.31,1447.28 1190.04,1441.23
        1196.09,1437.97 1193.37,1432.93 1187.32,1436.2 1184.05,1430.15 1179.02,1432.87 1182.28,1438.92 1176.24,1442.19 			"/>
                <polygon style="fill:#F5F4F6;" points="1258.82,1404.08 1264.87,1400.81 1268.14,1406.86 1273.18,1404.13 1269.91,1398.09
        1275.96,1394.82 1273.24,1389.78 1267.19,1393.05 1263.92,1387 1258.88,1389.72 1262.15,1395.77 1256.1,1399.04 			"/>

                <line style="fill:none;stroke:#D1D3D3;stroke-width:0.4706;stroke-miterlimit:10;" x1="1297.43" y1="1407.89" x2="1257.99" y2="1429.2"/>

                <line style="fill:none;stroke:#D1D3D3;stroke-width:0.4706;stroke-miterlimit:10;" x1="1217.76" y1="1450.93" x2="1178.32" y2="1472.24"/>
    </g>
  </g>
            <g id="inner-private">
    <g id="private-labs">
      <g>
        <g id="background_5_">
          <g>
            <path style="fill:#FFFFFF;" d="M1018.75,1016.62L606.6,1428.17C409.37,1257.8,348.22,957.72,476.61,742L1018.75,1016.62z"/>
          </g>
        </g>
        <g>
          <g id="icon_2_">

              <rect x="529.21" y="1036.57" transform="matrix(-0.1913 -0.9815 0.9815 -0.1913 -393.2295 1876.0098)" style="fill:#E4E4E6;" width="93.97" height="126.84"/>

            <rect x="551.4" y="977.43" transform="matrix(-0.1913 -0.9815 0.9815 -0.1913 -348.9138 1794.2344)" style="fill:#5CBCE2;" width="26.54" height="126.84"/>

            <rect x="459.57" y="1111.49" transform="matrix(-0.1913 -0.9815 0.9815 -0.1913 -485.1467 1829.6459)" style="fill:#5CBCE2;" width="103.14" height="6.37"/>

            <rect x="485.33" y="1047.74" transform="matrix(-0.1913 -0.9815 0.9815 -0.1913 -437.3784 1741.4994)" style="fill:#3DA4C4;" width="26.76" height="6.37"/>
            <polygon style="fill:#3DA4C4;" points="521.3,1102.63 520.54,1062.97 504.91,1066.02 522.89,1158.25 						"/>

            <rect x="517.68" y="1096.01" transform="matrix(-0.1913 -0.9815 0.9815 -0.1913 -428.0146 1857.1364)" style="fill:#5CBCE2;" width="66.7" height="17.75"/>

            <rect x="541.15" y="1091.44" transform="matrix(-0.1913 -0.9815 0.9815 -0.1913 -395.5668 1874.7205)" style="fill:#5CBCE2;" width="66.7" height="17.75"/>

            <rect x="608.29" y="1056.45" transform="matrix(-0.1913 -0.9815 0.9815 -0.1913 -319.1741 1886.1486)" style="fill:#3DA4C4;" width="18.22" height="36.22"/>
          </g>
        </g>
      </g>
    </g>
  </g>
            <g id="hospital-wards">
    <path style="fill:#EE7A31;" d="M1618.13,930.13c-17.02-109.37-84.89-223.96-173.87-312.43l-423.32,398.6L1618.13,930.13z"/>
              <g>
      <path style="fill:#5CBCE2;" d="M1517.4,893.34c0.51,0.17,0.99,0.27,1.44,0.33c1.23-1.11,2.08-2.98,2.81-5.22
        c1.46-4.43,1.92-8.64-2.51-10.09l-45-14.78l-67.36-133.26c-2.1-4.16-5.39-2.28-9.55-0.18c-2.33,1.18-4.45,2.18-5.48,3.57
        l71.74,141.93L1517.4,893.34z"/>
                <path style="fill:#F7F9F9;" d="M1512.45,898.48l1.42-4.07l-51.65-16.97l-72.53-143.5l21.26-10.75c1.07-0.54,1.5-1.85,0.96-2.91
        l-1.98-3.93c-0.54-1.07-1.84-1.5-2.91-0.96l-23.65,11.96c-2.28,1.15-3.19,3.93-2.04,6.21l76.23,150.83l50.26,16.39
        C1509.73,901.4,1511.79,900.38,1512.45,898.48z"/>
                <path style="fill:#41CFF6;" d="M1463.28,828.14l-50.59-100.09c-0.16-0.32-0.55-0.44-0.86-0.28l-5.04,2.55l50.88,100.66
        L1463.28,828.14z"/>
                <path style="fill:#FFFFFF;" d="M1463.28,828.14l13.14,26c0.11,0.21,0.02,0.47-0.19,0.58l-5.22,2.64l-13.34-26.39L1463.28,828.14z
        "/>
                <path style="fill:#41CFF6;" d="M1474.14,863.57l-3.14-6.22l3.54-1.79c0,0,19.68-5.62,31.31,14.45c0,0,4.63-1.72,2.7,4.85
        L1474.14,863.57z"/>
                <path style="fill:#F7F9F9;" d="M1463.76,816.81l-37.47-74.13c-1.19-2.36-4.08-3.31-6.44-2.12l-22.7,11.47
        c-0.59,0.3-0.83,1.03-0.53,1.62c0.3,0.59,1.03,0.83,1.62,0.53l7.54-3.81l39.61,78.38l-7.54,3.81c-0.59,0.3-0.83,1.03-0.53,1.62
        c0.3,0.59,1.03,0.83,1.62,0.53l22.7-11.47C1464,822.06,1464.95,819.17,1463.76,816.81z M1460.55,821.1l-2.94,1.49l-8.65-17.12
        l5.07-2.56l7.58,14.99C1462.2,819.07,1461.73,820.51,1460.55,821.1z M1427.74,763.48l5.07-2.56l20.14,39.84l-5.07,2.56
        L1427.74,763.48z M1424.14,743.78l7.58,14.99l-5.07,2.56l-8.65-17.11l2.94-1.49C1422.11,742.13,1423.55,742.6,1424.14,743.78z
         M1407.93,749.3l7.91-4l39.61,78.38l-7.91,4L1407.93,749.3z"/>
                <path style="fill:#F7F9F9;" d="M1443.09,892.03l-74.27-146.94c-0.13-0.27-0.03-0.59,0.24-0.72l7.92-4.01
        c0.27-0.13,0.59-0.03,0.72,0.24l74.27,146.94c0.13,0.27,0.03,0.59-0.24,0.72l-7.92,4.01
        C1443.55,892.41,1443.23,892.3,1443.09,892.03z"/>

                <rect x="1378.64" y="751.44" transform="matrix(0.4511 0.8925 -0.8925 0.4511 1435.5934 -824.8796)" style="fill:#41CFF6;" width="19.51" height="6.4"/>

                <rect x="1420.44" y="857.27" transform="matrix(0.4511 0.8925 -0.8925 0.4511 1559.4042 -814.528)" style="fill:#41CFF6;" width="42.87" height="6.4"/>
                <g>
        <g>
          <path style="fill:#41CFF6;" d="M1372.94,753.23l-16.3,8.24c-1.45,0.73-2.03,2.51-1.3,3.96c0.73,1.45,2.51,2.03,3.96,1.3
            l16.3-8.24L1372.94,753.23z"/>
          <circle style="fill:#FFFFFF;" cx="1357.91" cy="764.13" r="7.4"/>
          <circle style="fill:#5CBCE2;" cx="1357.91" cy="764.13" r="4.41"/>
        </g>
                  <g>
          <path style="fill:#41CFF6;" d="M1436.32,878.64l-16.3,8.24c-1.45,0.73-2.03,2.51-1.3,3.96c0.73,1.45,2.51,2.03,3.96,1.3
            l16.3-8.24L1436.32,878.64z"/>
                    <circle style="fill:#FFFFFF;" cx="1421.29" cy="889.53" r="7.4"/>
                    <circle style="fill:#5CBCE2;" cx="1421.29" cy="889.53" r="4.41"/>
        </g>
      </g>
    </g>
  </g>
            <g id="hospital-clinics">
    <path style="fill:#EE7A31;" d="M1270.98,494.9c-47.39-24.31-98.01-40.95-150.02-50.18l-100.29,568.34l421.79-397.16
      C1390.43,564.56,1331.32,522.14,1270.98,494.9z"/>
              <g>
      <g>

          <rect x="1304.13" y="605.75" transform="matrix(0.4514 -0.8923 0.8923 0.4514 167.0427 1507.6416)" style="fill:#B8CDDB;" width="10.92" height="24.45"/>
      </g>
                <g>
        <g>

            <rect x="1298.39" y="644.77" transform="matrix(0.4514 -0.8923 0.8923 0.4514 139.986 1517.4617)" style="fill:#BABABC;" width="11.32" height="0.23"/>
        </g>
                  <g>

            <rect x="1292.73" y="635.8" transform="matrix(0.4514 -0.8923 0.8923 0.4514 139.4354 1510.84)" style="fill:#D7DDDD;" width="11.32" height="12.45"/>
        </g>
                  <g>

            <rect x="1293.47" y="636.62" transform="matrix(0.4514 -0.8923 0.8923 0.4514 139.4352 1510.8419)" style="fill:#18B4DF;" width="9.83" height="10.82"/>
        </g>
                  <g>

            <rect x="1289.14" y="639.28" transform="matrix(0.4514 -0.8923 0.8923 0.4514 139.0136 1505.7711)" style="fill:#00A1CC;" width="9.83" height="1.1"/>
        </g>
                  <g>

            <rect x="1294.48" y="642.42" transform="matrix(0.4514 -0.8923 0.8923 0.4514 139.6059 1512.89)" style="fill:#D7DDDD;" width="11.32" height="0.99"/>
        </g>
      </g>
                <g>
        <g>

            <rect x="1307.77" y="626.22" transform="matrix(0.4514 -0.8923 0.8923 0.4514 161.6872 1515.6572)" style="fill:#BABABC;" width="11.32" height="0.23"/>
        </g>
                  <g>

            <rect x="1302.11" y="617.25" transform="matrix(0.4514 -0.8923 0.8923 0.4514 161.1366 1509.0356)" style="fill:#D7DDDD;" width="11.32" height="12.45"/>
        </g>
                  <g>

            <rect x="1302.85" y="618.07" transform="matrix(0.4514 -0.8923 0.8923 0.4514 161.1375 1509.0375)" style="fill:#18B4DF;" width="9.83" height="10.82"/>
        </g>
                  <g>

            <rect x="1298.52" y="620.73" transform="matrix(0.4514 -0.8923 0.8923 0.4514 160.7138 1503.9668)" style="fill:#00A1CC;" width="9.83" height="1.1"/>
        </g>
                  <g>

            <rect x="1303.86" y="623.87" transform="matrix(0.4514 -0.8923 0.8923 0.4514 161.3071 1511.0856)" style="fill:#D7DDDD;" width="11.32" height="0.99"/>
        </g>
      </g>
                <g>

          <rect x="1222" y="536.33" transform="matrix(0.4514 -0.8923 0.8923 0.4514 172.3833 1399.2448)" style="fill:#F7F9F9;" width="4.22" height="46.21"/>
      </g>
                <g>

          <rect x="1216.99" y="545.66" transform="matrix(0.4514 -0.8923 0.8923 0.4514 165.7467 1399.7979)" style="fill:#C3D3DB;" width="8.5" height="38.89"/>
      </g>
                <g>
        <polygon style="fill:#B8CDDB;" points="1247.52,564.33 1245.67,567.98 1244.91,567.59 1246.29,564.88 1243.43,563.43
          1242.05,566.15 1241.83,566.03 1243.67,562.39 				"/>
      </g>
                <g>
        <polygon style="fill:#D7DDDD;" points="1201.66,545.72 1200.73,545.25 1200.15,546.4 1198.83,549.01 1240.06,569.87
          1241.39,567.25 1241.97,566.1 1241.04,565.63 				"/>
      </g>
                <g>

          <rect x="1213.28" y="543.78" transform="matrix(0.4514 -0.8923 0.8923 0.4514 165.3858 1395.4573)" style="fill:#B8CDDB;" width="8.5" height="38.89"/>
      </g>
                <g>

          <rect x="1291.96" y="584.53" transform="matrix(0.4514 -0.8923 0.8923 0.4514 172.3923 1487.8959)" style="fill:#C3D3DB;" width="8.5" height="38.44"/>
      </g>
                <g>

          <rect x="1286.22" y="583.94" transform="matrix(0.4514 -0.8923 0.8923 0.4514 171.8346 1481.1892)" style="fill:#B8CDDB;" width="8.5" height="33.82"/>
      </g>
                <g>

          <rect x="1184.71" y="548.77" transform="matrix(0.4514 -0.8923 0.8923 0.4514 123.9903 1429.7551)" style="fill:#B8CDDB;" width="80.02" height="130.56"/>
      </g>
                <g>

          <rect x="1204.72" y="566.83" transform="matrix(0.4514 -0.8923 0.8923 0.4514 102.8594 1431.5122)" style="fill:#41CFF6;" width="21.72" height="130.56"/>
      </g>
                <g>

          <rect x="1234.97" y="517.72" transform="matrix(0.4514 -0.8923 0.8923 0.4514 160.3154 1426.7347)" style="fill:#41CFF6;" width="10.92" height="130.56"/>
      </g>
                <g>

          <rect x="1253.87" y="636.8" transform="matrix(0.4514 -0.8923 0.8923 0.4514 130.7176 1510.6619)" style="fill:#B8CDDB;" width="80.02" height="24.45"/>
      </g>
                <g>

          <rect x="1273.88" y="654.86" transform="matrix(0.4514 -0.8923 0.8923 0.4514 109.5867 1512.4189)" style="fill:#B8CDDB;" width="21.72" height="24.45"/>
      </g>
                <g>
        <g>

            <rect x="1272.42" y="631.64" transform="matrix(0.4514 -0.8923 0.8923 0.4514 137.4601 1487.0831)" style="fill:#BABABC;" width="11.32" height="0.24"/>
        </g>
                  <g>

            <rect x="1266.76" y="622.66" transform="matrix(0.4514 -0.8923 0.8923 0.4514 136.9094 1480.4602)" style="fill:#F7F9F9;" width="11.32" height="12.45"/>
        </g>
                  <g>

            <rect x="1267.5" y="623.48" transform="matrix(0.4514 -0.8923 0.8923 0.4514 136.9091 1480.4611)" style="fill:#41CFF6;" width="9.83" height="10.82"/>
        </g>
                  <g>

            <rect x="1268.51" y="629.28" transform="matrix(0.4514 -0.8923 0.8923 0.4514 137.0798 1482.5104)" style="fill:#F7F9F9;" width="11.32" height="0.99"/>
        </g>
      </g>
                <g>
        <g>

            <rect x="1281.8" y="613.09" transform="matrix(0.4514 -0.8923 0.8923 0.4514 159.1613 1485.2787)" style="fill:#BABABC;" width="11.32" height="0.24"/>
        </g>
                  <g>

            <rect x="1276.14" y="604.11" transform="matrix(0.4514 -0.8923 0.8923 0.4514 158.6106 1478.6559)" style="fill:#F7F9F9;" width="11.32" height="12.45"/>
        </g>
                  <g>

            <rect x="1276.88" y="604.93" transform="matrix(0.4514 -0.8923 0.8923 0.4514 158.6114 1478.6565)" style="fill:#41CFF6;" width="9.83" height="10.82"/>
        </g>
                  <g>

            <rect x="1277.89" y="610.73" transform="matrix(0.4514 -0.8923 0.8923 0.4514 158.7811 1480.7059)" style="fill:#F7F9F9;" width="11.32" height="0.99"/>
        </g>
      </g>
                <g>
        <polygon style="fill:#F7F9F9;" points="1278.74,651.88 1256.58,640.67 1253.28,639 1167.32,595.52 1167.31,595.52
          1162.37,593.02 1167.46,582.95 1283.83,641.82 				"/>
      </g>
                <g>
        <g>

            <rect x="1211.01" y="627.63" transform="matrix(0.4514 -0.8923 0.8923 0.4514 96.0349 1437.8176)" style="fill:#41CFF6;" width="12.58" height="26.35"/>
        </g>
                  <g>

            <rect x="1219.26" y="618.88" transform="matrix(0.4514 -0.8923 0.8923 0.4514 106.2792 1436.9658)" style="fill:#41CFF6;" width="4.94" height="26.35"/>
        </g>
                  <g>

            <rect x="1220.96" y="607.02" transform="matrix(0.4514 -0.8923 0.8923 0.4514 111.5576 1434.8225)" style="fill:#F7F9F9;" width="3.34" height="39.34"/>
        </g>
                  <g>

            <rect x="1222.2" y="603.6" transform="matrix(0.4514 -0.8923 0.8923 0.4514 113.5557 1433.4799)" style="fill:#6D6F7B;" width="0.68" height="41.58"/>
        </g>
                  <g>
          <polygon style="fill:#D7DDDD;" points="1228.6,654.75 1228.6,654.75 1228.6,654.75 1230.09,655.51 1239.42,637.06
            1237.93,636.3 					"/>
        </g>
                  <g>
          <polygon style="fill:#D7DDDD;" points="1228.68,654.8 1228.69,654.8 1228.69,654.8 1226.68,653.78 1236.02,635.33
            1238.02,636.34 					"/>
        </g>
                  <g>
          <polygon style="fill:#F7F9F9;" points="1236.02,635.33 1228.24,631.4 1227.09,630.81 1196.93,615.56 1196.93,615.56
            1195.2,614.68 1196.7,611.71 1237.52,632.35 					"/>
        </g>
                  <g>
          <polygon style="fill:#F7F9F9;" points="1239.83,632.76 1239.52,633.37 1237.12,632.15 1197.32,612.02 1194.91,610.8
            1195.22,610.2 					"/>
        </g>
                  <g>
          <polygon style="fill:#41CFF6;" points="1200.21,623.94 1202.91,618.59 1202.91,618.58 1193.57,637.04 1193.58,637.04
            1199.73,624.87 					"/>
        </g>
                  <g>
          <polygon style="fill:#41CFF6;" points="1232.84,641.62 1226.68,653.78 1226.69,653.79 1236.02,635.33 1236.02,635.33
            1233.31,640.68 					"/>
        </g>
                  <g>
          <g>
            <polygon style="fill:#41CFF6;" points="1200.67,625.35 1194.99,636.58 1195.46,636.81 1201.77,640 1207.45,628.78
              1201.14,625.59 						"/>
          </g>
                    <g>
            <polygon style="fill:#41CFF6;" points="1203.85,620.24 1203.38,620 1201.15,624.41 1201.62,624.65 1207.92,627.84
              1210.15,623.43 						"/>
          </g>
        </g>
                  <g>
          <rect x="1194.28" y="636.61" style="fill:#D7DDDD;" width="0" height="1.58"/>
        </g>
                  <g>
          <rect x="1198.14" y="635.81" style="fill:#D7DDDD;" width="0" height="7.06"/>
        </g>
                  <g>
          <polygon style="fill:#F7F9F9;" points="1231.9,641.14 1226.22,652.37 1202.71,640.48 1202.23,641.41 1226.68,653.78
            1232.84,641.62 					"/>
        </g>
                  <g>

            <rect x="1232.08" y="640.39" transform="matrix(0.4514 -0.8923 0.8923 0.4514 104.3391 1451.5294)" style="fill:#F7F9F9;" width="1.05" height="1.05"/>
        </g>
                  <g>
          <rect x="1201.76" y="640.65" style="fill:#D7DDDD;" width="0" height="1.05"/>
        </g>
                  <g>
          <polygon style="fill:#41CFF6;" points="1192.5,620.04 1195.2,614.69 1195.2,614.68 1185.86,633.14 1185.87,633.14
            1192.02,620.97 					"/>
        </g>
                  <g>

            <rect x="1187.45" y="625.36" transform="matrix(0.4514 -0.8923 0.8923 0.4514 93.7432 1410.2552)" style="fill:#41CFF6;" width="12.58" height="7.06"/>
        </g>
                  <g>

            <rect x="1195.7" y="616.6" transform="matrix(0.4514 -0.8923 0.8923 0.4514 103.9868 1409.4059)" style="fill:#41CFF6;" width="4.94" height="7.07"/>
        </g>
                  <g>
          <path style="fill:#F7F9F9;" d="M1202.92,618.59l-6.31-3.19l-1.41-0.71l-2.71,5.35l-0.47,0.94l-6.15,12.16l1.41,0.71l6.31,3.19
            l0.94,0.47l0.47-0.94l5.68-11.23l0.47-0.94l2.23-4.41l0.47-0.94L1202.92,618.59z M1194.06,636.1l-6.31-3.19l-0.47-0.24
            l5.68-11.23l0.47,0.24l6.31,3.19L1194.06,636.1z M1200.21,623.94l-6.31-3.19l-0.47-0.24l2.23-4.41l0.47,0.24l6.31,3.19
            L1200.21,623.94z"/>
        </g>
                  <g>

            <rect x="1178.06" y="630.87" transform="matrix(0.4514 -0.8923 0.8923 0.4514 84.3281 1402.2335)" style="fill:#F7F9F9;" width="8.9" height="3.34"/>
        </g>
                  <g>

            <rect x="1179.52" y="631.64" transform="matrix(0.4514 -0.8923 0.8923 0.4514 84.4699 1403.9392)" style="fill:#A3A3A3;" width="8.9" height="3.27"/>
        </g>
                  <g>

            <rect x="1222.73" y="653.04" transform="matrix(0.4514 -0.8923 0.8923 0.4514 88.6731 1454.4905)" style="fill:#F7F9F9;" width="8.9" height="4.19"/>
        </g>
                  <g>

            <rect x="1223.9" y="654.27" transform="matrix(0.4514 -0.8923 0.8923 0.4514 88.7869 1455.859)" style="fill:#A3A3A3;" width="8.9" height="2.91"/>
        </g>
                  <g>
          <path style="fill:#F7F9F9;" d="M1210.63,622.49l-6.3-3.19l-1.41-0.71l-2.71,5.35l-0.47,0.94l-6.15,12.16l1.41,0.71l6.3,3.19
            l0.94,0.47l0.47-0.94l5.68-11.23l0.47-0.94l2.23-4.41l0.47-0.94L1210.63,622.49z M1201.77,640l-6.3-3.19l-0.47-0.24l5.68-11.23
            l0.47,0.24l6.3,3.19L1201.77,640z M1207.92,627.84l-6.3-3.19l-0.47-0.24l2.23-4.41l0.47,0.24l6.3,3.19L1207.92,627.84z"/>
        </g>
                  <g>

            <rect x="1204.46" y="635.66" transform="matrix(0.4514 -0.8923 0.8923 0.4514 98.7886 1433.0485)" style="fill:#F7F9F9;" width="20.68" height="1.05"/>
        </g>
                  <g>

            <rect x="1212.83" y="639.9" transform="matrix(0.4514 -0.8923 0.8923 0.4514 99.6028 1442.8402)" style="fill:#F7F9F9;" width="20.68" height="1.05"/>
        </g>
                  <g>

            <rect x="1219.85" y="621.55" transform="matrix(0.4514 -0.8923 0.8923 0.4514 103.1498 1437.226)" style="fill:#F7F9F9;" width="1.05" height="26.35"/>
        </g>
                  <g>
          <polygon style="fill:#F7F9F9;" points="1234.6,635.79 1232.37,640.21 1233.31,640.68 1236.02,635.33 1211.56,622.96
            1211.09,623.9 					"/>
        </g>
                  <g>

            <rect x="1205.46" y="621" transform="matrix(0.4514 -0.8923 0.8923 0.4514 87.422 1428.8958)" style="fill:#F7F9F9;" width="0.55" height="44.71"/>
        </g>
                  <g>

            <rect x="1204.54" y="621.9" transform="matrix(0.4514 -0.8923 0.8923 0.4514 86.3627 1428.9839)" style="fill:#D7DDDD;" width="1.48" height="44.71"/>
        </g>
                  <g>

            <rect x="1204.05" y="621.58" transform="matrix(0.4514 -0.8923 0.8923 0.4514 85.5134 1428.3289)" style="fill:#F7F9F9;" width="0.55" height="46.09"/>
        </g>
                  <g>

            <rect x="1203.13" y="622.48" transform="matrix(0.4514 -0.8923 0.8923 0.4514 84.4536 1428.417)" style="fill:#D7DDDD;" width="1.48" height="46.09"/>
        </g>
                  <g>

            <rect x="1202.42" y="621.79" transform="matrix(0.4514 -0.8923 0.8923 0.4514 83.5934 1427.5037)" style="fill:#F7F9F9;" width="0.55" height="47.97"/>
        </g>
                  <g>

            <rect x="1201.5" y="622.69" transform="matrix(0.4514 -0.8923 0.8923 0.4514 82.5344 1427.5917)" style="fill:#D7DDDD;" width="1.48" height="47.97"/>
        </g>
      </g>
                <g>
        <g>

            <rect x="1259.1" y="657.53" transform="matrix(0.4514 -0.8923 0.8923 0.4514 107.0486 1489.4)" style="fill:#BABABC;" width="11.31" height="0.24"/>
        </g>
                  <g>

            <rect x="1255.13" y="651.3" transform="matrix(0.4514 -0.8923 0.8923 0.4514 106.6621 1484.7507)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
        </g>
                  <g>

            <rect x="1256.13" y="651.95" transform="matrix(0.4514 -0.8923 0.8923 0.4514 106.691 1485.0453)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
        </g>
                  <g>

            <rect x="1258.56" y="656.87" transform="matrix(0.4514 -0.8923 0.8923 0.4514 106.9995 1488.76)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
        </g>
      </g>
                <g>
        <g>

            <rect x="1245.91" y="650.86" transform="matrix(0.4514 -0.8923 0.8923 0.4514 105.7657 1473.9705)" style="fill:#BABABC;" width="11.31" height="0.24"/>
        </g>
                  <g>

            <rect x="1241.94" y="644.63" transform="matrix(0.4514 -0.8923 0.8923 0.4514 105.3791 1469.3212)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
        </g>
                  <g>

            <rect x="1242.94" y="645.28" transform="matrix(0.4514 -0.8923 0.8923 0.4514 105.4081 1469.6157)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
        </g>
                  <g>

            <rect x="1245.37" y="650.2" transform="matrix(0.4514 -0.8923 0.8923 0.4514 105.7165 1473.3308)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
        </g>
      </g>
                <g>
        <g>
          <g>

              <rect x="1172.4" y="581.05" transform="matrix(0.4514 -0.8923 0.8923 0.4514 127.7238 1370.0719)" style="fill:#BABABC;" width="11.3" height="0.24"/>
          </g>
          <g>

              <rect x="1168.42" y="574.82" transform="matrix(0.4514 -0.8923 0.8923 0.4514 127.3371 1365.421)" style="fill:#F7F9F9;" width="11.3" height="8.68"/>
          </g>
          <g>

              <rect x="1169.42" y="575.48" transform="matrix(0.4514 -0.8923 0.8923 0.4514 127.365 1365.7172)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
          <g>

              <rect x="1171.85" y="580.39" transform="matrix(0.4514 -0.8923 0.8923 0.4514 127.673 1369.4307)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1180.35" y="585.07" transform="matrix(0.4514 -0.8923 0.8923 0.4514 128.4982 1379.3698)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1176.37" y="578.84" transform="matrix(0.4514 -0.8923 0.8923 0.4514 128.1115 1374.7202)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1177.37" y="579.5" transform="matrix(0.4514 -0.8923 0.8923 0.4514 128.1386 1375.016)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1179.8" y="584.41" transform="matrix(0.4514 -0.8923 0.8923 0.4514 128.4464 1378.7321)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1188.3" y="589.09" transform="matrix(0.4514 -0.8923 0.8923 0.4514 129.2719 1388.6698)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1184.32" y="582.86" transform="matrix(0.4514 -0.8923 0.8923 0.4514 128.8853 1384.0206)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1185.32" y="583.52" transform="matrix(0.4514 -0.8923 0.8923 0.4514 128.9132 1384.3171)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1187.75" y="588.43" transform="matrix(0.4514 -0.8923 0.8923 0.4514 129.222 1388.0311)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1196.25" y="593.11" transform="matrix(0.4514 -0.8923 0.8923 0.4514 130.0461 1397.9714)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1192.27" y="586.88" transform="matrix(0.4514 -0.8923 0.8923 0.4514 129.6594 1393.3202)" style="fill:#F7F9F9;" width="11.31" height="8.68"/>
          </g>
                    <g>

              <rect x="1193.27" y="587.54" transform="matrix(0.4514 -0.8923 0.8923 0.4514 129.6865 1393.6169)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1195.7" y="592.45" transform="matrix(0.4514 -0.8923 0.8923 0.4514 129.9961 1397.3311)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1204.2" y="597.13" transform="matrix(0.4514 -0.8923 0.8923 0.4514 130.8209 1407.2698)" style="fill:#BABABC;" width="11.3" height="0.24"/>
          </g>
                    <g>

              <rect x="1200.23" y="590.9" transform="matrix(0.4514 -0.8923 0.8923 0.4514 130.4344 1402.6205)" style="fill:#F7F9F9;" width="11.3" height="8.67"/>
          </g>
                    <g>

              <rect x="1201.22" y="591.56" transform="matrix(0.4514 -0.8923 0.8923 0.4514 130.4618 1402.9167)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1203.65" y="596.47" transform="matrix(0.4514 -0.8923 0.8923 0.4514 130.7698 1406.6311)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1212.15" y="601.15" transform="matrix(0.4514 -0.8923 0.8923 0.4514 131.5952 1416.5725)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1208.18" y="594.92" transform="matrix(0.4514 -0.8923 0.8923 0.4514 131.2085 1411.9216)" style="fill:#F7F9F9;" width="11.31" height="8.68"/>
          </g>
                    <g>

              <rect x="1209.17" y="595.58" transform="matrix(0.4514 -0.8923 0.8923 0.4514 131.2367 1412.2159)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1211.6" y="600.49" transform="matrix(0.4514 -0.8923 0.8923 0.4514 131.5432 1415.9315)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1220.1" y="605.17" transform="matrix(0.4514 -0.8923 0.8923 0.4514 132.3696 1425.8706)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1216.12" y="598.94" transform="matrix(0.4514 -0.8923 0.8923 0.4514 131.983 1421.2209)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1217.12" y="599.6" transform="matrix(0.4514 -0.8923 0.8923 0.4514 132.0115 1421.5155)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1219.55" y="604.51" transform="matrix(0.4514 -0.8923 0.8923 0.4514 132.3187 1425.2299)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1228.05" y="609.19" transform="matrix(0.4514 -0.8923 0.8923 0.4514 133.1429 1435.171)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1224.07" y="602.96" transform="matrix(0.4514 -0.8923 0.8923 0.4514 132.7563 1430.521)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1225.07" y="603.62" transform="matrix(0.4514 -0.8923 0.8923 0.4514 132.7848 1430.8164)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1227.5" y="608.53" transform="matrix(0.4514 -0.8923 0.8923 0.4514 133.0949 1434.5309)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1236" y="613.21" transform="matrix(0.4514 -0.8923 0.8923 0.4514 133.9172 1444.4697)" style="fill:#BABABC;" width="11.3" height="0.23"/>
          </g>
                    <g>

              <rect x="1232.03" y="606.98" transform="matrix(0.4514 -0.8923 0.8923 0.4514 133.5307 1439.821)" style="fill:#F7F9F9;" width="11.3" height="8.67"/>
          </g>
                    <g>

              <rect x="1233.02" y="607.64" transform="matrix(0.4514 -0.8923 0.8923 0.4514 133.5585 1440.116)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1235.45" y="612.55" transform="matrix(0.4514 -0.8923 0.8923 0.4514 133.8687 1443.8318)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1243.95" y="617.23" transform="matrix(0.4514 -0.8923 0.8923 0.4514 134.6931 1453.77)" style="fill:#BABABC;" width="11.3" height="0.23"/>
          </g>
                    <g>

              <rect x="1239.98" y="611" transform="matrix(0.4514 -0.8923 0.8923 0.4514 134.3064 1449.1196)" style="fill:#F7F9F9;" width="11.3" height="8.68"/>
          </g>
                    <g>

              <rect x="1240.97" y="611.66" transform="matrix(0.4514 -0.8923 0.8923 0.4514 134.3338 1449.4154)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1243.4" y="616.57" transform="matrix(0.4514 -0.8923 0.8923 0.4514 134.6419 1453.1305)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1251.9" y="621.25" transform="matrix(0.4514 -0.8923 0.8923 0.4514 135.468 1463.0712)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1247.93" y="615.02" transform="matrix(0.4514 -0.8923 0.8923 0.4514 135.0814 1458.4208)" style="fill:#F7F9F9;" width="11.31" height="8.68"/>
          </g>
                    <g>

              <rect x="1248.92" y="615.68" transform="matrix(0.4514 -0.8923 0.8923 0.4514 135.1084 1458.7158)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1251.35" y="620.59" transform="matrix(0.4514 -0.8923 0.8923 0.4514 135.4156 1462.431)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
      </g>
                <g>
        <g>
          <g>

              <rect x="1181.78" y="562.5" transform="matrix(0.4514 -0.8923 0.8923 0.4514 149.4238 1368.2676)" style="fill:#BABABC;" width="11.3" height="0.24"/>
          </g>
          <g>

              <rect x="1177.81" y="556.27" transform="matrix(0.4514 -0.8923 0.8923 0.4514 149.037 1363.6167)" style="fill:#F7F9F9;" width="11.3" height="8.68"/>
          </g>
          <g>

              <rect x="1178.8" y="556.93" transform="matrix(0.4514 -0.8923 0.8923 0.4514 149.0641 1363.913)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
          <g>

              <rect x="1181.24" y="561.84" transform="matrix(0.4514 -0.8923 0.8923 0.4514 149.3733 1367.6263)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1189.73" y="566.52" transform="matrix(0.4514 -0.8923 0.8923 0.4514 150.1985 1377.5654)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1185.76" y="560.29" transform="matrix(0.4514 -0.8923 0.8923 0.4514 149.8118 1372.9159)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1186.75" y="560.95" transform="matrix(0.4514 -0.8923 0.8923 0.4514 149.839 1373.2117)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1189.19" y="565.86" transform="matrix(0.4514 -0.8923 0.8923 0.4514 150.1479 1376.9276)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1197.68" y="570.54" transform="matrix(0.4514 -0.8923 0.8923 0.4514 150.973 1386.8654)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1193.71" y="564.31" transform="matrix(0.4514 -0.8923 0.8923 0.4514 150.5864 1382.2162)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1194.7" y="564.97" transform="matrix(0.4514 -0.8923 0.8923 0.4514 150.6139 1382.5128)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1197.14" y="569.88" transform="matrix(0.4514 -0.8923 0.8923 0.4514 150.922 1386.2268)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1205.63" y="574.56" transform="matrix(0.4514 -0.8923 0.8923 0.4514 151.7464 1396.1671)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1201.66" y="568.33" transform="matrix(0.4514 -0.8923 0.8923 0.4514 151.3597 1391.5159)" style="fill:#F7F9F9;" width="11.31" height="8.68"/>
          </g>
                    <g>

              <rect x="1202.66" y="568.99" transform="matrix(0.4514 -0.8923 0.8923 0.4514 151.3884 1391.8124)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1205.08" y="573.9" transform="matrix(0.4514 -0.8923 0.8923 0.4514 151.6964 1395.5267)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1213.58" y="578.58" transform="matrix(0.4514 -0.8923 0.8923 0.4514 152.5216 1405.4653)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1209.61" y="572.35" transform="matrix(0.4514 -0.8923 0.8923 0.4514 152.1351 1400.8162)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1210.61" y="573.01" transform="matrix(0.4514 -0.8923 0.8923 0.4514 152.162 1401.1124)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1213.04" y="577.92" transform="matrix(0.4514 -0.8923 0.8923 0.4514 152.4709 1404.8268)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1221.53" y="582.6" transform="matrix(0.4514 -0.8923 0.8923 0.4514 153.2952 1414.7682)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1217.56" y="576.37" transform="matrix(0.4514 -0.8923 0.8923 0.4514 152.9084 1410.1173)" style="fill:#F7F9F9;" width="11.31" height="8.68"/>
          </g>
                    <g>

              <rect x="1218.55" y="577.03" transform="matrix(0.4514 -0.8923 0.8923 0.4514 152.9357 1410.4116)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1220.99" y="581.94" transform="matrix(0.4514 -0.8923 0.8923 0.4514 153.2455 1414.1271)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1229.48" y="586.62" transform="matrix(0.4514 -0.8923 0.8923 0.4514 154.0706 1424.0662)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1225.51" y="580.39" transform="matrix(0.4514 -0.8923 0.8923 0.4514 153.684 1419.4165)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1226.5" y="581.05" transform="matrix(0.4514 -0.8923 0.8923 0.4514 153.7106 1419.7112)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1228.94" y="585.96" transform="matrix(0.4514 -0.8923 0.8923 0.4514 154.0186 1423.4255)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1237.43" y="590.64" transform="matrix(0.4514 -0.8923 0.8923 0.4514 154.8448 1433.3666)" style="fill:#BABABC;" width="11.31" height="0.24"/>
          </g>
                    <g>

              <rect x="1233.46" y="584.41" transform="matrix(0.4514 -0.8923 0.8923 0.4514 154.4581 1428.7166)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1234.46" y="585.07" transform="matrix(0.4514 -0.8923 0.8923 0.4514 154.4852 1429.012)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1236.89" y="589.98" transform="matrix(0.4514 -0.8923 0.8923 0.4514 154.7937 1432.7267)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1245.38" y="594.66" transform="matrix(0.4514 -0.8923 0.8923 0.4514 155.6184 1442.6654)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1241.41" y="588.43" transform="matrix(0.4514 -0.8923 0.8923 0.4514 155.2318 1438.0166)" style="fill:#F7F9F9;" width="11.31" height="8.67"/>
          </g>
                    <g>

              <rect x="1242.41" y="589.09" transform="matrix(0.4514 -0.8923 0.8923 0.4514 155.2597 1438.3115)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1244.84" y="594" transform="matrix(0.4514 -0.8923 0.8923 0.4514 155.567 1442.0276)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1253.33" y="598.68" transform="matrix(0.4514 -0.8923 0.8923 0.4514 156.3925 1451.9657)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1249.36" y="592.45" transform="matrix(0.4514 -0.8923 0.8923 0.4514 156.0058 1447.3153)" style="fill:#F7F9F9;" width="11.31" height="8.68"/>
          </g>
                    <g>

              <rect x="1250.35" y="593.11" transform="matrix(0.4514 -0.8923 0.8923 0.4514 156.033 1447.6112)" style="fill:#41CFF6;" width="9.82" height="7.61"/>
          </g>
                    <g>

              <rect x="1252.79" y="598.02" transform="matrix(0.4514 -0.8923 0.8923 0.4514 156.3439 1451.326)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
                  <g>
          <g>

              <rect x="1261.28" y="602.7" transform="matrix(0.4514 -0.8923 0.8923 0.4514 157.1667 1461.267)" style="fill:#BABABC;" width="11.31" height="0.23"/>
          </g>
                    <g>

              <rect x="1257.31" y="596.47" transform="matrix(0.4514 -0.8923 0.8923 0.4514 156.7801 1456.6166)" style="fill:#F7F9F9;" width="11.31" height="8.68"/>
          </g>
                    <g>

              <rect x="1258.3" y="597.13" transform="matrix(0.4514 -0.8923 0.8923 0.4514 156.8074 1456.9116)" style="fill:#41CFF6;" width="9.82" height="7.6"/>
          </g>
                    <g>

              <rect x="1260.74" y="602.04" transform="matrix(0.4514 -0.8923 0.8923 0.4514 157.1172 1460.6266)" style="fill:#F7F9F9;" width="11.31" height="0.99"/>
          </g>
        </g>
      </g>
                <g>
        <g>

            <rect x="1157.64" y="603.31" transform="matrix(0.4514 -0.8923 0.8923 0.4514 91.2456 1382.8478)" style="fill:#F7F9F9;" width="25.13" height="27.82"/>
        </g>
                  <g>

            <rect x="1158.63" y="603.41" transform="matrix(0.4514 -0.8923 0.8923 0.4514 91.6636 1382.813)" style="fill:#D7DDDD;" width="23.51" height="26.91"/>
        </g>
                  <g>
          <g>
            <g>

                <rect x="1175.09" y="593.84" transform="matrix(0.4514 -0.8923 0.8923 0.4514 102.8535 1381.8826)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1174.35" y="595.3" transform="matrix(0.4514 -0.8923 0.8923 0.4514 101.1418 1382.0249)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1173.61" y="596.77" transform="matrix(0.4514 -0.8923 0.8923 0.4514 99.4289 1382.1672)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1172.87" y="598.23" transform="matrix(0.4514 -0.8923 0.8923 0.4514 97.7155 1382.3097)" style="fill:#B0BEC5;" width="0.25" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1172.13" y="599.7" transform="matrix(0.4514 -0.8923 0.8923 0.4514 96.0041 1382.452)" style="fill:#B0BEC5;" width="0.25" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1171.39" y="601.16" transform="matrix(0.4514 -0.8923 0.8923 0.4514 94.2924 1382.5944)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1170.65" y="602.62" transform="matrix(0.4514 -0.8923 0.8923 0.4514 92.5795 1382.7368)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1169.91" y="604.09" transform="matrix(0.4514 -0.8923 0.8923 0.4514 90.8665 1382.8793)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1169.17" y="605.55" transform="matrix(0.4514 -0.8923 0.8923 0.4514 89.1552 1383.0215)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1168.43" y="607.01" transform="matrix(0.4514 -0.8923 0.8923 0.4514 87.4438 1383.1638)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1167.69" y="608.48" transform="matrix(0.4514 -0.8923 0.8923 0.4514 85.7313 1383.3062)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1166.95" y="609.94" transform="matrix(0.4514 -0.8923 0.8923 0.4514 84.0187 1383.4486)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1166.21" y="611.41" transform="matrix(0.4514 -0.8923 0.8923 0.4514 82.3058 1383.5911)" style="fill:#B0BEC5;" width="0.25" height="26.91"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1165.47" y="612.87" transform="matrix(0.4514 -0.8923 0.8923 0.4514 80.5945 1383.7333)" style="fill:#B0BEC5;" width="0.26" height="26.91"/>
            </g>
          </g>
        </g>
      </g>
                <g>
        <g>

            <rect x="1270.22" y="667.73" transform="matrix(0.4514 -0.8923 0.8923 0.4514 102.1965 1514.5514)" style="fill:#F7F9F9;" width="25.13" height="12.87"/>
        </g>
                  <g>

            <rect x="1271.21" y="667.58" transform="matrix(0.4514 -0.8923 0.8923 0.4514 102.6146 1514.5175)" style="fill:#859DA8;" width="23.51" height="12.45"/>
        </g>
                  <g>
          <g>
            <g>

                <rect x="1287.67" y="658.02" transform="matrix(0.4514 -0.8923 0.8923 0.4514 113.8046 1513.587)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1286.94" y="659.48" transform="matrix(0.4514 -0.8923 0.8923 0.4514 112.0928 1513.7294)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1286.2" y="660.95" transform="matrix(0.4514 -0.8923 0.8923 0.4514 110.3799 1513.8718)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1285.46" y="662.41" transform="matrix(0.4514 -0.8923 0.8923 0.4514 108.6666 1514.0143)" style="fill:#6D6F7B;" width="0.25" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1284.72" y="663.87" transform="matrix(0.4514 -0.8923 0.8923 0.4514 106.9551 1514.1565)" style="fill:#6D6F7B;" width="0.25" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1283.97" y="665.34" transform="matrix(0.4514 -0.8923 0.8923 0.4514 105.2434 1514.2988)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1283.23" y="666.8" transform="matrix(0.4514 -0.8923 0.8923 0.4514 103.5305 1514.4413)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1282.49" y="668.26" transform="matrix(0.4514 -0.8923 0.8923 0.4514 101.8175 1514.5837)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1281.75" y="669.73" transform="matrix(0.4514 -0.8923 0.8923 0.4514 100.1062 1514.726)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1281.01" y="671.19" transform="matrix(0.4514 -0.8923 0.8923 0.4514 98.3949 1514.8683)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1280.27" y="672.65" transform="matrix(0.4514 -0.8923 0.8923 0.4514 96.6823 1515.0107)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1279.53" y="674.12" transform="matrix(0.4514 -0.8923 0.8923 0.4514 94.9698 1515.1531)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1278.79" y="675.58" transform="matrix(0.4514 -0.8923 0.8923 0.4514 93.2568 1515.2955)" style="fill:#6D6F7B;" width="0.25" height="12.45"/>
            </g>
          </g>
                    <g>
            <g>

                <rect x="1278.05" y="677.04" transform="matrix(0.4514 -0.8923 0.8923 0.4514 91.5455 1515.4379)" style="fill:#6D6F7B;" width="0.26" height="12.45"/>
            </g>
          </g>
        </g>
      </g>
                <g>
        <polygon style="fill:#B8CDDB;" points="1202.58,541.6 1200.73,545.25 1201.66,545.72 1203.04,543 1242.41,562.92
          1241.04,565.63 1241.97,566.1 1243.81,562.46 				"/>
      </g>
    </g>
  </g>
            <g id="hospital-ae">
    <path style="fill:#EE7A31;" d="M754.56,500.12l0.2,0.39l-0.03-0.05l263.42,512.22l100.3-568.4
      C996.49,423.21,868.14,442.39,754.56,500.12z"/>
              <g>
      <polygon style="fill:#FFFFFF;" points="943.39,531.75 949.86,530.8 949.12,525.8 954.11,525.06 953.15,518.61 948.17,519.34
        947.43,514.35 940.96,515.31 941.7,520.3 936.7,521.04 937.65,527.5 942.65,526.76 			"/>
                <g>
        <polygon style="fill:#FFFFFF;" points="986.56,543.84 997.16,615.46 997.42,617.19 1031.48,612.14 1031.35,611.28
          1031.23,610.41 1027.11,582.61 1025.63,572.64 1024.94,568 1023.47,558.03 1022.78,553.39 1021.3,543.43 1020.62,538.8 				"/>

                  <rect x="997.11" y="576.17" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -74.3523 153.128)" style="fill:#1FC3F3;" width="10.08" height="10.08"/>

                  <rect x="994.94" y="561.56" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -72.2345 152.6531)" style="fill:#1FC3F3;" width="10.08" height="10.08"/>

                  <rect x="992.78" y="546.97" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -70.118 152.1784)" style="fill:#1FC3F3;" width="10.08" height="10.06"/>

                  <rect x="1011.72" y="574.01" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -73.8774 155.2459)" style="fill:#1FC3F3;" width="10.08" height="10.08"/>

                  <rect x="1009.56" y="559.4" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -71.7596 154.771)" style="fill:#1FC3F3;" width="10.08" height="10.08"/>

                  <rect x="1007.39" y="544.8" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -69.643 154.2964)" style="fill:#1FC3F3;" width="10.08" height="10.06"/>
                  <polygon style="fill:#FFFFFF;" points="922.67,626.49 918.55,598.7 917.07,588.72 916.38,584.08 914.91,574.11 914.22,569.47
          912.75,559.52 912.06,554.88 878,559.92 888.61,631.54 888.73,632.4 888.86,633.27 922.92,628.23 				"/>

                  <rect x="888.55" y="592.26" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -77.8808 137.3938)" style="fill:#1FC3F3;" width="10.09" height="10.08"/>

                  <rect x="886.38" y="577.65" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -75.763 136.9189)" style="fill:#1FC3F3;" width="10.09" height="10.08"/>

                  <rect x="884.22" y="563.05" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -73.6464 136.4442)" style="fill:#1FC3F3;" width="10.09" height="10.06"/>

                  <rect x="903.16" y="590.09" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -77.4059 139.5115)" style="fill:#1FC3F3;" width="10.08" height="10.08"/>

                  <rect x="901" y="575.48" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -75.2881 139.0365)" style="fill:#1FC3F3;" width="10.08" height="10.08"/>

                  <rect x="898.83" y="560.89" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -73.1715 138.5619)" style="fill:#1FC3F3;" width="10.08" height="10.06"/>
                  <polygon style="fill:#1FC3F3;" points="913.79,554.62 924.4,626.23 924.66,627.97 995.69,617.45 995.43,615.71 984.82,544.1
          983.71,536.62 986.78,536.16 985.77,529.29 984.75,522.42 951.13,527.4 951.86,532.27 941.91,533.75 941.19,528.87
          907.58,533.85 908.6,540.72 909.62,547.6 912.69,547.14 				"/>

                  <rect x="927.84" y="598.1" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -79.4818 143.6497)" style="fill:#FFFFFF;" width="14.83" height="26.38"/>

                  <rect x="944.25" y="595.8" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -78.9771 145.9007)" style="fill:#FFFFFF;" width="13.07" height="26.38"/>

                  <rect x="958.91" y="593.63" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -78.5008 148.0245)" style="fill:#FFFFFF;" width="13.05" height="26.38"/>

                  <rect x="973.55" y="591.33" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -77.9963 150.2743)" style="fill:#FFFFFF;" width="14.83" height="26.38"/>

                  <rect x="923.8" y="578.42" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -75.0306 146.1303)" style="fill:#FFFFFF;" width="61.03" height="7.81"/>

                  <rect x="921.37" y="562.02" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -72.6539 145.5974)" style="fill:#FFFFFF;" width="61.03" height="7.8"/>

                  <rect x="918.94" y="545.64" transform="matrix(0.9892 -0.1465 0.1465 0.9892 -70.2784 145.0647)" style="fill:#FFFFFF;" width="61.03" height="7.79"/>
                  <polygon style="fill:#FFFFFF;" points="911.09,545.6 910.59,542.2 984.29,531.29 984.79,534.69 944.71,540.62 				"/>
      </g>
    </g>
  </g>
            <g id="hospital-labs">
    <path style="fill:#EE7A31;" d="M752.47,501.62l-0.17-0.41c-104.08,52.91-193.69,137.17-252.95,251.81l517.47,262.62L752.47,501.62
      L752.47,501.62z"/>
              <g>

        <rect x="629.61" y="711.48" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -347.0028 704.3652)" style="fill:#5CBCE2;" width="45.67" height="95.21"/>

                <rect x="647.27" y="645.76" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -280.1811 729.6423)" style="fill:#91E0F2;" width="136.47" height="95.21"/>

                <rect x="588.3" y="724" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -335.305 666.7292)" style="fill:#1FC3F3;" width="51.73" height="5.09"/>

                <rect x="609.89" y="653.32" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -263.4357 693.9156)" style="fill:#F7F9F9;" width="144.18" height="5.09"/>

                <rect x="657.64" y="691.61" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -297.9266 700.2974)" style="fill:#FFFFFF;" width="29.17" height="15.78"/>

                <rect x="683.1" y="670.67" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -276.6284 708.3541)" style="fill:#FFFFFF;" width="18.44" height="15.78"/>

                <rect x="702.12" y="653.64" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -259.3171 714.9025)" style="fill:#FFFFFF;" width="13.07" height="15.78"/>

                <rect x="713.78" y="635.99" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -241.3748 721.6896)" style="fill:#FFFFFF;" width="23.59" height="15.78"/>

                <rect x="693.64" y="726.16" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -311.7752 736.9072)" style="fill:#FFFFFF;" width="29.17" height="15.78"/>

                <rect x="719.1" y="705.21" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -290.477 744.9638)" style="fill:#FFFFFF;" width="18.44" height="15.78"/>

                <rect x="738.12" y="688.18" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -273.1657 751.5123)" style="fill:#FFFFFF;" width="13.07" height="15.78"/>

                <rect x="749.79" y="670.54" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -255.2234 758.2994)" style="fill:#FFFFFF;" width="23.59" height="15.78"/>

                <rect x="728.54" y="656.69" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -250.9082 740.7156)" style="fill:#FFFFFF;" width="29.17" height="15.78"/>

                <rect x="713.81" y="677.63" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -272.2061 732.6591)" style="fill:#FFFFFF;" width="18.44" height="15.78"/>

                <rect x="700.16" y="694.66" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -289.5179 726.1104)" style="fill:#FFFFFF;" width="13.07" height="15.78"/>

                <rect x="677.97" y="712.3" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -307.4597 719.3235)" style="fill:#FFFFFF;" width="23.59" height="15.78"/>
    </g>
  </g>
</g>
          <g id="inner-titles">
  <circle style="fill:none;" cx="1017.5" cy="1017.5" r="673.46"/>
            <g id="community">
    <path style="fill:#044A6D;" d="M1386.29,1536.54c1.13-2.08,2.75-4.01,4.82-5.49c3.79-2.65,8.39-3.45,12.75-2.54
      c4.33,0.87,8.4,3.45,11.27,7.46c2.87,4.02,3.99,8.71,3.41,13.08c-0.59,4.45-2.79,8.51-6.55,11.2c-1.82,1.3-3.87,2.19-5.91,2.63
      c-2.19,0.42-4.43,0.42-6.63,0.02l0.46-2.31c1.88,0.31,3.82,0.34,5.67-0.03c1.78-0.38,3.47-1.14,5.03-2.26
      c3.21-2.3,5.09-5.75,5.57-9.54c0.48-3.79-0.47-7.9-2.98-11.41c-2.51-3.51-6.08-5.74-9.83-6.51c-3.7-0.8-7.62-0.11-10.86,2.14
      c-1.77,1.27-3.12,2.87-4.13,4.68c-0.99,1.92-1.53,4.03-1.63,6.21l-2.4-0.14C1384.47,1541.22,1385.14,1538.77,1386.29,1536.54z"/>
              <path style="fill:#044A6D;" d="M1382.19,1564.44c-2.44,1.59-5.28,2.08-8.03,1.38c-2.49-0.68-4.87-2.35-6.72-5.18
      c-1.84-2.83-2.52-5.68-2.11-8.29c0.42-2.69,2-5.02,4.57-6.69s5.33-2.1,7.91-1.37c2.56,0.69,4.89,2.46,6.7,5.25
      c1.81,2.79,2.42,5.62,2.01,8.24C1386.14,1560.51,1384.58,1562.88,1382.19,1564.44z M1384.17,1557.38c0.31-2-0.18-4.28-1.66-6.55
      c-1.47-2.26-3.34-3.71-5.33-4.28c-1.95-0.53-4.02-0.17-5.98,1.1c-1.96,1.28-3.16,3.05-3.52,5.08c-0.31,2,0.26,4.3,1.76,6.61
      c1.5,2.31,3.36,3.64,5.31,4.17c2.1,0.56,4.25,0.15,6.12-1.07C1382.72,1561.24,1383.85,1559.46,1384.17,1557.38z"/>
              <path style="fill:#044A6D;" d="M1367.29,1569.24c0.02,2.58-0.96,5.33-3.78,6.99c-2.51,1.48-5.2,1.62-7.59-0.29
      c-0.18,3.18-1.6,5.77-4.16,7.28c-3.85,2.27-7.04,1.67-9.96-3.29l-6.97-11.82l2.06-1.21l6.97,11.82c1.8,3.04,4,4.22,6.82,2.56
      c3.36-1.98,3.41-5.57,2.81-8.23l-6.97-11.82l2.06-1.21l6.97,11.82c2.24,3.8,4.68,3.94,7.14,2.49c2.91-1.72,3.26-5.72,2.31-8.96
      l-6.57-11.15l2.06-1.21l10.53,17.86l-2.06,1.21L1367.29,1569.24z"/>
              <path style="fill:#044A6D;" d="M1338.48,1586.06c-0.12,2.58-1.23,5.27-4.14,6.79c-2.58,1.35-5.28,1.35-7.56-0.68
      c-0.34,3.17-1.9,5.68-4.53,7.05c-3.96,2.07-7.11,1.31-9.78-3.81l-6.35-12.16l2.12-1.11l6.35,12.16c1.63,3.13,3.77,4.42,6.67,2.91
      c3.45-1.8,3.69-5.39,3.23-8.07l-6.34-12.16l2.12-1.11l6.35,12.16c2.04,3.92,4.46,4.18,7,2.85c2.99-1.56,3.55-5.55,2.78-8.83
      l-5.98-11.47l2.12-1.11l9.59,18.38l-2.12,1.11L1338.48,1586.06z"/>
              <path style="fill:#044A6D;" d="M1304.43,1591.73c-1.5-3.2-5.53-3.72-8.02-2.55c-2.54,1.19-4.72,4.62-3.22,7.82l5.84,12.47
      l-2.16,1.01l-5.84-12.47c-1.74-3.71-0.08-8.62,4.48-10.76c4.52-2.12,9.35-0.25,11.09,3.47l5.84,12.47l-2.16,1.01L1304.43,1591.73z
      "/>
              <path style="fill:#044A6D;" d="M1289.86,1610.62c-0.5,2.53-1.74,4.77-5.37,6.32c-3.58,1.54-7.53,0.64-9.73-4.47l-5.4-12.56
      l2.2-0.94l5.21,12.13c1.74,4.06,4.52,4.85,7,3.78c4.15-1.79,5.04-5.79,4.3-8.41l-5.29-12.32l2.2-0.94l8.17,19l-2.2,0.94
      L1289.86,1610.62z"/>
              <path style="fill:#044A6D;" d="M1264.48,1601.89l2.21-0.9l7.77,19.16l-2.21,0.9L1264.48,1601.89z M1274.48,1628.89
      c-0.43-1.06,0.09-2.28,1.15-2.71c1.06-0.43,2.28,0.08,2.71,1.15c0.43,1.06-0.08,2.28-1.14,2.71
      C1276.13,1630.47,1274.91,1629.95,1274.48,1628.89z"/>
              <path style="fill:#044A6D;" d="M1265.53,1623.75l3.67,9.45l-2.23,0.87l-3.67-9.44l-3.2,1.24l-0.87-2.23l3.2-1.24l-6.64-17.1
      l2.23-0.86l6.64,17.1l3.2-1.24l0.87,2.23L1265.53,1623.75z"/>
              <path style="fill:#044A6D;" d="M1241.45,1632.71l-2.35,0.84l0.99-33.03l2.45-0.88l-0.39,11.4l14.23,16.32l-2.4,0.86l-11.96-13.83
      L1241.45,1632.71z"/>
  </g>
            <g id="hospital">
    <path style="fill:#044A6D;" d="M1064.44,363.01l19.15,1.66l1.3-15.06l2.38,0.21l-2.94,34.01l-2.38-0.21l1.47-16.98l-19.15-1.66
      l-1.47,16.98l-2.38-0.21l2.94-34.01l2.38,0.21L1064.44,363.01z"/>
              <path style="fill:#044A6D;" d="M1100.14,363.89c2.89,0.37,5.37,1.83,7.02,4.13c1.46,2.12,2.2,4.94,1.78,8.29
      c-0.42,3.35-1.75,5.96-3.75,7.7c-2.06,1.78-4.77,2.54-7.81,2.16c-3.04-0.38-5.42-1.83-6.92-4.07c-1.51-2.18-2.14-5.04-1.72-8.34
      c0.42-3.3,1.79-5.85,3.79-7.59C1094.59,364.34,1097.31,363.54,1100.14,363.89z M1094.07,368c-1.53,1.33-2.63,3.39-2.96,6.07
      c-0.34,2.68,0.15,5,1.3,6.71c1.15,1.66,2.96,2.73,5.27,3.02c2.32,0.29,4.38-0.28,5.96-1.61c1.53-1.32,2.58-3.44,2.93-6.18
      c0.35-2.73-0.21-4.95-1.36-6.61c-1.24-1.78-3.15-2.86-5.37-3.14C1097.68,365.99,1095.66,366.63,1094.07,368z"/>
              <path style="fill:#044A6D;" d="M1115.17,371.49c-0.45,2.82,1.71,3.85,4.64,4.89c3.14,1.08,6.59,2.93,5.84,7.66
      c-0.43,2.72-3.39,5.78-8.67,4.94c-3.95-0.62-6.64-3.58-6.73-6.69l1.99-0.63c0.39,2.17,1.99,4.68,5.54,5.24
      c1.33,0.21,3.88-0.28,4.94-1.96c0.48-0.71,0.73-2.25,0.55-3.12c-0.34-1.53-1.93-2.41-4.26-3.36c-3.02-1.21-6.66-3.16-6-7.32
      c0.48-3.03,3.16-5.02,7.17-4.39c2.77,0.44,4.83,2.08,5.84,4.29l-1.86,1.13c-0.91-1.56-2.14-3.13-4.03-3.43
      C1117.41,368.32,1115.46,369.69,1115.17,371.49z"/>
              <path style="fill:#044A6D;" d="M1128.4,379.86l2.21-10.79l2.34,0.48l-0.71,3.46c0.13-0.13,0.27-0.26,0.45-0.39
      c2.2-1.67,5.12-2.45,7.87-1.89c2.75,0.56,5.01,2.46,6.38,4.86c1.37,2.35,1.88,5.32,1.26,8.32c-0.62,3-2.25,5.53-4.44,7.15
      c-2.2,1.67-4.95,2.43-7.7,1.87c-2.8-0.57-5.15-2.54-6.57-4.95c-0.08-0.12-0.15-0.3-0.22-0.47l-2.5,12.22l-2.34-0.48L1128.4,379.86
      z M1142.35,389.19c1.7-1.3,3.01-3.31,3.51-5.75c0.49-2.39,0.07-4.76-0.98-6.62c-1.05-1.86-2.75-3.16-4.78-3.58
      c-2.09-0.43-4.16,0.1-5.86,1.4s-3.01,3.31-3.5,5.7c-0.5,2.44-0.38,4.96,0.67,6.82c1.06,1.81,3.04,3.01,5.13,3.44
      C1138.58,391.01,1140.66,390.43,1142.35,389.19z"/>
              <path style="fill:#044A6D;" d="M1150.3,394.1l-2.34-0.5l4.3-20.23l2.34,0.5L1150.3,394.1z M1157.15,366.13
      c-0.24,1.12-1.35,1.84-2.47,1.6c-1.12-0.24-1.84-1.35-1.6-2.47c0.24-1.12,1.35-1.84,2.46-1.6
      C1156.67,363.91,1157.39,365.02,1157.15,366.13z"/>
              <path style="fill:#044A6D;" d="M1161.71,375.41l2.25-9.88l2.33,0.53l-2.25,9.88l3.34,0.76l-0.53,2.33l-3.34-0.76l-4.08,17.88
      l-2.33-0.53l4.08-17.88l-3.34-0.76l0.53-2.33L1161.71,375.41z"/>
              <path style="fill:#044A6D;" d="M1184.77,383.56c0.1,0.24,0.3,0.72,0.39,1.01l0.72-3.25l2.32,0.59l-5.08,20.05l-2.32-0.59
      l0.93-3.25c-0.22,0.21-0.63,0.59-0.83,0.75c-2.32,1.55-5.11,2.19-7.83,1.5c-2.72-0.69-4.87-2.57-6.17-5.05
      c-1.27-2.41-1.63-5.4-0.88-8.37c0.75-2.97,2.5-5.42,4.76-6.94c2.32-1.56,5.11-2.19,7.83-1.5
      C1181.31,379.2,1183.46,381.08,1184.77,383.56z M1178.01,380.83c-2.07-0.52-4.17-0.04-5.92,1.13c-1.76,1.22-3.16,3.17-3.77,5.58
      c-0.61,2.42-0.31,4.8,0.66,6.71c0.98,1.86,2.6,3.29,4.66,3.81c2.02,0.51,4.2,0.15,5.94-1.02c1.76-1.22,3.14-3.28,3.75-5.7
      c0.61-2.42,0.44-4.93-0.57-6.84C1181.78,382.64,1180.02,381.34,1178.01,380.83z"/>
              <path style="fill:#044A6D;" d="M1188.04,403.36l-2.3-0.64l10.06-36.2l2.3,0.64L1188.04,403.36z"/>
  </g>
            <g id="private_1_">
    <path style="fill:#044A6D;" d="M404.55,1187.78l0.68,2.38l-33.78,9.6l-0.03-0.1c-3.52-8.65-4.54-16.57,4.18-19.05
      c5.84-1.66,10.24,1.67,11.97,7.76l1.17,3.91L404.55,1187.78z M386.73,1192.85l-1.03-3.62c-1.85-6.51-6.24-7.6-9.59-6.65
      c-5.84,1.66-6.11,7.15-3.48,14.27L386.73,1192.85z"/>
              <path style="fill:#044A6D;" d="M381.08,1171.31c-1.91-0.57-3.78-2.05-4.38-4.44c-0.48-1.93,0-3.76,1.3-5.2l2.04,0.87
      c-0.89,0.88-1.9,2.14-1.45,3.96c0.64,2.55,3.89,3.84,7.32,3.6l12.76-3.19l0.6,2.4l-20.79,5.19l-0.6-2.4L381.08,1171.31z"/>
              <path style="fill:#044A6D;" d="M365.95,1158.35c1.15-0.26,2.31,0.46,2.57,1.61c0.26,1.15-0.46,2.31-1.61,2.57
      c-1.15,0.26-2.31-0.46-2.57-1.61C364.07,1159.78,364.8,1158.62,365.95,1158.35z M395.15,1152.58l0.55,2.41l-20.83,4.79l-0.55-2.41
      L395.15,1152.58z"/>
              <path style="fill:#044A6D;" d="M388.57,1140.57l-18.57-3.24l-0.52-2.58l25.1,4.6l-21.32,14.03l-0.52-2.58L388.57,1140.57z"/>
              <path style="fill:#044A6D;" d="M368.54,1113.26c0.19-0.19,0.56-0.58,0.8-0.78l-3.38,0.65l-0.39-2.44l21.1-3.37l0.39,2.44
      l-3.46,0.44c0.29,0.12,0.82,0.36,1.06,0.48c2.43,1.57,4.17,3.96,4.62,6.82c0.46,2.86-0.45,5.67-2.27,7.92
      c-1.77,2.19-4.46,3.76-7.58,4.26c-3.13,0.5-6.17-0.15-8.53-1.68c-2.43-1.57-4.17-3.96-4.62-6.82
      C365.82,1118.32,366.72,1115.51,368.54,1113.26z M368.71,1120.79c0.35,2.17,1.67,3.97,3.49,5.15c1.87,1.17,4.3,1.71,6.84,1.3
      c2.55-0.41,4.68-1.67,6.1-3.37c1.36-1.69,2.06-3.81,1.71-5.98c-0.34-2.12-1.57-4.04-3.39-5.22c-1.87-1.17-4.39-1.63-6.94-1.23
      c-2.55,0.41-4.86,1.59-6.26,3.34C368.89,1116.47,368.37,1118.67,368.71,1120.79z"/>
              <path style="fill:#044A6D;" d="M364.65,1103.92l-10.39,1.3l-0.31-2.45l10.39-1.3l-0.44-3.52l2.45-0.31l0.44,3.52l18.81-2.35
      l0.31,2.45l-18.81,2.35l0.44,3.52l-2.45,0.31L364.65,1103.92z"/>
              <path style="fill:#044A6D;" d="M383.38,1078.09c0.86,1.7,1.32,3.65,1.48,5.41c0.27,2.89-0.76,5.63-2.72,7.75
      c-1.91,2.01-4.69,3.46-7.84,3.76c-3.15,0.3-6.15-0.6-8.41-2.23c-2.32-1.72-3.9-4.21-4.18-7.1c-0.23-2.46,0.52-4.79,1.95-6.76
      c1.38-1.91,3.51-3.4,6.02-4.23c0.42-0.09,0.84-0.24,1.32-0.29c0.1-0.06,0.15-0.07,0.26-0.08l0.15,1.55l0.09,0.91l1.5,15.82
      c0.38,0.02,0.7-0.01,1.07-0.05c2.57-0.24,4.78-1.37,6.29-3.02c1.47-1.54,2.25-3.61,2.04-5.8c-0.14-1.5-0.51-3.13-1.19-4.58
      c-0.53-1.08-1.27-1.98-2.18-2.54l1.26-2.11C381.66,1075.28,382.65,1076.59,383.38,1078.09z M365.64,1080.41
      c-1.05,1.45-1.64,3.23-1.47,5.05c0.21,2.19,1.41,4.07,3.15,5.31c0.93,0.67,2,1.21,3.16,1.48l-1.38-14.65
      C367.71,1078.27,366.51,1079.25,365.64,1080.41z"/>
  </g>
</g>
          <g id="centeral-circle">
  <g>
    <g>
      <path style="fill:#FFFFFF;" d="M1017.9,712.52c39.55,0,78.09,7.5,114.55,22.28c37.73,15.3,71.6,37.79,100.66,66.86
        c29.07,29.07,51.56,62.93,66.86,100.66c14.79,36.46,22.28,75,22.28,114.55c0,39.55-7.5,78.09-22.28,114.55
        c-15.3,37.73-37.79,71.6-66.86,100.66c-29.07,29.07-62.93,51.56-100.66,66.86c-36.46,14.79-75,22.28-114.55,22.28
        s-78.09-7.5-114.55-22.28c-37.73-15.3-71.6-37.79-100.66-66.86c-29.07-29.07-51.56-62.93-66.86-100.66
        c-14.79-36.46-22.28-75-22.28-114.55c0-39.55,7.5-78.09,22.28-114.55c15.3-37.73,37.79-71.6,66.86-100.66
        c29.07-29.07,62.93-51.56,100.66-66.86C939.81,720.01,978.35,712.52,1017.9,712.52 M1017.9,672.2
        c-88.21,0-176.42,33.65-243.72,100.95c-134.6,134.6-134.6,352.84,0,487.44c67.3,67.3,155.51,100.95,243.72,100.95
        s176.42-33.65,243.72-100.95c134.6-134.6,134.6-352.84,0-487.44C1194.31,705.85,1106.11,672.2,1017.9,672.2L1017.9,672.2z"/>
    </g>
    <g>
      <defs>
        <circle id="SVGID_14_" cx="1017.9" cy="1016.87" r="332.77"/>
      </defs>
      <clipPath id="SVGID_15_">
        <use xlink:href="#SVGID_14_"  style="overflow:visible;"/>
      </clipPath>
      <g style="clip-path:url(#SVGID_15_);">
        <circle style="fill:#F5F4F6;" cx="1017.9" cy="1016.87" r="360.36"/>
        <g>
          <defs>
            <circle id="SVGID_16_" cx="1017.9" cy="1016.87" r="360.36"/>
          </defs>
          <clipPath id="SVGID_17_">
            <use xlink:href="#SVGID_16_"  style="overflow:visible;"/>
          </clipPath>
          <g style="clip-path:url(#SVGID_17_);">
            <g>
              <path style="fill:#914837;" d="M649.29,1888.1L649.29,1888.1c-11.34-3.68-17.54-15.86-13.86-27.2l6.35-19.53
                c3.68-11.34,15.86-17.54,27.2-13.86h0c11.34,3.68,17.54,15.86,13.86,27.2l-6.35,19.53
                C672.81,1885.58,660.63,1891.79,649.29,1888.1z"/>
              <g>
                <path style="fill:#DAD9DC;" d="M566.52,1888.01l68.79-26.25c9.33-0.13,31.14,0.86,45.42,0.3c0,0,18.44,21.46,14.96,48
                  c-0.98,7.44-2.71,5.85-13.48,6.45l-103.44,1.06C551.59,1917.84,542.46,1895.82,566.52,1888.01z"/>
              </g>
              <path style="fill:#002D55;" d="M766.23,1439.11H867c23.24,0,42.25-19.01,42.25-42.25v-92.4H723.98v92.4
                C723.98,1420.1,742.99,1439.11,766.23,1439.11z"/>
              <path style="fill:#002D55;" d="M867.88,1627.99h4.41c20.49,0,37.26-16.76,37.26-37.26v-252.05
                c0-20.49-16.76-37.26-37.26-37.26h-4.41c-20.49,0-37.26,16.76-37.26,37.26v252.05
                C830.62,1611.23,847.39,1627.99,867.88,1627.99z"/>
              <path style="fill:#002D55;" d="M705.33,1624.88l9.83,3.17c18.23,5.88,37.23-6.84,42.23-28.26l55.34-237.01
                c5-21.42-5.82-43.76-24.04-49.64l-9.83-3.17c-18.23-5.88-37.23,6.84-42.23,28.26l-55.34,237.01
                C676.28,1596.66,687.1,1619,705.33,1624.88z"/>
              <path style="fill:#914837;" d="M866.9,1889.66L866.9,1889.66c11.92,0,21.59-9.67,21.59-21.59v-20.54
                c0-11.92-9.67-21.59-21.59-21.59h0c-11.92,0-21.59,9.67-21.59,21.59v20.54C845.32,1880,854.98,1889.66,866.9,1889.66z"/>
              <path style="fill:#002D55;" d="M830.39,1846.64h78.92v-277.57c0-20.32-16.63-36.95-36.95-36.95h-5.01
                c-20.32,0-36.95,16.63-36.95,36.95V1846.64z"/>
              <path style="fill:#002D55;" d="M619.9,1835.76l76.74,18.42l66.37-276.46c4.74-19.76-7.54-39.81-27.31-44.56l-4.87-1.17
                c-19.76-4.74-39.81,7.54-44.56,27.31L619.9,1835.76z"/>
              <g>
                <path style="fill:#DAD9DC;" d="M959.53,1888.01l-71.03-26.23c-16.87,0-30.36,0.6-43.17,0.29c0,0-18.44,21.46-14.96,48
                  c0.98,7.44,2.71,5.85,13.48,6.45l103.44,1.06C974.45,1917.84,983.58,1895.82,959.53,1888.01z"/>
              </g>
              <g>
                <path style="fill:#044A6D;" d="M966.88,1210.77L966.88,1210.77c11.59-7.58,14.93-22.86,7.46-34.14l-78.32-118.34
                  c-7.47-11.28-22.92-14.29-34.51-6.71c-11.59,7.57-14.93,22.86-7.46,34.14l78.32,118.34
                  C939.84,1215.34,955.29,1218.34,966.88,1210.77"/>
                <path style="fill:#333333;" d="M830.27,898.45c28.63,23.48,65.09,169.2,34.75,192.24c-10.67,8.1-23.02,7.52-44.48,9.45
                  c-16.3,1.46-60.35,3.77-75.75-8.24c-20.68-16.13-2.62-158.37,13.08-182.79C767.87,893.55,798.56,872.44,830.27,898.45z"/>
                <path style="fill:#044A6D;" d="M909.25,1316.29l-8.32-212.32c-0.3-24.77-6.96-50.4-24.54-57.96l-79.05-31.44l-68.14,39.49
                  c-17,9.68-25.98,36.91-23.13,62.13L732.89,1313L909.25,1316.29z"/>
                <path style="fill:#914837;" d="M817.12,1017.91l-0.58-29.97l-39.21,0.23l3.13,31.52c0.22,3.63-1.7,7.02-4.82,8.52
                  l-11.84,5.68l3.57,11.7c10.69,35.05,58.48,31.89,64.52-4.26l1.95-11.69l-11.01-3.9
                  C819.6,1024.61,817.33,1021.5,817.12,1017.91"/>
                <polygon style="fill:#773124;" points="804.9,998.99 780.45,1021 778.3,997.85 								"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M805.27,987.31
                  c0,0-2.48,2.53-8.3,2.88c-5.07,0.3-7.73-2.73-7.73-2.73"/>
                <path style="fill:#914837;" d="M774.19,919.33l24.41-0.36c10.6-0.16,19.32,7.92,19.47,18.04l0.78,52.11
                  c0.15,10.12-8.32,18.46-18.92,18.62l-24.41,0.36c-10.6,0.16-19.32-7.92-19.47-18.04l-0.78-52.11
                  C755.12,927.82,763.59,919.49,774.19,919.33"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M756.79,949.99c0,0,8.34-1.1,14.02-1"
                />
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M785.68,949.27
                  c0,0,9.97-1.21,15.62-0.66"/>
                <polyline style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" points="776.35,965.27
                  775.81,979.87 783.26,980.24 								"/>
                <path style="fill:#232322;" d="M791.27,957.8c-0.07,1.29,0.86,2.4,2.08,2.48c1.22,0.08,2.26-0.89,2.33-2.18
                  c0.07-1.29-0.86-2.4-2.08-2.48C792.38,955.53,791.34,956.51,791.27,957.8z"/>
                <path style="fill:#232322;" d="M763.43,956.43c-0.07,1.29,0.86,2.4,2.08,2.48c1.22,0.08,2.26-0.9,2.33-2.18
                  c0.07-1.29-0.86-2.4-2.08-2.48C764.55,954.16,763.5,955.14,763.43,956.43z"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M775.73,992.03
                  c0,0,8.47,2.7,13.97-2.81"/>
                <path style="fill:#044A6D;" d="M633.24,1203.57L633.24,1203.57c-10.64-8.86-12.2-24.43-3.48-34.78l91.43-108.53
                  c8.72-10.35,24.41-11.55,35.05-2.69c10.64,8.86,12.2,24.43,3.48,34.78l-91.43,108.53
                  C659.57,1211.22,643.88,1212.43,633.24,1203.57"/>
                <g>
                  <path style="fill:#044A6D;" d="M773.83,1338.86L773.83,1338.86c11.04-10.11,11.89-28.24,1.91-40.49l-104.72-128.49
                    c-9.98-12.25-27.03-13.98-38.07-3.87h0c-11.04,10.11-11.89,28.24-1.91,40.49L735.76,1335
                    C745.75,1347.25,762.79,1348.98,773.83,1338.86z"/>
                  <line style="fill:#044A6D;" x1="735.76" y1="1335" x2="648.99" y2="1228.53"/>

                  <line style="fill:none;stroke:#87C6E8;stroke-width:0.3745;stroke-miterlimit:10;" x1="769.08" y1="1291.61" x2="682.31" y2="1185.14"/>
                </g>

                <ellipse transform="matrix(0.9546 -0.2978 0.2978 0.9546 -359.6342 286.5941)" style="fill:#914837;" cx="760.85" cy="1323.7" rx="17.88" ry="19.36"/>
                <path style="fill:#914837;" d="M781.14,1314.21c0,0,21.43,9.72,28.43,15.77c6.99,6.05,1.46,9.21,0.07,8.91
                  c-1.39-0.3-15.17-7.89-15.17-7.89s15.2,12.06,19.49,27.37c4.29,15.31-2.37,6.91-2.37,6.91s3.52,5.96,2.68,9.54
                  c-0.84,3.58-5.77-4.58-5.77-4.58s2.83,6.1,1.45,7.5s-11.71-9.13-11.71-9.13s5.94,5.12,4.22,6.97
                  c-1.72,1.85-11.13-2.41-16.2-6.93c-5.07-4.53-36.28-21.09-35.95-43.56C750.66,1302.63,781.14,1314.21,781.14,1314.21z"/>
                <path style="fill:#333333;" d="M753.93,932.33c-0.13,4.05,33.79,8.53,54.02,0.59c0,0,9.47-4.67,32.78-19.67
                  c0,0-20.83-38.24-64.07-19.91C768.9,896.63,754.69,908.94,753.93,932.33z"/>
                <path style="fill:#333333;" d="M807.46,916.67c0,0,0.05,41.9,10.15,46.93l11.06,5.97c0,0,1.94,4.62-8.46,13.98l-2.34,20.27
                  c0,0,23.89-15.68,29.37-34.83c4.03-14.08-4.53-41.68-6.5-55.74L807.46,916.67z"/>
                <path style="fill:#914837;" d="M817.19,964.33c0,0,5.93-7.88,10.64-5.31c4.7,2.57,1.79,24.62-6.4,27.3
                  C813.23,989,813.48,969.45,817.19,964.33"/>
                <g>
                  <path style="fill:#044A6D;" d="M811.64,1328.97L811.64,1328.97c-9.8-11.32-8.56-29.43,2.77-40.45l118.82-115.57
                    c11.33-11.02,28.46-10.77,38.26,0.54h0c9.8,11.32,8.56,29.43-2.77,40.45L849.9,1329.51
                    C838.57,1340.53,821.44,1340.29,811.64,1328.97z"/>

                  <line style="fill:none;stroke:#87C6E8;stroke-width:0.3745;stroke-miterlimit:10;" x1="849.9" y1="1329.51" x2="948.36" y2="1233.75"/>

                  <line style="fill:none;stroke:#87C6E8;stroke-width:0.3745;stroke-miterlimit:10;" x1="821.8" y1="1282.57" x2="920.26" y2="1186.81"/>
                </g>

                <ellipse transform="matrix(0.4057 -0.914 0.914 0.4057 -711.2364 1536.9021)" style="fill:#914837;" cx="826.28" cy="1315.4" rx="19.36" ry="17.88"/>
                <path style="fill:#914837;" d="M836.58,1318.01c-2.25,22.36-35.16,35.22-40.72,39.13c-5.56,3.91-15.4,7.06-16.89,5.02
                  c-1.5-2.04,5-6.44,5-6.44s-11.47,9.28-12.68,7.72c-1.21-1.55,2.3-7.29,2.3-7.29s-5.83,7.54-6.26,3.89
                  c-0.42-3.65,3.76-9.17,3.76-9.17s-7.59,7.57-1.56-7.14c6.03-14.71,22.52-24.94,22.52-24.94s-14.56,5.95-15.98,6.09
                  c-1.42,0.14-6.55-3.64,1.1-8.84c7.64-5.21,30.06-12.39,30.06-12.39S838.83,1295.65,836.58,1318.01z"/>
                <g>
                  <g>
                    <path style="fill:#FFFFFF;" d="M802.04,974.01l1.48,0.79c0.62-0.22,8.99-6.31,14.09-11.2l-0.85-0.53
                      C807.8,970.78,803.57,974.82,802.04,974.01z"/>
                  </g>
                  <g>
                    <path style="fill:#FFFFFF;" d="M799.94,977.73l0.27,1.43c0.55,0.38,11.81,6.76,18.6,7.96l1.94-0.62
                      C814.27,985.35,800.07,977.82,799.94,977.73z"/>
                  </g>
                  <path style="fill:#FFFFFF;" d="M813.98,982.02c0,0-11.36-10.33-27.11-13.57c-15.75-3.24-31.32,4.74-31.32,4.74
                    s-3.22,14.52,2.9,25.48c4.78,8.56,13.73,10.49,22.21,9.94c5.35-0.35,18.69-6.16,23.77-11.63
                    C809.5,991.52,813.98,982.02,813.98,982.02z"/>
                </g>
              </g>
            </g>
            <g>
              <path style="fill:#B25D32;" d="M1384.3,1873.82L1384.3,1873.82c11.26-3.93,17.19-16.24,13.26-27.5l-6.77-19.39
                c-3.93-11.26-16.24-17.19-27.5-13.26l0,0c-11.26,3.93-17.19,16.24-13.26,27.5l6.77,19.39
                C1360.73,1871.81,1373.05,1877.75,1384.3,1873.82z"/>
              <g>
                <path style="fill:#DAD9DC;" d="M1467.06,1871.91l
-69.34-24.74c-9.33,0.07-31.11,1.54-45.41,1.29
                  c0,0-17.97,21.85-13.91,48.31c1.14,7.42,2.84,5.79,13.62,6.15l103.44-1.2C1482.63,1901.41,1491.28,1879.2,1467.06,1871.91z"
                />
              </g>
              <path style="fill:#002D55;" d="M1254.87,1427.55l-110.54,2.42c-24.01,0.53-44.09-18.69-44.61-42.7l-1.99-90.96l197.86-4.33
                l1.99,90.96C1298.1,1406.95,1278.88,1427.03,1254.87,1427.55z"/>
              <path style="fill:#002D55;" d="M1146.28,1618.85l-4.4,0.1c-20.49,0.45-37.61-15.95-38.06-36.43l-5.51-251.99
                c-0.45-20.49,15.95-37.61,36.43-38.06l4.4-0.1c20.49-0.45,37.61,15.95,38.06,36.43l5.51,251.99
                C1183.16,1601.28,1166.77,1618.4,1146.28,1618.85z"/>
              <path style="fill:#002D55;" d="M1322.52,1611.88l-9.75,3.38c-18.09,6.28-37.37-6.02-42.84-27.33l-60.51-235.75
                c-5.47-21.31,4.86-43.88,22.95-50.15l9.75-3.38c18.09-6.28,37.37,6.02,42.84,27.33l60.51,235.75
                C1350.95,1583.04,1340.62,1605.61,1322.52,1611.88z"/>
              <path style="fill:#B25D32;" d="M1152.97,1880.44L1152.97,1880.44c-11.92,0.26-21.79-9.19-22.05-21.11l-0.45-20.53
                c-0.26-11.92,9.19-21.79,21.11-22.05l0,0c11.92-0.26,21.79,9.19,22.05,21.11l0.45,20.53
                C1174.34,1870.3,1164.89,1880.18,1152.97,1880.44z"/>
              <path style="fill:#002D55;" d="M1188.54,1836.62l-78.9,1.73l-6.07-277.5c-0.44-20.32,15.82-37.31,36.14-37.75l5.01-0.11
                c20.32-0.44,37.31,15.82,37.75,36.14L1188.54,1836.62z"/>
              <path style="fill:#002D55;" d="M1412.54,1820.84l-76.31,20.09l-72.4-274.95c-5.18-19.65,6.67-39.97,26.32-45.14l4.85-1.28
                c19.65-5.18,39.97,6.67,45.14,26.32L1412.54,1820.84z"/>
              <g>
                <path style="fill:#DAD9DC;" d="M1060.34,1880.81l70.44-27.78c16.86-0.37,30.36-0.06,43.17-0.66c0,0,18.9,21.05,16.01,47.66
                  c-0.81,7.46-2.58,5.91-13.33,6.74l-103.39,3.32C1046.07,1910.96,1036.46,1889.14,1060.34,1880.81z"/>
              </g>
              <g>
                <path style="fill:#282828;" d="M1153.05,928.05c0,0-6.42-30.88,14.23-37.03c0,0,16.23-3.52,21.34-12.49
                  c0,0,8.97-5.55,20.34-0.74c10.34-7.43,17.34-4.64,21.99-0.19c7.34,7.03,21.87,11.55,21.87,11.55
                  c20.9,5.24,15.84,36.37,15.84,36.37c21.3,14.73,0.89,40.5,0.89,40.5c0.39,17.72-11.92,27.74-20.32,30.19
                  c-14.3,4.18-18.07,16.22-18.07,16.22l-3.11-21.74c21.49-30.45-1.53-26.27-1.53-26.27c0.15-16.46-6.11-23.61-6.11-23.61
                  c-2.8-3.15-6.01-5.15-9.39-6.34c-3.32,1.33-6.45,3.47-9.11,6.74c0,0-5.94,7.42-5.07,23.86c0,0-17.68-0.7,5.12,28.78
                  l-0.45,13.56c0,0-11.49-6.04-25.96-9.59c-8.5-2.08-21.23-11.56-21.62-29.28C1153.94,968.55,1132.42,943.69,1153.05,928.05z"
                />
                <path style="fill:#EE7A31;" d="M1047.58,1197.87L1047.58,1197.87c-11.26-8.06-13.96-23.47-6.03-34.43l83.22-114.95
                  c7.93-10.96,23.5-13.31,34.76-5.26c11.26,8.05,13.96,23.47,6.03,34.43l-83.22,114.95
                  C1074.4,1203.57,1058.84,1205.93,1047.58,1197.87"/>
                <path style="fill:#EE7A31;" d="M1097.33,1307.76l16.97-212.35c-0.25-24.78,10.13-53.03,27.54-60.97l73.18-26.92l73.07,31.63
                  c27.73,18.1,23.59,38.94,21.29,64.21l-18.38,212.01L1097.33,1307.76z"/>
                <path style="fill:#B25D32;" d="M1196.21,1007.53l-0.07-29.97l39.2-0.63l-2.44,31.58c-0.14,3.64,1.85,6.98,5.01,8.41
                  l11.96,5.42l-3.31,11.77c-9.92,35.27-57.77,33.16-64.59-2.85l-2.21-11.64l10.92-4.14
                  C1193.88,1014.28,1196.07,1011.13,1196.21,1007.53"/>
                <polygon style="fill:#7B4014;" points="1208.01,988.35 1232.93,1009.82 1234.57,986.63 								"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M1207.39,976.68
                  c0,0,2.54,2.48,8.36,2.7c5.07,0.19,7.67-2.9,7.67-2.9"/>
                <path style="fill:#B25D32;" d="M1236.97,908.03l-24.41,0.17c-10.6,0.07-19.14,8.34-19.07,18.47l0.36,52.12
                  c0.07,10.12,8.72,18.27,19.32,18.2l24.41-0.17c10.6-0.07,19.14-8.34,19.07-18.46l-0.36-52.12
                  C1256.22,916.11,1247.57,907.96,1236.97,908.03"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M1255.04,938.31
                  c0,0-8.36-0.92-14.03-0.69"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M1226.14,938.22
                  c0,0-9.99-0.99-15.63-0.31"/>
                <polyline style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" points="1235.81,954.01
                  1236.67,968.6 1229.24,969.12 								"/>
                <path style="fill:#232322;" d="M1220.74,946.87c0.1,1.29-0.81,2.42-2.02,2.53c-1.22,0.11-2.28-0.85-2.38-2.13
                  c-0.1-1.29,0.81-2.42,2.02-2.53C1219.57,944.62,1220.64,945.58,1220.74,946.87z"/>
                <path style="fill:#232322;" d="M1248.54,944.89c0.1,1.29-0.81,2.42-2.02,2.53c-1.22,0.11-2.28-0.85-2.38-2.13
                  c-0.1-1.29,0.81-2.42,2.02-2.53C1247.37,942.65,1248.44,943.6,1248.54,944.89z"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.7356;stroke-miterlimit:10;" d="M1237.02,980.75
                  c0,0-8.4,2.89-14.03-2.5"/>
                <path style="fill:#EE7A31;" d="M1379.48,1206.27L1379.48,1206.27c11.42-7.83,14.43-23.18,6.72-34.3l-80.89-116.6
                  c-7.71-11.12-23.22-13.78-34.64-5.96c-11.42,7.83-14.43,23.18-6.72,34.3l80.89,116.6
                  C1352.55,1211.43,1368.06,1214.1,1379.48,1206.27"/>
                <g>
                  <path style="fill:#EE7A31;" d="M1226.86,1327.83L1226.86,1327.83c-10.05-11.1-9.2-29.23,1.88-40.5l116.27-118.14
                    c11.09-11.26,28.22-11.39,38.26-0.29l0,0c10.05,11.1,9.2,29.23-1.88,40.5l-116.27,118.14
                    C1254.04,1338.81,1236.91,1338.94,1226.86,1327.83z"/>

                  <line style="fill:none;stroke:#DB6638;stroke-width:0.7491;stroke-miterlimit:10;" x1="1265.13" y1="1327.54" x2="1361.47" y2="1229.65"/>

                  <line style="fill:none;stroke:#DB6638;stroke-width:0.7491;stroke-miterlimit:10;" x1="1236.01" y1="1281.23" x2="1332.35" y2="1183.34"/>
                </g>

                <ellipse transform="matrix(0.3857 -0.9226 0.9226 0.3857 -449.7861 1952.3962)" style="fill:#B25D32;" cx="1241.2" cy="1313.95" rx="19.36" ry="17.88"/>
                <path style="fill:#B25D32;" d="M1251.56,1316.33c-1.76,22.4-34.39,35.98-39.86,40.01c-5.47,4.03-15.24,7.39-16.78,5.39
                  c-1.54-2,4.86-6.54,4.86-6.54s-11.27,9.52-12.51,8c-1.24-1.53,2.14-7.34,2.14-7.34s-5.67,7.67-6.17,4.02
                  c-0.5-3.64,3.56-9.25,3.56-9.25s-7.42,7.74-1.72-7.11c5.7-14.84,21.97-25.43,21.97-25.43s-14.43,6.27-15.84,6.44
                  c-1.41,0.17-6.62-3.49,0.9-8.86c7.53-5.37,29.78-13.05,29.78-13.05S1253.32,1293.93,1251.56,1316.33z"/>
                <path style="fill:#282828;" d="M1257.51,920.58c0.22,4.04-33.59,9.27-54,1.77c0,0-9.57-4.47-33.2-18.94
                  c0,0,19.99-38.68,63.62-21.31C1241.77,885.23,1256.24,897.22,1257.51,920.58z"/>
                <path style="fill:#282828;" d="M1203.65,906.1c0,0,0.87,41.89-9.12,47.14l-10.93,6.22c0,0,0.29,5.41,10.9,14.55l1.7,13.46
                  l0.07,5.97c0,0-25.35-15.13-31.24-34.16c-4.33-13.98,0.12-39.86,1.77-53.97L1203.65,906.1z"/>
                <path style="fill:#B25D32;" d="M1194.96,953.97c0,0-6.1-7.75-10.75-5.07c-4.64,2.67-1.25,24.65,7,27.15
                  C1199.47,978.55,1198.78,959,1194.96,953.97"/>
                <g>
                  <path style="fill:#EE7A31;" d="M1197.72,1322.48L1197.72,1322.48c10.27-10.9,9.79-29.04-1.07-40.53l-113.87-120.45
                    c-10.86-11.48-27.98-11.96-38.25-1.06h0c-10.27,10.9-9.79,29.04,1.07,40.53l113.87,120.45
                    C1170.33,1332.9,1187.45,1333.38,1197.72,1322.48z"/>

                  <line style="fill:none;stroke:#DB6638;stroke-width:0.7491;stroke-miterlimit:10;" x1="1159.47" y1="1321.42" x2="1065.12" y2="1221.61"/>

                  <line style="fill:none;stroke:#DB6638;stroke-width:0.7491;stroke-miterlimit:10;" x1="1189.52" y1="1275.7" x2="1095.17" y2="1175.89"/>
                </g>

                <ellipse transform="matrix(0.9302 -0.367 0.367 0.9302 -397.5888 525.7655)" style="fill:#B25D32;" cx="1183.67" cy="1308.31" rx="17.88" ry="19.36"/>
                <path style="fill:#B25D32;" d="M1203.2,1297.36c0,0,22.09,8.12,29.51,13.64c7.42,5.52,2.14,9.08,0.73,8.88
                  c-1.41-0.2-15.71-6.76-15.71-6.76s16.05,10.91,21.45,25.87c5.4,14.95-1.86,7.07-1.86,7.07s3.95,5.69,3.37,9.32
                  c-0.57,3.63-6.09-4.15-6.09-4.15s3.27,5.88,2,7.38c-1.27,1.5-12.35-8.25-12.35-8.25s6.3,4.67,4.72,6.64
                  c-1.58,1.97-11.28-1.58-16.67-5.72c-5.39-4.14-37.73-18.37-39.05-40.8C1171.95,1288.05,1203.2,1297.36,1203.2,1297.36z"/>
                <g>
                  <g>
                    <path style="fill:#FFFFFF;" d="M1210.32,963.31l-1.46,0.82c-0.63-0.21-9.12-6.11-14.33-10.89l0.84-0.55
                      C1204.5,960.21,1208.81,964.16,1210.32,963.31z"/>
                  </g>
                  <g>
                    <path style="fill:#FFFFFF;" d="M1212.5,966.98l-0.24,1.43c-0.54,0.39-11.66,7.01-18.42,8.36l-1.95-0.58
                      C1198.35,974.91,1212.37,967.08,1212.5,966.98z"/>
                  </g>
                  <path style="fill:#FFFFFF;" d="M1198.57,971.58c0,0,11.13-10.58,26.8-14.16c15.67-3.58,31.41,4.06,31.41,4.06
                    s3.54,14.45-2.34,25.54c-4.59,8.66-13.5,10.79-21.99,10.43c-5.36-0.23-18.82-5.75-24.01-11.11
                    C1203.25,980.98,1198.57,971.58,1198.57,971.58z"/>
                </g>
              </g>
            </g>
            <g>
              <path style="fill:#002D55;" d="M1073.3,1624.49h3.98c18.53,0,33.69-15.16,33.69-33.69v-227.9
                c0-18.53-15.16-33.69-33.69-33.69h-3.98c-18.53,0-33.69,15.16-33.69,33.69v227.9
                C1039.61,1609.33,1054.77,1624.49,1073.3,1624.49z"/>
              <path style="fill:#ED926E;" d="M1072.63,1870.08L1072.63,1870.08c10.78,0,19.52-8.74,19.52-19.52v-18.57
                c0-10.78-8.74-19.52-19.52-19.52l0,0c-10.78,0-19.52,8.74-19.52,19.52v18.57
                C1053.11,1861.34,1061.85,1870.08,1072.63,1870.08z"/>
              <path style="fill:#002D55;" d="M1039.61,1831.18h71.36v-250.98c0-18.38-15.04-33.41-33.41-33.41h-4.53
                c-18.38,0-33.41,15.04-33.41,33.41V1831.18z"/>
              <g>
                <path style="fill:#DAD9DC;" d="M1156.38,1868.58l-64.23-23.72c-15.25,0-27.45,0.55-39.04,0.26c0,0-16.67,19.4-13.53,43.4
                  c0.88,6.73,2.45,5.29,12.19,5.83l93.53,0.96C1169.88,1895.56,1178.13,1875.65,1156.38,1868.58z"/>
              </g>
              <path style="fill:#002D55;" d="M1070.63,1440.59h-108.3c-23.22,0-42.22-19-42.22-42.22v-44.8c0-23.22,19-42.22,42.22-42.22
                h108.3c23.22,0,42.22,19,42.22,42.22v44.8C1112.85,1421.59,1093.85,1440.59,1070.63,1440.59z"/>
              <path style="fill:#002D55;" d="M957.78,1624.49h-3.98c-18.53,0-33.69-15.16-33.69-33.69v-227.9
                c0-18.53,15.16-33.69,33.69-33.69h3.98c18.53,0,33.69,15.16,33.69,33.69v227.9C991.46,1609.33,976.3,1624.49,957.78,1624.49z
                "/>
              <path style="fill:#ED926E;" d="M958.45,1870.08L958.45,1870.08c-10.78,0-19.52-8.74-19.52-19.52v-18.57
                c0-10.78,8.74-19.52,19.52-19.52h0c10.78,0,19.52,8.74,19.52,19.52v18.57C977.97,1861.34,969.23,1870.08,958.45,1870.08z"/>
              <path style="fill:#002D55;" d="M991.46,1831.18h-71.36v-250.98c0-18.38,15.04-33.41,33.41-33.41h4.53
                c18.38,0,33.41,15.04,33.41,33.41V1831.18z"/>
              <g>
                <path style="fill:#DAD9DC;" d="M874.7,1868.58l64.23-23.72c15.25,0,27.45,0.55,39.04,0.26c0,0,16.67,19.4,13.53,43.4
                  c-0.88,6.73-2.45,5.29-12.19,5.83l-93.53,0.96C861.2,1895.56,852.95,1875.65,874.7,1868.58z"/>
              </g>
              <g>
                <g>
                  <path style="fill:#7BCBEF;" d="M969.88,1081.86l-122.65,129.88c-11.1,11.76-29.8,12.29-41.56,1.19l-0.03-0.03
                    c-11.76-11.1-12.29-29.8-1.19-41.56l122.65-129.88c11.1-11.76,29.8-12.29,41.56-1.19l0.03,0.03
                    C980.44,1051.4,980.98,1070.1,969.88,1081.86z"/>
                </g>
                <path style="fill:#7BCBEF;" d="M910.75,1356.2l-0.45-250.9c-1.18-28.4,9.21-62.53,27.92-72.32l82.49-31.09l87.22,35.88
                  c19.13,9.99,17.17,39.93,15.59,69.01l-3.73,249.38L910.75,1356.2z"/>
                <polygon style="fill:#ED926E;" points="873.65,1317.36 852.36,1330.51 861.12,1343.57 886.73,1341.25 								"/>
                <path style="fill:#7BCBEF;" d="M834.32,1318.47l-36.35-112.22c-4.99-15.39,3.53-32.07,18.92-37.06h0
                  c15.39-4.99,32.07,3.53,37.06,18.92l36.35,112.22c4.99,15.39-3.53,32.07-18.92,37.06l0,0
                  C855.98,1342.38,839.31,1333.87,834.32,1318.47z"/>
                <path style="fill:#ED926E;" d="M886.73,1341.25c0,0,14.5,28.81,16.06,34.02c1.13,3.77-1.58,7.14-13.76-7.69
                  c0,0-5.81-7.56-5.62,29.76c0.02,4.3-0.36,25.47-4.97,16.48c0,0-19.17-36.78-17.33-70.25L886.73,1341.25z"/>
                <g>
                  <path style="fill:#7BCBEF;" d="M1074.71,1085.44l116.27,135.63c10.52,12.28,29.18,13.71,41.45,3.19l0.03-0.03
                    c12.28-10.52,13.71-29.18,3.19-41.45l-116.27-135.63c-10.52-12.28-29.18-13.71-41.45-3.19l-0.03,0.03
                    C1065.62,1054.52,1064.19,1073.17,1074.71,1085.44z"/>
                </g>
                <polygon style="fill:#ED926E;" points="1159.51,1325.3 1180.15,1339.46 1170.77,1352.08 1145.3,1348.54 								"/>
                <path style="fill:#7BCBEF;" d="M1198.74,1328.3l41.71-110.34c5.72-15.14-1.98-32.2-17.12-37.92l0,0
                  c-15.14-5.72-32.2,1.98-37.92,17.12l-41.71,110.34c-5.72,15.14,1.98,32.2,17.12,37.92l0,0
                  C1175.96,1351.14,1193.02,1343.44,1198.74,1328.3z"/>
                <path style="fill:#ED926E;" d="M1145.3,1348.54c0,0-15.87,28.08-17.68,33.21c-1.31,3.72,1.24,7.21,14.11-7.02
                  c0,0,6.17-7.27,4.18,29.99c-0.23,4.3-0.86,25.46,4.17,16.7c0,0,20.92-35.82,20.68-69.34L1145.3,1348.54z"/>
                <path style="fill:#ED926E;" d="M991.52,976.01l44.65-1.01l19.41,40.5c-17.55,20.78-24.09,27.17-39.56,40.84
                  c0,0-27.24-26.7-36.5-39.68L991.52,976.01z"/>
                <path style="fill:#D87F60;" d="M991.52,976.01l44.65-1.01l7.6,15.5c-8.17,8.7-17.28,14.48-28.08,14.71
                  c-13.08,0.28-25.23-8.5-27.59-17.61L991.52,976.01z"/>
                <path style="fill:#ED926E;" d="M1031.17,990.85h-27.51c-17.94,0-32.49-14.55-32.49-32.49v-94.94
                  c0-7.1,5.76-12.86,12.86-12.86h66.76c7.1,0,12.86,5.76,12.86,12.86v94.94C1063.66,976.31,1049.12,990.85,1031.17,990.85z"/>
                <path style="fill:#ED926E;" d="M969.63,916.58c0,0-8.28-9.89-13.48-5.59c-5.2,4.3,1.66,34.17,11.83,36.54
                  C978.15,949.9,974.84,923.03,969.63,916.58"/>
                <path style="fill:#ED926E;" d="M1062.72,916.58c0,0,7.52-9.89,12.25-5.59c4.73,4.3-1.5,34.17-10.74,36.54
                  C1054.98,949.9,1058,923.03,1062.72,916.58"/>
                <path style="fill:#232322;" d="M1001.47,916.63c0,1.8-1.46,3.26-3.26,3.26c-1.8,0-3.26-1.46-3.26-3.26
                  c0-1.8,1.46-3.26,3.26-3.26C1000.01,913.37,1001.47,914.83,1001.47,916.63"/>
                <path style="fill:#232322;" d="M1043.1,916.63c0,1.8-1.46,3.26-3.26,3.26c-1.8,0-3.26-1.46-3.26-3.26
                  c0-1.8,1.46-3.26,3.26-3.26C1041.64,913.37,1043.1,914.83,1043.1,916.63"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.8482;stroke-miterlimit:10;" d="M1052.72,907.89
                  c0,0-13.97-1.91-19.7-0.64"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.8482;stroke-miterlimit:10;" d="M1004.81,907.89
                  c0,0-13.97-1.91-19.7-0.64"/>
                <path style="fill:none;stroke:#232322;stroke-width:0.8482;stroke-miterlimit:10;" d="M1016.73,925.62
                  c0,0-2.58,9.61-3.35,17.36c-0.17,1.71,1.18,3.19,2.9,3.23c1.93,0.05,4.04,0.1,4.04,0.1"/>
                <path style="fill:#511E0A;" d="M971.18,918.49c0,0,2.9-29.52,29.68-29.52c0,0,39.68,4.92,45.63-16.72
                  c0,0,14.91,43.27,16.86,45.23c0,0,15.43-28.94,6.19-52.33c-10.08-25.53-83.42-50.61-115.64,10.37
                  C948.8,885.19,948.74,900.75,971.18,918.49z"/>
                <rect x="967.81" y="913.37" style="fill:#511E0A;" width="3.9" height="17.77"/>
                <rect x="1061.41" y="913.37" style="fill:#511E0A;" width="3.9" height="17.77"/>
                <path style="fill:#FFFFFF;" d="M1056.58,940.1l-2.05-0.52c0.16-0.62,2.36-15.41,10.3-24.88l1.74,0.72
                  C1058.93,924.52,1056.61,939.95,1056.58,940.1z"/>
                <path style="fill:#FFFFFF;" d="M1058.87,950.97l0.45,1.83c1.61-0.63,3.9-2.02,4.05-2.03v-2.05
                  C1063.3,948.71,1061.54,949.37,1058.87,950.97"/>
                <path style="fill:#FFFFFF;" d="M975.94,950.74l0.99-1.6c-1.58-0.71-5.63-1.87-5.75-1.96v1.94
                  C971.22,949.16,972.93,949.96,975.94,950.74"/>
                <path style="fill:#FFFFFF;" d="M979.32,940.1l2.05-0.52c-0.16-0.62-4-15.41-11.94-24.88l-1.62,1.36
                  C975.44,925.16,979.28,939.95,979.32,940.1z"/>
                <path style="fill:#FFFFFF;" d="M971.71,937.4c0,0,13.17-7.28,34.01-11.8c20.83-4.52,46.32,8.68,57.27,17.4
                  c0,0-2.02,5.27-5.99,17.95c-8.25,26.33-19.27,31.65-43.49,31.64C990.55,992.58,971.78,970.54,971.71,937.4z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</g>
</svg>

        <div id="tooltip" class="bg-white p-4 absolute w-64 rounded-lg shadow-md" style="display: none;">
          <h3 class=" border-b border-b-orange-mid pb-2 mb-2 capitalize text-xl" v-html="toolTipObject.title"></h3>
          <p class="text-sm border-b0 pb-2 mb-2" v-html="toolTipObject.dscpn"></p>
          <p class="text-sm inline-block border-b border-b-orange-mid pb-1">Read More</p>
        </div>
      </div>

        <div class="absolute top-40 left-0 p-8 z-20">
            <h4 class="mb-2">Wheel Controls</h4>
            <div class="b-blue-darkest p-2 mb-2 bg-white block rounded-lg rounded cursor-pointer flex items-center justify-between" @click="rotateLeft">Rotate Right
                <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="b-blue-darkest p-2 mb-2 bg-white block rounded-lg rounded cursor-pointer flex items-center justify-between" @click="rotateRight">Rotate Left
                <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="b-blue-darkest p-2 mb-2 bg-white block rounded-lg rounded cursor-pointer flex items-center justify-between" @click="rotateInnerLeft">Rotate Inner Right
                <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="b-blue-darkest p-2 mb-2 bg-white block rounded-lg rounded cursor-pointer flex items-center justify-between" @click="rotateInnerRight">Rotate Inner Left
                <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>

    </div>
</template>
<script>
import {SVG} from '@svgdotjs/svg.js';


export default {
  name: "wheelsvg",
  props:['data'],

  data: function () {
    return {
      'outerCenter': {centerX: 0, centerY: 0, diameter: 0},
      'innerCircle': {centerX: 0, centerY: 0, diameter: 0},
      'saves': [],
      rotation: 0,
      innerRotation: 0,
      currentWedge: null,
      toolTipObject: {title:'',dscpn:''}
    }
  },
  mounted() {
    let outerCircle = document.getElementById('outer-circle');
    let outerCircleSVG = SVG(document.getElementById('outer-circle'));
    this.outerCenter.centerX = outerCircleSVG.cx()
    this.outerCenter.centerY = outerCircleSVG.cy()
    this.outerCenter.diameter = outerCircleSVG.width();

    this.bindItems(outerCircle.children, 'outerCenter')

    let innerCircle = document.getElementById('inner-circle');
    let innerCircleSVG = SVG(document.getElementById('inner-circle'));
    this.innerCircle.centerX = innerCircleSVG.cx()
    this.innerCircle.centerY = innerCircleSVG.cy()
    this.innerCircle.diameter = innerCircleSVG.width();
    this.bindItems(innerCircle.children, 'innerCircle')

  },
  methods: {
    bindItems(items, key) {
      let that = this;
      for (var i = 0; i < items.length; i++) {
        items[i].classList.add('cursor-pointer');
        items[i].addEventListener('click', function (e) {
          if(key === 'innerCircle'){
            that.$emit('sector', e.target.closest("#inner-circle > g").id)
          }else{
            that.$emit('sector', e.target.closest("#outer-circle > g").id)
          }

        })
        items[i].addEventListener('mouseenter', function (e) {
          let element = SVG('#' + e.target.id);
          that.currentWedge = e.target.id;
          let elementCenterX = element.cx();
          let elementCenterY = element.cy();
          that.saves[e.target.id] = element.transform();
          let tooltip = document.getElementById("tooltip");
          tooltip.style.display = "block";
          let deltaX = ((that.$data[key].centerX - elementCenterX) / 50);
          let deltaY = ((that.$data[key].centerY - elementCenterY) / 50);
          that.data.forEach(function(item){
            if(item.slug === e.target.id){
              that.toolTipObject.title = item.title.rendered;
              that.toolTipObject.dscpn = item.acf.acf.description;
            }
          })
          element.animate().transform({
            translateX: deltaX,
            translateY: deltaY,
          })
        });
        items[i].addEventListener('mouseleave', function (e) {
          let element = SVG('#' + e.target.id);
          that.currentWedge = null;
          var tooltip = document.getElementById("tooltip");
          tooltip.style.display = "none";
          that.toolTipObject = {title:'',dscpn:''};
          element.animate().transform({
            translateX: 0,
            translateY: 0,
          })
        });
      }
    },
    cursorPlotter(event) {
      let tooltip = document.getElementById("tooltip");
      let container = document.getElementById("svg-relative");
      var bodyRect = document.body.getBoundingClientRect(),
          elemRect = container.getBoundingClientRect(),
          offset   = elemRect.top - bodyRect.top;
      let x = event.pageX - container.offsetLeft;
      let y = event.pageY - (container.offsetTop - window.scrollY );
      tooltip.style.left = (x + 20) + 'px';
      tooltip.style.top = (y - offset) + 'px';
    },
    getRelativeCoordinates(event, referenceElement) {

      const position = {
        x: event.pageX,
        y: event.pageY
      };

      const offset = {
        left: referenceElement.offsetLeft,
        top: referenceElement.offsetTop
      };

      let reference = referenceElement.offsetParent;

      while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
      }

      return {
        x: position.x - offset.left,
        y: position.y - offset.top,
      };

    },
    rotateLeft() {
      this.rotation = this.rotation + 45;
      let circle = SVG(document.getElementById('outer-circle'));
      let circleTitles = SVG(document.getElementById('outer-titles'));
      circle.animate().transform({rotate: this.rotation})
      circleTitles.animate().transform({rotate: this.rotation})
    },
    rotateRight() {
      this.rotation = this.rotation - 45;
      let circle = SVG(document.getElementById('outer-circle'));
      let circleTitles = SVG(document.getElementById('outer-titles'));
      circle.animate().transform({rotate: this.rotation})
      circleTitles.animate().transform({rotate: this.rotation})
    },
    rotateInnerLeft() {
      this.innerRotation = this.innerRotation + 45;
      let circle = SVG(document.getElementById('inner-circle'));
      let circleTitles = SVG(document.getElementById('inner-titles'));
      circle.animate().transform({rotate: this.innerRotation})
      circleTitles.animate().transform({rotate: this.innerRotation})
    },
    rotateInnerRight() {
      this.innerRotation = this.innerRotation - 45;
      let circle = SVG(document.getElementById('inner-circle'));
      let circleTitles = SVG(document.getElementById('inner-titles'));
      circle.animate().transform({rotate: this.innerRotation})
      circleTitles.animate().transform({rotate: this.innerRotation})
    }
  }
}
</script>
<style>

.dark-blue {
  color: #044A6D;
}
</style>