import {createApp}  from 'vue'
import App from './App.vue'
import VueKinesis from "vue-kinesis";
import WheelView from './Page/wheelview'
import SectorDisplay from './Page/SectorDisplay'
import ProductDisplay from './Page/ProductDisplay'
import { createRouter, createWebHistory } from 'vue-router'
import VueGtag from "vue-gtag";

import './assets/styles/index.css';
import './assets/tailwind.css';
import 'swiper/swiper-bundle.css';
import AboutDisplay from "./Page/AboutDisplay";
import SuccessPage from "./Page/SuccessPage";

const routes = [
    { path: '/', component: WheelView, name:'home' },
    { path: '/success', component: SuccessPage, name:'success' },
    { path: '/sector/:tag', component: SectorDisplay, name:'sector.view' },
    { path: '/product/:tag', component: ProductDisplay, name:'product.view' },
    { path: '/about', component: AboutDisplay, name:'about.view' },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
            window.scrollTo(0, 0);
            document.getElementById("mainApp").scrollTo(0, 0);
    },
})

const app = createApp(App)
app.use(VueGtag, {
    config: { id: 'AW-319444870'}
})
app.use(router)
app.use(VueKinesis)
app.mount('#app')
