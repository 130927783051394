<template>
    <div class="main-circle relative z-10">
      <div class="w-full h-screen md:h-screen-60 relative">
        <div class="absolute z-20 flex flex-wrap justify-center items-center content-center top-0 left-0 w-full h-full">
          <div class="w-60 mb-6 mx-auto">
            <img :src="'/images/icons/' + sectorPage.tag + '.svg'"/>
          </div>
          <div class="w-full text-center">
            <h1 class="text-5xl lg:text-6xl text-b-orange-mid tracking-wide" v-html="sectorPage.title"></h1>
          </div>
        </div>

            <kinesis-container class="main-container z-10">
                <kinesis-element v-for="circle in circles" :strength="(circle * 2)" v-once :key="'circle-' + circle"
                                 class="circle"
                                 :style="generateStyle(circle)"></kinesis-element>
            </kinesis-container>
        </div>
      <div class="w-full md:h-screen-60 relative bg-white" v-if="typeof sectorPage.acf.video_link !== 'undefined' && sectorPage.acf.video_link !== ''">
        <iframe class="w-full h-96 p-6 md:w-1/2 md:h-full mx-auto py-12" :src="sectorPage.acf.video_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
        <div id="main-content"  class="w-full relative bg-b-blue-darkest py-24">
            <div class="container md:flex flex-wrap">

                <div class="px-8 md:px-0 w-full md:w-6/12 text-white mb-8 md:mb-0">



                    <h3 class="text-4xl text-b-orange-mid mb-4" v-html="sectorPage.title"></h3>

                    <div class="mb-4 prose">
                        <div class="tracking-wide text-lg" v-html="sectorPage.content"></div>
                    </div>

                    <a href="#" class="w-24 bg-white text-b-blue-darkest p-2 inline-block rounded-lg text-center tracking-wide"
                       @click="goBack">Back</a>
                </div>

                <div class="w-full md:w-6/12 w-6/12 px-8 md:px-0 md:pl-28">
                  <div class="mb-4">
                    <contact-form @completed="formSent"> <template v-slot:button-text>Get your free sample today</template></contact-form>
                  </div>

                    <h3 class="text-2xl text-b-orange-mid mb-4">Suitable COVID-19 Range</h3>
                    <ul>
                        <li class="mb-4" v-for="product in sectorPage.acf.products" :key="product.ID">
                            <router-link class="p-2 bg-white block rounded-lg tracking-wide text-lg"
                                         :to="{name : 'product.view', params:{tag : product.post_name}}">
                                {{ product.post_title }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ContactForm from "../components/form";
const axios = require('axios');
import debounce from 'lodash/debounce';

export default {
    name: "SectorDisplay",
    data() {
        return {
            circles: 50,
            sectorPage: {title: '', content: '', tag: '', acf: []},
        }
    },
  components:{
    ContactForm
  },
  beforeUnmount() {
    document.getElementById('mainApp').removeEventListener('scroll', this.handleDebouncedScroll);
  },
    mounted() {
        let that = this;
      this.handleDebouncedScroll = debounce(this.changeLogo, 100);
      document.getElementById('mainApp').addEventListener('scroll', this.handleDebouncedScroll);
      this.$emit('logoChange', "bg-transparent");
        axios.get('/admin/wp-json/wp/v2/posts?per_page=50')
            .then(function (response) {
                response.data.forEach(function (page) {
                    if (page.slug === that.$route.params.tag) {
                        that.sectorPage.title = page.title.rendered;
                        that.sectorPage.content = page.content.rendered;
                        that.sectorPage.tag = page.slug;
                        axios.get('/admin/wp-json/acf/v3/posts/' + page.id)
                            .then(function (response) {
                                that.sectorPage.acf = response.data.acf;
                            })
                    }

                });
            });
    },
    methods: {
      formSent(){
        this.$router.push('/success')
      },
        goBack() {
            this.$router.go(-1)
        },
        generateStyle() {
            let style = {
                border: "10px solid #FFF",
                opacity: 0.1,
                width: "4vw",
                height: "4vw",
            };
            let borderSize = this.randomIntFromInterval(1, (5))
            let widthSize = this.randomIntFromInterval(100, (1000)) / 1000;

            style.border = borderSize + 'px solid #FFF';
            style.opacity = this.randomIntFromInterval(100, 1000) / 1000;
            style.width = widthSize + 'vw';
            style.height = widthSize + 'vw';
            style.left = this.randomIntFromInterval(1, 100) + '%';
            style.top = this.randomIntFromInterval(1, 100) + '%';

            return style;
        },
        randomIntFromInterval(min, max) { // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
      changeLogo() {
        let element = document.getElementById('main-content');
        let yPosition = this.getPosition(element);
        console.log(yPosition);
        if(yPosition < 80){
          this.$emit('logoChange', "bg-b-blue-darkest");
        }else{
          this.$emit('logoChange', "bg-transparent");
        }
      },
      getPosition(element) {
        let yPosition = 0;
        while(element) {
          yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
          element = element.offsetParent;
        }
        return yPosition;
      }
    }
}
</script>
<style scoped>

</style>