<template>
  <div id="mainApp" class="page overflow-y-scroll overflow-x-hidden text-b-blue-darkest">
    <header class="w-full h-24 fixed z-40" :class="bgClass">
      <Logo :colour="logoColor"></Logo>
      <About :colour="logoColor" @colourChange="colourChange"></About>
    </header>
    <router-view @logoChange="logoColour" ></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Logo from "./components/brand/logo";
import About from "./components/brand/about"
import Footer from "./components/brand/footer"
export default {
  name: 'App',
  data() {
    return {
      logoColor: "white",
      bgClass: "bg-transparent"
    }
  },
  methods: {
    logoColour(colour) {
      if(colour === "bg-transparent-home"){
        this.bgClass = "bg-transparent";
        this.logoColor = "white";
      }else if(colour === "bg-b-blue-darkest"){
        this.bgClass = colour;
        this.logoColor = "white";
      }else{
        this.bgClass = colour;
        this.logoColor = "#004B6B";
      }

    },
    colourChange(option){
      console.log(option);
    }
  },
  components: {
    Logo,
    About,
    Footer,
  }
}
</script>

<style>
.page {
  width: 100%;
  height: 100%;
  position: relative;
  background: #DCECEF;
}

@font-face {
  font-family: "PaneuropaNeueNormal";
  src: url("/fonts/PaneuropaNeueNormal.woff") format('woff');
  font-weight: normal;
}

</style>
