<template>

    <div class="main-circle relative z-10">

        <div class="w-full relative bg-b-blue-darkest py-36 px-8 md:px-0">
            <div class="container ">

                <h3 class="text-4xl text-b-orange-mid mb-4">About</h3>
                <div class="md:w-6/12 mb-4 prose">
                    <h3>The Novacyt Group - a global specialist diagnostic solutions provider within the healthcare
                        industry.</h3>
                </div>

                <div class=" md:flex flex-wrap text-white">
                    <div class="md:w-6/12 md:pr-2 tracking-wide mb-4 md:mb-0 prose text-lg">

                        <p>At Novacyt Group, we pride ourselves in concentrating our expertise on specific market needs.
                            Offering a comprehensive and developing portfolio of quality products, backed by proven
                            supply and delivery services, to ensure the highest possible standards of customer service
                            around the world. Our passion is for customer-centric solutions that advance the science
                            behind diagnostics. This fuels our drive to deliver products that save lives and help in the
                            fight against infectious disease.</p>

                        <p>We offer an increasing portfolio of in-vitro diagnostic tests, utilising molecular and
                            protein detection technologies – supporting healthcare and disease prevention across the
                            globe. Deep strengths in product development, commercialisation, contract design and
                            manufacturing ensure quality products and robust supply. Our Group has specialist experience
                            and expertise throughout our business units: Primerdesign, Microgen Bioproducts, IT-IS
                            International and Lab21 Healthcare – creating solutions for the diagnostics industry,
                            supplying an extensive range of high-quality assays and reagents worldwide.</p>


                    </div>
                    <div class="md:w-6/12  md:pl-4 tracking-wide prose text-lg">

                        <p>We directly serve the microbiology, haematology, and serology markets, with customers ranging
                            from hospitals to multinational corporates. We are also investing in innovative new product
                            development in therapy areas such as infectious disease testing, respiratory and
                            transplant.</p>

                        <p>As a pioneer in clinical diagnostics, we have a proven history of responding quickly to changing
                            global health needs, including providing testing solutions for Zika, swine flu, and Ebola
                            viruses. Solidifying this position, we were among the first to respond to the COVID-19
                            pandemic in 2020, providing a rapid and reliable CE-IVD marked SARS-CoV-2 test kit.
                        </p>


                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "AboutDisplay",
    mounted() {
        this.$emit('logoChange', "bg-transparent");
    },
    methods: {}
}
</script>

<style scoped>

</style>