<template>
  <div>
    <div class="flex items-center">
      <h3 class="text-2xl w-full pt-4 px-8 mb-4">{{ currentTitleInner }}</h3>
      <p class="text-sm text-center w-48">Swipe to navigate</p>
    </div>
    <div>
      <swiper
          :slides-per-view="InnerCircleSlides"
          grabCursor
          :pagination="{ clickable: true }"
          @slideChange="onSlideChangeInner"
          @swiper="setInnerCircleSwiper"
          @click="goToSectorInner"
      >
        <template v-for="slugObject in innerCircle" :key="slugObject.tag">
          <template v-for="item in data" :key="item.id">
            <swiper-slide v-if="slugObject.tag === item.slug">
              <div :data-item="slugObject.tag" class="element-swiper text-center h-56 p-4 flex flex-wrap align-center justify-center content-center" :style="'background-color:'+slugObject.color+';color:'+slugObject.text">
                <img class="block w-24 h-24 mx-auto w-full mb-2" :src="'/images/icons/'+ item.slug +'.svg'">
                <p class="bottom-0 w-full" v-html="item.title.rendered"></p>
              </div>
            </swiper-slide>
          </template>
        </template>
      </swiper>
    </div>


    <div class="flex items-center">
      <h3 class="text-2xl w-full pt-4 px-8 mb-4">{{ currentTitleOuter }}</h3>
      <p class="text-sm text-center w-48">Swipe to navigate</p>
    </div>

    <div>
      <swiper
          :slides-per-view="OuterCircleSlides"
          grabCursor
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange"
          @swiper="setOuterCircleSwiper"
          @click="goToSectorOuter"
      >
        <template v-for="slugObject in outerCircle" :key="slugObject.tag">
          <template v-for="item in data" :key="item.id">
            <swiper-slide v-if="slugObject.tag === item.slug">
              <div :data-item="slugObject.tag" class="element-swiper text-center h-56 p-4 flex flex-wrap align-center justify-center content-center" :style="'background-color:'+slugObject.color+';color:'+slugObject.text">
                <img class="block w-24 h-24 mx-auto w-full mb-2" :src="'/images/icons/'+ item.slug +'.svg'">
                <p class="bottom-0 w-full" v-html="item.title.rendered"></p>
              </div>
            </swiper-slide>
          </template>
        </template>
      </swiper>
    </div>

  </div>

</template>

<script>

import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  name: "outer-swiper",
  props: ['data'],
  components: {
    Swiper,
    SwiperSlide,
  },
  data: function () {
    return {
      OuterCircleSlides: 2,
      InnerCircleSlides: 2,
      currentTitleOuter: 'Offshore',
      currentTitleInner: 'Hospital',
      outerCircleSwiper: null,
      innerCircleSwiper: null,
      outerCircle: [
        {
          tag: 'oil-rig',
          color: '#6AD0EF',
          text: '#FFFFFF'
        },
        {
          tag: 'large-ships',
          color: '#6AD0EF',
          text: '#FFFFFF'
        },
        {
          tag: 'cruise',
          color: '#6AD0EF',
          text: '#FFFFFF'
        },
        {
          tag: 'sme',
          color: '#044A6D',
          text: '#FFFFFF'
        },
        {
          tag: 'mncs',
          color: '#065777',
          text: '#FFFFFF'
        },
        {
          tag: 'educational',
          color: '#FFFFFF',
          text: '#044A6D'
        },
        {
          tag: 'airport',
          color: '#EE7A31',
          text: '#FFFFFF'
        },
        {
          tag: 'ports',
          color: '#EE7A31',
          text: '#FFFFFF'
        },
        {
          tag: 'surge-management',
          color: '#5CBCE2',
          text: '#FFFFFF'
        },
        {
          tag: 'defence',
          color: '#FFFFFF',
          text: '#044A6D'
        },
        {
          tag: 'police-force',
          color: '#076489',
          text: '#FFFFFF'
        },
        {
          tag: 'firefighters',
          color: '#076489',
          text: '#FFFFFF'
        },
        {
          tag: 'ambulance',
          color: '#076489',
          text: '#FFFFFF'
        },
        {
          tag: 'paramedics',
          color: '#076489',
          text: '#FFFFFF'
        },
        {
          tag: 'events',
          color: '#ED8951',
          text: '#FFFFFF'
        },
        {
          tag: 'sports',
          color: '#ED8951',
          text: '#FFFFFF'
        },
        {
          tag: 'worship',
          color: '#ED8951',
          text: '#FFFFFF'
        },
      ],
      outerCircleGroups: [
        {
          'title': 'Off Shore',
          'index': 0,
          'slides': 3,
        },
        {
          'title': 'SME',
          'index': 3,
          'slides': 1,
        },
        {
          'title': 'MNCS',
          'index': 4,
          'slides': 1,
        },
        {
          'title': 'Educational Institutions',
          'index': 5,
          'slides': 1,
        },
        {
          'title': 'Border Control',
          'index': 6,
          'slides': 2,
        },
        {
          'title': 'Surge Management',
          'index': 8,
          'slides': 1,
        },
        {
          'title': 'Defence',
          'index': 9,
          'slides': 1,
        },
        {
          'title': 'First Responders',
          'index': 10,
          'slides': 4,
        },
        {
          'title': 'Mass Testing',
          'index': 14,
          'slides': 1,
        },

      ],
      innerCircle:
          [
            {
              tag: 'hospital-labs',
              color: '#EE7A31',
              text: '#FFFFFF'
            },
            {
              tag: 'hospital-ae',
              color: '#EE7A31',
              text: '#FFFFFF'
            },
            {
              tag: 'hospital-clincs',
              color: '#EE7A31',
              text: '#FFFFFF'
            },
            {
              tag: 'hospital-wards',
              color: '#EE7A31',
              text: '#FFFFFF'
            },
            {
              tag: 'gps-clinics',
              color: '#044A6D',
              text: '#FFFFFF'
            },
            {
              tag: 'retail-pharmacies',
              color: '#044A6D',
              text: '#FFFFFF'
            },
            {
              tag: 'care-homes',
              color: '#044A6D',
              text: '#FFFFFF'
            },
            {
              tag: 'inner-private',
              color: '#FFFFFF',
              text: '#044A6D'
            },
          ],
      innerCircleGroups: [
        {
          'title': 'Hospital',
          'index': 0,
          'slides': 3,
        },
        {
          'title': 'Community',
          'index': 3,
          'slides': 3,
        },
        {
          'title': 'Private',
          'index': 6,
          'slides': 1,
        },
      ],
    }

  },

  mounted() {
    this.currentTitle = this.outerCircleGroups[0].title;
  },
  methods: {
    onSlideChange() {
      let that = this;
      that.outerCircleGroups.forEach(function (item) {

        if (item.slides > 1) {
          if (that.outerCircleSwiper.activeIndex >= item.index && that.outerCircleSwiper.activeIndex <= (item.index + (item.slides - 1))) {
            that.currentTitleOuter = item.title;
          }
        } else {
          if (that.outerCircleSwiper.activeIndex === item.index) {
            that.currentTitleOuter = item.title;
          }
        }
      });
    },
    setOuterCircleSwiper(swiper) {

      this.outerCircleSwiper = swiper;
    },

    onSlideChangeInner() {
      let that = this;
      that.innerCircleGroups.forEach(function (item) {

        if (item.slides > 1) {
          if (that.innerCircleSwiper.activeIndex >= item.index && that.innerCircleSwiper.activeIndex <= (item.index + (item.slides - 1))) {
            that.currentTitleInner = item.title;
          }
        } else {
          if (that.innerCircleSwiper.activeIndex === item.index) {
            that.currentTitleInner = item.title;
          }
        }
      });
    },
    setInnerCircleSwiper(swiper) {
      this.innerCircleSwiper = swiper;
    },


    goToSectorOuter(swiper, event){
      let tag = event.target.closest('.element-swiper').getAttribute('data-item');
      this.$emit('sector', tag)
    },
    goToSectorInner(swiper, event){
      let tag = event.target.closest('.element-swiper').getAttribute('data-item');
      this.$emit('sector', tag)
    }
  }
}
</script>