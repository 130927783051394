<template>
    <div class="main-circle relative">

    <div class="w-full h-screen md:h-screen-80 relative">
      <!--
      <img src="http://placehold.it/1920x1080/A0EBF6/A0EBF6" class="w-full object-cover h-full relative z-10"/>
      -->
      <video class="w-full object-cover h-full relative z-10" data-object-fit autoplay muted loop playsinline
             preload="auto" width="1920" id="banner-video"
             poster="" src="/videos/backdrop-2.mp4">
        <source src="/videos/backdrop-2.mp4" type="video/mp4">
        Your browser does not support video.
      </video>

      <div class="absolute z-20 flex justify-center items-center top-0 left-0 w-full h-full">

        <div class="container">
          <div class="w-8/12 mx-auto text-white text-center">
            <h1 class="text-4xl lg:text-7xl mb-8 tracking-wide">Whatever your testing needs are, we've got you covered</h1>
            <p class="text-xl lg:text-2xl tracking-wide">Please click on your sector to explore the best COVID-19 solutions for you</p>
            <div class="flex justify-center">
              <a href="/e-book.pdf" target="_blank" class="mt-4 mr-4 bg-b-orange-mid hover:bg-b-orange-mid text-white font-bold py-2 px-4 text-center rounded cursor-pointer inline-block md:text-xl">Download our e-book now!</a>
              <div class="mr-4 mt-4 ">
                <contact-form> <template v-slot:button-text>Get your free sample today</template></contact-form>
              </div>
              <documentary></documentary>
             </div>

          </div>
        </div>
      </div>
      <Waypoint @change="changeLogo" class="absolute bottom-6"/>
    </div>

    <div class="lg:hidden">
      <outer-swipe @sector="goToSector" :data="datajson"></outer-swipe>
    </div>

    <div class="hidden lg:block w-screen relative mb-4 py-8">
      <kinesis-container class="main-container">
        <kinesis-element v-for="circle in circles" :strength="(circle * 2)" v-once :key="'circle-' + circle"
                         class="circle"
                         :style="generateStyle(circle)"></kinesis-element>
      </kinesis-container>
      <Wheelsvg @sector="goToSector" :data="datajson"></Wheelsvg>
    </div>

  </div>
</template>
<script>
import Wheelsvg from "../components/Wheels/wheelsvg";
import OuterSwipe from "../components/mobile/OuterSwipe";
import {Waypoint} from 'vue-waypoint'
import ContactForm from "../components/form";
import Documentary from "../components/documentary";

const axios = require('axios');

export default {
  name: 'wheelView',
  data() {
    return {
      circles: 50,
      infoBox: {'title': null, 'content': null},
      datajson: [],
      infoBoxOpen: false,
    }
  },
  mounted() {
    let that = this;
    this.$emit('logoChange', "bg-transparent-home");
    axios.get('/admin/wp-json/wp/v2/posts?per_page=50')
        .then(function (response) {
          that.datajson = response.data;
          that.datajson.forEach(function (page, index) {
            axios.get('/admin/wp-json/acf/v3/posts/' + page.id)
                .then(function (response) {
                  that.datajson[index].acf = response.data;
                })
          });
        });
  },
  methods: {
    generateStyle() {
      let style = {
        border: "10px solid #FFF",
        opacity: 0.1,
        width: "4vw",
        height: "4vw",
      };
      let borderSize = this.randomIntFromInterval(1, (5))
      let widthSize = this.randomIntFromInterval(100, (1000)) / 1000;

      style.border = borderSize + 'px solid #FFF';
      style.opacity = this.randomIntFromInterval(100, 1000) / 1000;
      style.width = widthSize + 'vw';
      style.height = widthSize + 'vw';
      style.left = this.randomIntFromInterval(1, 100) + '%';
      style.top = this.randomIntFromInterval(1, 100) + '%';
      return style;
    },
    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    goToSector(sector) {
      this.$router.push({name: 'sector.view', params: {'tag': sector}})
    },
    changeLogo(waypointState) {
      if (waypointState.going === 'IN') {
        this.$emit('logoChange', "bg-transparent-home");
      } else {
        this.$emit('logoChange', "bg-b-blue-darkest");
      }
    }
  },
  components: {
    Documentary,
    ContactForm,
    Wheelsvg,
    OuterSwipe,
    Waypoint
  }
}
</script>
<style>
.main-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.circle {
  position: absolute;
  border-radius: 100%;
  background: #FFF;
  transition: all 0.5s ease;
}

.st1 {
  fill: #ffffff;
}
</style>
