<template>
  <div id="google_translate_element"></div>
    <button
        class="text-xl md:text-3xl tracking-wide flex items-center mr-4"
        :style="'color:'+colour"
        type="button" v-on:click="toggleDropdown()" ref="btnDropdownRef">
      Language
    </button>
    <div v-bind:class="{'hidden': !dropdownPopoverShow, 'block': dropdownPopoverShow}"
         class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
         style="min-width:12rem" ref="popoverDropdownRef">
      <a href="#"
         @click.prevent="googleTranslate('en')"
         class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-blueGray-700">
        English
      </a>
      <a href="#"
         @click.prevent="googleTranslate('fr')"
         class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-blueGray-700">
        French
      </a>
      <a href="#"
         @click.prevent="googleTranslate('es')"
         class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-blueGray-700">
        Spanish
      </a>
      <a href="#"
         @click.prevent="googleTranslate('de')"
         class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-blueGray-700">
        German
      </a>
    </div>

</template>
<script>
import {createPopper} from "@popperjs/core";

export default {
  name: 'translate',
  props: ['colour'],
  data() {
    return {
      dropdownPopoverShow: false,
      googleTranslator: null
    }
  },
  methods: {
    toggleDropdown: function () {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    },
    changeEvent(el) {
      if (el.fireEvent) {
        el.fireEvent('onchange');
      } else {
        var evObj = document.createEvent("HTMLEvents");

        evObj.initEvent("change", false, true);
        el.dispatchEvent(evObj);
      }
    },
    googleTranslate: function (lang) {
      this.googleTranslator = document.getElementById("google_translate_element").querySelector(".goog-te-combo");

      if (this.googleTranslator) {
        this.googleTranslator.value = lang;
        this.changeEvent(this.googleTranslator);

      }
      this.toggleDropdown();
    }

  }

}
</script>

<style>
#google_translate_element {
  display: none;
}
</style>