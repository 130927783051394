<template>
    <div class="bg-b-orange-mid hover:bg-b-orange-mid text-white font-bold py-2 px-4 text-center rounded cursor-pointer inline-block md:text-xl" @click="open = true"><slot name="button-text"></slot></div>
    <transition name="modal-fade" v-if="open">
      <div class="fixed z-50 inset-0 overflow-y-auto">
        <div
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75" @click="open = false"></div>
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
          >&#8203;</span
          >
          <div
              class="mx-6 w-full mt-24 h-screen-80 overflow-scroll inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
          >
            <div class="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                  <div class="flex justify-between">
                    <h3
                        class="text-lg md:text-3xl leading-6 font-medium text-b-blue-darkest mb-2"
                        id="modal-headline"
                    >
                      Contact us to discuss your free sample today!
                    </h3>
                    <svg class="svg-icon cursor-pointer" viewBox="0 0 20 20" @click="open = false">
                      <path fill="none" d="M12.71,7.291c-0.15-0.15-0.393-0.15-0.542,0L10,9.458L7.833,7.291c-0.15-0.15-0.392-0.15-0.542,0c-0.149,0.149-0.149,0.392,0,0.541L9.458,10l-2.168,2.167c-0.149,0.15-0.149,0.393,0,0.542c0.15,0.149,0.392,0.149,0.542,0L10,10.542l2.168,2.167c0.149,0.149,0.392,0.149,0.542,0c0.148-0.149,0.148-0.392,0-0.542L10.542,10l2.168-2.168C12.858,7.683,12.858,7.44,12.71,7.291z M10,1.188c-4.867,0-8.812,3.946-8.812,8.812c0,4.867,3.945,8.812,8.812,8.812s8.812-3.945,8.812-8.812C18.812,5.133,14.867,1.188,10,1.188z M10,18.046c-4.444,0-8.046-3.603-8.046-8.046c0-4.444,3.603-8.046,8.046-8.046c4.443,0,8.046,3.602,8.046,8.046C18.046,14.443,14.443,18.046,10,18.046z"></path>
                    </svg>
                  </div>

                  <div class="mt-2">
                    <contact-form-element @complete="formSent"></contact-form-element>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>

import ContactFormElement from "./contactFormElement";
export default {
  name: "contactForm",
  components: {ContactFormElement},
  data() {
    return {
      open:false,
    }

  },
  methods:{
    formSent(){
      this.open = false;
      this.$router.push('/success');
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1.5em;
  height: 1.5em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #004B6B;
}

.svg-icon circle {
  stroke: #004B6B;
  stroke-width: 1;
}
</style>