<template>
  <div class="main-circle relative z-10">
    <div class="w-full h-screen relative">
      <div class="absolute z-20 flex flex-wrap justify-center items-center content-center top-0 left-0 w-full h-full">
        <div class="w-full text-center">
          <h1 class="text-5xl lg:text-6xl text-b-orange-mid tracking-wide">Your message has been sent</h1>
          <p class="mt-2">We will contact you shortly about your free sample</p>
          <a href="#" class="mt-4 w-48 bg-white text-b-blue-darkest p-2 inline-block rounded-lg text-center tracking-wide"
             @click="goBack">Return to homepage</a>
        </div>
      </div>
      <kinesis-container class="main-container z-10">
        <kinesis-element v-for="circle in circles" :strength="(circle * 2)" v-once :key="'circle-' + circle"
                         class="circle"
                         :style="generateStyle(circle)"></kinesis-element>
      </kinesis-container>
    </div>
  </div>
</template>

<script>

export default {
  name: "SectorDisplay",
  data() {
    return {
      circles: 50,
      sectorPage: {title: '', content: '', tag: '', acf: []},
    }
  },
  mounted() {
    this.$emit('logoChange', "bg-white");
  },
  methods: {
    goBack() {
      this.$router.push('/')
    },
    generateStyle() {
      let style = {
        border: "10px solid #FFF",
        opacity: 0.1,
        width: "4vw",
        height: "4vw",
      };
      let borderSize = this.randomIntFromInterval(1, (5))
      let widthSize = this.randomIntFromInterval(100, (1000)) / 1000;

      style.border = borderSize + 'px solid #FFF';
      style.opacity = this.randomIntFromInterval(100, 1000) / 1000;
      style.width = widthSize + 'vw';
      style.height = widthSize + 'vw';
      style.left = this.randomIntFromInterval(1, 100) + '%';
      style.top = this.randomIntFromInterval(1, 100) + '%';

      return style;
    },
    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
  }
}
</script>
<style scoped>

</style>